<template>
  <overall-settings v-bind="$props">
    <div class="c-mcn" v-if="fields">
      <div class="c-mcn__content">
        <jss-rich-text :field="fields.title" tag="div" class="c-mcn__mainTitle" />
        <AdaptiveImage v-if="fields.image" class="c-mcn__mainImage" :field="fields.image" :isBackground="false" />
        <section
          class="c-mcn__slideIconContent"
          v-if="
            fields.slide_list &&
              (($deviceComputes.isMobileDevice.value && fields.slide_list.length > 1) ||
                ($deviceComputes.isTabletDevice.value && fields.slide_list.length > 2) ||
                ($deviceComputes.largeThanTablet.value && fields.slide_list.length > fields?.countPerView?.fields?.phrase?.value))
          "
        >
          <Icon :field="fields.slide_icon_left" :class="{ disable: page_activeIndex === 0 }" @click="ms_slide($event, 'prev')" />
          <Icon
            :field="fields.slide_icon_right"
            :class="{
              disable:
                ($deviceComputes.isMobileDevice.value && page_activeIndex === fields.slide_list.length - 1) ||
                ($deviceComputes.isTabletDevice.value && page_activeIndex === fields.slide_list.length - 2) ||
                ($deviceComputes.largeThanTablet.value && page_activeIndex === fields.slide_list.length - fields?.countPerView?.fields?.phrase?.value)
            }"
            @click="ms_slide($event, 'next')"
          />
        </section>

        <section class="c-mcn__slideContent" ref="swiperEl" v-if="fields.slide_list && fields.slide_list.length > 0">
          <div class="swiper-wrapper" ref="swiperWrapperEl">
            <div class="swiper-slide" v-for="(item, index) in page_slide_list" :key="item.id">
              <section class="cmsss-slide__square">
                <div class="cmsss-slide__top">
                  <jss-rich-text :field="item.fields.title" tag="div" class="cmsss-slide__title" />
                  <jss-rich-text :field="item.fields.price" tag="div" class="cmsss-slide__price" />
                </div>

                <div class="cmsss-slide__bottom">
                  <div class="cmsss-slide__remarks">
                    <jss-rich-text :field="item.fields.remarks" tag="div" :id="'remarksContentListOut' + index" />
                  </div>
                  <div class="performance-content">
                    <div class="performance-content__top" @mouseenter="iconRemarksHover($event, index)" @mouseleave="iconRemarksOut($event, index)">
                      <div class="performance-content__top__content" @click="iconRemarksClick($event, index)">
                        <jss-rich-text class="title" :field="item.fields.performanceTitle" tag="div" />
                        <Icon :field="fields.remark_icon" />
                      </div>
                      <section
                        class="performance-content__top__dialog"
                        :class="{ last: page_slide_list.length == index + 1 }"
                        v-show="item.iconRemarksShow"
                        v-if="!$isNullOrEmpty(item.fields.performanceDisclaimer?.value)"
                      >
                        <div @click="iconRemarksHide($event)" class="performance-content__top__dialog__close">
                          <Icon name="close" />
                        </div>
                        <jss-rich-text class="performance-content__top__tips" :field="item.fields.performanceDisclaimer" tag="div" />
                      </section>
                    </div>
                    <div class="performance-content__bottom">
                      <jss-rich-text :field="item.fields.performanceLeft" tag="div" />
                      <jss-rich-text :field="item.fields.performanceRight" tag="div" />
                    </div>
                  </div>
                  <div class="cmsss-slide__remarksList" v-for="(item2, index2) in item.fields.remarksList" :class="{ active: index2 === 0, tran: item2.empty }" :key="item2.id">
                    <div class="cmsss-slide__remarksList__title" @click="titleClick(index, index2)">
                      <jss-rich-text class="title" :field="item2?.fields?.title" tag="div" />
                      <Icon :field="fields.slide_open_icon" :class="item2?.fields?.title?.value ? '' : 'tran'" v-if="!item2.selected" />
                      <Icon :field="fields.slide_close_icon" :class="item2?.fields?.title?.value ? '' : 'tran'" v-if="item2.selected" />
                    </div>
                    <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
                      <div class="cmsss-slide__remarksList__remarks" :class="'cmsss-slide__remarksList__remarks_' + index2" v-if="item2?.selected" :data-id="index2">
                        <div class="remarks-item" v-for="item3 in item2?.fields?.remarks" :key="item3.id">
                          <jss-rich-text :field="item3?.fields?.remarks" tag="div" />
                        </div>
                      </div>
                    </transition>
                  </div>
                  <SiteButton v-bind="item.fields.button" />
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { inject, reactive, toRefs, onMounted, onUnmounted, onBeforeMount, nextTick, onBeforeUnmount } from 'vue';
import Swiper from 'swiper';
import { isMobile, isTablet, largeThanTablet, canUseDOM } from '@/utils/dom-utils';
import gsap from 'gsap';
import { onWidthChanged } from '@/hooks/onWidthChanged';
import { debounce } from 'lodash';

/**
 * @typedef ModelComparisionNewFields
 * @property {GlobalSettingEntry} theme
 * */
export default {
  name: 'ModelComparisionNew',
  props: {
    /**@type ModelComparisionNewFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    let swiper = null;
    const state = reactive({
      swiperEl: null,
      swiperWrapperEl: null,
      page_slide_list: [],
      page_activeIndex: 0,
      page_slide_st: 0,
      slideCc: [],
      startX: 0,
      allEls: []
    });

    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      let slidesPerView = null;
      if (isMobile()) {
        slidesPerView = 1.005;
      } else if (isTablet()) {
        slidesPerView = 2.005;
      } else {
        slidesPerView = Number(props?.fields?.countPerView?.fields?.phrase?.value) + 0.005;
      }

      swiper = new Swiper(state.swiperEl, {
        slidesPerView: slidesPerView,
        spaceBetween: !largeThanTablet() ? 8 : 24,
        threshold: 50,
        maxBackfaceHiddenSlides: 2,
        on: {
          slideChange: (swiper) => {
            state.page_activeIndex = swiper.activeIndex;
          }
        }
      });
    };

    const methods = {
      isMobile,
      isTablet,
      largeThanTablet,

      iconRemarksHover(event, index) {
        if (largeThanTablet()) {
          event.stopPropagation();
          state.page_slide_list.forEach((val, e) => {
            if (e == index) {
              val.iconRemarksShow = true;
            } else {
              val.iconRemarksShow = false;
            }
          });
        }
      },

      iconRemarksOut(event, index) {
        if (largeThanTablet()) {
          event.stopPropagation();
          state.page_slide_list.forEach((val, e) => {
            if (e == index) {
              val.iconRemarksShow = false;
            }
          });
        }
      },

      iconRemarksClick(event, index) {
        if (!largeThanTablet()) {
          event.stopPropagation();
          state.page_slide_list.forEach((val, e) => {
            if (e == index) {
              val.iconRemarksShow = true;
            } else {
              val.iconRemarksShow = false;
            }
          });
        }
      },

      iconRemarksHide(event) {
        state.page_slide_list.forEach((val) => {
          val.iconRemarksShow = false;
        });
      },

      titleClick(index, index2) {
        if (state.page_slide_list[index].fields.remarksList[index2].empty) {
          return false;
        }
        state.page_slide_list.forEach((val, e) => {
          val.fields.remarksList.forEach((val, e) => {
            if (e === index2) {
              val.selected = !val.selected;
            }
          });
        });
      },

      ms_slide(e, type) {
        e.stopPropagation();

        if (state.page_slide_st == 0) {
          state.page_slide_st = 1;

          if (type === 'prev') {
            swiper.slidePrev();
          } else {
            swiper.slideNext();
          }
        }

        setTimeout(() => {
          state.page_slide_st = 0;
        }, 300);
      },

      onItemBeforeEnter(el) {
        el.style.height = 0;
        el.style.paddingBottom = 0;
      },
      onItemTransEnter(el, done) {
        let allEls = document.querySelectorAll('.cmsss-slide__remarksList__remarks_' + el.dataset.id);
        let maxHeight = 0;
        allEls.forEach((item) => {
          if (item.scrollHeight > maxHeight) {
            maxHeight = item.scrollHeight;
          }
        });

        gsap.to(el, {
          height: maxHeight + 20 + 8,
          paddingBottom: 20,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          paddingBottom: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      }
    };

    onBeforeMount(() => {
      if (props.fields?.slide_list.length > 0) {
        props.fields.slide_list.forEach((val, e) => {
          state.slideCc.push({ height: '', list: [] });

          if (val.fields.remarksList && val.fields.remarksList.length > 0) {
            val.fields.remarksList.forEach((val2, e2) => {
              state.slideCc[e].list.push({ height: '' });
            });
          }
        });
      }
    });
    onWidthChanged({
      callback: debounce(() => {
        buildSwiper();
      }, 50)
    });
    onMounted(() => {
      state.page_slide_list = props.fields?.slide_list ?? [];
      let maxLength = 0;
      state.page_slide_list.map((item) => {
        if (item.fields?.remarksList.length > maxLength) {
          maxLength = item.fields.remarksList.length;
        }
      });
      state.page_slide_list.map((item) => {
        let maxI = maxLength - item.fields.remarksList.length;
        if (maxI > 0) {
          for (let i = 0; i < maxI; i++) {
            let Item = {
              empty: true
            };
            item.fields.remarksList.push(Item);
          }
        }
      });
      nextTick(() => {
        buildSwiper();
        let remarksEls = document.querySelectorAll('.cmsss-slide__remarks');
        let remarksHeight = 0;
        remarksEls.forEach((item) => {
          if (item.scrollHeight > remarksHeight) {
            remarksHeight = item.scrollHeight;
          }
        });
        remarksEls.forEach((item) => {
          item.style.height = remarksHeight + 'px';
        });

        let titleEls = document.querySelectorAll('.cmsss-slide__title');
        let titleHeight = 0;
        titleEls.forEach((item) => {
          if (item.scrollHeight > titleHeight) {
            titleHeight = item.scrollHeight;
          }
        });
        titleEls.forEach((item) => {
          item.style.height = titleHeight + 'px';
        });

        let priceEls = document.querySelectorAll('.cmsss-slide__price');
        let priceHeight = 0;
        priceEls.forEach((item) => {
          if (item.scrollHeight > priceHeight) {
            priceHeight = item.scrollHeight;
          }
        });
        priceEls.forEach((item) => {
          item.style.height = priceHeight + 'px';
        });

        let botEls = document.querySelectorAll('.performance-content__bottom');
        let botHeight = 0;
        botEls.forEach((item) => {
          if (item.scrollHeight > botHeight) {
            botHeight = item.scrollHeight;
          }
        });
        botEls.forEach((item) => {
          item.style.height = botHeight + 'px';
        });
      });
      if (canUseDOM()) {
        document.addEventListener('click', methods.iconRemarksHide, true);
      }
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', methods.iconRemarksHide, true);
    });

    onUnmounted(() => {
      if (swiper) {
        swiper.destroy(true, true);
      }
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-mcn {
  width: 100%;
  @include grid-container;
  overflow: hidden;
  user-select: none;
}
.c-mcn__content {
  width: 100%;
  @include grid-block(2, 10);
}
.c-mcn__mainTitle {
  width: 100%;
  font-size: 32px;
  padding-bottom: 48px;
  line-height: 1.1;
  font-weight: normal;
}
.c-mcn__mainImage {
  img {
    width: 100%;
    height: auto;
  }
}
.c-mcn__slideIconContent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding-top: 16px;

  .e-icon {
    color: #000;
    cursor: pointer;
    width: 24px;
    height: 24px;

    &.disable {
      opacity: 0.5;
    }

    > svg {
      width: 24px;
      height: 24px;
    }
  }
}
.c-mcn__slideContent {
  padding-top: 24px;

  .cmsss-slide__square {
    width: 100%;
    border: 1px solid #e3e3e3;
    // overflow: hidden;

    .e-site-button {
      cursor: pointer;
      font-weight: bold;
      margin: 15px 0 15px 24px;
      &.primary-button,
      &.secondary-button,
      &.primary-link.bordered,
      &.icon-link.bordered {
        max-width: calc(100% - 48px);
      }
    }
  }
  .cmsss-slide__top {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 24px 16px 24px;
    justify-content: space-between;

    .cmsss-slide__title {
      font-size: 26px;
      line-height: 1;
    }
    .cmsss-slide__price {
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  .cmsss-slide__remarks {
    width: 100%;
    padding: 0 24px 0px 24px;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
  }
  .performance-content {
    padding: 16px 24px 0px 24px;

    &__top {
      display: flex;
      align-items: center;
      position: relative;

      &__content {
        display: flex;
        align-items: center;
      }

      .title {
        text-transform: uppercase;
        color: #000000;
        font-size: 12px;
        line-height: 20px;
      }

      .e-icon {
        width: 14px;
        height: 14px;
        margin-left: 5px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__dialog {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 20px;
        background: #f7f6f6;
        color: #000;
        padding: 40px;
        z-index: 2;

        &__close {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &__bottom {
      display: flex;
      margin-top: 8px;
      justify-content: space-between;
      .e-rich-text {
        p:nth-child(1) {
          font-size: 26px;
          line-height: 32px;
          color: $black;
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 20px;
          color: #757575;
        }
        p:nth-child(3) {
          font-size: 26px;
          line-height: 32px;
          color: $black;
          margin-top: 8px;
        }
        p:nth-child(4) {
          font-size: 12px;
          line-height: 20px;
          color: #757575;
        }
      }
    }
  }
  .cmsss-slide__remarksList {
    max-width: 100%;
    margin: 0 24px;
    border-bottom: 1px solid #e3e3e3;
    transition: padding 0.2s linear 0s;

    &:last-child {
      border: 0;
    }

    &.active {
      border-top: 1px solid #e3e3e3;
      margin-top: 32px;
    }

    &.tran {
      border-color: transparent;
    }
  }

  .cmsss-slide__remarksList__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    font-size: 16px;
    cursor: pointer;

    .title {
      // text-transform: uppercase;
    }

    .e-icon {
      color: #000;

      &.tran {
        color: transparent;
      }

      > svg {
        width: auto;
        height: 20px;
      }
    }
  }
  .cmsss-slide__remarksList__remarks {
    display: flex;
    width: 100%;
    line-height: 1.5;
    overflow: hidden;

    .remarks-item {
      flex: 1;
      padding-right: 10px;
      color: #727272;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

@include tablet-landscape {
  .c-mcn__content {
    @include grid-block(3, 20);
  }

  .c-mcn__mainTitle {
    font-size: 56px;
    padding-bottom: 0;
    line-height: 1.1;
  }

  .c-mcn__slideIconContent {
    padding-top: 0px;
    gap: 24px;

    .e-icon {
      > svg {
        height: 24px;
      }
    }
  }

  .c-mcn__slideContent {
    padding-top: 34px;

    .cmsss-slide__remarksList__remarks {
      .remarks-item {
        font-size: 12px;
      }
    }

    .cmsss-slide__square {
      .e-site-button {
        margin: 15px 0 15px 40px;
        &.primary-button,
        &.secondary-button,
        &.primary-link.bordered,
        &.icon-link.bordered {
          width: calc(100% - 80px);
        }
      }
    }

    .cmsss-slide__top {
      padding: 40px 40px 16px 40px;

      .cmsss-slide__title {
        font-size: 32px;
      }
      .cmsss-slide__price {
        font-size: 16px;
      }
    }

    .cmsss-slide__remarks {
      padding: 0px 40px 0px 40px;
    }

    .performance-content {
      padding: 16px 40px 0px 40px;

      &__top {
        &__dialog {
          width: 150%;
          &__close {
            display: none;
          }
          &.last {
            left: unset;
            right: 0;
          }
        }
        .e-icon {
          position: relative;
          top: -0.5px;
        }
      }
    }

    .cmsss-slide__remarksList {
      margin: 0 40px;

      .cmsss-slide__remarksList__title {
        font-size: 16px;
        padding: 16px 0 20px 0;

        .e-icon {
          > svg {
            width: auto;
            height: 24px;
          }
        }
      }
    }
  }
}
html.rtl {
  .c-mcn__slideIconContent {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}

@include mobile {
  .cmsss-slide__bottom {
    .e-site-button__icon {
      display: block;
    }
  }
}
@include desktop-normal {
  .c-mcn__slideContent {
    .performance-content {
      &__top {
        .e-icon {
          top: 0px;
        }
      }
    }
  }
}
</style>
