<template>
  <DashboardLayout :fields="fields?.dashboardHeader?.fields" active-menu="order-details">
    <div class="c-merchandise-service-plan-order-details" v-if="fields">
      <div class="c-merchandise-service-plan-order-details__top">
        <RouterLink class="c-merchandise-service-plan-order-details__back" :to="`/${lang}${fields.orderListLink?.value?.href}`">
          <Icon class="c-merchandise-service-plan-order-details__back-icon" name="left" />
          <JssRichText class="c-merchandise-service-plan-order-details__back-text" :field="fields.goBackText" />
        </RouterLink>
        <div class="c-merchandise-service-plan-order-details__name">{{ productItems[0]?.name }}</div>
        <div class="c-merchandise-service-plan-order-details__orderNo" v-if="orderDetails?.orderCode">
          <div class="c-merchandise-service-plan-order-details__orderNo-title" v-html="$tu('Order No')" />
          <div class="c-merchandise-service-plan-order-details__orderNo-desc">{{ orderDetails.orderCode }}</div>
        </div>
        <div class="c-merchandise-service-plan-order-details__info">
          <img class="c-merchandise-service-plan-order-details__info__rt" :src="productItems[0]?.image" :alt="productItems[0]?.name" />
          <div class="c-merchandise-service-plan-order-details__info__lt">
            <div class="c-merchandise-service-plan-order-details__info__lt__item">
              <div class="c-merchandise-service-plan-order-details__info__lt__item-label" v-html="$tu('Status')"></div>
              <div class="c-merchandise-service-plan-order-details__info__lt__item-value">{{ status?.name }}</div>
            </div>
            <div class="c-merchandise-service-plan-order-details__info__lt__item">
              <div class="c-merchandise-service-plan-order-details__info__lt__item-label" v-html="$tu('Order date')"></div>
              <div class="c-merchandise-service-plan-order-details__info__lt__item-value">{{ orderDetails?.dateCreate ? $formatDate(orderDetails.dateCreate, 'MM-dd-yyyy') : '' }}</div>
            </div>
            <JssRichText class="c-merchandise-service-plan-order-details__info__lt__item" :field="carBaseInfo?.fields?.description" />
          </div>
        </div>
      </div>
      <div class="c-merchandise-service-plan-order-details__bottom">
        <div class="c-merchandise-service-plan-order-details__bottom-orderDetails">
          <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-title" v-html="$tu('Order details')" />
          <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content">
            <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-number" v-if="orderDetails?.orderCode">
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-number-label" v-html="$tu('Order number')"></div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-number-value">{{ orderDetails.orderCode }}</div>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-date" v-if="orderDetails?.dateCreate">
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-date-label" v-html="$tu('Order date')"></div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-date-value">
                {{ orderDetails?.dateCreate ? $formatDate(orderDetails.dateCreate, 'MM-dd-yyyy') : '' }}
              </div>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-receiverAddress" v-if="orderDetails?.carModel || orderDetails?.vin">
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-receiverAddress-label" v-html="$tu('Vehicle')"></div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-receiverAddress-value">
                <p>{{ orderDetails?.carModel }}</p>
                <p>{{ orderDetails?.vin }}</p>
              </div>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-billingAddress" v-if="orderDetails?.billingAddressDTO">
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-billingAddress-label" v-html="$tu('Billing address')"></div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-billingAddress-value">
                <p>{{ orderDetails?.billingAddressDTO?.detailInfo }}</p>
                <p>{{ orderDetails?.billingAddressDTO?.provinceName }}</p>
                <p>{{ orderDetails?.billingAddressDTO?.telNumber }}</p>
              </div>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-emailAddress" v-if="orderDetails?.email">
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-emailAddress-label" v-html="$tu('Email address')"></div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-emailAddress-value">{{ orderDetails.email }}</div>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-phoneNumber" v-if="orderDetails?.receiverPhone">
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-phoneNumber-label" v-html="$tu('Phone number')"></div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-phoneNumber-value">+{{ orderDetails.areaCode }} {{ orderDetails.receiverPhone }}</div>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-invoice" v-if="invoiceDetail">
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-invoice-label" v-html="$tu('Invoice')"></div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-invoice-value" v-if="invoiceDetail?.invoiceNo">{{ $t('Invoice') }} {{ invoiceDetail.invoiceNo }}</div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-invoice-value" v-if="invoiceDetail?.invoiceMoney">
                {{ invoiceDetail.invoiceMoney ? $formatShopMoney(invoiceDetail.invoiceMoney, currency) : '' }}
              </div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-invoice-value" v-if="invoiceDetail?.createTime">
                {{ $formatDate(invoiceDetail.createTime, 'MM-dd-yyyy') }}
              </div>
              <div class="c-merchandise-service-plan-order-details__bottom-orderDetails-content-invoice-file" v-if="invoiceDetail?.invoiceUrl">
                <a :href="invoiceDetail?.invoiceUrl" target="_blank"> <Icon name="invoice-download" /> {{ $t('Download invoice') }} </a>
              </div>
            </div>
          </div>
        </div>
        <div class="c-merchandise-service-plan-order-details__bottom-orderSummary">
          <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-title" v-html="$tu('Order summary')"></div>
          <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content">
            <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-orderList" v-if="orderDetails?.items && orderDetails?.items?.length > 0">
              <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-orderList-item" v-for="(product, index) in productItems" :key="index">
                <BackgroundImage
                  v-if="product?.image"
                  class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-orderList-item-image"
                  :image="{
                    value: {
                      src: product.image
                    }
                  }"
                />
                <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-orderList-item-info">
                  <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-orderList-item-info-name" v-if="product?.name">{{ product.name }}</div>
                </div>
              </div>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-price">
              <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-price-subtotal">
                <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-price-subtotal-label" v-html="$tu('Total')"></div>
                <div class="c-merchandise-service-plan-order-details__bottom-orderSummary-content-price-subtotal-value">{{ subTotal ? $formatShopMoney(subTotal, currency) : '' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-merchandise-service-plan-order-details__bottom-faq" v-if="fields.faqList?.length">
          <div class="c-merchandise-service-plan-order-details__bottom-faq-title" v-html="$tu('FAQS')" />
          <div class="c-merchandise-service-plan-order-details__bottom-faq-content">
            <div class="c-merchandise-service-plan-order-details__bottom-faq-content-item" v-for="(item, index) in fields.faqList?.fields?.list" :key="index">
              <div class="c-merchandise-service-plan-order-details__bottom-faq-content-item-question" @click="questionClick(index)">
                <jss-rich-text :field="item.fields.question" tag="div" class="c-merchandise-service-plan-order-details__bottom-faq-content-item-question-item" />
                <Icon name="down" size="tiny" :class="item.selected ? 'open' : ''" />
              </div>
              <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
                <jss-rich-text :field="item.fields.answer" tag="div" class="c-merchandise-service-plan-order-details__bottom-faq-content-item-answer" v-if="item.selected" />
              </transition>
            </div>
            <div class="c-merchandise-service-plan-order-details__bottom-faq-content-contact" v-html="fields.faqLinkText?.value" />
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>
<script>
import { reactive, toRefs, inject, onMounted, computed, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getGlobalConfigs, encodeData, getGlobalCfgValues, scrollToTop, getCurrentAlpha2Code } from '@/utils/site-utils';
import { redirectTo, appendQuery, getBetterUrl, redirectToLang } from '@/utils/uri-utils';
import services from '@/services';
import Swiper from 'swiper';
import gsap from 'gsap';
import api from '@/api';
export default {
  name: 'MerchandiseServicePlanOrderDetails',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    let swiper = null;
    let stepSwiper = null;
    const route = useRoute();
    const toast = inject('toast');
    const appMethods = inject('appMethods');
    const router = useRouter();
    const { orderCode } = route?.query || {};
    if (!props.fields) return;
    const [shopCheckoutLifeLink] = getGlobalConfigs(props.page, 'shopCheckoutLifeLink');
    const [shopReturnLink] = getGlobalConfigs(props.page, 'shopReturnLink');
    let shopCheckoutLifeLinkHref = shopCheckoutLifeLink?.value?.href;
    let shopReturnLinkHref = shopReturnLink?.value?.href;
    const state = reactive({
      orderDetails: {},
      subTotal: 0,
      total: 0,
      currency: 'EUR',
      invoiceDetail: null,
      orderCodeNo: null,
      part: false,
      confirmEl: null,
      productItems: [],
      totleNumber: 0,
      status: null,
      storeLatitude: null,
      storeLongitude: null,
      storeDetail: null,
      helpLinks: [],
      carBaseInfo: {}
    });

    const computes = {
      lang: computed(() => router.currentRoute.value?.params.lang ?? {})
    };

    const methods = {
      getOrderDetails: async () => {
        const [res, ex] = await api.shop.orderDetails(null, {
          orderId: orderCode
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res) {
          return false;
        }
        state.carBaseInfo = props.fields.carInfo.find((x) => res.carModel?.toLowerCase()?.indexOf(x.fields?.code?.value?.toLowerCase()) !== 0);
        const [currencyVal] = getGlobalCfgValues(props.page, ['currency']);
        state.currency = res.currency ? res.currency : currencyVal;
        state.subTotal = 0;
        res.items.forEach((item) => {
          state.subTotal += item.price * item.num;
        });
        state.total = Number(state.subTotal) + Number(res.expressFee);
        state.orderDetails = res;
        props?.fields?.helpLinks?.forEach((item) => {
          if (item?.fields?.code?.value === 'hotline') {
            let helpItem = {
              ...item,
              href: 'tel:' + res.storeAddressDTO?.serviceCall,
              url: 'http://tel:' + res.storeAddressDTO?.serviceCall
            };
            state.helpLinks.push(helpItem);
          } else {
            state.helpLinks.push(item);
          }
        });
        if (state.orderDetails?.items?.length) {
          state.orderDetails?.items.forEach((item) => {
            item.spec = item.spec.replace(/\|/g, ',');
          });
        }
        state.productItems = res?.items;
        const [needRedirect, targetLang] = services.order.checkOrderLang(props.page, res?.country);
        if (needRedirect) {
          await state.orderLocaleRedirectModalRef.open();
          redirectToLang(targetLang);
        }
        const orderStatuses = await services.order.getServicePlanOrderStatuses();
        if (res.status == '51031003') {
          orderStatuses.forEach((item) => {
            if (item.code == '51031003' && item.stockCode == res.stockStatus) {
              state.status = item;
            } else if (item.code == '51031003' && res.stockStatus === 51041002 && item.stockCode == '51041003') {
              state.status = item;
            }
          });
        } else if (res.status == '51031006' && res.closeReason) {
          orderStatuses.forEach((item) => {
            if (item.code == '51031006' && item.stockCode == res.closeReason) {
              state.status = item;
            } else if (item.code == '51031006' && !item.stockCode) {
              state.status = item;
            }
          });
        } else {
          state.status = orderStatuses.find((x) => x.code == res.status);
        }
      },
      getInvoiceDetails: async () => {
        const [res, ex] = await api.shop.invoiceDetails(null, {
          orderId: orderCode
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (res) {
          state.invoiceDetail = res;
        }
      },
      questionClick(_index) {
        let faqList = props?.fields?.faqList;
        faqList?.fields?.list?.map((item, index) => {
          if (index === _index) {
            item.selected = !item.selected;
          }
        });
      },
      onItemBeforeEnter(el) {
        el.style.height = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 10,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      }
    };

    onMounted(async () => {
      scrollToTop();
      if (!orderCode) {
        const [orderListLink] = getGlobalConfigs(props.page, 'orderListLink');
        redirectTo({ url: orderListLink?.value?.href, router });
      } else {
        state.orderCodeNo = orderCode;
        methods.getOrderDetails();
      }
      methods.getInvoiceDetails();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-merchandise-service-plan-order-details {
  width: 100%;
  overflow: hidden;
  &__top {
    padding: 26px 0 0;
    margin: 0 grid-width(2);
  }
  &__name {
    font-size: 32px;
    line-height: 32px;
    color: $black;
    margin-top: 24px;
    text-transform: uppercase;
  }
  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    &-icon {
      width: 12px !important;
      height: 12px !important;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #4f4f4f;
      margin-left: 8px;
    }
  }
  &__orderNo {
    margin-top: 16px;
    display: flex;
    font-size: 12px;
    line-height: 20px;
    &-title {
      color: #757575;
    }
    &-desc {
      color: #262626;
      margin-left: 5px;
    }
  }
  &__info {
    width: 100%;
    margin-top: 24px;
    &__lt {
      display: flex;
      flex-direction: column;
      gap: 24px;
      &__item {
        &-label {
          font-size: 14px;
          line-height: 24px;
          color: #757575;
        }
        &-value {
          margin-top: 8px;
          font-size: 26px;
          line-height: 28px;
          color: #2f2f2f;
        }
        &-link {
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
          .e-site-link {
            display: flex;
            align-items: center;
            gap: 8px;
            .e-icon {
              width: 24px !important;
              height: 24px !important;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
          .direction {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 8px;
            .e-icon {
              width: 24px !important;
              height: 24px !important;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
          &-text {
            font-size: 16px;
            line-height: 24px;
            color: $black;
            text-decoration: underline;
          }
        }
      }
    }
    &__rt {
      width: 100%;
      height: auto;
      margin-bottom: 24px;
      object-fit: cover;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    position: relative;
    .swiper-wrapper {
      .swiper-slide {
        width: calc(50% - 4px);
        height: calc((grid-width(10) - 8px) * 1.21);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &-pagination {
      display: none;
    }
  }
  &__center {
    padding: 0 grid-width(2);
    margin-top: 48px;
    position: relative;
    &-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin-bottom: 16px;
      &-prev {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.swiper-button-disabled {
          svg,
          path {
            fill: rgba(0, 0, 0, 0.5);
          }
        }
        .e-icon {
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-next {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.swiper-button-disabled {
          svg,
          path {
            fill: rgba(0, 0, 0, 0.5);
          }
        }
        .e-icon {
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .swiper-wrapper {
      width: 100%;
      .swiper-slide {
        width: 100%;
        &.active {
          .c-merchandise-service-plan-order-details__center__line {
            width: 0;
          }
        }
      }
    }
    &__status {
      font-size: 12px;
      line-height: 20px;
      color: #757575;
      visibility: hidden;
      &.active {
        visibility: visible;
      }
    }
    &__title {
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      margin-top: 8px;
    }
    &__line {
      margin-top: 32px;
      position: relative;
      width: 100%;
      height: 1px;
      background: #b8b8b8;
      &.active {
        background: $black;
      }
      &__default {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        border: 1px solid #b8b8b8;
        background: $white;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
      }
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
      }
      .e-icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
        background: $white;
      }
    }
    &__description {
      font-size: 12px;
      line-height: 20px;
      color: #757575;
      margin-top: 24px;
      padding-right: 48px;
      &.active {
        color: $black;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      margin-top: 24px;
      color: #757575;
      cursor: pointer;
      &.active {
        color: $black;
      }
      &__desc {
        font-size: 14px;
        line-height: 20px;
      }
      &__id {
        font-size: 14px;
        line-height: 20px;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &__buttons {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  &__bottom {
    margin-top: 60px;
    padding: 96px grid-width(2) 64px;
    background: $black;
    &-orderDetails {
      display: flex;
      flex-direction: column;
      padding-bottom: 64px;
      border-bottom: 1px solid #2f2f2f;
      &-title {
        font-size: 26px;
        line-height: 26px;
        color: $white;
      }
      &-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        &-number {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-date {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-receiverAddress {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
            padding-right: 24px;
          }
        }
        &-billingAddress {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-emailAddress {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-phoneNumber {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-invoice {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
          &-file {
            margin-top: 5px;
            a {
              display: flex;
              align-items: center;
              color: #b8b8b8;
              .e-icon {
                color: #fff200;
              }
            }
          }
        }
        &-payMethods {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-button {
          margin-top: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    &-orderSummary {
      display: flex;
      flex-direction: column;
      padding-top: 64px;
      padding-bottom: 64px;
      border-bottom: 1px solid #2f2f2f;
      &-title {
        font-size: 26px;
        line-height: 26px;
        color: $white;
      }
      &-content {
        margin-top: 24px;
        &-orderList {
          display: flex;
          flex-direction: column;
          gap: 24px;
          &-item {
            display: flex;
            gap: 16px;
            &-image {
              width: 80px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              .e-background-image__img {
                width: 80px !important;
                height: 100px !important;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            &-info {
              display: flex;
              flex-direction: column;
              color: $white;
              &-name {
                font-size: 12px;
                line-height: 20px;
                font-weight: 700;
              }
              &-spec {
                font-size: 12px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.6);
              }
              &-num {
                display: flex;
                font-size: 12px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.6);
                gap: 5px;
              }
              &-return {
                font-size: 12px;
                line-height: 20px;
                color: #fff100;
              }
              &-price {
                font-size: 12px;
                line-height: 20px;
                flex: 1;
                display: flex;
                align-items: flex-end;
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }
        }
        &-price {
          margin-top: 24px;
          font-size: 18px;
          line-height: 24px;
          color: $white;
          &-subtotal {
            display: flex;
            justify-content: space-between;
          }
          &-standard {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
          }
        }
        &-total {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 2px;
          color: $white;
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
        }
      }
    }
    &-faq {
      display: flex;
      flex-direction: column;
      padding-top: 64px;
      &-title {
        font-size: 26px;
        line-height: 26px;
        color: $white;
      }
      &-content {
        margin-top: 40px;
        &-item {
          padding: 30px 0 10px;
          border-bottom: 1px solid #252525;
          &-question {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            line-height: 24px;
            color: $white;
            margin-bottom: 10px;
            cursor: pointer;
            &-item {
              width: 90%;
            }
            .e-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              transition: all 0.3s;
              &.open {
                transform: rotate(180deg);
                transform-origin: center center;
              }
            }
          }
          &-answer {
            font-size: 16px;
            color: rgb(117, 117, 117);
            overflow: hidden;
          }
        }
        &-contact {
          font-size: 16px;
          line-height: 24px;
          color: #848484;
          margin-top: 35px;
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  @include tablet-landscape {
    &__top {
      padding: 36px 0 0;
    }
    &__back {
      &-icon {
        width: 14px !important;
        height: 14px !important;
      }
      &-text {
        font-size: 16px;
      }
    }
    &__name {
      font-size: 56px;
      line-height: 56px;
      margin-top: 40px;
    }
    &__orderNo {
      margin-top: 24px;
    }
    &__info {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      &__lt {
        width: grid-width(5);
        order: 1;
      }
      &__rt {
        width: 50%;
        order: 2;
        margin-bottom: 0;
        object-fit: cover;
      }
    }
    &__content {
      margin-top: 58px;
      .swiper-wrapper {
        .swiper-slide {
          width: calc(25% - 6px);
          height: calc((grid-width(5) - 6px) * 1.21);
        }
      }
      &-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: grid-width(-1);
        top: -40px;
        gap: 16px;
        &-prev {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.swiper-button-disabled {
            svg,
            path {
              fill: rgba(0, 0, 0, 0.5);
            }
          }
          .e-icon {
            width: 100%;
            height: 100%;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        &-next {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.swiper-button-disabled {
            svg,
            path {
              fill: rgba(0, 0, 0, 0.5);
            }
          }
          .e-icon {
            width: 100%;
            height: 100%;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    &__center {
      margin-top: 80px;
      &-pagination {
        margin-right: grid-width(-1);
      }
      .swiper-wrapper {
        width: 100%;
        .swiper-slide {
          width: grid-width(6);
        }
      }
      &__description {
        padding-right: 64px;
      }
    }
    &__bottom {
      margin-top: 120px;
      padding: 120px grid-width(3) 80px;
      &-orderDetails {
        flex-direction: row;
        padding-bottom: 96px;
        &-title {
          font-size: 32px;
          line-height: 32px;
          flex: 1;
        }
        &-content {
          flex: 2;
          flex-direction: row;
          &-number {
            width: 50%;
            margin-top: 0;
          }
          &-date {
            width: 50%;
            margin-top: 0;
          }
          &-receiverAddress {
            width: 50%;
            margin-top: 48px;
          }
          &-billingAddress {
            width: 50%;
            margin-top: 48px;
          }
          &-emailAddress {
            width: 50%;
            margin-top: 48px;
          }
          &-phoneNumber {
            width: 50%;
            margin-top: 48px;
          }
          &-invoice {
            width: 50%;
            margin-top: 48px;
          }
          &-payMethods {
            width: 50%;
            margin-top: 48px;
          }
          &-button {
            margin-top: 30px;
          }
        }
      }
      &-orderSummary {
        padding-top: 96px;
        padding-bottom: 96px;
        flex-direction: row;
        &-title {
          font-size: 32px;
          line-height: 32px;
          flex: 1;
        }
        &-content {
          flex: 2;
          margin-top: 0;
          &-orderList {
            flex-direction: row;
            flex-wrap: wrap;
            &-item {
              width: calc(50% - 12px);
            }
          }
          &-price {
            margin-top: 48px;
          }
          &-total {
            margin-top: 40px;
          }
        }
      }
      &-faq {
        padding-top: 96px;
        padding-bottom: 96px;
        flex-direction: row;
        &-title {
          font-size: 32px;
          line-height: 32px;
          flex: 1;
        }
        &-content {
          flex: 2;
          margin-top: 0;
          &-item {
            padding: 40px 0 14px;
            overflow: hidden;
            &-question {
              .e-icon {
                position: relative;
                transition: all 0.3s;
                &.open {
                  transform: rotate(180deg);
                  transform-origin: center center;
                }
              }
            }
            &-answer {
              font-size: 16px;
              color: rgb(117, 117, 117);
            }
          }
          &-contact {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
</style>
