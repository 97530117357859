<template>
  <overall-settings v-bind="$props" ref="overallSettings">
    <div class="c-model-selector" ref="rootEl">
      <div class="c-model-selector__items">
        <AdaptiveImage class="c-model-selector__item" :field="item.fields.image" v-for="item in fields.items" :key="item.id"> </AdaptiveImage>
      </div>
      <div class="c-model-selector__contents">
        <div class="c-model-selector__content" v-for="item in fields.items" :key="item.id">
          <jss-rich-text class="c-model-selector__content-title" :field="item.fields.title" tag="div" />
          <jss-rich-text class="c-model-selector__content-description" :field="item.fields.description" tag="div" />
          <site-button class="c-model-selector__content-button" :fields="item.fields.button.fields" />
        </div>
      </div>
      <div class="c-model-selector__tab-bar">
        <jss-text
          class="c-model-selector__tab"
          :class="[{ active: activeIndex === index }]"
          :field="item.fields.name"
          v-for="(item, index) in fields.items"
          :key="item.id"
          tag="div"
          @click="(e) => switchTab(index)"
        />
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { headerHeights, loadScrollMagic } from '@/utils/site-utils';
import gsap from 'gsap';
import onScrollToView from '@/hooks/onScrollToView';
import { getScrollTop, isTablet } from '@/utils/dom-utils';
import isMobile from 'is-mobile';

export default {
  name: 'ModelSelector',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let /**@type ScrollMagic.Controller*/ controller, /**@type ScrollMagic.Scene*/ scene, /**@type core.Timeline*/ tl;
    const state = reactive({
      /**@type OverallSettings*/
      overallSettings: null,
      rootEl: null,
      offsetTop: 0,
      activeIndex: 0
    });
    const methods = {
      buildScrollMagic() {
        const ScrollMagic = loadScrollMagic();
        const len = props.fields.items.length;
        const winHeight = window.innerHeight;
        controller = new ScrollMagic.Controller();
        const headerHeight = isMobile() ? headerHeights[0] : isTablet() ? headerHeights[1] : headerHeights[2];
        scene = new ScrollMagic.Scene({
          triggerElement: state.rootEl,
          triggerHook: headerHeight / winHeight,
          duration: 200 + '%'
        });
        tl = gsap.timeline({
          smoothChildTiming: true,
          delay: 0.2
        });
        const $items = state.rootEl.querySelectorAll('.c-model-selector__item');
        const $contents = state.rootEl.querySelectorAll('.c-model-selector__content');
        let itemPosition = 0;
        for (let i = 0; i < len; i++) {
          const j = i + 1;
          if (i === 0) {
            tl.to($items[i], { scale: 1, duration: 0.2 }, itemPosition);
            tl.to($contents[i], { autoAlpha: 1, translateY: 0, zIndex: 1, duration: 0.2 }, itemPosition);
            ++itemPosition;
          }
          if (i < len - 1) {
            tl.to(
              $items[i],
              {
                autoAlpha: 0,
                zIndex: -1,
                delay: 0.2,
                onReverseComplete() {
                  state.activeIndex = i;
                }
              },
              itemPosition
            );
            tl.to(
              $contents[i],
              {
                autoAlpha: 0,
                zIndex: -1,
                translateY: '-100px',
                delay: 0.2
              },
              itemPosition
            );
          }
          if (j < len) {
            tl.to(
              $items[j],
              {
                autoAlpha: 1,
                zIndex: 1,
                scale: 1,
                delay: 0.2,
                onComplete() {
                  state.activeIndex = j;
                }
              },
              itemPosition
            );
            tl.to(
              $contents[j],
              {
                autoAlpha: 1,
                zIndex: 1,
                translateY: 0,
                delay: 0.2
              },
              itemPosition
            );
          }
          if (i === len - 1) {
            ++itemPosition;
            tl.to(
              $items[j],
              {
                autoAlpha: 1,
                delay: 0.2
              },
              itemPosition
            );
          }
          ++itemPosition;
        }
        scene
          .setPin(state.rootEl)
          .setTween(tl)
          .addTo(controller);
      },
      destroyScrollMagic() {
        controller.destroy(true);
        controller = null;
        scene = null;
      },
      onResize(e) {
        methods.destroyScrollMagic();
        methods.buildScrollMagic();
      },
      switchTab(index) {
        if (index === state.activeIndex) return;
        const $items = state.rootEl.querySelectorAll('.c-model-selector__item');
        const $contents = state.rootEl.querySelectorAll('.c-model-selector__content');
        gsap.to($items[index], {
          autoAlpha: 1,
          zIndex: 1,
          scale: 1,
          duration: 0.6,
          position: 0,
          onComplete() {
            state.activeIndex = index;
          }
        });
        gsap.to($contents[index], {
          autoAlpha: 1,
          zIndex: 1,
          translateY: 0,
          duration: 0.6,
          position: 0
        });
        gsap.to($items[state.activeIndex], {
          autoAlpha: 0,
          zIndex: -1,
          duration: 0.6,
          position: 0
        });
        gsap.to($contents[state.activeIndex], {
          autoAlpha: 0,
          zIndex: -1,
          translateY: '-100px',
          duration: 0.6,
          position: 0
        });
        // const offsetTop = state.overallSettings.rootEl.offsetTop;
        // const winHeight = window.innerHeight;
        // const total = props.fields.items.length;
        // window.scrollTo(0, offsetTop + (winHeight * 2 * (0.8 * index + 0.4)) / total);
      }
    };
    onMounted(() => {
      // methods.buildScrollMagic();
      // window.addEventListener('resize', methods.onResize);
    });
    onBeforeUnmount(() => {
      // methods.destroyScrollMagic();
      // window.removeEventListener('resize', methods.onResize);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-model-selector {
  $c: &;
  @include height-except-header;
  position: relative;
  &__items {
    position: relative;
  }
  &__item {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100vw;
    @include height-except-header;
    &:first-child {
      transform: scale(1.1);
    }
    &:not(:first-child) {
      visibility: hidden;
      transform: scale(1.1);
    }
  }
  &__content {
    position: absolute;
    z-index: -1;
    left: grid-width-m(1);
    top: grid-width-m(3);
    transform: translateY(100px);
    visibility: hidden;
    &-description {
      margin-top: $space-8;
    }
    &-button {
      margin-top: $space-24;
    }
  }
  &__tab-bar {
    position: absolute;
    z-index: 1;
    top: 40px;
    left: grid-width-m(1);
    width: grid-width-m(10);
    padding: 0 grid-width-m(1);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, 0.3);
      transform: skewX(-30deg);
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__tab {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: $space-8 0;
    cursor: pointer;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 0;
      border-top: 1px solid $black;
      transition: width 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
    }
    &.active {
      cursor: default;
      &:before {
        width: 100%;
      }
    }
    + #{$c}__tab {
      margin-left: $space-24;
    }
  }
  @include tablet-landscape {
    &__content {
      left: grid-width(1);
      top: grid-width(2);
    }
    &__tab-bar {
      top: auto;
      bottom: 40px;
      left: grid-width(8);
      width: grid-width(8);
      padding: 0 grid-width(1);
    }
  }
}
</style>
