<template>
  <overall-settings v-bind="$props">
    <div class="c-news-detail-description" v-if="fields">
      <jss-rich-text class="c-news-detail-description-item" :field="fields.firstDescription" tag="div" />
      <div class="c-news-detail-description-bottom-image-content">
        <div class="c-news-detail-description-bottom-image-inner-content" align="center">
          <BackgroundImage class="c-news-detail-description-bottom-image" :fullscreenEnabled="true" :image="fields.image" />
        </div>
        <jss-rich-text class="c-news-detail-description-bottom-image-desction" :field="fields.secondDescription" tag="div" />
      </div>
    </div>
  </overall-settings>
</template>

<script>
export default {
  name: 'NewsDetailDescription',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-news-detail-description {
  width: 100%;
  position: relative;
  padding-left: grid-width-m(1);
  &-item {
    @include h8;
    width: grid-width-m(10);
  }
  &-bottom-image-content {
    width: grid-width-m(10);
    margin-left: 0;
    margin-top: grid-width-m(1.5);
    position: relative;
    min-height: grid-width-m(14.5);
  }
  &-bottom-image-inner-content {
    width: grid-width-m(10);
    height: grid-width-m(14.5);
    margin-left: 0;
    margin-bottom: grid-width-m(0.3);
    position: relative;
  }
  &-bottom-image {
    width: 100%;
    height: 100%;
  }
  &-bottom-image-desction {
    margin-top: grid-width-m(1.5);
  }
  @include tablet-landscape {
    position: relative;
    padding: 0 grid-width(2) 0 grid-width(2);
    &-item {
      @include h8;
      width: grid-width(14);
      margin: 0 0 0 grid-width(3);
    }
    &-bottom-image-content {
      width: grid-width(14);
      margin-top: grid-width(0.5);
      margin-left: grid-width(3);
      min-height: grid-width(8.5);
      clear: both;
    }
    &-bottom-image-inner-content {
      width: grid-width(6);
      height: grid-width(8.5);
      margin-left: grid-width(-1);
      margin-bottom: grid-width(0.3);
      right: grid-width(-2);
      position: relative;
      float: right;
      clear: both;
    }
    &-bottom-image {
      width: 100%;
      height: 100%;
    }
    &-bottom-image-desction {
      margin-top: 0;
    }
  }
}
html.rtl {
  .c-news-detail-description {
    &-bottom-image-inner-content {
      float: left;
    }
  }
}
</style>
