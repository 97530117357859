<template>
  <div class="c-news-list-v2">
    <jss-rich-text class="c-news-list-v2__title" :field="$adaptiveTextField(fields.title, fields.titleMobile)" tag="div" />
    <a class="c-news-list-v2__item-main" v-if="fields.mainItem" :href="getNewsItemLink(fields.mainItem)">
      <background-image
        :image="$adaptiveImageField(fields.mainItem.fields.newsImage, fields.mainItem.fields.newsImageMobile)"
        :class="[...getGradientClasses(fields.mainItem), `align-${$settingValue(fields.mainItemAlign)}`, `valign-${$settingValue(fields.mainItemValign)}`]"
        :parallax="false"
        :has-animation="false"
        :isBackground="false"
      />
      <div class="c-news-list-v2__item-main-text">
        <jss-rich-text class="c-news-list-v2__item-title" :field="$adaptiveTextField(fields.mainItem.fields.newsHeading, fields.mainItem.fields.newsHeadingMobile)" tag="div" />
        <jss-rich-text class="c-news-list-v2__item-description" :field="$adaptiveTextField(fields.mainItem.fields.newsDescription, fields.mainItem.fields.newsDescriptionMobile)" tag="div" />
        <site-button v-bind="fields.mainViewButton" />
      </div>
    </a>
    <div class="c-news-list-v2__items">
      <template v-for="(item, index) in fields.listItems" :key="item.id">
        <a class="c-news-list-v2__item" :href="getNewsItemLink(item)" v-if="item && index < showCount">
          <background-image class="c-news-list-v2__item-image" :image="$adaptiveImageField(item.fields.newsImage, item.fields.newsImageMobile)" :parallax="false" :has-animation="false" />
          <jss-text class="c-news-list-v2__item-date" :field="item.fields.newsDate" tag="div" />
          <jss-rich-text class="c-news-list-v2__item-title" :field="$adaptiveTextField(item.fields.newsHeading, item.fields.newsHeadingMobile)" tag="div" />
          <jss-rich-text class="c-news-list-v2__item-description" :field="$adaptiveTextField(item.fields.newsDescription, item.fields.newsDescriptionMobile)" tag="div" />
          <site-button v-bind="fields.listViewButton" />
        </a>
      </template>
    </div>
    <div class="c-news-list-v2__buttons" v-if="fields.listItems?.length > 4 && showCount < fields.listItems?.length">
      <site-button v-bind="fields.showMoreButton" @click="showMore" />
    </div>
  </div>
</template>

<script>
/**
 * @typedef NewsListV2Fields
 * @property {SimpleField} title
 * @property {SimpleField} titleMobile
 * @property {GlobalSettingEntry} mainItemAlign
 * @property {GlobalSettingEntry} mainItemValign
 * @property {DropLinkField<NewsPageFields>} mainItem
 * @property {Array<NewsPage>} listItems
 * @property {ButtonField} mainViewButton
 * @property {ButtonField} listViewButton
 * @property {ButtonField} showMoreButton
 * */
import { settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import * as querystring from 'querystring';
export default {
  name: 'NewsListV2',
  props: {
    /**@type NewsListV2Fields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    const route = useRoute();
    const methods = {
      getGradientClasses(newsItem) {
        const { newsImageGradientColor, newsImageGradientDirection } = newsItem?.fields || {};
        const gradientColor = settingValue(newsImageGradientColor),
          gradientDirection = settingValue(newsImageGradientDirection, 'btt');
        if (!isNullOrEmpty(gradientColor)) {
          return [`gradient-${gradientColor}`, `gradient-${gradientDirection}`];
        }
        return [];
      },
      getNewsItemLink(newsItem) {
        const { path, query, hash } = route;
        const { name } = newsItem;
        return `${path}/${name}${query ? `?${querystring.stringify(query)}` : null}${hash}`;
      },
      showMore() {
        if (state.showCount >= props.fields.listItems.length) {
          state.showCount = props.fields.listItems.length;
          return;
        }
        state.showCount += 4;
      }
    };
    const state = reactive({
      showCount: 4
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-news-list-v2 {
  $c: &;
  &__title {
    margin-top: 120px;
    margin-bottom: 60px;
    font-family: 'Overpass Lotus Headlines Exlight', sans-serif;
    line-height: 1;
    padding-left: grid-width-m(2);
  }
  &__item {
    &-main {
      position: relative;
      display: block;
      .e-background-image {
        &__img {
          img {
            display: block;
          }
        }
        @each $color-name, $color-value in $colors {
          &.#{'gradient-' + $color-name} {
            position: relative;
            &.gradient-ltr,
            &.gradient-rtl,
            &.gradient-btt,
            &.gradient-ttb {
              .e-background-image__content {
                z-index: 2;
                transform: translateZ(1px);
              }
              .e-background-image__img {
                &:before {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  transform: translateZ(1px);
                  z-index: 1;
                }
              }
            }
            &.gradient-ltr {
              .e-background-image__img {
                &:before {
                  background-image: linear-gradient(to right, rgba($color-value, 0.6), transparent);
                }
              }
            }
            &.gradient-rtl {
              .e-background-image__img {
                &:before {
                  background-image: linear-gradient(to left, rgba($color-value, 0.6), transparent);
                }
              }
            }
            &.gradient-btt {
              .e-background-image__img {
                &:before {
                  background-image: linear-gradient(to top, rgba($color-value, 0.6), transparent);
                }
              }
            }
            &.gradient-ttb {
              .e-background-image__img {
                &:before {
                  background-image: linear-gradient(to bottom, rgba($color-value, 0.6), transparent);
                }
              }
            }
          }
        }
        &.align {
          &-left {
            .e-background-image__content {
              align-items: flex-start;
            }
          }
          &-center {
            .e-background-image__content {
              align-items: center;
            }
          }
          &-right {
            .e-background-image__content {
              align-items: flex-end;
              text-align: end;
            }
          }
        }
        &.valign {
          &-top {
            .e-background-image__content {
              justify-content: flex-start;
            }
          }
          &-center {
            .e-background-image__content {
              justify-content: center;
            }
          }
          &-bottom {
            .e-background-image__content {
              justify-content: flex-end;
            }
          }
        }
      }
      // .e-background-image__content {
      //   height: 100%;
      //   padding: grid-width-m(1);
      //   color: $white;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: flex-end;
      //   align-items: flex-start;
      // }
      &-text {
        position: absolute;
        left: grid-width-m(1);
        right: grid-width-m(1);
        bottom: 40px;
        z-index: 2;
        height: 100%;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }
      #{$c}__item-title {
        @include h6;
        margin-bottom: 24px;
        text-transform: uppercase;
      }
      #{$c}__item-description {
        margin-bottom: 32px;
        opacity: 0.8;
      }
      .e-site-button.primary-link {
        height: auto;
      }
    }
  }
  &__items {
    padding: 80px grid-width-m(1) 110px grid-width-m(1);
    #{$c}__item {
      display: block;
      width: 100%;
      &-image {
        height: grid-width-m(10, false, #{math.div(9, 16)});
        margin-bottom: 16px;
      }
      &-date {
        font-size: 14px;
        letter-spacing: 2px;
        font-weight: 600;
        color: $grey-next;
        margin-bottom: 4px;
      }
      &-title {
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 16px;
        font-weight: 600;
        color: #2f2f2f;
      }
      &-description {
        margin-bottom: 6px;
        opacity: 0.8;
        color: $grey-neutral;
      }
      .e-site-button {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.35;
      }
      + #{$c}__item {
        margin-top: 50px;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 grid-width-m(1) 80px grid-width-m(1);
  }
  @include tablet-landscape {
    &__title {
      margin-top: 160px;
      margin-bottom: 80px;
      padding-left: grid-width(4);
    }
    &__item {
      &-main {
        // .e-background-image__content {
        //   padding: grid-width(1) grid-width(2);
        // }
        &-text {
          width: grid-width(6);
          position: absolute;
          left: grid-width(2);
          right: grid-width(2);
          bottom: 80px;
        }
      }
    }
    &__items {
      padding: 120px grid-width(2) grid-width(1) grid-width(2);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 100px;
      grid-column-gap: 24px;
      #{$c}__item {
        &-image {
          height: calc((#{grid-width(20)} - 24px) * #{math.div(3, 4 * 2)});
        }
        &-title {
          padding-right: grid-width(1);
        }
        &-description {
          padding-right: grid-width(1);
        }
        + #{$c}__item {
          margin-top: 0;
        }
      }
    }
    &__buttons {
      flex-direction: row;
      align-items: center;
      padding-bottom: 80px;
    }
  }
}
html[lang='ja-JP'] {
  .c-news-list-v2 {
    &__title {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .c-news-list-v2 {
    &__title {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }
  }
}
</style>
