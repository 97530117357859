<template>
  <div class="c-news-detail-v2" v-if="fields">
    <div class="c-news-detail-v2__header">
      <jss-rich-text class="c-news-detail-v2__title" :field="$adaptiveTextField(page.fields.newsHeading, page.fields.newsHeadingMobile)" tag="div" />
      <jss-text class="c-news-detail-v2__date" :field="page.fields.newsDate" tag="div" />
    </div>
    <background-image
      class="c-news-detail-v2__image"
      :class="[...gradientClasses]"
      :image="$adaptiveImageField(page.fields.newsImage, page.fields.newsImageMobile)"
      :parallax="false"
      :has-animation="false"
      :is-background="false"
    />
    <div class="c-news-detail-v2__content">
      <template v-for="paragraph in fields.detail" :key="paragraph.id">
        <NewsDetailParagraph :fields="paragraph.fields" />
      </template>
    </div>
  </div>
</template>

<script>
import NewsDetailParagraph from '@/components/NewsDetailParagraph';
import { settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { computed } from 'vue';
/**
 * @typedef NewsDetailV2Fields
 * @property {Array<NewsDetailParagraph>} paragraphs
 * */
export default {
  name: 'NewsDetailV2',
  components: { NewsDetailParagraph },
  props: {
    /**@type NewsPage*/
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      gradientClasses: computed(() => {
        const { newsImageGradientColor, newsImageGradientDirection } = props?.page?.fields || {};
        const gradientColor = settingValue(newsImageGradientColor),
          gradientDirection = settingValue(newsImageGradientDirection, 'btt');
        if (!isNullOrEmpty(gradientColor)) {
          return [`gradient-${gradientColor}`, `gradient-${gradientDirection}`];
        }
        return [];
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-news-detail-v2 {
  &__header {
    padding: 120px grid-width-m(1) 0 grid-width-m(1);
    &:empty {
      display: none;
    }
  }
  &__title {
    @include h5;
    font-family: lotusFontFamily('Overpass Light'), sans-serif;
    line-height: 1;
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  &__date {
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  &__image {
    @each $color-name, $color-value in $colors {
      &.#{'gradient-' + $color-name} {
        position: relative;
        &.gradient-ltr,
        &.gradient-rtl,
        &.gradient-btt,
        &.gradient-ttb {
          .e-background-image__content {
            z-index: 1;
            transform: translateZ(1px);
          }
          .e-background-image__img {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              transform: translateZ(1px);
            }
          }
        }
        &.gradient-ltr {
          .e-background-image__img {
            &:before {
              background-image: linear-gradient(to right, rgba($color-value, 0.6), transparent);
            }
          }
        }
        &.gradient-rtl {
          .e-background-image__img {
            &:before {
              background-image: linear-gradient(to left, rgba($color-value, 0.6), transparent);
            }
          }
        }
        &.gradient-btt {
          .e-background-image__img {
            &:before {
              background-image: linear-gradient(to top, rgba($color-value, 0.6), transparent);
            }
          }
        }
        &.gradient-ttb {
          .e-background-image__img {
            &:before {
              background-image: linear-gradient(to bottom, rgba($color-value, 0.6), transparent);
            }
          }
        }
      }
    }
  }
  &__content {
    padding: 60px 24px 20px 24px;
    &:empty {
      display: none;
    }
  }
  &__paragraph {
    & + & {
      margin-top: 24px;
    }
  }
  @include tablet-landscape {
    &__header {
      padding: 160px grid-width(4) 0 grid-width(4);
    }
    &__date {
      margin-bottom: 120px;
    }
    &__content {
      padding: 80px 0 80px 0;
    }
  }
}
</style>
