<template>
  <overall-settings v-bind="$props">
    <div class="c-news-detail-image" v-if="fields">
      <BackgroundImage class="c-news-detail-image-item" :fullscreenEnabled="true" :image="fields.image" />
    </div>
  </overall-settings>
</template>

<script>
export default {
  name: 'NewsDetailImage',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-news-detail-image {
  width: 100%;
  position: relative;
  padding-left: grid-width-m(1);
  &-item {
    width: grid-width-m(10);
    height: grid-width-m(5);
    margin-left: 0;
    position: relative;
  }
  @include tablet-landscape {
    position: relative;
    padding: 0 grid-width(2) 0 grid-width(2);
    &-item {
      width: grid-width(18);
      height: grid-width(9);
      margin-left: grid-width(1);
      position: relative;
    }
  }
}
</style>
