<script setup>
import { computed, inject, nextTick, onMounted, reactive, ref } from 'vue';
import { APP_CONST, sitecoreComponentProps } from '@/utils/constants';
import { isArray } from '@/utils/obj-utils';
import api from '@/api';
import { getAdaptiveTextField, getGlobalCfgValues, getGlobalConfigs, getPageAlpha2Code } from '@/utils/site-utils';
import { canUseDOM } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import { getQueryStrings } from '@/utils/uri-utils';
import services from '@/services';
import { onWidthChanged } from '@/hooks/onWidthChanged';
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
/**@type AppStore*/
const appStore = useAppStore();
const toast = inject('toast');
const loading = inject('loading');
const { deviceState } = inject('device-common');
/**@type Ref<UsedCarFilterMenuRef>*/
const filterMenuRef = ref();
/**@type Ref<NoticeModalRef>*/
const authModalRef = ref();
const dealerSelectModalRef = ref();
const descRef = ref();
const showReadMore = ref(true);
const bodyVisible = ref(false);
const sortOption = ref(null);
const currency = ref('EUR');
const category = ref(null);
const fetching = ref(false);
const list = ref([]);
// const filter = reactive({
//   currency: 'EUR',
//   series: [],
//   yearStart: null,
//   yearEnd: null,
//   mileageStart: null,
//   mileageEnd: null,
//   priceStart: null,
//   priceEnd: null,
//   colors: [],
//   transmission: [],
//   approved: null
// });
const pagination = reactive({
  page: 1,
  pageSize: props.fields?.pageSize.value ?? 5,
  shownTotal: null,
  total: null
});
const adCardList = ref([]);
const [distanceCfg] = getGlobalConfigs(props.page, 'distanceUnit');
const distanceUnit = reactive({
  phrase: distanceCfg?.fields.phrase.value ?? 'km',
  text: distanceCfg?.fields.text.value ?? 'Km'
});
const count = computed(() => list.value.filter((x) => x.type === 'shop-product-card').length);
const toggleBody = () => {
  bodyVisible.value = !bodyVisible.value;
};
const checkDescHeight = () => {
  if (bodyVisible.value) return;
  const lineHeight = parseInt(global.getComputedStyle(descRef.value).lineHeight);
  const rect = descRef.value.getBoundingClientRect();
  const lines = Math.floor(rect.height / lineHeight);
  showReadMore.value = lines > 2;
};
const init = async () => {
  loading.show();
  const [shopClassifyCode, currencyVal] = getGlobalCfgValues(props.page, ['shopClassifyCode', 'currency']);
  let [cateId] = getQueryStrings('categoryId:number');
  const { adCards } = props.fields;
  currency.value = currencyVal;
  category.value = await services.mall.getCategory(shopClassifyCode, cateId);
  pagination.page = 1;
  const _adCards = [];
  for (let i = adCards.length - 1; i >= 0; i--) {
    const adCard = adCards[i];
    const { desktopPosition, tabletPosition, mobilePosition } = adCard.fields;
    const position = getAdaptiveTextField(deviceState, desktopPosition, mobilePosition, tabletPosition);
    const cardData = {
      type: 'shop-ad-card',
      position: position?.value,
      data: {
        id: adCard.id,
        fields: adCard.fields,
        page: props.page
      }
    };
    _adCards.push(cardData);
  }
  adCardList.value = _adCards;
  nextTick().then(() => {
    checkDescHeight();
  });
  await fetchData(true);
};
const onSortChange = (e, selectedOption) => {
  sortOption.value = selectedOption;
  pagination.page = 1;
  loading.show();
  fetchData(true);
};
const fetchData = async (refresh = false) => {
  fetching.value = true;
  const alpha2Code = getPageAlpha2Code(props.page);
  const body = {
    fcId: category.value?.id,
    market: APP_CONST.market.code,
    pageNum: pagination.page,
    pageSize: pagination.pageSize,
    sort: sortOption.value?.code ?? null,
    country: alpha2Code,
    currency: currency.value
  };
  const [res, ex] = await api.shop.getGoodsSpuList(body).finally(() => {
    fetching.value = false;
    loading.hide();
  });
  if (!isArray(res?.records)) {
    await toast.showEx(ex);
    return;
  }
  pagination.shownTotal = pagination.pageSize * (pagination.page - 1) + res.records.length;
  const result = res.records.map((x) => ({
    type: 'shop-product-card',
    data: {
      page: props.page,
      product: x
    }
  }));
  buildList(result, pagination.pageSize * (pagination.page - 1));
  if (refresh) {
    list.value = result;
  } else {
    list.value.push(...result);
  }
  pagination.total = Number(res?.total) ?? null;
};
const buildList = (_list, start) => {
  const end = start + _list.length;
  for (let i = adCardList.value.length - 1; i >= 0; i--) {
    const adCard = adCardList.value[i];
    const { position } = adCard;
    if (list.value.length > 0 && list.value[list.value.length - 1] === adCard) continue;
    if (position >= start && position <= end) {
      _list.splice(position - 1, 0, adCard);
    }
    if (position === end + 1) {
      _list.push(adCard);
    }
  }
  return _list;
};
const loadMore = async () => {
  pagination.page += 1;
  await fetchData();
};
if (canUseDOM()) {
  loading.show();
}
onMounted(async () => {
  await init();
});
onWidthChanged({
  callback() {
    const _list = list.value.filter((x) => x.type === 'shop-product-card');
    list.value = buildList(_list, 0);
    checkDescHeight();
  }
});
</script>

<template>
  <overall-settings v-bind="props">
    <div class="c-merchandise-list">
      <div class="c-merchandise-list__header">
        <h2 class="fs-32 tl-fs-56 __lh-1 ls-3 mg-b-24">{{ category?.name }}</h2>
        <div class="mg-b-24">
          <div class="c-merchandise-list__desc text-desc" :class="[{ 'show-all': bodyVisible }]" ref="descRef">{{ category?.description }}</div>
          <jss-rich-text class="inline tl-b tdl hand" :field="bodyVisible ? fields.collapseText : fields.readMoreText" @click="toggleBody" v-if="showReadMore" />
        </div>
        <div class="c-merchandise-list__top">
          <!--          <div class="c-merchandise-list__top-main">-->
          <!--            <dropdown class="c-merchandise-list__sort-ddl" theme="search-grey" v-bind="$formDdl(fields.sortDorpdown)" :selected-option="sortOption" @change="onSortChange" />-->
          <!--          </div>-->
          <jss-rich-text class="c-merchandise-list__help-m" :field="fields.mobileContactText" v-if="$deviceComputes.isMobileOrTablet.value" />
        </div>
        <div class="c-merchandise-list__summary">
          <div>
            <template-string
              :field="fields.totalText"
              :data="{ total: pagination.total, distance: `${$toThousands(fields.distanceRange?.value)} ${distanceUnit.text}` }"
              tag="span"
              v-if="pagination.total !== null"
            />
          </div>
          <jss-rich-text class="c-merchandise-list__help-p" :field="fields.contactText" v-if="!$deviceComputes.isMobileOrTablet.value" />
        </div>
      </div>
      <div class="c-merchandise-list__content" v-if="list.length > 0">
        <template v-for="item in list" :key="item.data?.product?.id ?? item.data?.id">
          <component :is="item.type" v-bind="item.data" />
        </template>
      </div>
      <div class="c-merchandise-list__pagination" v-if="pagination.total !== null">
        <template-string :field="fields.totalLoadText" :data="{ count, total: pagination.total }" tag="div" />
        <site-button v-bind="fields.loadMoreButton" :loading="fetching" @click="loadMore" v-if="pagination.shownTotal < pagination.total" />
      </div>
    </div>
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-list {
  $c: &;
  $ddl: '.e-dropdown';
  $btn: '.e-site-button';
  padding: 80px 32px 48px 32px;
  .e-dropdown {
    &__selection {
      font-size: 16px;
      border: 2px solid #000;
      font-weight: 700;
    }
    &__placeholder {
      font-size: 16px;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__desc {
    overflow: hidden;
    line-clamp: 2;
    text-overflow: ellipsis;
    &.show-all {
      overflow: visible;
    }
  }
  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-bottom: 32px;
    border-bottom: 1px solid $grey-89;
    &-main {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__filter-btn {
    background-color: transparent;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-family: lotusFontFamily('Overpass Bold'), sans-serif !important;
    border: 2px solid #000;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.355, 0.005, 0.26, 1);
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
  &__sort-ddl {
    width: 140px;
  }
  &__help-m {
    justify-self: flex-end;
  }
  &__summary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 48px 0;
  }
  &__location-btn {
    cursor: pointer;
    text-decoration: underline;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__pagination {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 72px;
  }
  @include tablet {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .s-shop-ad-card {
        @include grid-block(1, 2);
      }
    }
  }
  @include tablet-landscape {
    padding: 88px grid-width(2) 48px grid-width(2);
    &__top {
      display: block;
      &-main {
        justify-content: end;
      }
    }
    &__filter-btn {
      padding: 0 32px;
    }
    &__sort-ddl {
      width: 240px;
    }
    &__content {
      grid-template-columns: repeat(3, 1fr);
      .s-shop-ad-card {
        @include grid-block(1, 3);
      }
    }
  }
}
</style>
