<template>
  <div class="c-merchandise-checkout-life" ref="rootEl">
    <div class="c-merchandise-checkout-life__wrapper">
      <div class="c-merchandise-checkout-life__main" v-show="loaded">
        <MerchandiseCheckoutSteps :steps="fields.steps" :current="step" :exitButton="fields.exitButton" v-if="fields.steps?.length" />
        <div class="c-merchandise-checkout-life__content">
          <MerchandiseCheckoutVerify ref="verifyRef" v-bind="fields.verifySection" :bound="bound" :bind-required="bindRequired" :step="step" :stepCode="stepCode" />
          <MerchandiseCheckoutDelivery ref="deliveryRef" v-bind="fields.deliverySection" :step="step" :stepCode="stepCode" />
          <MerchandiseCheckoutPayment
            ref="paymentRef"
            v-bind="fields.paymentSection"
            :failed="paymentWasFailed"
            :paymentRes="paymentRes"
            :price="$formatShopMoney(prePrice?.fee ?? 0, currency)"
            :step="step"
            :stepCode="stepCode"
          />
          <div class="c-merchandise-checkout-life__actions">
            <SiteButton class="c-merchandise-checkout-life__actions-exit" v-bind="fields.exitButton" v-if="$deviceComputes.largeThanTablet.value" />
            <SiteButton v-bind="fields.continueButton" @click="goNext" v-if="step === 1" />
            <SiteButton v-bind="fields.payButton" @click="goNext" v-if="step === 2" />
          </div>
        </div>
      </div>

      <Transition name="bottom-slide-in">
        <div class="c-merchandise-checkout-life__aside" v-if="!$deviceComputes.isMobileOrTablet.value || openPriceBreakdown">
          <div class="c-merchandise-checkout-life__aside-header" v-if="$deviceComputes.isMobileOrTablet.value">
            <Icon name="close" @click="onClosePriceBreakdown" />
          </div>
          <MerchandiseCheckoutSummaryLife :page="page" :fields="fields" :skuList="skuList" :priceDetails="prePrice" :currency="currency" :deliveryDetails="deliveryDetails" v-if="loaded" />
        </div>
      </Transition>
    </div>

    <div class="c-merchandise-checkout-life-price-mobile" v-if="$deviceComputes.isMobileOrTablet.value">
      <div class="c-merchandise-checkout-life-price-mobile-bottom">
        <div
          class="c-merchandise-checkout-life-price-mobile-bottom-left"
          @click="!largeThanTablet() && toggleDis()"
          @mouseover="largeThanTablet() && showDis()"
          @mouseout="largeThanTablet() && hideDis()"
          :style="{ cursor: showDisclaimer ? 'pointer' : 'default' }"
        >
          <JssRichText class="c-merchandise-checkout-life-price-mobile-bottom-left" :field="fields.summaryLabel" />
          <Icon :field="fields.disclaimerIcon" v-if="!$isNullOrEmpty(fields.lifePriceDisclaimer)" />
          <div class="c-merchandise-checkout-life-price-mobile-bottom-left-des">{{ !$equalString(currentAlpha2Code, 'GB') ? $t('Excluded VAT') : $t('Including VAT') }}</div>
        </div>
        <div class="c-merchandise-checkout-life-price-mobile-bottom-right">{{ $formatShopMoney(prePrice?.fee ?? 0, currency) }}</div>
        <div
          class="c-merchandise-checkout-life-price-mobile-bottom-disclaimer"
          v-html="$formatString(fields.lifePriceDisclaimer?.fields.text.value, { price: $formatShopMoney(prePrice?.fee ?? 0, currency) })"
          v-if="showDisclaimer"
        />
      </div>
      <div class="c-merchandise-checkout-life-price-mobile-top" @click="onOpenPriceBreakdown">
        <div class="c-merchandise-checkout-life-price-mobile-top-btn">{{ $t('See details') }}</div>
        <Icon name="arrow-up" size="tiny" />
      </div>
    </div>

    <NoticeModal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
    <NoticeModal v-bind="fields.overWeightModal" ref="overWeightModalRef" />
    <GeeCaptcha ref="geeCaptchaRef" />
  </div>
</template>
<script>
import { toRefs, reactive, computed, inject, onMounted, nextTick, provide, watch } from 'vue';
import { buildAddress, getCurrentAlpha2Code, getGlobalConfigs, scrollToTop, decodeData } from '@/utils/site-utils';
import { appendQuery, getBetterUrl, getQueryStrings, redirectToLang, redirectToLogin } from '@/utils/uri-utils';
import { WEBSHOP_PAMENT_TYPE_CODE, DELIVERY_TYPE_CODE, WEBSHOP_PAMENT_STATUS_CODE } from '@/utils/constants';
import { equalString } from '@/utils/string-utils';
import { loadStripe } from '@/utils/stripe-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import { webStorage } from '@/utils/web-storage';
import { S_SHOP_ORDER_INFO } from '@/utils/web-storage-keys';
import { formatDate } from '@/utils/date-utils';
import { bindConsents, countryNeedDoubleOptin } from '@/services/siteService';
import { checkOrderLang } from '@/services/orderService';
import { debounce, merge, difference } from 'lodash';
import { gtmFormView, gtmFormStart, gtmFormStep, gtmFormSubmit, gtmShopOrder } from '@/utils/gtm-utils';
import { useRouter } from 'vue-router';
import api from '@/api';

export default {
  name: 'MerchandiseCheckoutLife',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const appStore = useAppStore();
    const router = useRouter();
    const { formType } = props.page.fields;
    const currentAlpha2Code = getCurrentAlpha2Code();
    const [shopConfirmationLifeLink, shopWeightLimit, market] = getGlobalConfigs(props.page, 'shopConfirmationLifeLink', 'shopWeightLimit', 'market');
    let token, clientSecret, redirectStatus, stripeHelper, stripe, orderCode, orderDetails;
    const shopConfirmationHref = shopConfirmationLifeLink?.value?.href;
    const state = reactive({
      loaded: false,
      rootEl: null,
      step: 0,
      verifyRef: null,
      deliveryRef: null,
      paymentRef: null,
      orderInfo: null,
      email: null,
      bound: {
        email: false,
        mobile: false
      },
      spuList: [],
      sendTypeList: [],
      skuList: [],
      itemPayType: '',
      totalPrice: 0,
      openPriceBreakdown: false,
      orderLocaleRedirectModalRef: null,
      overWeightModalRef: null,
      paymentWasFailed: false,
      paymentRes: null,
      currency: null,
      showDisclaimer: false,
      deliveryDetails: null,
      geeCaptchaRef: null,
      formChanged: false,
      totalWeight: 0,
      prePrice: null
    });

    const computes = {
      stepCode: computed(() => (state.step < props.fields.steps.length ? props.fields.steps[state.step]?.fields.code.value : '')),
      bindRequired: computed(() => appStore?.hasLoggedIn && !state.bound?.email)
    };

    const _methods = {
      checkPayment: async () => {
        loading.show();
        state.step = 2;
        if (redirectStatus === 'succeeded') {
          await _methods.startListening();
        } else {
          state.paymentWasFailed = true;
          state.loaded = true;
          await loading.hide();
          await nextTick();
          await state.paymentRef.initWidget();
        }
      },
      startListening: async () => {
        const [pk] = getQueryStrings('pk');
        stripeHelper = await loadStripe(props.page, toast);
        stripe = stripeHelper.initSdk({ publicKey: pk });
        await _methods.listening();
      },
      listening: async () => {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        let body = {};
        switch (paymentIntent.status) {
          case 'succeeded':
            if (!shopConfirmationHref || !state.orderInfo?.orderInfo.orderCode) return;
            // body = {
            //   address: buildAddress(state.orderInfo.billingAddress.address, state.orderInfo.billingAddress.address2),
            //   buyerName: `${state.orderInfo.personalInfo.firstName} ${state.orderInfo.personalInfo.lastName}`,
            //   invoiceEmail: state.email,
            //   invoiceMoney: paymentIntent.amount,
            //   phone: `+${state.orderInfo.personalInfo.mobile.area.code} ${state.orderInfo.personalInfo.mobile.number}`,
            //   invoicePhone: `+${state.orderInfo.personalInfo.mobile.area.code} ${state.orderInfo.personalInfo.mobile.number}`,
            //   orderCode: state.orderInfo.orderInfo.orderCode,
            //   userId: appStore.loginInfo?.lotusId,
            //   invoiceType: 51,
            //   detailAsk: 0,
            //   invoiceKind: 0,
            //   invoiceNumber: new Date().getTime()
            // };
            // api.shop.saveInvoice(null, body).then(() => {
            //   window.location = getBetterUrl(
            //     appendQuery(shopConfirmationHref, {
            //       orderId: state.orderInfo.orderInfo.orderCode
            //     }),
            //     props.page.itemLanguage,
            //     true
            //   );
            // });
            await _methods.checkClearCart();
            gtmShopOrder(
              appStore?.loginInfo,
              state.orderInfo.currency,
              state.orderInfo.orderInfo.orderCode,
              Number((Number(state.orderInfo.payFee) / 100).toFixed(2)),
              state.orderInfo.items.map((i) => ({
                item_id: i.skuId,
                item_name: i.name,
                item_variant: i.spec,
                item_brand: 'lotus shop',
                price: Number((Number(i.price) / 100).toFixed(2)),
                quantity: i.num
              }))
            );
            window.location = getBetterUrl(
              appendQuery(shopConfirmationHref, {
                orderId: state.orderInfo.orderInfo.orderCode
              }),
              props.page.itemLanguage,
              true
            );
            break;
          case 'processing':
            setTimeout(() => {
              _methods.listening();
            }, 2000);
            break;
          case 'requires_payment_method':
          default:
            state.loaded = true;
            state.paymentWasFailed = true;
            loading.hide();
            break;
        }
      },
      checkClearCart: async () => {
        orderDetails = decodeData(orderDetails);
        if (orderDetails.cartOrder) {
          appStore.loadCartInfo().then(() => {
            orderDetails.orderItems.forEach(async (i) => {
              await appStore.removeFromCart(market?.fields?.code?.value, i.skuId, i.num);
            });
          });
        }
      },
      checkBind: async () => {
        const [bindRes] = await api.cidp.bindable();
        if (bindRes) {
          state.bound = bindRes;
        } else {
          state.bound = { phone: true, email: true };
        }
      },
      submitCheckout: async () => {
        loading.show();
        const { email } = state.verifyRef;
        const { deliveryType, dealer, personalFormRef, addressFormRef, diffAddr, billingFormRef, consentFormRef } = state.deliveryRef;
        const [personalValid, personalData] = await personalFormRef.validate();
        const [shippingValid, shippingData] = await addressFormRef.validate();
        const [consentValid, consentData] = await consentFormRef.validate();
        const consents = consentFormRef.getVerifiedConsents();
        const deliveryCode = deliveryType?.fields?.code?.value;
        const pickup = equalString(deliveryCode, DELIVERY_TYPE_CODE.pickup);
        if (!personalValid || !shippingValid || !consentValid) {
          loading.hide();
          return null;
        }
        const newsletterConsent = consentData?.termsCondition ? consentFormRef.getItemConsents('termsCondition') : [];
        const bindConsent = difference(consents, newsletterConsent);
        const leadTypes = consentFormRef.getLeadTypes();
        const addressDetails = pickup ? dealer?.addressDetail : buildAddress(shippingData.address, shippingData.address2);
        let billingValid = null;
        let billingData = null;
        let billingAddressDetails = '';
        if (diffAddr) {
          [billingValid, billingData] = await billingFormRef.validate();
          if (!billingValid) {
            loading.hide();
            return null;
          }
          billingAddressDetails = buildAddress(billingData.address, billingData.address2);
        }
        if (isNullOrEmpty(state.orderInfo)) {
          if (!appStore?.hasLoggedIn) {
            const body = {
              email: email ?? state.email,
              firstName: personalData.firstName,
              lastName: personalData.lastName,
              mobileAreaCode: personalData.mobile?.area.code,
              phone: personalData.mobile?.number,
              terms: true,
              zipcode: shippingData.zipCode,
              source: '202',
              address: shippingData.address,
              address2: shippingData.address2,
              city: shippingData.city,
              country: shippingData.country.code,
              contactsAddress: buildAddress(shippingData.address, shippingData.address2),
              preferLanguage: props.page.itemLanguage,
              communicatePreference: 0,
              accountType: 1
            };
            const [loginRes, loginEx] = await api.cidp.bto.emailRegisterLogin(null, body);
            if (loginEx) {
              loading.hide();
              await toast.showEx(loginEx);
              if (loginEx.code === 68010030) {
                await appMethods.justLogout();
                --state.step;
                await nextTick();
                scrollToTop();
                return false;
              }
              return false;
            }
            appStore.updateLoginInfo(loginRes);
          }
          let addressId = '';
          let billingAddressId = '';
          const [addRes, addEx] = await api.shop.addAddress(null, {
            mobileAreaCode: personalData.mobile?.area.code,
            mobile: personalData.mobile?.number,
            countryCode: pickup ? dealer.countryCode : shippingData.country.code,
            countryName: pickup ? dealer.countryName : shippingData.country.text,
            city: pickup ? dealer.cityName : shippingData.city,
            cityName: pickup ? dealer.cityName : shippingData.city,
            province: pickup ? dealer.cityName : shippingData.city,
            provinceName: pickup ? dealer.cityName : shippingData.city,
            detail: addressDetails,
            postcode: pickup ? dealer.extra.storeZipCode : shippingData.zipCode,
            contactName: `${personalData.firstName} ${personalData.lastName}`,
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            nationalCode: pickup ? dealer.countryCode : shippingData.country.code,
            addressType: 1
          });
          if (addEx) {
            loading.hide();
            await toast.showEx(addEx);
            return null;
          }
          addressId = addRes.id;
          await _methods.initPrice(orderDetails, addressId, deliveryCode);
          if (diffAddr) {
            const [addRes, addEx] = await api.shop.addAddress(null, {
              mobileAreaCode: personalData.mobile?.area.code,
              mobile: personalData.mobile?.number,
              countryCode: billingData.country.code,
              countryName: billingData.country.text,
              city: billingData.city,
              cityName: billingData.city,
              province: billingData.city,
              provinceName: billingData.city,
              detail: billingAddressDetails,
              postcode: billingData.zipCode,
              contactName: `${personalData.firstName} ${personalData.lastName}`,
              firstName: personalData.firstName,
              lastName: personalData.lastName,
              nationalCode: billingData.country.code,
              addressType: 2
            });
            if (addEx) {
              await toast.showEx(addEx);
              loading.hide();
              return null;
            }
            billingAddressId = addRes.id;
          }
          state.deliveryDetails = {
            pickup,
            dealer,
            diffAddr,
            personalData,
            shippingData,
            billingData
          };
          const body = {
            market: market?.fields?.code?.value,
            businessSource: 1,
            couponFee: state.prePrice.couponFee,
            email: email ?? state.email,
            expressFee: state.prePrice.expressFee,
            fee: state.prePrice.fee,
            force: false,
            itemFee: state.prePrice.itemFee,
            itemPayType: state.itemPayType,
            items: state.prePrice.items,
            payFee: state.prePrice.payFee,
            payType: 51061005,
            pointAmount: state.prePrice.pointAmount,
            pointFee: 0,
            pointRate: 0,
            country: currentAlpha2Code,
            currency: state.currency,
            receiverAddressId: addressId,
            billingAddressId: diffAddr ? billingAddressId : addressId,
            requestId: Math.round(Math.random() * 100000000),
            phone: `+${personalData.mobile?.area.code} ${personalData.mobile?.number}`,
            userPhone: isNullOrEmpty(appStore?.phone) ? '' : `+${appStore?.mobileAreaCode} ${appStore?.phone}`,
            priceType: 10,
            secKill: false,
            sendType: deliveryType?.fields?.code?.value,
            serviceFee: state.prePrice.serviceFee,
            source: orderDetails.cartOrder ? WEBSHOP_PAMENT_TYPE_CODE.cartPayment : WEBSHOP_PAMENT_TYPE_CODE.directPayment
          };

          const [res, ex] = await api.shop.creatOrder(null, body);
          if (ex) {
            loading.hide();
            await toast.showEx(ex);
            return null;
          }
          state.orderInfo = res;

          const formDetails = {
            selected_model: null,
            selected_location_name: null,
            selected_location_type: null,
            selected_location_date: null,
            selected_location_time: null,
            customer_type: null,
            dealer_name: dealer?.storeName ?? null
          };

          const leadsDetails = {};
          bindConsents(bindConsent, {
            email: email ?? state.email,
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            countryRegion: personalData.mobile?.area.data.alpha2Code,
            phone: personalData.mobile?.number
          })
            .then(async (bindConsentRes) => {
              if (leadTypes?.length) {
                leadTypes.forEach((l) => {
                  leadsDetails[l.leadType] = bindConsentRes ?? null;
                });
              }
              if (consentData?.termsCondition) {
                const needDoubleOptin = await countryNeedDoubleOptin(currentAlpha2Code);
                const body = {
                  countryRegion: currentAlpha2Code,
                  language: props.page.itemLanguage,
                  needDoubleOptin,
                  termsAndConditions: newsletterConsent.map((revisionNumber) => ({
                    revisionNumber: revisionNumber,
                    title: window.document.title,
                    formUrl: window.location.href,
                    effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
                  })),
                  phone: `${personalData.mobile?.area.code}${personalData.mobile?.number}`,
                  firstName: personalData.firstName,
                  lastName: personalData.lastName,
                  email: email ?? state.email,
                  zipCode: diffAddr ? billingData.zipCode : shippingData.zipCode
                };
                merge(body, personalData?.externalData, shippingData?.externalData, billingData?.externalData, consentData?.externalData);
                api.lcms.crm.newsletter
                  .subscribe(null, body)
                  .then((newsletterRes) => {
                    if (leadTypes?.length) {
                      leadTypes.forEach((l) => {
                        if (equalString(l.consentType, 'termsCondition')) {
                          leadsDetails[l.leadType] = newsletterRes.leadId ?? null;
                        }
                      });
                    }
                  })
                  .catch();
              }
            })
            .catch();

          gtmFormSubmit(
            formType?.value,
            props.fields.deliverySection?.fields?.personalForm.id,
            {
              email: email ?? state.email,
              mobileAreaCode: personalData.mobile.area.code,
              phone: personalData.mobile.number
            },
            formDetails,
            leadsDetails
          );
          let shopTotalPrice = 0;
          let shopItems = [];
          state.prePrice?.items?.forEach((item) => {
            shopTotalPrice += item.price * item.num;
            let shopItem = {
              item_id: item.skuId,
              item_name: item.name,
              item_variant: item.specDetail,
              item_brand: 'lotus shop',
              price: item.price,
              quantity: item.num
            };
            shopItems.push(shopItem);
          });
          const orderInfo = merge(body, { orderInfo: res });
          webStorage.set(S_SHOP_ORDER_INFO, orderInfo);
        }
        await _methods.loadWidget(state.orderInfo.orderCode);
        return true;
      },
      paymentCheckout: async () => {
        state.paymentRef.onPay();
      },
      loadWidget: async (orderCode) => {
        const [res, ex] = await api.shop.payMulti(null, { orderCode: orderCode, payChannel: 70 });
        if (ex) {
          loading.hide();
          await toast.showEx(ex);
          return false;
        }
        state.paymentRes = res;
        await state.paymentRef.initWidget(res);
      },
      checkOrder: async (orderCode) => {
        const [orderRes, orderEx] = await api.shop.orderDetails({ orderId: orderCode });
        if (orderEx?.code == 60000009) {
          await appMethods.logoutToLogin();
        }
        const [needRedirect, targetLang] = checkOrderLang(props.page, orderRes.country);
        if (needRedirect) {
          await state.orderLocaleRedirectModalRef.open();
          redirectToLang(targetLang);
        }
        const orderItems = orderRes.items.map((i) => ({
          skuId: i.skuId,
          num: i.num
        }));
        orderDetails = {
          cartOrder: equalString(orderRes.source, WEBSHOP_PAMENT_TYPE_CODE.cartPayment),
          orderItems
        };
        await _methods.initPrice(orderDetails);
        if (orderRes.status == WEBSHOP_PAMENT_STATUS_CODE.paying) {
          await _methods.loadWidget(orderCode);
          state.step = 2;
        } else {
          window.location = getBetterUrl(
            appendQuery(shopConfirmationHref, {
              orderId: orderCode
            }),
            props.page.itemLanguage,
            true
          );
        }
      },
      initPrice: async (orderDetails, receiverAddressId = null, deliveryType = null) => {
        let ids = '';
        let items = [];
        orderDetails?.orderItems.map((i, index) => {
          if (index) {
            ids += ',' + i.skuId;
          } else {
            ids = i.skuId + '';
          }
          items.push(i);
        });

        const [skuRes, skuEx] = await api.shop.getGoodsBySkuList({ ids });
        state.skuList = [];
        if (!skuRes) {
          window.history.back();
        }
        skuRes.forEach((s) => {
          const priceInfo = s.priceInfoList.find((p) => equalString(p.country, currentAlpha2Code) && !equalString(p.priceCategory, 'Deposit Price'));
          s.price = priceInfo?.price ?? 0;
          if (s.status) {
            state.skuList.push(s);
            state.currency = priceInfo?.currency;
            const index = items.findIndex((i) => s.id == i.skuId);
            items[index] = merge(items[index], { price: priceInfo?.price });
            state.totalWeight += Number(s.weight);
          }
        });

        if (shopWeightLimit?.value && state.totalWeight > shopWeightLimit?.value) {
          await state.overWeightModalRef.open();
        }

        const [payRes, payEx] = await api.shop.preCreate(null, {
          sendType: deliveryType ?? DELIVERY_TYPE_CODE.delivery,
          priceType: '10',
          currency: state.currency,
          country: currentAlpha2Code,
          receiverAddressId,
          items,
          secKill: false,
          source: orderDetails.cartOrder ? WEBSHOP_PAMENT_TYPE_CODE.cartPayment : WEBSHOP_PAMENT_TYPE_CODE.directPayment
        });
        if (payEx) {
          if (payEx.code == 60000000) {
            await appMethods.logoutToLogin();
          } else {
            await _methods.initPrice(orderDetails);
          }
        }
        state.prePrice = payRes;
        payRes?.items.forEach((s) => {
          const index = state.skuList.findIndex((i) => s.skuId == i.id);
          state.skuList[index] = merge(state.skuList[index], s);
        });
      }
    };

    const methods = {
      largeThanTablet,
      goNext: debounce(async () => {
        if (state.step === 1) {
          const success = await _methods.submitCheckout();
          if (!success) {
            return;
          }
        }
        if (state.step === 2) {
          const success = await _methods.paymentCheckout();
          if (!success) {
            return;
          }
        }
        loading.hide();
        ++state.step;
        await nextTick();
        scrollToTop();
      }, 300),
      onOpenPriceBreakdown: () => {
        state.openPriceBreakdown = true;
      },
      onClosePriceBreakdown: () => {
        state.openPriceBreakdown = false;
      },
      toggleDis: () => {
        state.showDisclaimer = !state.showDisclaimer;
      },
      showDis: () => {
        state.showDisclaimer = true;
      },
      hideDis: () => {
        state.showDisclaimer = false;
      }
    };

    const merchandiseCheckoutMethods = {
      goNext: (options) => {
        if (state.step === 0) {
          state.email = options.email;
        }
        methods.goNext();
      },
      validateGeeTest() {
        return state.geeCaptchaRef.validate();
      }
    };
    provide('merchandiseCheckoutMethods', merchandiseCheckoutMethods);
    watch(
      () => state.bound,
      () => {
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.deliverySection?.fields?.personalForm.id);
        }
      },
      { immediate: true, deep: true }
    );
    watch(
      () => state.step,
      (v) => {
        gtmFormStep(formType?.value, props.fields.deliverySection?.fields?.personalForm.id, appStore?.loginInfo, Number(v.value) + 1, props.fields.steps[v].fields.title.value.toLowerCase(), null);
      },
      { immediate: true }
    );
    onMounted(async () => {
      if (!appStore?.hasLoggedIn) {
        redirectToLogin(props.page, router);
        return;
      }
      [token, orderDetails, clientSecret, redirectStatus, orderCode] = getQueryStrings('access_token', 'order_details', 'payment_intent_client_secret', 'redirect_status', 'order_code');

      if (isNullOrEmpty(orderDetails) && isNullOrEmpty(orderCode)) {
        window.history.back();
      }
      if (!isNullOrEmpty(orderCode)) {
        await _methods.checkOrder(orderCode);
        state.loaded = true;
        loading.hide();
      } else if (!isNullOrEmpty(clientSecret)) {
        state.orderInfo = webStorage.get(S_SHOP_ORDER_INFO);
        await _methods.checkPayment();
      } else {
        loading.show();
        if (!isNullOrEmpty(token)) {
          const [userInfo] = await api.cidp.user.info(null, null, {
            headers: {
              authorization: token
            }
          });
          if (userInfo) {
            userInfo.token = token;
            await appStore.setLoginInfo(userInfo);
          }
        }
        if (appStore?.hasLoggedIn) {
          await _methods.checkBind();
          if (state.bound.email) {
            state.email = appStore.loginInfo.email;
            state.step = 1;
            scrollToTop();
          }
        } else {
          await appMethods.logoutToLogin();
        }
        await state.geeCaptchaRef.init();
        orderDetails = decodeData(orderDetails);
        await _methods.initPrice(orderDetails);
        state.loaded = true;
        loading.hide();
        gtmFormView(formType?.value, props.fields.deliverySection?.fields?.personalForm.id);
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      currentAlpha2Code
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-checkout-life {
  &__main {
    padding: 40px grid-width-m(1) 120px grid-width-m(1);
  }
  .e-dropdown__desc {
    a {
      text-decoration: underline;
    }
  }
  &__aside {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: $black;
    color: $white;
    padding-top: #{mobile-header-height()};
    overflow-y: auto;
    &-header {
      height: 56px;
      padding: 24px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    &-exit {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .e-icon {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .e-site-button {
    &__text {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &-price-mobile {
    position: sticky;
    bottom: 0;
    width: 100%;
    color: $white;
    padding: 16px 24px 24px;
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    &-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 4px;
      margin-top: 12px;
      .e-icon {
        display: flex;
        align-items: center;
        > svg {
          padding: 2px;
        }
      }
    }
    &-bottom {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 20px;
      &-left {
        display: flex;
        flex-direction: row;
        gap: 6px;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;
        > p,
        div,
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }
        &-des {
          font-size: 12px;
          line-height: 20px;
          opacity: 0.5;
        }
      }
      &-right {
        font-size: 16px;
        line-height: 24px;
      }
      &-disclaimer {
        position: absolute;
        bottom: 60px;
        padding: 24px;
        color: $black;
        background-color: $grey-light;
        z-index: 1;
      }
    }
  }

  @include tablet-landscape {
    &__wrapper {
      @include grid-container;
      height: 100%;
    }
    &__main {
      @include grid-block(1, 12);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
    }
    &__content {
      padding: 0 grid-width(2);
      transition: all 0.4s cubic-bezier(0.355, 0.005, 0.26, 1);
    }
    &__aside {
      @include grid-block(13, 12);
      height: 100vh;
      padding-top: 0;
      position: sticky;
    }
  }
}
</style>
