<template>
  <OverallSettings v-bind="$props">
    <div class="c-model-hero-image" v-if="fields">
      <AdaptiveBackImage
        class="c-model-hero-image__backgroud"
        :class="{ 'bottom-margin': !isNullOrWhitespace(fields.video.value) || !isNullOrWhitespace(fields.videoImage.value) }"
        parallaxName="modelHeroImage"
        :pc-image="fields.backgroundImagePC"
        :mobile-image="fields.backgroundImageMobile"
      >
        <div class="c-model-hero-image__content" :class="{ reverse: fields.reverse.value }" ref="wrapperEl">
          <div class="c-model-hero-image__title-group" v-if="fields.title && fields.title.length">
            <AnimatedContent type="skew-in" v-for="(item, i) in fields.title" :key="item.id" :delay="1000 + 70 * i">
              <Heading class="c-model-hero-image__title-group-title" :field="item.fields.text" :type="fields.titleType" rich />
            </AnimatedContent>
          </div>
          <div class="c-model-hero-image__right-group">
            <div class="c-model-hero-image__right-top-group" v-if="fields.rightTop">
              <div class="c-model-hero-image__right-top-group-mobile" v-if="isMobile()">
                <AnimatedContent type="skew-in" :delay="1200">
                  <template v-for="item in fields.rightTop.fields.title" :key="item.id">
                    <JssRichText class="c-model-hero-image__right-top-group-title" :field="item.fields.text" tag="span" />
                  </template>
                </AnimatedContent>
              </div>
              <div class="c-model-hero-image__right-top-group-pc" v-else>
                <AnimatedContent type="skew-in" v-for="(item, i) in fields.rightTop.fields.title" :key="item.id" :delay="1600 + 70 * i">
                  <JssRichText class="c-model-hero-image__right-top-group-title" :field="item.fields.text" />
                </AnimatedContent>
              </div>
            </div>
            <div class="c-model-hero-image__right-bottom-group" v-if="fields.rightBottom">
              <AnimatedContent type="fade-in" :delay="1600">
                <JssRichText class="c-model-hero-image__right-bottom-group-body" :field="fields.rightBottom.fields.body" />
              </AnimatedContent>
              <div class="c-model-hero-image__right-bottom-group-btns">
                <AnimatedContent type="bottom-fade-in" v-for="(btn, i) in fields.rightBottom.fields.buttons" :key="btn.id" :delay="1000 + 200 * i" offset="110%">
                  <SiteButton class="c-model-hero-image__btn" v-bind="btn" />
                </AnimatedContent>
              </div>
            </div>
          </div>
          <div class="c-model-hero-image__left-bottom-group" v-if="fields.leftBottom">
            <AnimatedContent type="fade-in-top-left" ease="cubic-bezier(0.055, 0.215, 0.000, 1.000)" :duration="500" :delay="470">
              <Icon
                :svg="fields.leftBottom.fields.icon.value.svgCode"
                class="c-model-hero-image__left-bottom-group-icon"
                :class="`__c-${fields.leftBottom.fields.iconColor?.fields.phrase.value ?? 'black'}`"
              />
            </AnimatedContent>
            <AnimatedContent type="bottom-fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="800" :delay="600">
              <JssRichText class="c-model-hero-image__left-bottom-group-title" :field="fields.leftBottom.fields.title" />
            </AnimatedContent>
            <AnimatedContent type="bottom-fade-in" ease="cubic-bezier(0.355, 0.005, 0.260, 1.000)" :duration="800" :delay="600">
              <JssRichText class="c-model-hero-image__left-bottom-group-body" :field="fields.leftBottom.fields.body" />
            </AnimatedContent>
          </div>
          <div class="c-model-hero-image__background-group" v-if="fields.backgroundGroup">
            <div class="c-model-hero-image__background-group-body">
              <JssRichText class="c-model-hero-image__background-group-body-text" :field="fields.backgroundGroup.fields.body" />
            </div>
            <div class="c-model-hero-image__background-group-dec">
              <JssRichText class="c-model-hero-image__background-group-dec-text" :field="fields.backgroundGroup.fields.dec" />
            </div>
          </div>
          <div class="c-model-hero-image__button-group" v-if="fields.buttonGroup">
            <AnimatedContent type="fade-in">
              <JssRichText class="c-model-hero-image__button-group-text" :field="fields.buttonGroup.fields.body" />
            </AnimatedContent>
            <AnimatedContent type="bottom-fade-in" :delay="500">
              <SiteButton class="c-model-hero-image__button-group-btn" v-bind="fields.buttonGroup.fields.button" />
            </AnimatedContent>
          </div>
        </div>
      </AdaptiveBackImage>
      <div class="c-model-hero-image__video-group" v-if="!isNullOrWhitespace(fields.video.value) || !isNullOrWhitespace(fields.videoImage.value)">
        <VideoPlayer :options="videoOptions" showPlayButton v-if="fields.video?.value" />
        <BackgroundImage :image="fields.videoImage" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, computed } from 'vue';
import { isMobile } from '@/utils/dom-utils';
import { isNullOrWhitespace } from '@/utils/obj-utils';
export default {
  name: 'ModelHeroImage',
  props: {
    fields: {
      Object
    }
  },
  setup(props) {
    const state = reactive({
      wrapperEl: null
    });
    const computes = {
      videoOptions: computed(() => methods.buildVideoOps(props?.fields?.video))
    };
    const methods = {
      isMobile,
      isNullOrWhitespace,
      buildVideoOps(video) {
        return {
          controls: true,
          autoplay: false,
          muted: true,
          loop: true,
          poster: props.fields.videoImage?.value?.src,
          disableFullscreen: props.fields.videoDisablefullscreen?.value ?? false,
          disableUnmute: props.fields.videoDisableUnmute?.value ?? false,
          sources: [
            {
              type: 'video/mp4',
              src: video.value
            }
          ]
        };
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
@import '../styles/rte/backgroundColor.scss';
@import '../styles/rte/fontSize.scss';
.c-model-hero-image {
  position: relative;
  font-weight: 300;
  padding-bottom: $space-4;
  &__backgroud {
    padding-bottom: 40px;
    &.bottom-margin {
      margin-bottom: 180px;
      padding-bottom: 300px;
    }
  }
  &__content {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 0 grid-width-m(1);
    &-background-image {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__title-group {
    font-size: 140px;
    line-height: 1;
    font-weight: 250;
    letter-spacing: -20px;
    padding-top: $space-130;
    > .e-animated-content + .e-animated-content {
      text-indent: 80px;
    }
  }
  &__left-bottom-group {
    margin-top: $space-140;
    &-title {
      font-size: 14px;
      margin-top: $space-8;
    }
    &-body {
      font-size: 32px;
    }
    .e-icon {
      display: block;
      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__right-top-group {
    letter-spacing: -3px;
    margin-top: $space-130;
    &-title {
      font-size: 42px;
      line-height: 36px;
      display: inline-block;
    }
    &-body {
      display: block;
      margin-top: $space-20;
    }
  }
  &__right-bottom-group {
    display: flex;
    flex-direction: column;
    margin-top: $space-40;
    &-body {
      margin-bottom: $space-24;
    }
    .e-site-button {
      width: 100%;
    }
    .e-animated-content + .e-animated-content {
      margin-top: $space-10;
    }
    .e-animated-content.animating {
      overflow: unset;
    }
  }
  &__background-group {
    margin-top: $space-40;
    &-body {
      display: inline-block;
      font-style: italic;
      font-weight: 500;
      font-size: 21px;
      line-height: 18px;
      letter-spacing: -1px;
    }
    &-dec {
      margin-top: $space-12;
    }
  }
  &__button-group {
    margin-top: $space-60;
    &-text {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
    }
    .e-site-button {
      width: 100%;
      margin-top: $space-24;
    }
  }
  &__video-group {
    position: absolute;
    left: 0;
    bottom: 150px;
    margin-top: $space-140;
    height: grid-width-m(math.div(11 * 9, 16));
    width: grid-width-m(11);
    color: $white;
    .e-video-player {
      height: 100%;
    }
    .e-background-image {
      height: 100%;
    }
  }

  @include tablet {
    &__backgroud {
      padding-bottom: 0;
      &.bottom-margin {
        margin-bottom: 200px;
      }
    }
    &__content {
      @include grid-container;
      grid-template-columns: repeat(24, 1fr);
      padding: 0;
      padding-top: 200px;
      padding-bottom: 60px;
    }
    &__title-group {
      @include grid-block(2, 14, 1);
      font-size: 140px;
      line-height: 1;
      padding-top: 0;
      margin-top: -10px;
      & > div:last-child {
        margin-top: 20px;
      }
      > .e-animated-content + .e-animated-content {
        text-indent: 100px;
        margin-top: $space-32;
      }
    }
    &__left-bottom-group {
      @include grid-block(3, 14, 2);
      margin-top: $space-130;
      &-icon {
        margin-left: -20px;
      }
    }
    &__right-group {
      @include grid-block(15, 6, 1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__right-top-group {
      margin-top: 0;
      letter-spacing: unset;
      &-title {
        max-width: 280px;
        font-size: 60px;
        line-height: 60px;
      }
    }
    &__right-bottom-group {
      margin-top: 60px;
      margin-bottom: 130px;
      &-body {
        margin-bottom: 30px;
      }
      &-btns {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .e-site-button {
        width: $space-60 * 3;
        font-size: 14px;
      }
      .e-animated-content + .e-animated-content {
        margin-left: 0;
        margin-top: $space-10;
      }
    }
    &__background-group {
      @include grid-block(17, 6, 3);
      margin-top: $space-60;
      &-body {
        font-style: italic;
        font-size: 28px;
        line-height: 25px;
        margin-top: 0;
      }
      &-dec {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__button-group {
      @include grid-block(17, 5, 4);
      margin-top: 140px;
      .e-site-button {
        width: $space-60 * 3;
        margin-top: $space-20;
        font-size: 14px;
      }
    }
    &__video-group {
      left: grid-width(1);
      bottom: 140px;
      @include grid-block(2, 14, 4);
      height: grid-width(math.div(14 * 9, 16));
      width: grid-width(14);
      padding: 0;
      margin: 0;
      .e-video-player {
        height: 100%;
      }
    }
  }

  @include desktop {
    &__backgroud {
      padding-bottom: 0;
      &.bottom-margin {
        margin-bottom: 260px;
      }
    }
    &__content {
      @include grid-container;
      padding: 0;
      padding-top: $space-100;
      padding-bottom: 100px;
      &.reverse {
        .c-model-hero-image {
          &__background-group {
            @include grid-block(4, 5, 3);
          }
          &__button-group {
            @include grid-block(4, 5, 4);
          }
          &__video-group {
            @include grid-block(11, 14);
          }
        }
      }
    }
    &__title-group {
      @include grid-block(2, 14, 1);
      font-size: 280px;
      line-height: 400px;
      padding-top: 0;
      margin-top: -90px;
      & > div:last-child {
        margin-top: -110px;
      }
      > .e-animated-content + .e-animated-content {
        text-indent: 180px;
        margin-top: -$space-120;
      }
    }
    &__left-bottom-group {
      @include grid-block(3, 14, 2);
      margin-top: $space-130;
      &-icon {
        margin-left: -20px;
      }
    }
    &__right-group {
      @include grid-block(17, 5, 1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__right-top-group {
      margin-top: 0;
      letter-spacing: unset;
      &-title {
        max-width: 280px;
        font-size: 60px;
        line-height: 60px;
      }
    }
    &__right-bottom-group {
      margin-bottom: 130px;
      &-body {
        margin-bottom: 45px;
      }
      &-btns {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      .e-site-button {
        width: $space-60 * 3;
        font-size: 14px;
      }
      .e-animated-content + .e-animated-content {
        margin-left: $space-30;
        margin-top: 0;
      }
    }
    &__background-group {
      @include grid-block(17, 6, 3);
      margin-top: $space-100;
      &-body {
        font-style: italic;
        font-size: 28px;
        line-height: 25px;
        margin-top: 0;
      }
      &-dec {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__button-group {
      @include grid-block(17, 5, 4);
      margin-top: 280px;
      .e-site-button {
        width: $space-60 * 3;
        margin-top: $space-20;
        font-size: 14px;
      }
    }
    &__video-group {
      left: grid-width(1);
      bottom: 160px;
      @include grid-block(2, 14, 4);
      height: grid-width(math.div(14 * 9, 16));
      width: grid-width(14);
      padding: 0;
      margin: 0;
      margin-top: $space-100;
      .e-video-player {
        height: 100%;
      }
    }
  }

  @include desktop-large {
    &__content {
      padding-top: $space-120;
    }
    &__backgroud {
      padding-bottom: 0;
      &.bottom-margin {
        margin-bottom: 310px;
      }
    }
    &__title-group {
      letter-spacing: -14px;
      font-size: 400px;
      margin-top: -50px;
      margin-left: -60px;
      > .e-animated-content + .e-animated-content {
        margin-top: 20px;
        text-indent: 210px;
      }
    }
    &__right-bottom-group {
      margin-bottom: 90px;
    }
    &__left-bottom-group {
      margin-top: 200px;
    }
    &__background-group {
      @include grid-block(17, 4, 3);
      margin-top: 120px;
    }
    &__video-group {
      bottom: 220px;
      margin-top: 120px;
    }
    &__button-group {
      margin-top: 430px;
    }
  }
}
</style>
