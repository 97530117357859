<template>
  <OverallSettings v-bind="$props">
    <div class="c-mixed-image-grid" v-if="fields">
      <div class="c-mixed-image-grid__content" :class="[sequence, countPerView]">
        <AdaptiveBackImage v-for="(image, index) in fields.images" :class="'c-mixed-image-grid__image' + index" :key="image.id" :pc-image="image.fields.image" :mobile-image="image.fields.mobileImage">
          <div class="c-mixed-image-grid__tag-items">
            <Tag class="c-mixed-image-grid__tag-item" v-for="item in image.fields.tags" :key="item.id" v-bind="item" />
          </div>
        </AdaptiveBackImage>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'MixedImageGrid',
  props: {
    fields: {
      type: Object
    }
  },

  setup(props, context) {
    const computes = {
      sequence: computed(() => (props.fields.bigOnRight?.value ? 'reverse' : 'serial')),
      countPerView: computed(() => 'images-count' + props.fields.countPerView?.fields.text.value)
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
$c: c-mixed-image-grid;

.c-mixed-image-grid {
  @include component-overall-settings;
  @include component-themes;
  color: $white;
  &__content {
    @include grid-container;
    position: relative;
  }
  &__tag-items {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    .#{$c}__tag-item {
      margin-right: $space-20;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__image0 {
    @include grid-block(1, 24);
    height: grid-width(24);
  }
  &__image1,
  &__image2,
  &__image3,
  &__image4,
  &__image5,
  &__image6 {
    height: grid-width(16);
    &:nth-child(odd) {
      @include grid-block(13, 12);
    }
    &:nth-child(even) {
      @include grid-block(1, 12);
    }
  }
  @include tablet-landscape {
    &__tag-items {
      top: 30px;
      right: 30px;
    }
    .images-count4 {
      &.serial {
        .#{$c}__image0 {
          @include grid-block(1, 16, 1, 24);
          height: 100%;
        }
        .#{$c}__image1 {
          @include grid-block(17, 8, 1, 8);
          height: grid-width(math.div(8 * 9, 16));
        }
        .#{$c}__image2 {
          @include grid-block(17, 8, 9, 8);
          height: grid-width(math.div(8 * 9, 16));
        }
        .#{$c}__image3 {
          @include grid-block(17, 8, 16, 8);
          height: grid-width(math.div(8 * 9, 16));
        }
      }
      &.reverse {
        .#{$c}__image0 {
          @include grid-block(1, 8, 16, 8);
          height: grid-width(math.div(8 * 9, 16));
        }
        .#{$c}__image1 {
          @include grid-block(1, 8, 1, 8);
          height: grid-width(math.div(8 * 9, 16));
        }
        .#{$c}__image2 {
          @include grid-block(1, 8, 9, 8);
          height: grid-width(math.div(8 * 9, 16));
        }
        .#{$c}__image3 {
          @include grid-block(9, 16, 1, 24);
          height: 100%;
        }
      }
    }
    .images-count5 {
      &.serial {
        .#{$c}__image0 {
          @include grid-block(1, 16, 1, 18);
          height: grid-width(8);
        }
        .#{$c}__image1 {
          @include grid-block(17, 8, 1, 18);
          height: grid-width(8);
        }
      }
      &.reverse {
        .#{$c}__image0 {
          @include grid-block(9, 24, 1, 18);
          height: grid-width(8);
        }
        .#{$c}__image1 {
          @include grid-block(1, 8, 1, 18);
          height: grid-width(8);
        }
      }
      .#{$c}__image2 {
        @include grid-block(1, 8, 19, 6);
        height: grid-width(math.div(8 * 9, 16));
      }
      .#{$c}__image3 {
        @include grid-block(9, 8, 19, 6);
        height: grid-width(math.div(8 * 9, 16));
      }
      .#{$c}__image4 {
        @include grid-block(17, 8, 19, 6);
        height: grid-width(math.div(8 * 9, 16));
      }
    }
    .images-count6 {
      &.serial {
        .#{$c}__image0 {
          @include grid-block(1, 16, 1, 18);
          height: grid-width(8);
        }
        .#{$c}__image1 {
          @include grid-block(17, 8, 1, 9);
          height: grid-width(4);
        }
        .#{$c}__image2 {
          @include grid-block(17, 8, 10, 9);
          height: grid-width(4);
        }
      }
      &.reverse {
        .#{$c}__image0 {
          @include grid-block(9, 16, 1, 18);
          height: grid-width(8);
        }
        .#{$c}__image1 {
          @include grid-block(1, 8, 1, 9);
          height: grid-width(4);
        }
        .#{$c}__image2 {
          @include grid-block(1, 8, 10, 9);
          height: grid-width(4);
        }
      }
      .#{$c}__image3 {
        @include grid-block(9, 8, 19, 6);
        height: grid-width(math.div(8 * 9, 16));
      }
      .#{$c}__image4 {
        @include grid-block(17, 8, 19, 6);
        height: grid-width(math.div(8 * 9, 16));
      }
      .#{$c}__image5 {
        @include grid-block(1, 8, 19, 6);
        height: grid-width(math.div(8 * 9, 16));
      }
    }
  }
}
</style>
