<template>
  <div class="c-merchandise-confirmation-life" v-if="fields">
    <div class="c-merchandise-confirmation-life__main" v-if="orderDetails">
      <div class="c-merchandise-confirmation-life__main-content">
        <div class="c-merchandise-confirmation-life-label main">
          <Icon class="c-merchandise-confirmation-life-label-icon" :svg="fields.mainIcon?.value.svgCode" />
          <JssRichText class="c-merchandise-confirmation-life-label-text" :field="fields.mainLabel" />
        </div>
        <div class="c-merchandise-confirmation-life__main-details">
          <div class="c-merchandise-confirmation-life__main-details-number">{{ `${$t('Order number')}: ${orderDetails.orderCode}` }}</div>
          <div class="c-merchandise-confirmation-life__main-details-email">
            <JssRichText class="c-merchandise-confirmation-life__main-details-label" :field="fields.emailLabel" />
            <div class="c-merchandise-confirmation-life__main-details-text">{{ orderDetails.email }}</div>
          </div>
          <br />
          <div class="c-merchandise-confirmation-life__main-details-des">
            <JssRichText class="c-merchandise-confirmation-life__main-details-label" :field="fields.description" />
          </div>
          <div class="c-merchandise-confirmation-life__main-details-btns">
            <SiteButton class="detail-button" :fields="detailButton" />
            <SiteButton class="faq-button" v-bind="fields.faqButton" />
          </div>
        </div>
      </div>
    </div>
    <div class="c-merchandise-confirmation-life__summary" v-if="orderDetails">
      <div class="c-merchandise-confirmation-life__summary-content">
        <div class="c-merchandise-confirmation-life-label">
          <JssRichText class="c-merchandise-confirmation-life-label-text" :field="fields.summaryLabel" />
        </div>
        <div class="c-merchandise-confirmation-life__summary-main">
          <div class="c-merchandise-confirmation-life__summary-list">
            <div class="c-merchandise-confirmation-life__summary-item row" v-for="item in orderDetails?.items" :key="item.skuId">
              <div class="c-merchandise-confirmation-life__summary-item-left">
                <img :src="item.image" :alt="item.name" />
              </div>
              <div class="c-merchandise-confirmation-life__summary-item-right">
                <div class="c-merchandise-confirmation-life__summary-item-right-top">
                  <div class="c-merchandise-confirmation-life__summary-item-right-top-name">{{ item.name }}</div>
                  <div class="c-merchandise-confirmation-life__summary-item-right-top-des">{{ item.spec.replace(/\|/g, ', ') }}</div>
                  <div class="c-merchandise-confirmation-life__summary-item-right-top-num">{{ `${$t('Qty')}: ${item.num}` }}</div>
                </div>
                <div class="c-merchandise-confirmation-life__summary-item-right-bottom">
                  {{ $formatShopMoney(item.price ?? 0, orderDetails.currency) }}
                </div>
              </div>
            </div>
          </div>
          <div class="c-merchandise-confirmation-life__summary-details">
            <div class="c-merchandise-confirmation-life__summary-details-item">
              <div class="c-merchandise-confirmation-life__summary-details-item-label">
                {{ $t('Subtotal') }}
              </div>
              <div class="c-merchandise-confirmation-life__summary-details-item-num">
                {{ $formatShopMoney(orderDetails.itemFee ?? 0, orderDetails.currency) }}
              </div>
            </div>
            <div class="c-merchandise-confirmation-life__summary-details-item">
              <div class="c-merchandise-confirmation-life__summary-details-item-label">
                {{ $t('Shipping') }}
              </div>
              <div class="c-merchandise-confirmation-life__summary-details-item-num">
                {{ !!orderDetails.expressFee ? $formatShopMoney(orderDetails.expressFee ?? 0, orderDetails.currency) : $t('Free') }}
              </div>
            </div>
            <div class="c-merchandise-confirmation-life__summary-details-item" v-show="!$equalString(pageAlpha2Code, 'GB')">
              <div class="c-merchandise-confirmation-life__summary-details-item-label">
                {{ $tu('VAT') }}
              </div>
              <div class="c-merchandise-confirmation-life__summary-details-item-num">
                {{ $formatShopMoney(orderDetails.vat ?? 0, orderDetails.currency) }}
              </div>
            </div>
          </div>
          <div class="c-merchandise-confirmation-life__summary-total">
            <div
              class="c-merchandise-confirmation-life__summary-total-left"
              @click="!largeThanTablet() && toggleDis()"
              @mouseover="largeThanTablet() && showDis()"
              @mouseout="largeThanTablet() && hideDis()"
              :style="{ cursor: showDisclaimer ? 'pointer' : 'default' }"
            >
              <div class="c-merchandise-confirmation-life__summary-total-left-label">{{ $tu('Total') }}</div>
              <Icon :field="fields.disclaimerIcon" v-if="!$isNullOrEmpty(fields.lifePriceDisclaimer)" />
              <div class="c-merchandise-confirmation-life__summary-total-left-des">{{ !$equalString(pageAlpha2Code, 'GB') ? $t('Excluded VAT') : $t('Including VAT') }}</div>
            </div>
            <span>{{ $formatShopMoney(orderDetails.fee ?? 0, orderDetails.currency) }}</span>
            <div
              class="c-merchandise-confirmation-life__summary-total-left-disclaimer"
              v-html="$formatString(fields.lifePriceDisclaimer?.fields.text.value, { price: $formatShopMoney(orderDetails.fee ?? 0, orderDetails.currency) })"
              v-if="showDisclaimer"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="c-merchandise-confirmation-life__details" v-if="orderDetails">
      <div class="c-merchandise-confirmation-life__details-content">
        <div class="c-merchandise-confirmation-life-label">
          <JssRichText class="c-merchandise-confirmation-life-label-text" :field="fields.detailsLabel" />
        </div>
        <div class="c-merchandise-confirmation-life__details-main">
          <div class="c-merchandise-confirmation-life__details-item">
            <div class="c-merchandise-confirmation-life__details-item-label">{{ $tu('Order number') }}</div>
            <div class="c-merchandise-confirmation-life__details-item-text">{{ orderDetails.orderCode }}</div>
          </div>
          <div class="c-merchandise-confirmation-life__details-item">
            <div class="c-merchandise-confirmation-life__details-item-label">{{ $tu('Order date') }}</div>
            <div class="c-merchandise-confirmation-life__details-item-text">{{ $formatDate(orderDetails.dateCreate, 'dd-MM-yyyy HH:mm') }}</div>
          </div>
          <div class="c-merchandise-confirmation-life__details-item">
            <div class="c-merchandise-confirmation-life__details-item-label">{{ $tu('Shipping address') }}</div>
            <div class="c-merchandise-confirmation-life__details-item-text">
              <div>{{ orderDetails.receiverNameFirst }} {{ orderDetails.receiverNameLast }}</div>
              <div>{{ orderDetails.receiverAddressDetail.split(',')[0] }}</div>
              <div v-if="orderDetails.receiverAddressDetail.split(',')[1]">{{ orderDetails.receiverAddressDetail.split(',')[1] }}</div>
              <div>{{ orderDetails.receiverCityName }}</div>
              <div>{{ orderDetails.receiverCountryName }}</div>
              <div>{{ `+${orderDetails.areaCode} ${orderDetails.receiverPhone}` }}</div>
            </div>
          </div>
          <div class="c-merchandise-confirmation-life__details-item">
            <div class="c-merchandise-confirmation-life__details-item-label">{{ $tu('Billing address') }}</div>
            <div class="c-merchandise-confirmation-life__details-item-text">
              <div>{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[0] }}</div>
              <div v-if="orderDetails.billingAddressDTO?.detailInfo.split(',')[1]">{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[1] }}</div>
              <div>{{ orderDetails.billingAddressDTO?.cityName }}</div>
              <div>{{ orderDetails.billingAddressDTO?.nationalName }}</div>
            </div>
          </div>
          <div class="c-merchandise-confirmation-life__details-item">
            <div class="c-merchandise-confirmation-life__details-item-label">{{ $tu('Email address') }}</div>
            <div class="c-merchandise-confirmation-life__details-item-text">{{ appStore.loginInfo.email }}</div>
          </div>
          <div class="c-merchandise-confirmation-life__details-item" v-if="appStore?.loginInfo?.mobileAreaCode && appStore?.loginInfo?.phone">
            <div class="c-merchandise-confirmation-life__details-item-label">{{ $tu('Phone number') }}</div>
            <div class="c-merchandise-confirmation-life__details-item-text">{{ `+${appStore?.loginInfo?.mobileAreaCode} ${appStore?.loginInfo?.phone}` }}</div>
          </div>
          <!-- <div class="c-merchandise-confirmation-life__details-item">
          <div class="c-merchandise-confirmation-life__details-item-label">{{ $t('Invoice').toUpperCase() }}</div>
          <div class="c-merchandise-confirmation-life__details-item-text">
            <div class="c-merchandise-confirmation-life__invoice-id">{{ $t('Invoice') + ' ' + invoiceDetail.invoiceNo }}</div>
            <div class="c-merchandise-confirmation-life__invoice-amount">{{ $formatShopMoney(invoiceDetail.invoiceMoney ?? 0, orderDetails.currency) }}</div>
            <div class="c-merchandise-confirmation-life__invoice-date">{{ $formatDate(invoiceDetail.createTime, 'dd-MM-yyyy') }}</div>
            <div class="c-merchandise-confirmation-life__invoice-file" v-if="invoiceDetail.invoiceUrl">
              <a :href="invoiceDetail.invoiceUrl" target="_blank"> <Icon name="invoice-download" /> {{ $t('Download invoice') }} </a>
            </div>
          </div>
        </div>
        <div class="c-merchandise-confirmation-life__details-item">
          <div class="c-merchandise-confirmation-life__details-item-label">{{ $t('Payment method').toUpperCase() }}</div>
          <div class="c-merchandise-confirmation-life__details-item-text">
            <div class="c-merchandise-confirmation-life__payment-number">
              {{ $t('Visa') + ' ' + '******0477' }}
            </div>
            <div class="c-merchandise-confirmation-life__payment-date">
              {{ $t('Expires') + ': ' + '05-2025' }}
            </div>
          </div>
        </div> -->
        </div>
      </div>
    </div>
    <div class="c-merchandise-confirmation-life__faq" v-if="fields.faq">
      <div class="c-merchandise-confirmation-life__faq-left">
        <JssRichText class="c-merchandise-confirmation-life__faq-left-title" :field="fields.faq?.fields?.title" />
        <JssRichText class="c-merchandise-confirmation-life__faq-left-subtitle" :field="fields.faq?.fields?.description" />
      </div>
      <div class="c-merchandise-confirmation-life__faq-right">
        <div class="c-merchandise-confirmation-life__faq-right-item" :class="index === 0 ? 'active' : null" v-for="(item, index) in fields.faq?.fields?.list" :key="index">
          <div class="c-merchandise-confirmation-life__faq-right-row" @click="rowClick(index)">
            <JssRichText :field="item.fields.question" class="c-merchandise-confirmation-life__faq-right-row-text" />
            <Icon name="down" size="tiny" class="c-merchandise-confirmation-life__faq-right-row-icon" :class="item.selected ? 'open' : ''" />
          </div>
          <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
            <JssRichText :field="item.fields.answer" class="c-merchandise-confirmation-life__faq-right-text" v-if="item.selected" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs, reactive, computed, onMounted, inject } from 'vue';
import { getPageAlpha2Code } from '@/utils/site-utils';
import { useRouter } from 'vue-router';
import { getQueryStrings, appendQuery, redirectToLogin } from '@/utils/uri-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import api from '@/api';
import { merge } from 'lodash';
import gsap from 'gsap';
export default {
  name: 'MerchandiseConfirmationLife',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const router = useRouter();
    const appStore = useAppStore();
    let orderId;
    const state = reactive({
      orderDetails: null,
      invoiceDetail: null,
      totalPrice: 0,
      pageAlpha2Code: null,
      showDisclaimer: false
    });

    const computes = {
      detailButton: computed(() =>
        merge({}, props.fields.detailButton?.fields, {
          link: {
            value: {
              href: appendQuery(props.fields.detailButton?.fields.link.value.href, { orderCode: orderId })
            }
          }
        })
      )
    };

    const methods = {
      largeThanTablet,
      getOrderDetails: async (id) => {
        const [orderRes, orderEx] = await api.shop.orderDetails({ orderId: id });
        if (orderEx) {
          await toast.showEx(orderEx);
          if (orderEx.code == 60000000 || orderEx.code == 60000009 || orderEx.code == 60000110) {
            setTimeout(() => {
              redirectToLogin(props.page, router);
            }, 2000);
          }
          return null;
        }
        state.orderDetails = orderRes;
        orderRes.items.forEach((i) => {
          state.totalPrice += Number(i.price);
        });

        // const [invoiceRes, invoiceEx] = await api.shop.invoiceDetails({ orderId: id });
        // if (invoiceEx) {
        //   await toast.showEx(invoiceEx);
        //   return null;
        // }
        // state.invoiceDetail = invoiceRes;
      },
      rowClick(i) {
        let faq = props?.fields?.faq;
        faq?.fields.list?.map((item, index) => {
          if (index === i) {
            item.selected = !item.selected;
          }
        });
      },
      onItemBeforeEnter(el) {
        el.style.height = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 10,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      toggleDis: () => {
        state.showDisclaimer = !state.showDisclaimer;
      },
      showDis: () => {
        if (!isNullOrEmpty(props.fields.lifePriceDisclaimer)) {
          state.showDisclaimer = true;
        } else {
          state.showDisclaimer = false;
        }
      },
      hideDis: () => {
        state.showDisclaimer = false;
      }
    };

    onMounted(async () => {
      [orderId] = getQueryStrings('orderId');
      state.pageAlpha2Code = getPageAlpha2Code(props.page);
      await methods.getOrderDetails(orderId);
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.c-merchandise-confirmation-life {
  padding-top: 56px;
  &-label {
    &.main {
      text-align: center;
      padding-bottom: 56px;
    }
    &-icon {
      width: 42px;
      height: 42px;
      padding: 3px;
      svg {
        width: 100%;
        height: 100%;
        fill: none !important;
      }
    }
    &-text {
      margin-top: 14px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  &__main {
    padding: 0 24px;
    &-content {
      border-bottom: 1px solid $grey-89;
    }
    &-details {
      padding: 24px 0 68px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $grey-next;
      &-number {
        font-size: 26px;
        line-height: 32px;
        font-weight: 500;
        color: $black;
      }
      &-email {
        margin-top: 28px;
        font-weight: 700;
        color: $black;
        font-size: 16px;
        line-height: 24px;
      }
      &-label {
        color: $grey-next;
        font-weight: 400;
      }
      &-btns {
        margin-top: 28px;
        .e-site-button {
          padding: 0;
        }
        .detail-button {
          .e-site-button__text {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .faq-button {
          margin-top: 10px;
        }
      }
    }
  }
  &__summary {
    padding: 64px 24px 0;
    &-content {
      padding-bottom: 64px;
      border-bottom: 1px solid $grey-89;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px 0 48px;
      border-bottom: 1px solid $grey-89;
    }
    &-item {
      display: flex;
      flex-direction: row;
      gap: 16px;
      &-left {
        height: 100px;
        width: 80px;
        background-color: $grey-light;
        display: flex;
        align-items: center;
        > img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        font-size: 12px;
        line-height: 20px;
        &-top-des,
        &-top-num,
        &-bottom {
          opacity: 0.6;
        }
        &-top {
          &-name {
            font-weight: 700;
          }
        }
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 24px;
      padding: 16px 12px;
      border: 1px solid $grey-89;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $grey-next0;
      &-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &-delivery {
      padding: 24px 0;
      font-size: 12px;
      line-height: 20px;
      color: $grey-next;
      border-bottom: 1px solid $grey-89;
    }
    &-price {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      font-size: 16px;
      line-height: 24px;
      color: $grey-next;
      border: 1px solid var(--shades-nshadee, $grey-89);
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &-total {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      font-size: 18px;
      line-height: 28px;
      color: var(--core-carbonblack, $black);
      &-left {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        > .e-icon {
          display: flex;
        }
        &-des {
          font-size: 12px;
          line-height: 20px;
          opacity: 0.5;
        }
        &-disclaimer {
          position: absolute;
          bottom: 32px;
          padding: 24px;
          background-color: $grey-light;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: $black;
        }
      }
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 64px 24px;
    &-main {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 24px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-label {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--core-carbonblack, $black);
      }
      &-text {
        font-size: 16px;
        line-height: 24px;
        color: var(--core-carbonblack, $grey-next);
        > div + div {
          margin-top: 4px;
        }
      }
    }
  }
  &__invoice-file {
    color: $black;
    > a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }
  &__faq {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: $white;
    background-color: $black;
    padding: 56px 24px;
    &-left {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-title {
        font-size: 26px;
        line-height: 26px;
        letter-spacing: 2px;
      }
      &-subtitle {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 2px;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $grey-night;
      &-item {
        padding: 16px 0;
        border-top: 1px solid $grey-night;
        overflow: hidden;
      }
      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &-text {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &-text {
        margin-top: 16px;
        font-size: 12px;
        line-height: 20px;
        color: $grey-next;
      }
    }
  }
  @include tablet-landscape {
    padding-top: 56px;
    &-label {
      width: grid-width(6);
      &.main {
        text-align: start;
      }
      &-text {
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
    &__main {
      padding: 0 212px;
      &-content {
        display: flex;
        flex-direction: row;
        gap: 24px;
      }
      &-details {
        padding-top: 0;
        &-btns {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          > .fullWidthOnMobile {
            justify-content: flex-start !important;
          }
        }
      }
    }
    &__summary,
    &__details {
      padding: 96px 212px;
      &-content {
        display: flex;
        flex-direction: row;
        gap: 24px;
      }
      &-main {
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 48px 24px;
        margin: 0;
      }
      &-item {
        width: calc(50% - 12px);
      }
    }
    &__summary {
      padding-bottom: 0;
      &-content {
        padding-bottom: 96px;
      }
      &-list {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
      }
      &-details {
        margin-top: 48px;
      }
    }
    &__faq {
      padding: 96px 212px 120px;
      flex-direction: row;
      gap: 24px;
      &-left {
        width: grid-width(6);
        gap: 16px;
        &-title {
          font-size: 32px;
          line-height: 32px;
        }
        &-subtitle {
          font-size: 24px;
          line-height: 24px;
        }
      }
      &-right {
        height: fit-content;
        width: grid-width(10);
        &-item {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
