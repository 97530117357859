<template>
  <overall-settings v-bind="$props">
    <div class="c-news-detail-suspension" v-if="fields">
      <div class="c-news-detail-suspension-photo-video-content">
        <div class="c-news-detail-suspension-photo" @click="showFullSwiperImageFn">
          <Icon class="c-news-detail-suspension-video-icon" :svg="fields.photoIcon.value.svgCode" />
          <jss-rich-text :field="fields.photoTitle" tag="div" />
        </div>
        <div class="c-news-detail-suspension-video" @click="showFullSwiperVideoFn">
          <Icon class="c-news-detail-suspension-photo-icon" :svg="fields.videoIcon.value.svgCode" />
          <jss-rich-text :field="fields.videoTitle" tag="div" />
        </div>
      </div>

      <video-swiper
        :title="fields.fullTitle"
        :close="fields.closeIcon"
        :list="fields.videoList"
        @btn-click="hideFullSwiperVideoFn"
        :downloadvideo="fields.downloadvideo"
        :download="fields.downloadIcon"
        :left="fields.leftIcon"
        :right="fields.rightIcon"
        v-if="showFullSwiperVideo"
      />

      <image-swiper
        :title="fields.fullTitle"
        :close="fields.closeIcon"
        :list="fields.imageList"
        @btn-click="hideFullSwiperImageFn"
        :high="fields.high"
        :low="fields.low"
        :download="fields.downloadIcon"
        :left="fields.leftIcon"
        :right="fields.rightIcon"
        v-if="showFullSwiperImage"
      />
    </div>
  </overall-settings>
</template>

<script>
import { onMounted, toRefs, reactive } from 'vue';
export default {
  name: 'NewsDetailSuspension',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let state = reactive({
      showFullSwiperImage: false,
      showFullSwiperVideo: false
    });

    const methods = {
      showFullSwiperImageFn() {
        state.showFullSwiperImage = true;
        document.body.style.overflow = 'hidden';
      },
      hideFullSwiperImageFn() {
        state.showFullSwiperImage = false;
        document.body.style.overflow = 'auto';
      },
      showFullSwiperVideoFn() {
        state.showFullSwiperVideo = true;
        document.body.style.overflow = 'hidden';
      },
      hideFullSwiperVideoFn() {
        state.showFullSwiperVideo = false;
        document.body.style.overflow = 'auto';
      }
    };

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-news-detail-suspension {
  position: relative;
  width: grid-width-m(7);
  height: grid-width-m(3);
  float: right;
  &-photo-video-content {
    width: 100%;
    height: 100%;
    background: #000;
    padding: grid-width-m(0.5) grid-width-m(0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-photo {
    color: #fff;
    @include h8;
    display: flex;
    align-items: center;
  }
  &-photo-icon {
    margin-right: grid-width-m(0.3);
  }
  &-video {
    color: #fff;
    @include h8;
    display: flex;
    align-items: center;
  }
  &-video-icon {
    margin-right: grid-width-m(0.3);
  }
  @include tablet-landscape {
    position: absolute;
    width: grid-width(6);
    height: grid-width(2);
    left: grid-width(15);
    top: grid-width(10);
    margin-left: 0;
    &-photo-video-content {
      width: 100%;
      height: 100%;
      background: #000;
      padding: grid-width(0.5) grid-width(0.5);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-photo {
      color: #fff;
      @include h8;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &-photo-icon {
      margin-right: grid-width(0.3);
    }
    &-video {
      color: #fff;
      @include h8;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &-video-icon {
      margin-right: grid-width(0.3);
    }
  }
  @media (min-width: 1600px) {
    top: grid-width(7);
  }
}
html.rtl {
  .c-news-detail-suspension {
    float: left;
  }
}
</style>
