<template>
  <personal-center class="c-my-order__layout" :class="[{ empty: orders.length === 0 }]" :fields="fields?.personalCenterBar?.fields" active-menu="my-order">
    <div class="c-my-order">
      <div class="c-my-order__main" v-if="orders.length > 0">
        <div class="c-my-order__item" :class="[{ reservation: order.orderType !== '200' }]" v-for="(order, index) in orders" :key="index">
          <icon :name="order.vehicleModel?.toLowerCase()" />
          <div class="c-my-order__item-image">
            <div class="c-my-order__item-rn" v-if="order?.orderId">
              <jss-rich-text :field="ifBto(order, fields.orderNoTitle, fields.reservationNoTitle)" tag="span" />
              <p>{{ order?.orderId }}</p>
            </div>
            <div class="c-my-order__item-image-wrapper">
              <img :src="order.modelImage" :alt="order.model" v-if="order.modelImage" />
            </div>
          </div>
          <div class="c-my-order__item-statuses">
            <div class="c-my-order__item-status">
              <div class="c-my-order__item-status-label">{{ $t('Current status') }}</div>
              <div class="c-my-order__item-status-info">{{ order.status?.name ?? '' }}</div>
            </div>
            <div class="c-my-order__item-status" v-if="!$isNullOrEmpty(order.status?.nextStepDescription) || $equalString(order.orderType, '100')">
              <div class="c-my-order__item-status-label">{{ $t('Next step') }}</div>
              <div class="c-my-order__item-status-info" v-html="order.orderType === '100' ? $t('Convert to sales order') : order.status?.nextStepDescription" />
            </div>
          </div>
          <div class="c-my-order__item-bar">
            <div class="c-my-order__item-info">
              <div
                class="c-my-order__item-type"
                v-html="
                  ifBto(
                    order,
                    $formatString(fields.orderItemTitle?.value ?? '', {
                      financeType: $tu(order.paymentSchemeCode === '200' ? 'Loan' : 'Cash')
                    }),
                    fields.reservationItemTitle?.value
                  )
                "
              />
              <p class="c-my-order__item-date">{{ $formatString($t('Ordered on ##date##'), { date: $formatDate(order.createTime, 'yyyy-MM-dd') }) }}</p>
            </div>
            <site-button :fields="merge({}, fields?.detailButton?.fields, getOrderLink(order))" class="c-my-order__item-button" v-if="$equalString(order.orderType, '200')" />
            <site-button
              class="c-my-order__item-button"
              :fields="merge({}, fields?.convertButton?.fields, getMhpLink(order))"
              v-else-if="
                $equalString(order.orderType, '100') &&
                  $equalString(order.orderStatus, '200') &&
                  ($isNullOrEmpty(order.series) || $isNullOrEmpty(order.model) || orderRules.some((x) => $equalString(x.series, order.series) && x.rules.length > 0))
              "
            />
          </div>
        </div>
      </div>
      <div class="c-my-order__empty" v-else>
        <icon class="c-my-order__empty-icon" :field="fields.emptyIcon" />
        <jss-rich-text class="c-my-order__empty-body" :field="fields.emptyBody" tag="div" />
        <site-button class="c-my-order__empty-btn" :fields="fields.emptyButton?.fields" />
      </div>
    </div>
  </personal-center>
</template>

<script>
/**
 * @typedef MyOrderFields
 * @property {DropLinkField<PersonalCenterBar>} personalCenterBar
 * @property {Array<GlobalSettingEntry>} orderTypes
 * @property {ButtonField} detailButton
 * */
import { onMounted, reactive, toRefs, inject } from 'vue';
import useAppStore from '@/store/appStore';
import { canUseDOM } from '@/utils/dom-utils';
import api from '@/api';
import { merge } from 'lodash';
import { getGlobalConfigs, isLocalServer } from '@/utils/site-utils';
import { appendQuery, getBetterUrl } from '@/utils/uri-utils';
import { equalString, formatString } from '@/utils/string-utils';
import services from '@/services';
import { getCarModel } from '@/services/siteService';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'MyOrder',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    /**@type AppStore*/
    const appStore = useAppStore();
    const loading = inject('loading');
    const toast = inject('toast');
    const appMethods = inject('appMethods');
    let detailHref = null,
      btoHref = null,
      mhpHref = null;
    const state = reactive({
      orders: [],
      orderRules: []
    });
    const methods = {
      merge,
      isLocalServer,
      isBto(order) {
        return equalString(order.orderType, '200');
      },
      ifBto(order, fieldA, fieldB) {
        return methods.isBto(order) ? fieldA : fieldB;
      },
      getOrderLink(order) {
        if (!detailHref) return {};
        let href = getBetterUrl(detailHref, props.page.itemLanguage, true);
        href = appendQuery(href, {
          orderId: order.id
        });
        return {
          link: {
            value: {
              href
            }
          }
        };
      },
      getBtoLink(order) {
        if (!btoHref) return {};
        const [, targetLang] = services.order.checkOrderLang(props.page, order.countryCode);
        let href = getBetterUrl(btoHref, targetLang, true);
        href = appendQuery(href, {
          blind_order_id: order.id
        });
        return {
          link: {
            value: {
              href
            }
          }
        };
      },
      getMhpLink(order) {
        if (!mhpHref) return {};
        const [, targetLang] = services.order.checkOrderLang(props.page, order.countryCode);
        let href = formatString(mhpHref, {
          lang: targetLang,
          series: order.series?.toLowerCase() ?? 'eletre',
          model: order.model ?? 'ELETRE'
        });
        href = appendQuery(href, {
          accessToken: appStore?.loginInfo?.token,
          blindOrderId: order.id
        });
        return {
          link: {
            value: {
              href
            }
          }
        };
      }
    };
    if (canUseDOM()) {
      loading.show();
    }
    onMounted(async () => {
      const [detailLink, btoLink, mhpLink] = getGlobalConfigs(props.page, 'orderDetailLink', 'btoLink', 'mhpLink');
      detailHref = detailLink?.value?.href;
      btoHref = btoLink?.value?.href;
      mhpHref = mhpLink?.value;
      const [res, ex] = await api.order.intactList(null, {
        customerId: appStore?.loginInfo?.lotusId,
        pageNum: 0,
        pageSize: 10000
      });
      if (ex) {
        await toast.showEx(ex);
        if (ex.code === 60000000) {
          await appMethods.logoutToLogin();
        }
        loading.hide();
        return;
      }
      if (!res) return;
      // for (let item of res) {
      //   res.modelImg = buildModelImgUrl(res?.vehicleInfo?.basicInfo?.filterId, res?.vehicleInfo?.basicInfo?.featureList?.split(',') ?? []);
      // }
      const orderStatuses = await services.order.getOrderStatuses();
      const orders = res?.filter((x) => !equalString(x.orderStatus, 'New') && !equalString(x.orderStatus, '100'));
      const orderRules = [];
      for (let order of orders) {
        order.status = orderStatuses.find((x) => x.code === order.orderStatus);
        const carModel = await getCarModel(order.series ?? 'Eletre', order.model ?? 'Eletre');
        if (order.orderType === '200') {
          order.modelImage = order.carImages?.length > 0 ? order.carImages[0].imageUrl : null;
        } else {
          order.modelImage = carModel?.image?.src;
        }
        if (isNullOrEmpty(order.series) || isNullOrEmpty(order.model)) continue;
        const series = order.series;
        // model = order.model;
        if (!orderRules.find((x) => x.series === series)) {
          orderRules.push({
            series,
            orderType: '200',
            rules: []
          });
        }
      }
      state.orders = orders;
      for (let or of orderRules) {
        const [resConfig] = await api.order.getConfig(null, {
          carCode: or.series,
          carModel: '',
          orderType: or.orderType
        });
        or.rules = resConfig ?? [];
      }
      console.log('orderRules', orderRules);
      state.orderRules = orderRules;
      loading.hide();
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-my-order {
  $c: &;
  $l: #{'.s-personal-center'};
  padding: 20px;
  height: 100%;
  &__layout {
    &.empty {
      background: $white;
      color: $black;
      #{$l}__side-main {
        border-right: 1px solid rgba(#2c2c2c, 0.2);
      }
      #{$l}__main {
        display: flex;
        flex-direction: column;
        #{$l}__back {
          flex-shrink: 0;
        }
      }
    }
  }
  &__main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: $space-20;
    grid-column-gap: $space-20;
  }
  &__item {
    background: rgba($grey-neutral, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
    &:hover {
      background: rgba($grey-neutral, 0.5);
    }
    &-image-wrapper {
      min-height: 280px;
    }
    &-image {
      width: 100%;
      text-align: center;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
    }
    &-rn {
      position: absolute;
      left: 0;
      top: 0;
      text-align: start;
      padding: 16px;
    }
    &-status {
      & + & {
        margin-top: 16px;
      }
    }
    &-button {
      margin-top: 16px;
    }
    &-statuses,
    &-bar {
      align-self: stretch;
      padding: 20px 16px 16px;
      margin-top: 20px;
      border-top: 1px solid rgba($white, 0.3);
    }
    &.reservation {
      #{$c}__item-image {
        width: 100%;
        text-align: center;
        position: relative;
        img {
          width: 80%;
          height: auto;
        }
      }
      #{$c}__item-rn {
        position: static;
        margin-bottom: 24px;
      }
    }
  }
  &__empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-icon {
      margin-bottom: 24px;
    }
    &-body {
      align-self: stretch;
      margin-bottom: 24px;
    }
  }
  @include tablet-landscape {
    padding: 60px;
    &__main {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: $space-40;
      grid-column-gap: $space-40;
    }
    &__item {
      &-statuses,
      &-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &-statuses {
        align-items: flex-start;
      }
      &-bar {
        align-items: center;
      }
      &-status {
        width: calc(50% - 12px);
        flex-shrink: 0;
        & + & {
          margin-top: 0;
        }
      }
      &-button {
        margin-top: 0;
      }
    }

    &__empty {
      &-icon {
        svg {
          width: grid-width(2);
          height: auto;
        }
      }
    }
  }
}
</style>
