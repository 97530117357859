<template>
  <DashboardLayout :fields="fields?.dashboardHeader?.fields" active-menu="order-details">
    <div class="c-merchandise-order-details" v-if="fields">
      <div class="c-merchandise-order-details__top">
        <RouterLink class="c-merchandise-order-details__back" :to="`/${lang}${fields.orderListLink?.value?.href}`">
          <Icon class="c-merchandise-order-details__back-icon" name="back" />
          <JssRichText class="c-merchandise-order-details__back-text" :field="fields.goBackText" />
        </RouterLink>
        <div class="c-merchandise-order-details__orderNo" v-if="orderDetails?.orderCode">
          <div class="c-merchandise-order-details__orderNo-title" v-html="$tu('Order No')" />
          <div class="c-merchandise-order-details__orderNo-desc">{{ orderDetails.orderCode }}</div>
        </div>
        <div class="c-merchandise-order-details__content" ref="swiperEl">
          <div class="c-merchandise-order-details__content-pagination" v-if="orderDetails?.items?.length > 4">
            <div class="c-merchandise-order-details__content-pagination-prev" ref="prevEl">
              <Icon name="left" size="small" />
            </div>
            <div class="c-merchandise-order-details__content-pagination-next" ref="nextEl">
              <Icon name="right" size="small" />
            </div>
          </div>
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in orderDetails?.items" :key="item.id">
              <img :src="item.image" :alt="item.name" class="c-merchandise-order-details__content-image" />
            </div>
          </div>
        </div>
      </div>
      <div class="c-merchandise-order-details__center" ref="stepSwiperEl" v-if="orderSteps?.length">
        <div
          class="c-merchandise-order-details__center-pagination"
          v-if="(orderSteps?.length > 4 && !$deviceComputes.isMobileOrTablet.value) || ($deviceComputes.isMobileOrTablet.value && orderSteps?.length > 2)"
        >
          <div class="c-merchandise-order-details__center-pagination-prev" ref="stepPrevEl">
            <Icon name="left" size="small" />
          </div>
          <div class="c-merchandise-order-details__center-pagination-next" ref="stepNextEl">
            <Icon name="right" size="small" />
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in orderSteps" :key="item.id" :class="index === orderSteps.length - 1 ? 'active' : ''">
            <div class="c-merchandise-order-details__center__status" :class="index === currentStepIndex ? 'active' : null" v-html="$tu('Status')" />
            <jss-rich-text :field="item.fields?.statusTitle" tag="div" class="c-merchandise-order-details__center__title" />
            <div class="c-merchandise-order-details__center__line" :class="currentStepIndex > index ? 'active' : null">
              <img :src="fields.stepActiveIcon?.value.src" v-if="index === currentStepIndex && currentStepIndex !== orderSteps.length - 1" />
              <Icon :svg="fields.stepFinishedIcon?.value.svgCode" v-else-if="index < currentStepIndex || currentStepIndex === orderSteps.length - 1" />
              <div class="c-merchandise-order-details__center__line__default" v-else></div>
            </div>
            <jss-rich-text :field="item.fields?.description" tag="div" class="c-merchandise-order-details__center__description" :class="index === currentStepIndex ? 'active' : null" />
            <div
              class="c-merchandise-order-details__center__content"
              :class="index === currentStepIndex ? 'active' : null"
              v-if="index === currentStepIndex && (orderDetails?.status === 51031004 || (refundDetail?.status === 51171002 && refundDetail.refundExpressCode))"
              @click="goExpressWebsite"
            >
              <jss-rich-text :field="item.fields?.secondDescription" tag="div" class="c-merchandise-order-details__center__content__desc" />
              <div class="c-merchandise-order-details__center__content__id" v-if="orderDetails?.status === 51031004">{{ expressCode }}</div>
              <div class="c-merchandise-order-details__center__content__id" v-if="refundDetail?.status === 51171002 && refundDetail.refundExpressCode">{{ refundDetail.refundExpressCode }}</div>
            </div>
            <div class="c-merchandise-order-details__center__buttons" v-if="item.fields?.buttons?.length && index === currentStepIndex && showButton">
              <div class="c-merchandise-order-details__center__buttons__item" v-for="buttonItem in item.fields?.buttons" :key="buttonItem.id">
                <SiteButton v-bind="buttonItem" @click="progressClick(item, buttonItem)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-merchandise-order-details__bottom">
        <div class="c-merchandise-order-details__bottom-orderDetails">
          <div class="c-merchandise-order-details__bottom-orderDetails-title" v-html="$tu('Order details')" />
          <div class="c-merchandise-order-details__bottom-orderDetails-content">
            <div class="c-merchandise-order-details__bottom-orderDetails-content-number" v-if="orderDetails?.orderCode">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-number-label" v-html="$tu('Order number')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-number-value">{{ orderDetails.orderCode }}</div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-date" v-if="orderDetails?.dateCreate">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-date-label" v-html="$tu('Order date')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-date-value">{{ orderDetails?.dateCreate ? $formatDate(orderDetails.dateCreate, 'MM-dd-yyyy') : '' }}</div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-receiverAddress" v-if="orderDetails?.receiverAddress">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-receiverAddress-label" v-html="$tu('Shipping address')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-receiverAddress-value">
                <p>{{ orderDetails.receiverAddressDetail }}</p>
                <p>{{ orderDetails.receiverCityName }}</p>
                <p>{{ orderDetails.receiverCountryName }}</p>
              </div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-billingAddress" v-if="orderDetails?.billingAddressDTO">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-billingAddress-label" v-html="$tu('Billing address')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-billingAddress-value">
                <p>{{ orderDetails?.billingAddressDTO?.detailInfo }}</p>
                <p>{{ orderDetails?.billingAddressDTO?.provinceName }}</p>
              </div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-emailAddress" v-if="orderDetails?.email">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-emailAddress-label" v-html="$tu('Email address')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-emailAddress-value">{{ orderDetails.email }}</div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-phoneNumber" v-if="orderDetails?.receiverPhone">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-phoneNumber-label" v-html="$tu('Phone number')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-phoneNumber-value">+{{ orderDetails.areaCode }} {{ orderDetails.receiverPhone }}</div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-invoice" v-if="invoiceDetail">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-invoice-label" v-html="$tu('Invoice')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-invoice-value" v-if="invoiceDetail?.invoiceNo">{{ $t('Invoice') }} {{ invoiceDetail.invoiceNo }}</div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-invoice-value" v-if="invoiceDetail?.invoiceMoney">
                {{ invoiceDetail.invoiceMoney ? $formatShopMoney(invoiceDetail.invoiceMoney, currency) : '' }}
              </div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-invoice-value" v-if="invoiceDetail?.createTime">{{ $formatDate(invoiceDetail.createTime, 'MM-dd-yyyy') }}</div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-invoice-file" v-if="invoiceDetail?.invoiceUrl">
                <a :href="invoiceDetail?.invoiceUrl" target="_blank"> <Icon name="invoice-download" /> {{ $t('Download invoice') }} </a>
              </div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-payMethods" v-if="orderDetails?.spuPayType">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-payMethods-label" v-html="$tu('PAYMENT METHOD')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-payMethods-value" v-html="orderDetails.spuPayType == 51071001 ? $tu('Cash order') : null" />
            </div>
            <div class="c-merchandise-order-details__bottom-orderDetails-content-button">
              <SiteButton v-bind="fields.cancelOrderButton" @click="cancelOrder" v-if="orderDetails?.status === 51031002" />
            </div>
          </div>
        </div>
        <div class="c-merchandise-order-details__bottom-orderSummary">
          <div class="c-merchandise-order-details__bottom-orderSummary-title" v-html="$tu('Order summary')"></div>
          <div class="c-merchandise-order-details__bottom-orderSummary-content">
            <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList" v-if="orderDetails?.items && orderDetails?.items?.length > 0">
              <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item" v-for="(product, index) in productItems" :key="index">
                <BackgroundImage
                  v-if="product?.image"
                  class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-image"
                  :image="{
                    value: {
                      src: product.image
                    }
                  }"
                />
                <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info">
                  <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-name" v-if="product?.name">{{ product.name }}</div>
                  <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-spec" v-if="product?.spec">{{ product.spec }}</div>
                  <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-num" v-if="product?.name">
                    <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-num-label">
                      {{ `${$t('Qty')}:` }}
                    </div>
                    <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-num-value">
                      {{ product.num }}
                    </div>
                  </div>
                  <div
                    class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-return"
                    v-if="product.salesReturnNum > 0 && !product.afterSalesCompletedNum && refundDetail.status != 51171006"
                  >
                    {{ `${$t('Return Initiated')}` }}
                  </div>
                  <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-return" v-if="product.afterSalesCompletedNum > 0 && refundDetail.status != 51171006">
                    {{ product.afterSalesCompletedNum }} {{ `${$t('Items is returned')}` }}
                  </div>
                  <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-return" v-if="refundDetail.status == 51171006">
                    {{ `${$t('Return closed')}` }}
                  </div>
                  <div class="c-merchandise-order-details__bottom-orderSummary-content-orderList-item-info-price" v-if="product?.price">{{ $formatShopMoney(product.price, currency) }}</div>
                </div>
              </div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderSummary-content-price">
              <div class="c-merchandise-order-details__bottom-orderSummary-content-price-subtotal">
                <div class="c-merchandise-order-details__bottom-orderSummary-content-price-subtotal-label" v-html="$t('Subtotal')"></div>
                <div class="c-merchandise-order-details__bottom-orderSummary-content-price-subtotal-value">
                  {{ subTotal ? $formatShopMoney(subTotal, currency) : '' }}
                </div>
              </div>
              <div class="c-merchandise-order-details__bottom-orderSummary-content-price-standard">
                <div class="c-merchandise-order-details__bottom-orderSummary-content-price-standard-label" v-html="$t('Shipping')"></div>
                <div class="c-merchandise-order-details__bottom-orderSummary-content-price-standard-value">
                  {{ orderDetails?.expressFee ? $formatShopMoney(orderDetails.expressFee, currency) : 'Free' }}
                </div>
              </div>
              <div class="c-merchandise-order-details__bottom-orderSummary-content-price-standard" v-if="currentAlpha2Code != 'GB'">
                <div class="c-merchandise-order-details__bottom-orderSummary-content-price-standard-label" v-html="$t('VAT')"></div>
                <div class="c-merchandise-order-details__bottom-orderSummary-content-price-standard-value">
                  {{ !!orderDetails.vat ? $formatShopMoney(orderDetails.vat, currency) : $formatShopMoney(0, currency) }}
                </div>
              </div>
            </div>
            <div class="c-merchandise-order-details__bottom-orderSummary-content-total">
              <div class="c-merchandise-order-details__bottom-orderDetails-content-total-label" v-html="$tu('Total')"></div>
              <div class="c-merchandise-order-details__bottom-orderDetails-content-total-value">{{ total ? $formatShopMoney(total, currency) : '' }}</div>
            </div>
          </div>
        </div>
        <div class="c-merchandise-order-details__bottom-faq">
          <div class="c-merchandise-order-details__bottom-faq-title" v-html="$tu('FAQS')" />
          <div class="c-merchandise-order-details__bottom-faq-content">
            <div class="c-merchandise-order-details__bottom-faq-content-item" v-for="(item, index) in fields.faqList?.fields?.list" :key="index">
              <div class="c-merchandise-order-details__bottom-faq-content-item-question" @click="questionClick(index)">
                <jss-rich-text :field="item.fields.question" tag="div" class="c-merchandise-order-details__bottom-faq-content-item-question-item" />
                <Icon name="down" size="tiny" :class="item.selected ? 'open' : ''" />
              </div>
              <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
                <jss-rich-text :field="item.fields.answer" tag="div" class="c-merchandise-order-details__bottom-faq-content-item-answer" v-if="item.selected" />
              </transition>
            </div>
            <div class="c-merchandise-order-details__bottom-faq-content-contact" v-html="fields.faqLinkText?.value" />
          </div>
        </div>
      </div>
    </div>
    <ShopCancelPaymentModal v-bind="fields.cancelPaymentModal" :orderCode="orderCodeNo" ref="cancelPaymentEl" @update="getOrderDetails" />
    <ShopCancelOrderModal v-bind="fields.cancelOrderModal" :orderCode="orderCodeNo" :subTotal="subTotal" :page="page" ref="cancelOrderEl" @update="getOrderDetails" />
    <ShopWithdrawalModal v-bind="fields.withdrawalModal" :refundOrderCode="refundOrderCode" @update="getOrderDetails" ref="withdrawalEl" />
    <ShopTrackModal v-bind="fields.trackModal" :expressCode="expressCode" :express="express" ref="trackEl" />
    <ShopConfirmModal v-bind="fields.confirmModal" :orderCode="orderCodeNo" @update="getOrderDetails" ref="confirmEl" />
    <NoticeModal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
  </DashboardLayout>
</template>
<script>
import { reactive, toRefs, inject, onMounted, computed, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getGlobalConfigs, encodeData, getGlobalCfgValues, scrollToTop, getCurrentAlpha2Code } from '@/utils/site-utils';
import { redirectTo, appendQuery, getBetterUrl, redirectToLang } from '@/utils/uri-utils';
import services from '@/services';
import Swiper from 'swiper';
import gsap from 'gsap';
import api from '@/api';
export default {
  name: 'MerchandiseOrderDetails',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    let swiper = null;
    let stepSwiper = null;
    const route = useRoute();
    const toast = inject('toast');
    const appMethods = inject('appMethods');
    const router = useRouter();
    const { orderCode } = route?.query || {};
    if (!props.fields) return;
    const [shopCheckoutLifeLink] = getGlobalConfigs(props.page, 'shopCheckoutLifeLink');
    const [shopReturnLink] = getGlobalConfigs(props.page, 'shopReturnLink');
    let shopCheckoutLifeLinkHref = shopCheckoutLifeLink?.value?.href;
    let shopReturnLinkHref = shopReturnLink?.value?.href;
    const state = reactive({
      orderDetails: {},
      subTotal: 0,
      total: 0,
      currency: 'EUR',
      invoiceDetail: null,
      swiperEl: null,
      prevEl: null,
      nextEl: null,
      stepPrevEl: null,
      stepNextEl: null,
      stepSwiperEl: null,
      cancelOrderEl: null,
      withdrawalEl: null,
      cancelPaymentEl: null,
      trackEl: null,
      orderSteps: [],
      currentStepIndex: 0,
      orderCodeNo: null,
      payTimeoutSecond: null,
      returnItems: [],
      refundDetail: {},
      part: false,
      refundOrderCode: null,
      expressCode: null,
      express: [],
      expresslink: null,
      showButton: false,
      confirmEl: null,
      productItems: [],
      allowAfterSalesNum: 0,
      currentAlpha2Code: null,
      totleNumber: 0,
      afterSalesCompletedNum: 0,
      orderLocaleRedirectModalRef: null
    });

    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      swiper = new Swiper(state.swiperEl, {
        slidesPerView: 'auto',
        spaceBetween: 8,
        navigation: {
          prevEl: state.prevEl,
          nextEl: state.nextEl
        }
      });
    };

    const buildStepSwiper = () => {
      if (stepSwiper) {
        stepSwiper.destroy(true, true);
      }
      stepSwiper = new Swiper(state.stepSwiperEl, {
        slidesPerView: 'auto',
        navigation: {
          prevEl: state.stepPrevEl,
          nextEl: state.stepNextEl
        }
      });
    };

    const computes = {
      lang: computed(() => router.currentRoute.value?.params.lang ?? {})
    };

    const methods = {
      getOrderDetails: async () => {
        const [res, ex] = await api.shop.orderDetails(null, {
          orderId: orderCode
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res) {
          return false;
        }
        const [currencyVal] = getGlobalCfgValues(props.page, ['currency']);
        state.currency = res.currency ? res.currency : currencyVal;
        state.subTotal = 0;
        state.allowAfterSalesNum = 0;
        res.items.forEach((item) => {
          state.subTotal += item.price * item.num;
          state.allowAfterSalesNum += item.allowAfterSalesNum;
        });
        const [express] = getGlobalConfigs(props.page, 'shopExpress');
        state.expressCode = res.expressCode;
        if (res.expressCode) {
          methods.getExpress(res.expressCode);
        }
        state.expresslink = express?.find((x) => x.fields.code.value === res.expressCompany);
        let returnItems = [];
        if (res.items.length) {
          res.items.map((item) => {
            if (item.allowAfterSalesNum > 0) {
              let returnItem = {};
              returnItem = {
                num: item.allowAfterSalesNum,
                price: item.price,
                allowAfterSalesNum: item.allowAfterSalesNum,
                image: item.image,
                skuId: item.skuId,
                spuId: item.spuId,
                spec: item.spec.replace('|', ', '),
                name: item.name,
                currency: state.currency
              };
              returnItems.push(returnItem);
            }
          });
        }
        state.returnItems = returnItems;
        state.total = Number(state.subTotal) + Number(res.expressFee);
        state.orderDetails = res;
        if (state.orderDetails?.items?.length) {
          state.orderDetails?.items.forEach((item) => {
            item.spec = item.spec.replace('|', ', ');
          });
        }
        state.productItems = res?.items;
        const [needRedirect, targetLang] = services.order.checkOrderLang(props.page, res?.country);
        if (needRedirect) {
          await state.orderLocaleRedirectModalRef.open();
          redirectToLang(targetLang);
        }
        if (res.refundOrderCode) {
          state.refundOrderCode = res.refundOrderCode;
          methods.getRefundDetailByCustomer(res.refundOrderCode);
        } else {
          state.showButton = true;
          methods.getOrderSteps();
        }
        nextTick(() => {
          buildSwiper();
        });
      },
      async getRulesList() {
        const [res, ex] = await api.shop.getRulesList({ orderRuleId: 15 });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res.length) {
          return false;
        }
        let list = [];
        res.forEach((item) => {
          let listItem = {
            fields: {
              code: {
                value: item.id
              },
              text: {
                value: item.name
              }
            }
          };
          list.push(listItem);
        });
        if (props?.fields?.cancelOrderModal?.fields?.form?.fields?.items?.length) {
          props?.fields?.cancelOrderModal?.fields?.form?.fields?.items.forEach((item) => {
            if (item?.fields?.fieldType?.fields?.phrase?.value === 'dropdown') {
              item.fields.options = list;
            }
          });
        }
      },
      async getRefundDetailByCustomer(refundOrderCode) {
        const [res, ex] = await api.shop.getRefundDetailByCustomer({
          refundOrderCode: refundOrderCode
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res) {
          return false;
        } else {
          state.totleNumber = 0;
          state.afterSalesCompletedNum = 0;
          res.refundItems?.forEach((el) => {
            state.totleNumber += el.skuNum;
            state.afterSalesCompletedNum += el.afterSalesCompletedNum;
          });

          state.productItems.forEach((item) => {
            res.refundItems?.forEach((el) => {
              if (item.skuId === el.skuId) {
                item.salesReturnNum = el.salesReturnNum;
              }
            });
          });
          state.refundDetail = res;
          if ((res.status === 51171004 && state.returnItems.length) || res.status !== 51171004) {
            state.showButton = true;
          }
        }
        methods.getOrderSteps();
      },
      async getExpress(expressCode) {
        const [res, ex] = await api.shop.getExpress({
          expressCode: expressCode
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res) {
          return false;
        }
        state.express = res;
      },
      getOrderSteps() {
        if (!state.orderDetails || !props?.fields?.orderStepsList?.length) {
          return false;
        }
        let orderStepsList = props?.fields?.orderStepsList;
        let paymentPendingSteps = orderStepsList.find((x) => x.fields.code.value === 'payment_pending');
        let refundSteps = orderStepsList.find((x) => x.fields.code.value === 'refund');
        let returnSteps = orderStepsList.find((x) => x.fields.code.value === 'return');
        let orderSteps = [];
        if (!state.orderDetails.payTime && state.orderDetails.status === 51031006 && state.orderDetails.closeReason == '51091001') {
          paymentPendingSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value === '51031002' || (item.fields.statusCode.value === '51031006' && item.fields.stockCode.value == '51091001')) {
              orderSteps.push(item);
            }
          });
        } else if (!state.orderDetails.payTime && state.orderDetails.status === 51031006 && state.orderDetails.closeReason == '51091002') {
          paymentPendingSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value === '51031002' || (item.fields.statusCode.value === '51031006' && item.fields.stockCode.value == '51091002')) {
              orderSteps.push(item);
            }
          });
        } else if (state.orderDetails.status === 51031002) {
          paymentPendingSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51031006' && item.fields.stockCode.value !== '51041002') {
              orderSteps.push(item);
            }
            if (item.fields.statusCode.value === '51031002') {
              let strBefore = item.fields.description.value;
              let strAfter = item.fields.secondDescription.value;
              let payTimeoutSecond = state.orderDetails.payTimeoutSecond;
              let timer = setInterval((v) => {
                if (payTimeoutSecond < 1) {
                  clearInterval(timer);
                  return;
                }
                payTimeoutSecond--;
                let minutes = Math.floor(payTimeoutSecond / 60) < 10 ? '0' + Math.floor(payTimeoutSecond / 60) : Math.floor(payTimeoutSecond / 60);
                let seconds = payTimeoutSecond % 60 < 10 ? '0' + (payTimeoutSecond % 60) : payTimeoutSecond % 60;
                state.payTimeoutSecond = minutes + ':' + seconds;
                item.fields.description.value = strBefore + ' ' + state.payTimeoutSecond + ' ' + strAfter;
              }, 1000);
            }
          });
        } else if (
          state.orderDetails.payTime &&
          (!state.orderDetails.refundOrderCode || (state.orderDetails.refundOrderCode && state.refundDetail.refundType === 51181004 && state.totleNumber !== state.afterSalesCompletedNum))
        ) {
          paymentPendingSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51031002' && item.fields.statusCode.value !== '51031006' && item.fields.stockCode.value !== '51041002') {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181001 && state.refundDetail.status === 51171009) {
          refundSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51171004' && item.fields.statusCode.value !== '51171006') {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181001 && state.refundDetail.status === 51171006) {
          refundSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51171004' && item.fields.statusCode.value !== '51171009') {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181001 && state.refundDetail.status !== 51171006 && state.refundDetail.status !== 51171009) {
          refundSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51171006' && item.fields.statusCode.value !== '51171009') {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181002 && state.refundDetail.status === 51171006 && state.refundDetail.refundExamine === 51191001) {
          returnSteps.fields?.list?.map((item) => {
            if (
              item.fields.statusCode.value !== '51191003' &&
              item.fields.statusCode.value !== '51171009' &&
              item.fields.statusCode.value !== '51171004' &&
              item.fields.statusCode.value !== '51171003' &&
              item.fields.statusCode.value !== '51171002' &&
              item.fields.statusCode.value !== '51171005'
            ) {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181002 && state.refundDetail.status === 51171006 && state.refundDetail.refundExamine === 51191002) {
          returnSteps.fields?.list?.map((item) => {
            if (
              item.fields.statusCode.value !== '51191003' &&
              item.fields.statusCode.value !== '51171009' &&
              item.fields.statusCode.value !== '51171004' &&
              item.fields.stockCode.value !== '51031003' &&
              item.fields.statusCode.value !== '51171005' &&
              item.fields.statusCode.value !== '51171003'
            ) {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181002 && state.refundDetail.refundExamine === 51191003) {
          returnSteps.fields?.list?.map((item) => {
            if (
              item.fields.statusCode.value !== '51171009' &&
              item.fields.statusCode.value !== '51171002' &&
              item.fields.statusCode.value !== '51171003' &&
              item.fields.statusCode.value !== '51171004' &&
              item.fields.statusCode.value !== '51171005' &&
              item.fields.statusCode.value !== '51171006'
            ) {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181002 && state.refundDetail.status === 51171009 && state.refundDetail.refundExamine === 51191002) {
          returnSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51171006' && item.fields.statusCode.value !== '51171004' && item.fields.statusCode.value !== '51191003') {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181002 && state.refundDetail.status === 51171001 && state.refundDetail.refundExamine === 51191001) {
          returnSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51171006' && item.fields.statusCode.value !== '51171009' && item.fields.statusCode.value !== '51191003') {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181002 && state.refundDetail.status === 51171009 && state.refundDetail.refundExamine !== 51191002) {
          returnSteps.fields?.list?.map((item) => {
            if (
              item.fields.statusCode.value !== '51171006' &&
              item.fields.statusCode.value !== '51171004' &&
              item.fields.statusCode.value !== '51191003' &&
              item.fields.statusCode.value !== '51171001'
            ) {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181002 && state.refundDetail.status !== 51171009 && state.refundDetail.status !== 51171006 && state.refundDetail.refundExamine === 51191002) {
          returnSteps.fields?.list?.map((item) => {
            if (item.fields.statusCode.value !== '51171006' && item.fields.statusCode.value !== '51191003' && item.fields.statusCode.value !== '51171009') {
              orderSteps.push(item);
            }
          });
        } else if (state.refundDetail.refundType === 51181004 && state.refundDetail.status !== 51171009 && state.refundDetail.status !== 51171006 && state.refundDetail.refundExamine === 51191002) {
          returnSteps.fields?.list?.map((item) => {
            if (
              item.fields.statusCode.value !== '51171006' &&
              item.fields.statusCode.value !== '51191003' &&
              item.fields.statusCode.value !== '51171009' &&
              item.fields.statusCode.value !== '51171003' &&
              item.fields.statusCode.value !== '51171002' &&
              item.fields.statusCode.value !== '51171001' &&
              item.fields.statusCode.value !== '51031005' &&
              item.fields.statusCode.value !== '51031004' &&
              item.fields.statusCode.value !== '51031003'
            ) {
              orderSteps.push(item);
            }
          });
        } else if (
          state.refundDetail.refundType === 51181002 &&
          state.refundDetail.status !== 51171009 &&
          state.refundDetail.refundExamine !== 51171006 &&
          state.refundDetail.refundExamine !== 51191002
        ) {
          returnSteps.fields?.list?.map((item) => {
            if (
              item.fields.statusCode.value !== '51171006' &&
              item.fields.statusCode.value !== '51191003' &&
              item.fields.statusCode.value !== '51171009' &&
              item.fields.statusCode.value !== '51171001'
            ) {
              orderSteps.push(item);
            }
          });
        }
        state.orderSteps = orderSteps;
        if (!state.orderDetails.refundOrderCode || (state.orderDetails.refundOrderCode && state.refundDetail.refundType === 51181004 && state.totleNumber !== state.afterSalesCompletedNum)) {
          if (state.orderDetails.status === 51031003) {
            state.orderSteps?.forEach((item, index) => {
              if (item.fields.stockCode.value == state.orderDetails.stockStatus && item.fields.statusCode.value === '51031003') {
                state.currentStepIndex = index;
              } else if (item.fields.statusCode.value === '51031003' && state.orderDetails.stockStatus === 51041002 && item.fields.stockCode.value == '51041003') {
                state.currentStepIndex = index;
              }
            });
          } else {
            state.orderSteps?.forEach((item, index) => {
              if (item?.fields?.statusCode?.value == state.orderDetails.status) {
                state.currentStepIndex = index;
              }
            });
          }
        } else {
          if (state.refundDetail.refundExpressCode) {
            state.orderSteps?.forEach((item, index) => {
              if (item?.fields?.statusCode?.value == '51171002' && state.refundDetail.status == '51171002' && item?.fields?.stockCode?.value) {
                state.currentStepIndex = index;
              } else if (item?.fields?.statusCode?.value == state.refundDetail.status && item?.fields?.statusCode?.value != '51171002') {
                state.currentStepIndex = index;
              }
            });
          } else {
            state.orderSteps?.forEach((item, index) => {
              if (item?.fields?.statusCode?.value == '51171002' && state.refundDetail.status === 51171002 && !state.refundDetail.refundExpressCode) {
                state.currentStepIndex = index - 1;
              } else if (item?.fields?.statusCode?.value == '51171002' && state.refundDetail.status === 51171002 && state.refundDetail.refundExpressCode) {
                state.currentStepIndex = index;
              } else if (item?.fields?.statusCode?.value == state.refundDetail.status && state.refundDetail.status !== 51171002 && state.refundDetail.refundExamine !== 51191003) {
                state.currentStepIndex = index;
              } else if (state.refundDetail.refundExamine === 51191003 && item?.fields?.statusCode?.value == '51191003') {
                state.currentStepIndex = index;
              }
            });
          }
        }
        state.orderSteps.forEach((item) => {
          if (item.fields.buttons.length) {
            item.fields.buttons.forEach((buttonItem, index) => {
              if (buttonItem.fields?.buttonCode?.value === 'return' && ((state.orderDetails.refundTimeoutSecond && state.orderDetails.refundTimeoutSecond < 1) || state.allowAfterSalesNum < 1)) {
                item.fields.buttons.splice(index, 1);
              }
            });
          }
        });
        nextTick(() => {
          buildStepSwiper();
          stepSwiper.slideTo(state.currentStepIndex, 0);
        });
      },
      async progressClick(item, buttonItem) {
        if (buttonItem?.fields?.buttonCode?.value === 'pay') {
          let url = getBetterUrl(
            appendQuery(shopCheckoutLifeLinkHref, {
              order_code: orderCode
            }),
            props.page.itemLanguage,
            true
          );
          window.location = url;
        } else if (buttonItem?.fields?.buttonCode?.value === 'cancel_payment') {
          state.cancelPaymentEl.open();
        } else if (buttonItem?.fields?.buttonCode?.value === 'cancel_order') {
          state.cancelOrderEl.open();
        } else if (buttonItem?.fields?.buttonCode?.value === 'return') {
          let url = getBetterUrl(
            appendQuery(shopReturnLinkHref, {
              returnItems: encodeData(state.returnItems),
              orderCode: orderCode
            }),
            props.page.itemLanguage,
            true
          );
          window.location = url;
        } else if (buttonItem?.fields?.buttonCode?.value === 'withdrawn') {
          state.withdrawalEl.open();
        } else if (buttonItem?.fields?.buttonCode?.value === 'send') {
          let url = getBetterUrl(
            appendQuery(props.fields.expressLink?.value?.href, {
              refundOrderCode: state.orderDetails.refundOrderCode,
              orderCode: orderCode
            }),
            props.page.itemLanguage,
            true
          );
          window.location = url;
        } else if (buttonItem?.fields?.buttonCode?.value === 'recieved') {
          state.confirmEl.open();
        } else if (buttonItem?.fields?.buttonCode?.value === 'track') {
          state.trackEl.open();
        }
      },
      getInvoiceDetails: async () => {
        const [res, ex] = await api.shop.invoiceDetails(null, {
          orderId: orderCode
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (res) {
          state.invoiceDetail = res;
        }
      },
      questionClick(_index) {
        let faqList = props?.fields?.faqList;
        faqList?.fields?.list?.map((item, index) => {
          if (index === _index) {
            item.selected = !item.selected;
          }
        });
      },
      onItemBeforeEnter(el) {
        el.style.height = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 10,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      cancelOrder() {
        if (state.orderDetails?.status === 51031002) {
          state.cancelPaymentEl.open();
        } else {
          state.cancelOrderEl.open();
        }
      },
      async goExpressWebsite() {
        if (state.expresslink?.fields?.link?.value && state.orderDetails?.status === 51031004) {
          window.open(state.expresslink?.fields?.link?.value, '_blank');
        } else if (state.refundDetail?.status === 51171002) {
          let steps = state.orderSteps.find((x) => x.fields.statusCode.value == 51171002 && x.fields.stockCode.value == 51031003);
          methods.copyToClipboard(state.refundDetail.refundExpressCode);
          await toast.showSuccess(steps?.fields?.successText?.value);
        }
      },
      copyToClipboard(text) {
        let textarea = document.createElement('textarea');
        textarea.style.position = 'fixed';
        textarea.style.opacity = 0;
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    };

    onMounted(async () => {
      const currentAlpha2Code = getCurrentAlpha2Code();
      state.currentAlpha2Code = currentAlpha2Code;
      scrollToTop();
      if (!orderCode) {
        const [orderListLink] = getGlobalConfigs(props.page, 'orderListLink');
        redirectTo({ url: orderListLink?.value?.href, router });
      } else {
        state.orderCodeNo = orderCode;
        methods.getOrderDetails();
      }
      methods.getInvoiceDetails();
      methods.getRulesList();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-merchandise-order-details {
  width: 100%;
  overflow: hidden;
  &__top {
    padding: 26px 0 48px;
    margin: 0 grid-width(2);
    border-bottom: 1px solid #e3e3e3;
  }
  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    &-icon {
      width: 12px;
      height: 12px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #4f4f4f;
      margin-left: 8px;
    }
  }
  &__orderNo {
    margin-top: 20px;
    display: flex;
    font-size: 12px;
    line-height: 20px;
    &-title {
      color: #757575;
    }
    &-desc {
      color: #262626;
      margin-left: 5px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    position: relative;
    .swiper-wrapper {
      .swiper-slide {
        width: calc(50% - 4px);
        height: calc((grid-width(10) - 8px) * 1.21);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &-pagination {
      display: none;
    }
  }
  &__center {
    padding: 0 grid-width(2);
    margin-top: 48px;
    position: relative;
    &-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin-bottom: 16px;
      &-prev {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.swiper-button-disabled {
          svg,
          path {
            fill: rgba(0, 0, 0, 0.5);
          }
        }
        .e-icon {
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-next {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.swiper-button-disabled {
          svg,
          path {
            fill: rgba(0, 0, 0, 0.5);
          }
        }
        .e-icon {
          width: 100%;
          height: 100%;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .swiper-wrapper {
      width: 100%;
      .swiper-slide {
        width: 100%;
        &.active {
          .c-merchandise-order-details__center__line {
            width: 0;
          }
        }
      }
    }
    &__status {
      font-size: 12px;
      line-height: 20px;
      color: #757575;
      visibility: hidden;
      &.active {
        visibility: visible;
      }
    }
    &__title {
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      margin-top: 8px;
    }
    &__line {
      margin-top: 32px;
      position: relative;
      width: 100%;
      height: 1px;
      background: #b8b8b8;
      &.active {
        background: $black;
      }
      &__default {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        border: 1px solid #b8b8b8;
        background: $white;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
      }
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
      }
      .e-icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
        background: $white;
      }
    }
    &__description {
      font-size: 12px;
      line-height: 20px;
      color: #757575;
      margin-top: 24px;
      padding-right: 48px;
      &.active {
        color: $black;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      margin-top: 24px;
      color: #757575;
      cursor: pointer;
      &.active {
        color: $black;
      }
      &__desc {
        font-size: 14px;
        line-height: 20px;
      }
      &__id {
        font-size: 14px;
        line-height: 20px;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &__buttons {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  &__bottom {
    margin-top: 68px;
    padding: 96px grid-width(2) 64px;
    background: $black;
    &-orderDetails {
      display: flex;
      flex-direction: column;
      padding-bottom: 64px;
      border-bottom: 1px solid #2f2f2f;
      &-title {
        font-size: 26px;
        line-height: 26px;
        color: $white;
      }
      &-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        &-number {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-date {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-receiverAddress {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
            padding-right: 24px;
          }
        }
        &-billingAddress {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-emailAddress {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-phoneNumber {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-invoice {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
          &-file {
            margin-top: 5px;
            a {
              display: flex;
              align-items: center;
              color: #b8b8b8;
              .e-icon {
                color: #fff200;
              }
            }
          }
        }
        &-payMethods {
          margin-top: 24px;
          &-label {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            color: $white;
          }
          &-value {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
            margin-top: 8px;
          }
        }
        &-button {
          margin-top: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    &-orderSummary {
      display: flex;
      flex-direction: column;
      padding-top: 64px;
      padding-bottom: 64px;
      border-bottom: 1px solid #2f2f2f;
      &-title {
        font-size: 26px;
        line-height: 26px;
        color: $white;
      }
      &-content {
        margin-top: 24px;
        &-orderList {
          display: flex;
          flex-direction: column;
          gap: 24px;
          &-item {
            display: flex;
            gap: 16px;
            &-image {
              width: 80px;
              height: 100px;
              background: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              .e-background-image__img {
                width: 70px !important;
                height: 70px !important;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            &-info {
              display: flex;
              flex-direction: column;
              color: $white;
              &-name {
                font-size: 12px;
                line-height: 20px;
                font-weight: 700;
              }
              &-spec {
                font-size: 12px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.6);
              }
              &-num {
                display: flex;
                font-size: 12px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.6);
                gap: 5px;
              }
              &-return {
                font-size: 12px;
                line-height: 20px;
                color: #fff100;
              }
              &-price {
                font-size: 12px;
                line-height: 20px;
                flex: 1;
                display: flex;
                align-items: flex-end;
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }
        }
        &-price {
          border: 1px solid #2f2f2f;
          padding: 24px;
          margin-top: 24px;
          font-size: 16px;
          line-height: 24px;
          color: #b8b8b8;
          &-subtotal {
            display: flex;
            justify-content: space-between;
          }
          &-standard {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
          }
        }
        &-total {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 2px;
          color: $white;
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
        }
      }
    }
    &-faq {
      display: flex;
      flex-direction: column;
      padding-top: 64px;
      &-title {
        font-size: 26px;
        line-height: 26px;
        color: $white;
      }
      &-content {
        margin-top: 40px;
        &-item {
          padding: 30px 0 10px;
          border-bottom: 1px solid #252525;
          &-question {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            line-height: 24px;
            color: $white;
            margin-bottom: 10px;
            cursor: pointer;
            &-item {
              width: 90%;
            }
            .e-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              transition: all 0.3s;
              &.open {
                transform: rotate(180deg);
                transform-origin: center center;
              }
            }
          }
          &-answer {
            font-size: 16px;
            color: rgb(117, 117, 117);
            overflow: hidden;
          }
        }
        &-contact {
          font-size: 16px;
          line-height: 24px;
          color: #848484;
          margin-top: 35px;
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  @include tablet-landscape {
    &__top {
      padding: 36px 0 80px;
    }
    &__back {
      &-icon {
        width: 14px;
        height: 14px;
      }
      &-text {
        font-size: 16px;
      }
    }
    &__orderNo {
      margin-top: 40px;
    }
    &__content {
      margin-top: 58px;
      .swiper-wrapper {
        .swiper-slide {
          width: calc(25% - 6px);
          height: calc((grid-width(5) - 6px) * 1.21);
        }
      }
      &-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: grid-width(-1);
        top: -40px;
        gap: 16px;
        &-prev {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.swiper-button-disabled {
            svg,
            path {
              fill: rgba(0, 0, 0, 0.5);
            }
          }
          .e-icon {
            width: 100%;
            height: 100%;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        &-next {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.swiper-button-disabled {
            svg,
            path {
              fill: rgba(0, 0, 0, 0.5);
            }
          }
          .e-icon {
            width: 100%;
            height: 100%;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    &__center {
      margin-top: 80px;
      &-pagination {
        margin-right: grid-width(-1);
      }
      .swiper-wrapper {
        width: 100%;
        .swiper-slide {
          width: grid-width(6);
        }
      }
      &__description {
        padding-right: 64px;
      }
    }
    &__bottom {
      margin-top: 109px;
      padding: 120px grid-width(3) 80px;
      &-orderDetails {
        flex-direction: row;
        padding-bottom: 96px;
        &-title {
          font-size: 32px;
          line-height: 32px;
          flex: 1;
        }
        &-content {
          flex: 2;
          flex-direction: row;
          &-number {
            width: 50%;
            margin-top: 0;
          }
          &-date {
            width: 50%;
            margin-top: 0;
          }
          &-receiverAddress {
            width: 50%;
            margin-top: 48px;
          }
          &-billingAddress {
            width: 50%;
            margin-top: 48px;
          }
          &-emailAddress {
            width: 50%;
            margin-top: 48px;
          }
          &-phoneNumber {
            width: 50%;
            margin-top: 48px;
          }
          &-invoice {
            width: 50%;
            margin-top: 48px;
          }
          &-payMethods {
            width: 50%;
            margin-top: 48px;
          }
          &-button {
            margin-top: 30px;
          }
        }
      }
      &-orderSummary {
        padding-top: 96px;
        padding-bottom: 96px;
        flex-direction: row;
        &-title {
          font-size: 32px;
          line-height: 32px;
          flex: 1;
        }
        &-content {
          flex: 2;
          margin-top: 0;
          &-orderList {
            flex-direction: row;
            flex-wrap: wrap;
            &-item {
              width: calc(50% - 12px);
            }
          }
          &-price {
            padding: 16px;
            margin-top: 48px;
          }
          &-total {
            margin-top: 40px;
          }
        }
      }
      &-faq {
        padding-top: 96px;
        padding-bottom: 96px;
        flex-direction: row;
        &-title {
          font-size: 32px;
          line-height: 32px;
          flex: 1;
        }
        &-content {
          flex: 2;
          margin-top: 0;
          &-item {
            padding: 40px 0 14px;
            overflow: hidden;
            &-question {
              .e-icon {
                position: relative;
                transition: all 0.3s;
                &.open {
                  transform: rotate(180deg);
                  transform-origin: center center;
                }
              }
            }
            &-answer {
              font-size: 16px;
              color: rgb(117, 117, 117);
            }
          }
          &-contact {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
</style>
