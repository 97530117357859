<template>
  <overall-settings v-bind="$props">
    <div class="c-merchandise-wheels-landing" v-if="fields && list.length > 0">
      <div class="c-merchandise-wheels-landing__content">
        <div class="c-merchandise-wheels-landing__content__top">
          <jss-rich-text class="c-merchandise-wheels-landing__content__top__title" :field="fields.title" tag="div" />
          <div class="c-merchandise-wheels-landing__content__top__pagination" v-if="list.length > 2">
            <div class="c-merchandise-wheels-landing__content__top__pagination__left" ref="prevEl">
              <Icon name="left" size="small" />
            </div>
            <div class="c-merchandise-wheels-landing__content__top__pagination__right" ref="nextEl">
              <Icon name="right" size="small" />
            </div>
          </div>
        </div>
        <div ref="swiperEl" class="c-merchandise-wheels-landing__content__swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in list" :key="item.id">
              <RouterLink class="c-merchandise-wheels-landing__content__swiper__item" :to="`/${lang}${shopWinterWheelsDetailLinkHref}?spuBusId=${item.spuBusId}`">
                <div class="c-merchandise-wheels-landing__content__swiper__item__tag">
                  <div class="c-merchandise-wheels-landing__content__swiper__item__tag__item" v-for="tag in item.tagList" :key="tag.id">
                    {{ tag.tagName }}
                  </div>
                  <div class="c-merchandise-wheels-landing__content__swiper__item__tag__item">{{ item.sellOut === 1 ? $t('Out of stock') : null }}</div>
                </div>
                <BackgroundImage class="c-merchandise-wheels-landing__content__swiper__item__image" :image="item.cover" />
                <div class="c-merchandise-wheels-landing__content__swiper__item__bottom">
                  <div class="c-merchandise-wheels-landing__content__swiper__item__name">{{ item.name }}</div>
                  <div class="c-merchandise-wheels-landing__content__swiper__item__attr">
                    <div class="c-merchandise-wheels-landing__content__swiper__item__price" v-if="item.minPrice">
                      {{ $formatShopMoney(item.minPrice, currency) }}
                    </div>
                  </div>
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
        <div
          class="c-merchandise-wheels-landing__content__progress"
          ref="progressContentEl"
          v-if="(list?.length > 2 && !$deviceComputes.isMobileOrTablet.value) || ($deviceComputes.isMobileOrTablet.value && list?.length > 1)"
        >
          <div class="c-merchandise-wheels-landing__content__progress__inner" ref="progressEl"></div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, computed, inject } from 'vue';
import Swiper from 'swiper';
import api from '@/api';
import { isMobile } from '@/utils/dom-utils';
import { getCurrentAlpha2Code, getGlobalConfigs, getGlobalCfgValues, getPageAlpha2Code } from '@/utils/site-utils';
import { useRouter } from 'vue-router';

export default {
  name: 'MerchandiseWheelsLanding',
  props: {
    /**@type MerchandiseLandingFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const loading = inject('loading');
    let swiper = null;
    const router = useRouter();
    const state = reactive({
      list: [],
      swiperEl: null,
      currency: 'EUR',
      prevEl: null,
      nextEl: null,
      progressContentEl: null,
      progressEl: null,
      shopWinterWheelsDetailLinkHref: null
    });
    const computes = {
      lang: computed(() => router.currentRoute.value?.params.lang ?? {})
    };

    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      swiper = new Swiper(state.swiperEl, {
        slidesPerView: 'auto',
        observer: true,
        observeParents: true,
        spaceBetween: 20,
        navigation: {
          prevEl: state.prevEl,
          nextEl: state.nextEl
        },
        on: {
          slideChange: (swiper) => {
            let width = state.progressContentEl?.getBoundingClientRect()?.width;
            let index = swiper.activeIndex + 2;
            if (state.progressEl) {
              state.progressEl.style.width = width * (index / state.list.length) + 'px';
            }
          }
        }
      });
    };

    const methods = {
      async getListSpuBanner(positionCode) {
        const [market] = getGlobalConfigs(props.page, 'market');
        const positionCodeValue = props?.fields?.positionCode?.value?.split(',');
        const alpha2Code = getPageAlpha2Code(props.page);
        const currentAlpha2Code = getCurrentAlpha2Code();
        const body = {
          productCode: '30',
          positionCode: positionCodeValue,
          marketCode: market?.fields?.phrase?.value,
          country: alpha2Code,
          currency: props?.currency
        };

        const [res, ex] = await api.shop.getListSpuBanner(null, body);
        loading.hide();
        let list = [];
        if (res && res[0]?.bannerResourceList?.length) {
          state.list = res[0].bannerResourceList;
          state.list.map((item) => {
            item.cover = {
              value: {
                src: item.content
              }
            };
            item.name = item.bannerName;
            item.spuBusId = item.bannerGoodsInfo?.goodsId;
            item.minPrice = Number(item.bannerGoodsInfo?.goodsPrice) * 100;
            if (item.bannerGoodsInfo?.currency) {
              state.currency = item.bannerGoodsInfo?.currency;
            }
            if (item.bannerGoodsInfo?.tagList?.length) {
              item.bannerGoodsInfo?.tagList.map((option) => {
                if (option.tagCode === props?.fields?.newTagCode?.value) {
                  item.isNew = true;
                } else {
                  item.isNew = false;
                }
              });
            } else {
              item.isNew = false;
            }
          });
          nextTick(() => {
            if (!isMobile()) {
              buildSwiper();
              let width = state.progressContentEl?.getBoundingClientRect()?.width;
              if (state.progressEl) {
                state.progressEl.style.width = width * (2 / state.list.length) + 'px';
              }
            }
          });
        }
      }
    };

    onMounted(() => {
      const [shopWinterWheelsDetailLink] = getGlobalConfigs(props.page, 'shopWinterWheelsDetailLink');
      state.shopWinterWheelsDetailLinkHref = shopWinterWheelsDetailLink?.value?.href;
      loading.show();
      methods.getListSpuBanner();
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-wheels-landing {
  user-select: none;
  width: 100%;
  overflow: hidden;
  @include grid-container;
  &__content {
    width: 100%;
    @include grid-block(2, 10);
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        font-size: 26px;
        line-height: 1;
        color: $black;
        text-transform: uppercase;
      }
      &__pagination {
        display: none;
      }
    }
    &__swiper {
      margin-top: 32px;
      .swiper-wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
      .swiper-slide {
        width: grid-width(19);
        height: calc(grid-width(19) * 1.1 + 30px);
        background: #f2f1f0;
      }
      &__item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0 16px;
        &__tag {
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          width: 100%;
          padding: 0 24px;
          min-height: 20px;
          &__item {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        &__bottom {
          height: 48px;
          margin-top: 15px;
          width: calc(100% - 48px);
        }
        &__image {
          width: grid-width(14);
          height: calc(grid-width(14));
        }
        &__name {
          width: 100%;
          font-size: 18px;
          line-height: 32px;
          text-align: left;
          color: $black;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__price {
          width: calc(100% - 48px);
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: rgba(0, 0, 0, 0.6);
        }
        &__attr {
          display: flex;
          justify-content: space-between;
        }
        &__color {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
          white-space: nowrap;
        }
      }
    }
    &__progress {
      width: grid-width(10);
      height: 1px;
      background: rgba(200, 200, 200, 1);
      margin-top: 60px;
      position: relative;
      display: none;
      &__inner {
        height: 1px;
        width: 0;
        position: absolute;
        left: 0;
        top: 0;
        background: $black;
        transition: all 0.3s;
      }
    }
  }
}
@include tablet-landscape {
  .c-merchandise-wheels-landing {
    &__content {
      @include grid-block(3, 20);
      &__top {
        &__title {
          font-size: 32px;
        }
        &__pagination {
          display: flex;
          gap: 27px;
          &__left {
            width: 24px;
            height: 24px;
            cursor: pointer;
            &.swiper-button-disabled {
              svg,
              path {
                fill: rgba(0, 0, 0, 0.5);
              }
            }
          }
          &__right {
            width: 24px;
            height: 24px;
            cursor: pointer;
            &.swiper-button-disabled {
              svg,
              path {
                fill: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      &__swiper {
        margin-top: 52px;
        .swiper-wrapper {
          flex-direction: row;
        }
        .swiper-slide {
          width: grid-width(8.5);
          height: calc(grid-width(8.5) - 20px);
        }
        &__item {
          padding: 20px 0 16px;
          &__image {
            height: calc((grid-width(7.5) - 60px));
            width: calc((grid-width(7.5) - 60px));
          }
          &__name {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      &__progress {
        display: block;
        margin: 65px auto 0;
      }
    }
  }
}
</style>
