<template>
  <PersonalCenter :fields="fields?.personalCenterBar?.fields" active-menu="my-details">
    <div class="c-my-details">
      <JssRichText class="c-my-details__title" :field="fields.title" />
      <section class="c-my-details__section c-my-details__email-group">
        <JssRichText class="c-my-details__section-explain" :field="fields.emailExplain" />
        <div class="c-my-details__section-change-group">
          <div class="c-my-details__section-change-group-icon">
            <Icon name="email" />
          </div>
          <div class="c-my-details__section-change-group-main" @click="changeEmail">
            <div class="c-my-details__section-change-group-main-text">
              <div class="c-my-details__section-change-group-main-text-title">
                <JssRichText class="c-my-details__section-verify-explain" :field="appStore?.loginInfo?.email ? fields.emailVerficationExplain : fields.addEmailVerficationExplain" />
              </div>
              <div class="c-my-details__section-change-group-main-text-detail" v-if="appStore?.loginInfo?.email">{{ appStore?.loginInfo?.email }}</div>
            </div>
            <div class="c-my-details__section-change-group-main-icon">
              <Icon name="arrow-right" />
            </div>
          </div>
        </div>
        <Modal ref="changeEmailRef" class="c-my-details__section-modal" closable>
          <div class="c-my-details__section-change">
            <JssRichText class="c-my-details__section-change-title" :field="fields.emailTitle" />
            <DynamicForm :form="fields.changeEmailForm" :data="emailFormConfig" ref="changeEmailFormRef" />
            <div class="c-my-details__section-change-btn">
              <SiteButton v-bind="fields.changeEmailButton" @click="verifyEmail" />
            </div>
          </div>
        </Modal>
        <Modal ref="verifyEmailRef" class="c-my-details__section-modal" closable>
          <div class="c-my-details__section-verify">
            <JssRichText class="c-my-details__section-verify-title" :field="fields.emailVerficationPrompt" />
            <div>{{ email }}</div>
            <DynamicForm ref="verifyEmailFormRef" :form="fields.verifyEmailCodeForm" />
            <div class="c-my-details__section-verify-btn">
              <SiteButton v-bind="fields.verifyEmailButton" @click="updateEmail" />
              <SiteButton v-bind="fields.requestEmailCodeButton" @click="resendEmailCode" />
            </div>
          </div>
        </Modal>
      </section>
      <section class="c-my-details__section c-my-details__phone-group">
        <JssRichText class="c-my-details__section-explain" :field="fields.mobileExplain" />
        <div class="c-my-details__section-change-group">
          <div class="c-my-details__section-change-group-icon">
            <Icon name="phone" />
          </div>
          <div class="c-my-details__section-change-group-main" @click="changeMobile">
            <div class="c-my-details__section-change-group-main-text">
              <div class="c-my-details__section-change-group-main-text-title">
                <JssRichText class="c-my-details__section-verify-explain" :field="appStore?.loginInfo?.phone ? fields.mobileVerficationExplain : fields.addMobileVerficationExplain" />
              </div>
              <div class="c-my-details__section-change-group-main-text-detail" v-if="appStore?.loginInfo?.phone">+{{ appStore?.loginInfo?.mobileAreaCode }} | {{ appStore?.loginInfo?.phone }}</div>
            </div>
            <div class="c-my-details__section-change-group-main-icon">
              <Icon name="arrow-right" />
            </div>
          </div>
        </div>
        <Modal ref="changeMobileRef" class="c-my-details__section-modal" closable>
          <div class="c-my-details__section-change">
            <JssRichText class="c-my-details__section-change-title" :field="fields.mobileTitle" />
            <DynamicForm :form="fields.changeMobileForm" :data="mobileFormConfig" ref="changeMobileFormRef" />
            <div class="c-my-details__section-change-btn">
              <SiteButton v-bind="fields.changeMobileButton" @click="verifyMobile" />
            </div>
          </div>
        </Modal>
        <Modal ref="verifyMobileRef" class="c-my-details__section-modal" closable>
          <div class="c-my-details__section-verify">
            <JssRichText class="c-my-details__section-verify-title" :field="fields.mobileVerficationPrompt" />
            <div>+{{ phone.code }} | {{ phone.number }}</div>
            <DynamicForm :form="fields.verifyMobileCodeForm" ref="verifyMobileFormRef" />
            <div class="c-my-details__section-verify-btn">
              <SiteButton v-bind="fields.verifyMobileButton" @click="updateMobile" />
              <SiteButton v-bind="fields.requestMobileCodeButton" @click="resendMobileCode" />
            </div>
          </div>
        </Modal>
      </section>
      <section class="c-my-details__section c-my-details__personal-group">
        <JssRichText class="c-my-details__section-title" :field="fields.personalTitle" />
        <DynamicForm :form="fields.personalForm" :data="personalFormConfig" ref="personalFormRef" />
        <div class="c-my-details__personal-group-btn">
          <SiteButton v-bind="fields.personalUpdateButton" @click="updateUserInfo" />
        </div>
        <div class="c-my-details__personal-group-tips">
          <div class="c-my-details__personal-group-tips-main">
            <div class="c-my-details__personal-group-tips-icon">
              <Icon name="query" />
            </div>
            <div class="c-my-details__personal-group-tips-text">
              <JssRichText class="c-my-details__section-title" :field="fields.deleteAccountExplain" />
            </div>
          </div>
          <div class="c-my-details__personal-group-tips-main">
            <div class="c-my-details__personal-group-tips-icon">
              <Icon name="query" />
            </div>
            <div class="c-my-details__personal-group-tips-text">
              <JssRichText class="c-my-details__section-title" :field="fields.forgetAccountExplain" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </PersonalCenter>
</template>

<script>
import { reactive, toRefs, inject, watch, onMounted } from 'vue';
import { debounce } from 'lodash';
import api from '@/api';
import useAppStore from '@/store/appStore';
import { CAPTCHA_TYPE, CAPTCHA_SCENE } from '@/utils/constants';
import { redirectToLogin } from '@/utils/uri-utils';
import { isNullOrWhitespace } from '@/utils/obj-utils';
import { useRouter } from 'vue-router';

export default {
  name: 'MyDetails',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const appStore = useAppStore();
    const loading = inject('loading');
    const toast = inject('toast');
    const router = useRouter();
    const state = reactive({
      changeEmailRef: null,
      verifyEmailRef: null,
      changeMobileRef: null,
      verifyMobileRef: null,
      changeEmailFormRef: null,
      verifyEmailFormRef: null,
      changeMobileFormRef: null,
      verifyMobileFormRef: null,
      personalFormRef: null,
      captchaType: null,
      email: null,
      phone: {
        number: '',
        code: ''
      },
      emailFormConfig: {
        email: {
          controlProps: {
            value: null
          }
        }
      },
      mobileFormConfig: {
        phone: {
          controlProps: {
            value: null
          }
        }
      },
      personalFormConfig: {
        firstName: {
          controlProps: {
            value: null
          }
        },
        lastName: {
          controlProps: {
            value: null
          }
        }
      }
    });
    watch(
      () => appStore.loginInfo,
      () => {
        methods.initPersonalInfo();
      },
      {
        deep: true
      }
    );
    const methods = {
      changeEmail: async () => {
        loading.show();
        state.captchaType = CAPTCHA_TYPE.email;
        if (!isNullOrWhitespace(appStore?.loginInfo?.email)) {
          state.email = appStore?.loginInfo?.email;
          const [res, ex] = await api.cidp.captcha.getAuth(null, {
            captchaType: CAPTCHA_TYPE.email,
            captchaScene: CAPTCHA_SCENE.bindEmail
          });
          if (ex) {
            loading.hide();
            toast.showEx(ex);
            return;
          } else {
            loading.hide();
            state.verifyEmailRef.open();
          }
        } else {
          state.phone = {
            code: appStore?.loginInfo?.mobileAreaCode,
            number: appStore?.loginInfo?.phone
          };
          const [res, ex] = await api.cidp.captcha.getAuth(null, {
            captchaType: CAPTCHA_TYPE.phone,
            captchaScene: CAPTCHA_SCENE.bindPhone
          });
          if (ex) {
            loading.hide();
            toast.showEx(ex);
            return;
          } else {
            loading.hide();
            state.verifyMobileRef.open();
          }
        }
      },
      changeMobile: async () => {
        loading.show();
        state.captchaType = CAPTCHA_TYPE.phone;
        if (!isNullOrWhitespace(appStore?.loginInfo?.phone)) {
          state.phone = {
            code: appStore?.loginInfo?.mobileAreaCode,
            number: appStore?.loginInfo?.phone
          };
          const [res, ex] = await api.cidp.captcha.getAuth(null, {
            captchaType: CAPTCHA_TYPE.phone,
            captchaScene: CAPTCHA_SCENE.bindPhone
          });
          if (ex) {
            loading.hide();
            toast.showEx(ex);
            return;
          } else {
            loading.hide();
            state.verifyMobileRef.open();
          }
        } else {
          state.email = appStore?.loginInfo?.email;
          const [res, ex] = await api.cidp.captcha.getAuth(null, {
            captchaType: CAPTCHA_TYPE.email,
            captchaScene: CAPTCHA_SCENE.bindEmail
          });
          if (ex) {
            loading.hide();
            toast.showEx(ex);
            return;
          } else {
            loading.hide();
            state.verifyEmailRef.open();
          }
        }
      },
      verifyEmail: async () => {
        const [valid, formData] = state.changeEmailFormRef.validate();
        if (valid) {
          const [res, ex] = await api.cidp.captcha.emailBind.get(null, {
            captchaType: CAPTCHA_TYPE.email,
            captchaScene: CAPTCHA_SCENE.bindEmail,
            email: formData.email
          });
          if (ex) {
            await toast.showEx(ex);
            return;
          } else {
            state.email = formData.email;
            await state.changeEmailRef.close().catch();
            state.verifyEmailRef.open();
          }
        }
      },
      updateEmail: async () => {
        const [codeValid, code] = state.verifyEmailFormRef.validate();
        if (codeValid) {
          loading.show();
          if (state.captchaType === 2) {
            if (state.email === appStore?.loginInfo?.email) {
              const [res, ex] = await api.cidp.captcha.checkAuth(null, {
                captchaType: CAPTCHA_TYPE.email,
                captchaScene: CAPTCHA_SCENE.bindEmail,
                captcha: code.emailVerifyCode
              });
              if (ex) {
                await toast.showEx(ex);
                loading.hide();
                return;
              } else {
                loading.hide();
                await state.verifyEmailRef.close().catch();
                state.changeEmailRef.open();
              }
            } else {
              const [res, ex] = await api.cidp.details.updatePhoneOrEmail(null, {
                captchaType: CAPTCHA_TYPE.email,
                account: state.email,
                captcha: code.emailVerifyCode,
                SYSTEM_FLAG: '2'
              });
              if (ex) {
                await toast.showEx(ex);
                loading.hide();
                return;
              } else {
                const [res, ex] = await api.cidp.user.info();
                if (ex) {
                  appStore.updateLoginInfo({ email: state.email });
                } else {
                  if (Object.keys(res).includes('token')) {
                    delete res.token;
                  }
                  appStore.updateLoginInfo({
                    ...res,
                    checkTime: new Date().getTime()
                  });
                }
                loading.hide();
                await state.verifyEmailRef.close().catch();
              }
            }
          } else {
            const [res, ex] = await api.cidp.captcha.checkAuth(null, {
              captchaType: CAPTCHA_TYPE.email,
              captchaScene: CAPTCHA_SCENE.bindEmail,
              captcha: code.emailVerifyCode
            });
            if (ex) {
              await toast.showEx(ex);
              loading.hide();
              return;
            } else {
              loading.hide();
              await state.verifyEmailRef.close().catch();
              state.changeMobileRef.open();
            }
          }
        }
      },
      resendEmailCode: async () => {
        if (state.email === appStore?.loginInfo?.email) {
          const [res, ex] = await api.cidp.captcha.getAuth(null, {
            captchaType: CAPTCHA_TYPE.email,
            captchaScene: CAPTCHA_SCENE.bindEmail
          });
          if (ex) {
            await toast.showEx(ex);
            return;
          }
        } else {
          const [res, ex] = await api.cidp.captcha.emailBind.get(null, {
            captchaType: CAPTCHA_TYPE.email,
            captchaScene: CAPTCHA_SCENE.bindEmail,
            email: state.email
          });
          if (ex) {
            await toast.showEx(ex);
            return;
          }
        }
      },
      verifyMobile: async () => {
        const [valid, formData] = state.changeMobileFormRef.validate();
        if (valid) {
          const [res, ex] = await api.cidp.captcha.emailBind.get(null, {
            captchaType: CAPTCHA_TYPE.phone,
            captchaScene: CAPTCHA_SCENE.bindPhone,
            mobileAreaCode: formData.phone.area.code,
            phone: formData.phone.number
          });
          if (ex) {
            await toast.showEx(ex);
            return;
          } else {
            state.phone = {
              number: formData.phone.number,
              code: formData.phone.area.code
            };
            await state.changeMobileRef.close().catch();
            state.verifyMobileRef.open();
          }
        }
      },
      updateMobile: async () => {
        const [codeValid, code] = state.verifyMobileFormRef.validate();
        if (codeValid) {
          loading.show();
          if (state.captchaType === 1) {
            if (state.phone.number === appStore?.loginInfo?.phone) {
              const [res, ex] = await api.cidp.captcha.checkAuth(null, {
                captchaType: CAPTCHA_TYPE.phone,
                captchaScene: CAPTCHA_SCENE.bindPhone,
                captcha: code.phoneVerifyCode
              });
              if (ex) {
                await toast.showEx(ex);
                loading.hide();
                return;
              } else {
                loading.hide();
                await state.verifyMobileRef.close().catch();
                state.changeMobileRef.open();
              }
            } else {
              const [res, ex] = await api.cidp.details.updatePhoneOrEmail(null, {
                captchaType: CAPTCHA_TYPE.phone,
                account: state.phone.number,
                mobileAreaCode: state.phone.code,
                captcha: code.phoneVerifyCode,
                SYSTEM_FLAG: '2'
              });
              if (ex) {
                await toast.showEx(ex);
                loading.hide();
                return;
              } else {
                const [res, ex] = await api.cidp.user.info();
                if (ex) {
                  appStore.updateLoginInfo({ phone: state.phone.number });
                } else {
                  if (Object.keys(res).includes('token')) {
                    delete res.token;
                  }
                  appStore.updateLoginInfo({
                    ...res,
                    checkTime: new Date().getTime()
                  });
                }
                loading.hide();
                await state.verifyMobileRef.close().catch();
              }
            }
          } else {
            const [res, ex] = await api.cidp.captcha.checkAuth(null, {
              captchaType: CAPTCHA_TYPE.phone,
              captchaScene: CAPTCHA_SCENE.bindPhone,
              captcha: code.phoneVerifyCode
            });
            if (ex) {
              await toast.showEx(ex);
              loading.hide();
              return;
            } else {
              loading.hide();
              await state.verifyMobileRef.close().catch();
              state.changeEmailRef.open();
            }
          }
        }
      },
      resendMobileCode: async () => {
        if (state.phone.number === appStore?.loginInfo?.phone) {
          const [res, ex] = await api.cidp.captcha.getAuth(null, {
            captchaType: CAPTCHA_TYPE.phone,
            captchaScene: CAPTCHA_SCENE.bindPhone
          });
          if (ex) {
            await toast.showEx(ex);
            return;
          }
        } else {
          const [res, ex] = await api.cidp.captcha.emailBind.get(null, {
            captchaType: CAPTCHA_TYPE.phone,
            captchaScene: CAPTCHA_SCENE.bindPhone,
            mobileAreaCode: state.phone.code,
            phone: state.phone.number
          });
          if (ex) {
            await toast.showEx(ex);
            return;
          }
        }
      },
      initPersonalInfo: () => {
        state.personalFormConfig.firstName.controlProps.value = appStore?.loginInfo?.firstName;
        state.personalFormConfig.lastName.controlProps.value = appStore?.loginInfo?.lastName;
      },
      updateUserInfo: debounce(async () => {
        const [valid, formData] = await state.personalFormRef.validate();
        if (valid) {
          loading.show();
          const [res, ex] = await api.cidp.details.updateName(null, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            SYSTEM_FLAG: '2'
          });
          if (ex) {
            await toast.showEx(ex);
            loading.hide();
            return;
          }
          appStore.updateLoginInfo({
            firstName: formData.firstName,
            lastName: formData.lastName
          });
          loading.hide();
        }
      }, 100)
    };
    onMounted(() => {
      if (!appStore?.hasLoggedIn) {
        redirectToLogin(props.page, router);
        return;
      }
    });
    return {
      appStore,
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-my-details {
  margin-top: 16px;
  padding: 0 32px;
  &__section {
    margin-top: 40px;
    &-explain {
      padding-bottom: 16px;
      border-bottom: 1px solid #5f5f5f;
    }
    &-change-group {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      &-icon {
        margin-right: 10px;
      }
      &-main {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &-text-detail {
          min-height: 24px;
        }
        &-icon {
          cursor: pointer;
        }
      }
    }
    &-modal {
      &.e-modal {
        @include height-except-header;
        margin-top: mobile-header-height();
      }
      .e-modal__content {
        width: 100%;
        height: 100%;
      }
    }
    &-change {
      margin: 56px 32px;
      .s-dynamic-form {
        margin-top: 24px;
      }
      &-btn {
        width: 190px;
        margin: 0 auto;
        .e-site-button {
          min-width: 190px;
        }
      }
    }
    &-verify {
      margin: 56px 32px;
      .s-dynamic-form {
        margin-top: 24px;
      }
      &-btn {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .e-site-button {
          min-width: 190px;
          & + .e-site-button {
            margin-top: 10px;
          }
        }
      }
    }
    &-title {
      a {
        text-decoration: underline;
      }
    }
  }
  &__personal-group {
    &-btn {
      width: 190px;
      margin: 0 auto;
      .e-site-button {
        width: 100%;
      }
    }
    &-tips {
      display: flex;
      flex-direction: column;
      margin: 56px auto;
      &-main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        & + .c-my-details__personal-group-tips-main {
          margin-top: 20px;
        }
      }
      &-icon {
        margin-right: 10px;
      }
    }
    .s-dynamic-form {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: inset 0 0 0 1000px transparent !important;
      }
    }
  }
  @include tablet-landscape {
    width: grid-width(10);
    &__section {
      margin-top: 32px;
      padding: 20px 24px;
      &-explain {
        padding-bottom: 24px;
      }
      &-change-group {
        margin-top: 24px;
        &-main {
          cursor: pointer;
        }
      }
      &-modal {
        .e-modal__content {
          width: 540px;
          height: auto;
          border: 1px solid $white;
          overflow: unset;
        }
      }
      &-verify-btn {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .e-site-button {
          & + .e-site-button {
            margin-top: 0;
          }
        }
      }
      &-change-btn {
        margin: 0;
      }
    }
    &__personal-group {
      margin-top: 56px;
      &-btn {
        margin: 0;
      }
      &-tips {
        flex-direction: row;
        margin: 75px auto;
        &-main {
          & + .c-my-details__personal-group-tips-main {
            margin-top: 0;
            margin-left: 24px;
          }
        }
      }
    }
  }
}
</style>
