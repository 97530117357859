<template>
  <overall-settings v-bind="$props">
    <div class="c-news-detail-header" v-if="fields">
      <jss-rich-text class="c-news-detail-header-date" :field="fields.date" tag="div" />
      <div class="c-news-detail-header-title-content">
        <jss-rich-text class="c-news-detail-header-title" :field="fields.title" tag="div" />
      </div>
      <BackgroundImage class="c-news-detail-header-image" :image="fields.image" />
    </div>
  </overall-settings>
</template>

<script>
export default {
  name: 'NewsDetailHeader',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-news-detail-header {
  width: 100%;
  position: relative;
  padding: grid-width-m(1.5) 0 0 grid-width-m(1);
  &-date {
    @include h7;
    margin-left: grid-width-m(2);
  }
  &-title-content {
    margin: grid-width-m(0.8) 0 0 grid-width-m(1);
    padding: 0 grid-width-m(1) grid-width-m(1.3) grid-width-m(1);
    position: relative;
    background: #fff;
    z-index: 1;
  }
  &-title {
    @include h4;
  }
  &-image {
    width: grid-width-m(12);
    height: grid-width-m(8);
    margin-left: grid-width-m(-1);
    margin-top: grid-width-m(-1.5);
  }
  @include tablet-landscape {
    position: relative;
    padding: grid-width(1) grid-width(2) 0 grid-width(2);
    &-date {
      @include h8;
      margin-left: grid-width(6);
    }
    &-title-content {
      margin: grid-width(0.3) 0 0 grid-width(5);
      padding: 0 0 grid-width(1) grid-width(1);
      position: relative;
      background: #fff;
      z-index: 1;
    }
    &-title {
      @include h4;
    }
    &-image {
      width: grid-width(17);
      height: grid-width(10);
      margin-left: grid-width(-2);
      margin-top: grid-width(-1.5);
    }
  }
}
</style>
