<template>
  <overall-settings v-bind="$props">
    <div class="c-news-detail-article" v-if="fields" ref="swiperEl">
      <JssText class="c-news-detail-article-title" :field="fields.title" tag="div" />
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in fields.articleList" :key="index">
          <RouterLink :to="`/${lang}${item.url}`">
            <BackgroundImage class="c-news-detail-article-slide-image" :image="item.fields.newsImage ?? item.fields.newsPoster">
              <div class="c-news-detail-article-slide-button-inner-content">
                <Icon class="c-news-detail-article-slide-button-icon" :svg="fields.icon.value.svgCode" />
              </div>
            </BackgroundImage>
            <JssText class="c-news-detail-article-slide-date" :field="item.fields.createDate" tag="div" />
            <jss-rich-text class="c-news-detail-article-slide-title" :field="item.fields.newsHeading ?? item.fields.newsTitle" tag="div" />
            <div class="c-news-detail-article-slide-button-content" v-if="item.fields.tag?.fields.text">
              <div class="c-news-detail-article-slide-news">
                <JssText :field="item.fields.tag?.fields.text" tag="div" />
              </div>
            </div>
            <jss-rich-text class="c-news-detail-article-slide-desc" :field="item.fields.newsDescription ?? item.fields.newsSummary" tag="div" />
          </RouterLink>
        </div>
      </div>
      <div class="swiper-pagination" ref="paginationEl"></div>
      <div class="c-news-detail-article-left"></div>
    </div>
  </overall-settings>
</template>

<script>
import { onMounted, onUnmounted, nextTick, toRefs, reactive, computed } from 'vue';
import Swiper from 'swiper';
import { isDesktop } from '@/utils/dom-utils';
import { gridWidth, gridWidthMobile } from '@/utils/site-utils';
import { useRouter } from 'vue-router';
export default {
  name: 'NewsDetailArticle',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper = null;
    const router = useRouter();
    let state = reactive({
      showFullImage: false,
      swiperEl: null,
      paginationEl: null
    });
    const computes = {
      lang: computed(() => router.currentRoute.value?.params.lang ?? {})
    };
    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      swiper = new Swiper(state.swiperEl, {
        slidesPerView: isDesktop() ? '2' : '1',
        spaceBetween: isDesktop() ? gridWidth(2) : gridWidthMobile(1),
        pagination: {
          el: state.paginationEl,
          bulletElement: 'div',
          clickable: true
        }
      });
    };
    onMounted(() => {
      window.addEventListener('resize', buildSwiper);
      nextTick(() => {
        buildSwiper();
      });
    });
    onUnmounted(() => {
      swiper.destroy(true, true);
      window.removeEventListener('resize', buildSwiper);
    });
    return {
      ...toRefs(state),
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-news-detail-article {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 grid-width-m(1);
  &-title {
    @include h7;
  }
  .swiper-wrapper {
    margin-top: grid-width-m(1);
    width: 100%;
    .swiper-slide:hover {
      .c-news-detail-article-slide-title {
        text-decoration: underline;
      }
      .c-news-detail-article-slide-button-inner-content {
        transform: translateX(0);
      }
    }
  }
  .swiper-pagination {
    margin-top: grid-width-m(2);
    display: block;
    width: 100%;
    height: 8px;
    display: flex;
    justify-content: center;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      margin-left: 20px;
    }
    .swiper-pagination-bullet:first-child {
      margin-left: 0;
    }
    .swiper-pagination-bullet-active {
      background: #000;
    }
  }
  &-slide {
    &-image {
      width: grid-width-m(10);
      height: grid-width-m(6);
      .e-background-image__content {
        height: 100%;
      }
    }
    &-button-inner-content {
      width: grid-width(1);
      height: grid-width(1);
      background-color: $yellow;
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.8s ease;
      transform: translateX(grid-width(1));
      z-index: -1;
    }
  }
  &-slide-date {
    width: grid-width(9);
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    margin-top: 24px;
  }
  &-slide-title {
    width: grid-width-m(10);
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-top: 16px;
  }
  &-slide-news {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  &-slide-desc {
    display: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }
  &-slide-button-content {
    @include h8;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-slide-button-inner-content {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  // &-slide-button-icon {
  //   margin-left: 10px;
  // }
  &-slide-news {
    background: #fff200;
    // @include h8;
    padding: 6px 8px;
  }
  &-left {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    background: #fff;
    z-index: 2;
  }
  @include tablet-landscape {
    overflow: hidden;
    padding: 0 grid-width(2);
    &-title {
      @include h6;
    }
    .swiper-wrapper {
      margin-top: grid-width(0.5);
      width: grid-width(22);
      cursor: pointer;
    }
    .swiper-pagination {
      display: none;
    }
    &-slide-image {
      width: grid-width(9);
      height: grid-width(6);
    }
    &-slide-date {
      width: grid-width(9);
      font-size: 16px;
      line-height: 1.3;
      margin-top: 24px;
    }
    &-slide-title {
      width: grid-width(9);
      font-size: 24px;
      font-weight: 500;
      line-height: 1.3;
      margin-top: 16px;
    }
    &-slide-desc {
      display: block;
      width: grid-width(9);
      font-size: 14px;
      line-height: 21px;
      margin-top: 30px;
    }
    &-slide-button-content {
      @include h8;
      margin-top: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    // &-slide-button-icon {
    //   margin-left: 10px;
    // }
    // &-slide-news {
    //   display: none;
    // }
  }
}
</style>
