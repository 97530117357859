<template>
  <div class="c-news-waterfall" ref="waterfallEl">
    <div class="c-news-waterfall__top">
      <div class="c-news-waterfall__left-title">
        <JssRichText :field="fields.title" tag="div" />
      </div>
      <div class="c-news-waterfall__left-othertitle">
        <JssRichText :field="fields.othertitle" tag="div" />
      </div>
    </div>
    <div class="c-news-waterfall__list">
      <div class="c-news-waterfall__list-item" v-for="item in list" :key="item.id">
        <div class="c-news-waterfall__list-img">
          <BackgroundImage class="c-news-waterfall__list-img-con" :image="item.fields.image" />
        </div>
        <TextAnimate class="c-news-waterfall__list-content">
          <JssRichText class="c-news-waterfall__list-title" :field="item.fields.title" tag="div" />
          <JssRichText class="c-news-waterfall__list-body" :field="item.fields.body" tag="div" />
        </TextAnimate>
      </div>
    </div>
    <SiteButton class="c-news-waterfall__btn" v-bind="fields.button" />
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted, nextTick } from 'vue';
import { loadScrollMagic } from '@/utils/site-utils';
import gsap from 'gsap';
export default {
  name: 'NewsWaterFall',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let ScrollMagic, controller;
    const state = reactive({
      list: props.fields.items,
      waterfallEl: null
    });

    onMounted(() => {
      ScrollMagic = loadScrollMagic();
      controller = new ScrollMagic.Controller();
      new ScrollMagic.Scene({
        triggerElement: state.waterfallEl,
        offset: -document.documentElement.clientHeight,
        triggerHook: 0
      })
        .addTo(controller)
        .setClassToggle(state.waterfallEl, 'active');
    });

    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-news-waterfall {
  $c: &;
  padding: 0 grid-width(2);
  background: #fff;
  color: #000;
  &.active {
    .c-news-waterfall__top div div {
      transform: translateY(0) rotate(0);
    }
  }
  &__top {
    font-size: 48px;
    > div {
      overflow: hidden;
      div {
        transform: translateY(130%) rotate(5deg);
        transition: all 1s;
      }
    }
  }
  &__list {
    &-item {
      margin-bottom: 60px;
      &:first-child {
        .c-news-waterfall__list-img {
          height: grid-width(math.div(20 * 3, 4));
        }
      }
    }
    &-img {
      position: relative;
      width: grid-width(20);
      margin: 30px 0;
      height: grid-width(math.div(20 * 2, 3));
      .e-background-image {
        &.has-animation {
          width: 100%;
          height: 100%;
          .e-background-image__img {
            &:before {
              background: #000;
            }
          }
        }
      }
    }
    &-title {
      @include h8;
      padding: 0 0 20px 0;
    }
    &-body {
      @include h4;
    }
  }
  .c-news-waterfall__btn {
    display: block;
    text-align: center;
  }
  @include tablet-landscape {
    &__top {
      font-size: 80px;
      line-height: 1;
      letter-spacing: 5px;
      margin-bottom: 40px;
    }
    &__list {
      overflow: hidden;
      &-item {
        width: grid-width(8.5);
        margin-bottom: 80px;
        float: left;
        &:nth-child(2n + 1) {
          margin-left: grid-width(3);
          .c-news-waterfall__list-img {
            margin-top: grid-width(2);
          }
        }
        &:first-child {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-left: 0;
          .c-news-waterfall__list-img {
            width: grid-width(14);
            height: grid-width(math.div(14 * 3, 4));
            margin: 0;
          }
          .c-news-waterfall__list-content {
            width: grid-width(5);
            margin-bottom: grid-width(1);
          }
        }
      }
      &-img {
        width: grid-width(8.5);
        height: grid-width(math.div(8.5 * 2, 3));
        margin: 0;
      }
    }
    &__btn {
      margin-top: 45px;
    }
  }
}

@keyframes leftAnimate {
  0% {
    opacity: 1;
    top: 100%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
html.rtl {
  .c-news-waterfall {
    &__list {
      &-item {
        float: right;
      }
    }
  }
}
</style>
