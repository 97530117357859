<template>
  <div class="c-merchandise-return">
    <div class="c-merchandise-return__content">
      <div class="c-merchandise-return__content__main">
        <MerchandiseCheckoutSteps :steps="fields.steps" :current="step" :exitText="fields.exitText" v-if="fields.steps?.length" />
        <div class="c-merchandise-return__content__main__content">
          <MerchandiseReturnItem
            v-bind="fields.itemContent"
            :selectAllText="fields.selectAllText"
            :returnItems="returnItems"
            :currency="currency"
            :page="page"
            :step="step"
            @updateDate="updateDate"
            @updateStep="updateStep"
          />
          <MerchandiseReturnReason v-bind="reasonContent" :step="step" @updateStep="updateStep" />
          <MerchandiseReturnConfirmation v-bind="fields.confirmationContent" :step="step" :total="total" :page="page" :formData="formData" :orderCode="orderCode" :returnItems="returnListItems" />
          <div class="c-merchandise-return__content__main__exit" @click="onExitClick" v-if="!$deviceComputes.isMobileOrTablet.value">
            <Icon name="back" type="tiny" />
            <div class="c-merchandise-return__content__main__exit__text" v-html="fields.exitText.value" />
          </div>
        </div>
      </div>
      <div class="c-merchandise-return__content__aslide">
        <BackgroundImage class="c-merchandise-return__content__aslide__image" :image="fields.image" v-if="step === 0" />
        <Transition name="bottom-slide-in" v-if="step === 0">
          <MerchandiseReturnSummary
            v-if="!$deviceComputes.isMobileOrTablet.value || openPriceBreakdown"
            :step="step"
            :total="total"
            :page="page"
            :currency="currency"
            :title="fields.faqs?.fields?.title"
            :description="fields.faqs?.fields?.description"
            :faqlist="fields.faqs?.fields?.list"
            @questionClick="questionClick"
            @onClosePriceBreakdown="onClosePriceBreakdown"
          />
        </Transition>
        <Transition name="bottom-slide-in" v-if="step !== 0">
          <MerchandiseReturnList
            v-if="!$deviceComputes.isMobileOrTablet.value || openMerchandiseDetail"
            :step="step"
            :page="page"
            :currency="currency"
            :returnItemsText="fields.returnItemsText"
            :returnItems="returnListItems"
            :total="total"
            @onCloseMerchandiseDetail="onCloseMerchandiseDetail"
          />
        </Transition>
      </div>
      <div class="c-merchandise-return__content__mobileprice">
        <div class="c-merchandise-return__content__mobileprice__item">
          <div class="c-merchandise-return__content__mobileprice__item__content">
            <div class="c-merchandise-return__content__mobileprice__item__content__label" v-html="$t('Total')" />
            <div class="c-merchandise-return__content__mobileprice__item__content__item" v-html="!$equalString(pageAlpha2Code, 'GB') ? $t('Excluded VAT') : $t('Including VAT')" />
          </div>
          <div class="c-merchandise-return__content__mobileprice__item__value">{{ $formatShopMoney(total, currency) }}</div>
        </div>
        <div class="c-merchandise-return__content__mobileprice__items" @click="onOpenPriceBreakdown">
          <jss-rich-text class="c-merchandise-return__content__mobileprice__items__label" v-if="step !== 0" :field="fields.returnItemsText" />
          <div class="c-merchandise-return__content__mobileprice__items__label" v-if="step === 0" v-html="$t('Summary')" />
          <Icon name="arrow-up" type="tiny" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs, inject } from 'vue';
import { settingValue, decodeData, getGlobalCfgValues, getPageAlpha2Code } from '@/utils/site-utils';
import { useRoute, useRouter } from 'vue-router';
import gsap from 'gsap';
import api from '@/api';

export default {
  name: 'MerchandiseReturn',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props, context) {
    const toast = inject('toast');
    const appMethods = inject('appMethods');
    const route = useRoute();
    const router = useRouter();
    const { returnItems } = route?.query || {};
    const { orderCode } = route?.query || {};
    const state = reactive({
      step: 0,
      openPriceBreakdown: false,
      openMerchandiseDetail: false,
      returnItems: null,
      orderCode: null,
      total: 0,
      form: null,
      formData: null,
      currency: 'EUR',
      reasonContent: null,
      returnListItems: [],
      pageAlpha2Code: null
    });
    const computes = {
      lang: computed(() => router.currentRoute.value?.params.lang ?? {})
    };
    const methods = {
      questionClick(_index) {
        let faqList = props?.fields?.faqs;
        faqList?.fields.list?.map((item, index) => {
          if (index === _index) {
            item.selected = !item.selected;
          }
        });
      },
      onOpenPriceBreakdown: () => {
        if (state.step === 0) {
          state.openPriceBreakdown = true;
        } else {
          state.openMerchandiseDetail = true;
        }
      },
      onClosePriceBreakdown: () => {
        state.openPriceBreakdown = false;
      },
      onCloseMerchandiseDetail: () => {
        state.openMerchandiseDetail = false;
      },
      onExitClick() {
        window.history.go(-1);
      },
      updateDate(obj) {
        state.total = obj.total;
        state.returnListItems = obj.returnItems;
      },
      updateStep(data) {
        state.formData = data.formData;
        state.step = data.step;
        methods.getRulesList();
      },
      async getRulesList() {
        const [res, ex] = await api.shop.getRulesList({ orderRuleId: 16 });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (!res.length) {
          return false;
        }
        let list = [];
        res.forEach((item) => {
          let listItem = {
            fields: {
              code: {
                value: item.id
              },
              text: {
                value: item.name
              }
            }
          };
          list.push(listItem);
        });
        if (props?.fields?.reasonContent?.fields?.form?.fields?.items?.length) {
          props?.fields?.reasonContent?.fields?.form?.fields?.items.forEach((item) => {
            if (item?.fields?.fieldType?.fields?.phrase?.value === 'dropdown') {
              item.fields.options = list;
            }
          });
        }
        state.reasonContent = props?.fields?.reasonContent;
      }
    };
    onMounted(() => {
      const [currencyVal] = getGlobalCfgValues(props.page, ['currency']);
      state.returnItems = decodeData(returnItems);
      state.currency = state.returnItems[0].currency ? state.returnItems[0].currency : currencyVal;
      state.orderCode = orderCode;
      state.pageAlpha2Code = getPageAlpha2Code(props.page);
      methods.getRulesList();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-return {
  $c: &;
  @include grid-container;
  position: relative;
  z-index: 2;
  &__content {
    @include grid-block(1, 12);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    &__main {
      order: 2;
      padding: 52px grid-width(2) 0;
      &__content {
        width: 100%;
      }
    }
    &__aslide {
      order: 1;
      top: 0;
      &__image {
        width: 100%;
        height: 67vw;
      }
      &__faq {
        // display: none;
      }
    }
    &__mobileprice {
      order: 3;
      width: 100%;
      color: $white;
      padding: 16px 24px 24px;
      background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0, rgba(0, 0, 0, 0.9) 100%);
      display: flex;
      flex-direction: column;
      gap: 12px;
      position: fixed;
      bottom: 0;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__content {
          display: flex;
          align-items: center;
          gap: 5px;
          &__label {
            font-size: 12px;
            line-height: 20px;
          }
          &__item {
            font-size: 12px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.5);
          }
        }
        &__value {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &__items {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $white;
        gap: 5px;
        &__label {
          font-size: 12px;
          line-height: 20px;
        }
        .e-icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  @include tablet-landscape {
    &__content {
      @include grid-block(1, 24);
      flex-direction: row;
      &__main {
        padding: 24px 0 0;
        order: 1;
        width: 50%;
        &__content {
          padding: 0 grid-width(2);
        }
        &__exit {
          display: flex;
          align-items: center;
          color: #4f4f4f;
          gap: 8px;
          margin-top: 100px;
          margin-bottom: 146px;
          cursor: pointer;
          &__text {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
      &__aslide {
        // height: 120vh;
        min-height: 100vh;
        order: 2;
        width: 50%;
        background: $black;
        position: sticky !important;
        &__image {
          height: calc(grid-width(12) * 0.5625);
        }
        &__faq {
          display: block;
          padding: 60px grid-width(1) 0;
          &__title {
            font-size: 26px;
            line-height: 32px;
            color: $white;
          }
          &__subtitle {
            font-size: 12px;
            line-height: 20px;
            color: $white;
            margin-top: 24px;
          }
          &__item {
            padding: 16px 0 6px;
            border-top: 1px solid #2f2f2f;
            overflow: hidden;
            &.active {
              margin-top: 44px;
            }
            &__question {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              line-height: 24px;
              color: $white;
              margin-bottom: 10px;
              cursor: pointer;
              &-item {
                width: 90%;
              }
              .e-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                transition: all 0.3s;
                &.open {
                  transform: rotate(180deg);
                  transform-origin: center center;
                }
              }
            }
            &__answer {
              font-size: 12px;
              line-height: 20px;
              color: #757575;
            }
          }
        }
        &__price {
          width: 100%;
          padding: 24px grid-width(2);
          border-top: 1px solid #2f2f2f;
          background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0, rgba(0, 0, 0, 0.9) 100%);
          display: flex;
          flex-direction: column;
          gap: 30px;
          &__item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            &__content {
              display: flex;
              align-items: center;
              gap: 5px;
              &__label {
                font-size: 12px;
                line-height: 20px;
                color: $white;
              }
              &__item {
                font-size: 12px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.5);
              }
            }
            &__label {
              font-size: 12px;
              line-height: 20px;
              color: $white;
            }
            &__value {
              font-size: 12px;
              line-height: 20px;
              color: $white;
            }
          }
        }
      }
      &__mobileprice {
        display: none;
      }
    }
  }
}
</style>
