<template>
  <div class="c-merchandise-checkout" ref="rootEl">
    <div class="c-merchandise-checkout__wrapper">
      <div class="c-merchandise-checkout__main" v-show="loaded">
        <MerchandiseCheckoutSteps :steps="fields.steps" :current="step" :exitText="fields.exitText" v-if="fields.steps?.length" />
        <div class="c-merchandise-checkout__content">
          <MerchandiseCheckoutVerify ref="verifyRef" v-bind="fields.verifySection" :bound="bound" :bind-required="bindRequired" :step="step" :stepCode="stepCode" />
          <MerchandiseCheckoutDelivery ref="deliveryRef" v-bind="fields.deliverySection" :step="step" :stepCode="stepCode" />
          <MerchandiseCheckoutPayment
            ref="paymentRef"
            v-bind="fields.paymentSection"
            :failed="paymentWasFailed"
            :paymentRes="paymentRes"
            :price="$formatShopMoney(depositPrice ? depositPrice : totalPrice, currency)"
            :step="step"
            :stepCode="stepCode"
          />
          <div class="c-merchandise-checkout__actions">
            <div class="c-merchandise-checkout__actions-exit" @click="onExitClick" v-if="!$deviceComputes.isMobileOrTablet.value">
              <Icon name="left" />
              <div class="c-merchandise-checkout__actions-exit-text" v-html="fields.exitText.value" />
            </div>
            <SiteButton v-bind="fields.continueButton" @click="goNext" v-if="step === 1" />
            <SiteButton v-bind="fields.payButton" @click="goNext" v-if="step === 2" />
          </div>
        </div>
      </div>

      <Transition name="bottom-slide-in">
        <div class="c-merchandise-checkout__aside" v-if="!$deviceComputes.isMobileOrTablet.value || openPriceBreakdown">
          <div class="c-merchandise-checkout__aside-header" v-if="$deviceComputes.isMobileOrTablet.value">
            <Icon name="close" @click="onClosePriceBreakdown" />
          </div>
          <MerchandiseCheckoutSummary
            :page="page"
            :fields="fields"
            :skuList="skuList"
            :totalPrice="depositPrice ? depositPrice : totalPrice"
            :currency="currency"
            :deliveryDetails="deliveryDetails"
            v-if="loaded"
          />
        </div>
      </Transition>
    </div>

    <div class="c-merchandise-checkout-price-mobile" v-if="$deviceComputes.isMobileOrTablet.value">
      <div class="c-merchandise-checkout-price-mobile-top" @click="onOpenPriceBreakdown">
        <div class="c-merchandise-checkout-price-mobile-top-btn">{{ $t('See details') }}</div>
        <Icon name="arrowUp" size="tiny" />
      </div>
      <div class="c-merchandise-checkout-price-mobile-bottom">
        <JssRichText
          class="c-merchandise-checkout-price-mobile-bottom-left"
          :field="fields.summaryLabel"
          @click="!largeThanTablet() && toggleDis()"
          @mouseover="largeThanTablet() && showDis()"
          @mouseout="largeThanTablet() && hideDis()"
        />
        <div class="c-merchandise-checkout-price-mobile-bottom-right">{{ $formatShopMoney(depositPrice ? depositPrice : totalPrice, currency) }}</div>
        <div
          class="c-merchandise-checkout-price-mobile-bottom-disclaimer"
          v-html="$formatString(fields.priceDisclaimer?.value, { price: $formatShopMoney(depositPrice ? depositPrice : totalPrice, currency) })"
          v-if="showDisclaimer"
        />
      </div>
    </div>

    <NoticeModal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
    <NoticeModal v-bind="fields.accountModal" ref="accountModalRef" />
    <GeeCaptcha ref="geeCaptchaRef" />
  </div>
</template>
<script>
import { toRefs, reactive, computed, inject, onMounted, nextTick, provide } from 'vue';
import { useRouter } from 'vue-router';
import { buildAddress, getCurrentAlpha2Code, getGlobalConfigs, scrollToTop, decodeData } from '@/utils/site-utils';
import { appendQuery, getBetterUrl, getQueryStrings } from '@/utils/uri-utils';
import { DELIVERY_TYPE_CODE, WEBSHOP_PAMENT_TYPE_CODE } from '@/utils/constants';
import { equalString } from '@/utils/string-utils';
import { loadStripe } from '@/utils/stripe-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import { webStorage } from '@/utils/web-storage';
import { S_SHOP_ORDER_INFO } from '@/utils/web-storage-keys';
import { formatDate } from '@/utils/date-utils';
import { bindConsents, countryNeedDoubleOptin } from '@/services/siteService';
import { debounce, merge, difference } from 'lodash';
import api from '@/api';

export default {
  name: 'MerchandiseCheckout',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const appStore = useAppStore();
    const router = useRouter();
    const currentAlpha2Code = getCurrentAlpha2Code();
    const [shopConfirmationLink, disableSkuCode, market] = getGlobalConfigs(props.page, 'shopConfirmationLink', 'disableSkuCode', 'market');
    let spuBusIds, skuIds, token, clientSecret, redirectStatus, stripeHelper, stripe;
    const shopConfirmationHref = shopConfirmationLink?.value?.href;
    const state = reactive({
      loaded: false,
      rootEl: null,
      step: 0,
      verifyRef: null,
      deliveryRef: null,
      paymentRef: null,
      orderInfo: null,
      email: null,
      bound: {
        email: false,
        mobile: false
      },
      spuList: [],
      sendTypeList: [],
      skuList: [],
      itemPayType: '',
      totalPrice: 0,
      depositPrice: 0,
      openPriceBreakdown: false,
      orderLocaleRedirectModalRef: null,
      accountModalRef: null,
      paymentWasFailed: false,
      paymentRes: null,
      currency: null,
      showDisclaimer: false,
      deliveryDetails: null,
      geeCaptchaRef: null
    });

    const computes = {
      stepCode: computed(() => (state.step < props.fields.steps.length ? props.fields.steps[state.step]?.fields.code.value : '')),
      bindRequired: computed(() => appStore?.hasLoggedIn && !state.bound?.email)
    };

    const _methods = {
      checkPayment: async () => {
        loading.show();
        state.step = 2;
        if (redirectStatus === 'succeeded') {
          await _methods.startListening();
        } else {
          state.paymentWasFailed = true;
          state.loaded = true;
          await loading.hide();
          await nextTick();
          await state.paymentRef.initWidget();
        }
      },
      startListening: async () => {
        const [pk] = getQueryStrings('pk');
        stripeHelper = await loadStripe(props.page, toast);
        stripe = stripeHelper.initSdk({ publicKey: pk });
        await _methods.listening();
      },
      listening: async () => {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        let body = {};
        switch (paymentIntent.status) {
          case 'succeeded':
            if (!shopConfirmationHref || !state.orderInfo?.orderInfo.orderCode) return;
            // body = {
            //   address: buildAddress(state.orderInfo.billingAddress.address, state.orderInfo.billingAddress.address2),
            //   buyerName: `${state.orderInfo.personalInfo.firstName} ${state.orderInfo.personalInfo.lastName}`,
            //   invoiceEmail: state.email,
            //   invoiceMoney: paymentIntent.amount,
            //   phone: `+${state.orderInfo.personalInfo.mobile.area.code} ${state.orderInfo.personalInfo.mobile.number}`,
            //   invoicePhone: `+${state.orderInfo.personalInfo.mobile.area.code} ${state.orderInfo.personalInfo.mobile.number}`,
            //   orderCode: state.orderInfo.orderInfo.orderCode,
            //   userId: appStore.loginInfo?.lotusId,
            //   invoiceType: 51,
            //   detailAsk: 0,
            //   invoiceKind: 0,
            //   invoiceNumber: new Date().getTime()
            // };
            // api.shop.saveInvoice(null, body).then(() => {
            //   window.location = getBetterUrl(
            //     appendQuery(shopConfirmationHref, {
            //       orderId: state.orderInfo.orderInfo.orderCode
            //     }),
            //     props.page.itemLanguage,
            //     true
            //   );
            // });
            window.location = getBetterUrl(
              appendQuery(shopConfirmationHref, {
                orderId: state.orderInfo.orderInfo.orderCode
              }),
              props.page.itemLanguage,
              true
            );
            break;
          case 'processing':
            setTimeout(() => {
              _methods.listening();
            }, 2000);
            break;
          case 'requires_payment_method':
          default:
            state.loaded = true;
            state.paymentWasFailed = true;
            loading.hide();
            break;
        }
      },
      checkBind: async () => {
        const [bindRes] = await api.cidp.bindable();
        if (bindRes) {
          state.bound = bindRes;
        } else {
          state.bound = { phone: true, email: true };
        }
      },
      accountSignOut: async () => {
        const button = await state.accountModalRef.open();
        if (button?.buttonCode === 'sign-out') {
          await appMethods.justLogout();
          await _methods.checkBind();
        } else {
          await _methods.checkBind();
          if (state.bound.email) {
            state.email = appStore.loginInfo.email;
            state.step = 1;
            scrollToTop();
          }
        }
      },
      submitCheckout: async () => {
        loading.show();
        const { email } = state.verifyRef;
        const { deliveryType, dealer, personalFormRef, addressFormRef, diffAddr, billingFormRef, consentFormRef } = state.deliveryRef;
        const [personalValid, personalData] = await personalFormRef.validate();
        const [shippingValid, shippingData] = await addressFormRef.validate();
        const [consentValid, consentData] = await consentFormRef.validate();
        const consents = consentFormRef.getVerifiedConsents();
        const deliveryCode = deliveryType?.fields?.code?.value;
        const pickup = equalString(deliveryCode, '51081003');
        if (!personalValid || !shippingValid || !consentValid) {
          loading.hide();
          return null;
        }
        const newsletterConsent = consentData?.termsCondition ? consentFormRef.getItemConsents('termsCondition') : [];
        const bindConsent = difference(consents, newsletterConsent);
        const addressDetails = pickup ? dealer?.addressDetail : buildAddress(shippingData.address, shippingData.address2);
        let billingValid = null;
        let billingData = null;
        let billingAddressDetails = '';
        if (diffAddr) {
          [billingValid, billingData] = await billingFormRef.validate();
          if (!billingValid) {
            loading.hide();
            return null;
          }
          billingAddressDetails = buildAddress(billingData.address, billingData.address2);
        }
        if (isNullOrEmpty(state.orderInfo)) {
          if (!appStore?.hasLoggedIn) {
            const body = {
              email: email ?? state.email,
              firstName: personalData.firstName,
              lastName: personalData.lastName,
              mobileAreaCode: personalData.mobile?.area.code,
              phone: personalData.mobile?.number,
              terms: true,
              zipcode: shippingData.zipCode,
              source: '202',
              address: shippingData.address,
              address2: shippingData.address2,
              city: shippingData.city,
              country: shippingData.country.code,
              contactsAddress: buildAddress(shippingData.address, shippingData.address2),
              preferLanguage: props.page.itemLanguage,
              communicatePreference: 0,
              accountType: 1
            };
            const [loginRes, loginEx] = await api.cidp.bto.emailRegisterLogin(null, body);
            if (loginEx) {
              loading.hide();
              await toast.showEx(loginEx);
              if (loginEx.code === 68010030) {
                await appMethods.justLogout();
                --state.step;
                await nextTick();
                scrollToTop();
                return false;
              }
              return false;
            }
            appStore.updateLoginInfo(loginRes);
          }
          const items = state.skuList.map((s) => ({
            num: 1,
            skuId: s.skuId,
            price: s.itemPrice,
            currency: state.currency
          }));
          let addressId = '';
          let billingAddressId = '';
          const [addRes, addEx] = await api.shop.addAddress(null, {
            mobileAreaCode: personalData.mobile?.area.code,
            mobile: personalData.mobile?.number,
            countryCode: pickup ? dealer.countryCode : shippingData.country.code,
            countryName: pickup ? dealer.countryName : shippingData.country.text,
            city: pickup ? dealer.cityName : shippingData.city,
            cityName: pickup ? dealer.cityName : shippingData.city,
            province: pickup ? dealer.cityName : shippingData.city,
            provinceName: pickup ? dealer.cityName : shippingData.city,
            detail: addressDetails,
            postcode: pickup ? dealer.extra.storeZipCode : shippingData.zipCode,
            contactName: `${personalData.firstName} ${personalData.lastName}`,
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            nationalCode: pickup ? dealer.countryCode : shippingData.country.code,
            addressType: 1
          });
          if (addEx) {
            loading.hide();
            await toast.showEx(addEx);
            return null;
          }
          addressId = addRes.id;
          if (diffAddr) {
            const [addRes, addEx] = await api.shop.addAddress(null, {
              mobileAreaCode: personalData.mobile?.area.code,
              mobile: personalData.mobile?.number,
              countryCode: billingData.country.code,
              countryName: billingData.country.text,
              city: billingData.city,
              cityName: billingData.city,
              province: billingData.city,
              provinceName: billingData.city,
              detail: billingAddressDetails,
              postcode: billingData.zipCode,
              contactName: `${personalData.firstName} ${personalData.lastName}`,
              firstName: personalData.firstName,
              lastName: personalData.lastName,
              nationalCode: billingData.country.code,
              addressType: 2
            });
            if (addEx) {
              await toast.showEx(addEx);
              loading.hide();
              return null;
            }
            billingAddressId = addRes.id;
          }
          state.deliveryDetails = {
            pickup,
            dealer,
            diffAddr,
            personalData,
            shippingData,
            billingData
          };
          const body = {
            market: market?.fields?.code?.value,
            businessSource: 1,
            couponFee: 0,
            email: email ?? state.email,
            expressFee: 0,
            fee: state.totalPrice,
            force: false,
            itemFee: state.totalPrice,
            itemPayType: state.itemPayType,
            items,
            payFee: state.depositPrice ? state.depositPrice : state.totalPrice,
            payType: 51061005,
            pointAmount: 0,
            pointFee: 0,
            pointRate: 0,
            country: currentAlpha2Code,
            currency: state.currency,
            receiverAddressId: addressId,
            billingAddressId: diffAddr ? billingAddressId : addressId,
            requestId: Math.round(Math.random() * 100000000),
            phone: `+${personalData.mobile?.area.code} ${personalData.mobile?.number}`,
            userPhone: isNullOrEmpty(appStore?.phone) ? '' : `+${appStore?.mobileAreaCode} ${appStore?.phone}`,
            priceType: 10,
            secKill: false,
            sendType: deliveryType?.fields?.code?.value,
            serviceFee: 0,
            source: WEBSHOP_PAMENT_TYPE_CODE.directPayment
          };

          const [res, ex] = await api.shop.creatOrder(null, body);
          if (ex) {
            loading.hide();
            await toast.showEx(ex);
            return null;
          }
          state.orderInfo = res;

          bindConsents(bindConsent, {
            email: email ?? state.email,
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            countryRegion: personalData.mobile?.area.data.alpha2Code,
            phone: personalData.mobile?.number
          })
            .then(async () => {
              if (consentData?.termsCondition) {
                const needDoubleOptin = await countryNeedDoubleOptin(currentAlpha2Code);
                const body = {
                  countryRegion: currentAlpha2Code,
                  language: props.page.itemLanguage,
                  needDoubleOptin,
                  termsAndConditions: newsletterConsent.map((revisionNumber) => ({
                    revisionNumber: revisionNumber,
                    title: window.document.title,
                    formUrl: window.location.href,
                    effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
                  })),
                  phone: `${personalData.mobile?.area.code}${personalData.mobile?.number}`,
                  firstName: personalData.firstName,
                  lastName: personalData.lastName,
                  email: email ?? state.email,
                  zipCode: diffAddr ? billingData.zipCode : shippingData.zipCode
                };
                merge(body, personalData?.externalData, shippingData?.externalData, billingData?.externalData, consentData?.externalData);
                api.lcms.crm.newsletter.subscribe(null, body).catch();
              }
            })
            .catch();

          const orderInfo = merge(body, { orderInfo: res });
          webStorage.set(S_SHOP_ORDER_INFO, orderInfo);
        }
        const [resP, exP] = await api.shop.payMulti(null, {
          orderCode: state.orderInfo.orderCode,
          payChannel: 70
        });
        if (exP) {
          loading.hide();
          await toast.showEx(exP);
          return false;
        }
        state.paymentRes = resP;
        await state.paymentRef.initWidget(resP);
        return true;
      },
      paymentCheckout: async () => {
        state.paymentRef.onPay();
      }
    };

    const methods = {
      largeThanTablet,
      onExitClick: () => {
        window.history.back();
      },
      goNext: debounce(async () => {
        if (state.step === 1) {
          const success = await _methods.submitCheckout();
          if (!success) {
            return;
          }
        }
        if (state.step === 2) {
          const success = await _methods.paymentCheckout();
          if (!success) {
            return;
          }
        }
        loading.hide();
        ++state.step;
        await nextTick();
        scrollToTop();
      }, 300),
      onOpenPriceBreakdown: () => {
        state.openPriceBreakdown = true;
      },
      onClosePriceBreakdown: () => {
        state.openPriceBreakdown = false;
      },
      toggleDis: () => {
        state.showDisclaimer = !state.showDisclaimer;
      },
      showDis: () => {
        state.showDisclaimer = true;
      },
      hideDis: () => {
        state.showDisclaimer = false;
      }
    };

    const merchandiseCheckoutMethods = {
      goNext: (options) => {
        if (state.step === 0) {
          state.email = options.email;
        }
        methods.goNext();
      },
      hideLoading: () => {
        loading.hide();
      },
      validateGeeTest() {
        return state.geeCaptchaRef.validate();
      }
    };
    provide('merchandiseCheckoutMethods', merchandiseCheckoutMethods);
    onMounted(async () => {
      [token, spuBusIds, skuIds, clientSecret, redirectStatus] = getQueryStrings('access_token', 'spuBusIds', 'skuIds', 'payment_intent_client_secret', 'redirect_status');

      if (isNullOrEmpty(spuBusIds) || isNullOrEmpty(skuIds)) {
        window.history.back();
      }
      if (!isNullOrEmpty(clientSecret)) {
        state.orderInfo = webStorage.get(S_SHOP_ORDER_INFO);
        await _methods.checkPayment();
      } else {
        loading.show();
        if (!isNullOrEmpty(token)) {
          const [userInfo] = await api.cidp.user.info(null, null, {
            headers: {
              authorization: token
            }
          });
          if (userInfo) {
            userInfo.token = token;
            appStore.setLoginInfo(userInfo);
          }
        }
        if (appStore?.hasLoggedIn) {
          await _methods.checkBind();
        }
        if (appStore?.hasLoggedIn) {
          await _methods.accountSignOut();
        }
        await state.geeCaptchaRef.init();

        const spuIdArray = (decodeData(spuBusIds) + '').split(',');
        const skuIdArray = (decodeData(skuIds) + '').split(',');

        spuIdArray.forEach(async (s) => {
          const [spuRes, spuEx] = await api.shop.getGoodsDetails({ spuBusId: s });
          if (spuEx) {
            await toast.showEx(spuEx);
            loading.hide();
            return null;
          }
          state.spuList.push(spuRes);
          const sendType = spuRes?.sendType ? spuRes?.sendType.split(',') : null;
          let itemPayType = [];
          itemPayType.push(spuRes.payType);
          state.itemPayType = itemPayType.join(',');
          state.sendTypeList = state.sendTypeList.concat(sendType);
          props.fields.deliverySection?.fields.deliveryTypes?.forEach((d) => {
            if (state.sendTypeList.includes(d.fields.code.value)) {
              d.disabled = false;
            } else {
              d.disabled = true;
            }
          });
          const [skuRes, skuEx] = await api.shop.getGoodsSkuList({
            entrance: '1',
            id: spuRes.id
          });
          if (skuEx) {
            await toast.showEx(skuEx);
            loading.hide();
            return null;
          }
          const skuDetails = skuRes.skuList.find((s) => skuIdArray.includes(s.skuId + ''));
          const disableSkuList = disableSkuCode?.value.split(',');
          if (skuDetails?.attrValueList.some((a) => disableSkuList.includes(a.attrValueCode))) {
            await toast.showEx(null);
            setTimeout(() => {
              window.history.back();
            }, 4500);
          }
          const currentAlpha2Code = getCurrentAlpha2Code();
          let depositPrice = null;
          if (spuRes.paymentMethod == 2) {
            depositPrice = skuDetails.priceInfoList.find((p) => equalString(p.priceCategory, 'Deposit Price'));
          }
          const priceInfo = skuDetails.priceInfoList.find((p) => equalString(p.country, currentAlpha2Code) && !equalString(p.priceCategory, 'Deposit Price'));
          skuDetails.name = skuRes.name;
          skuDetails.paymentMethod = spuRes.paymentMethod;
          skuDetails.description = skuDetails.attrValueList.map((a) => a.attrValue).join(', ');
          state.currency = priceInfo?.currency;
          skuDetails.depositPrice = depositPrice?.price ?? 0;
          skuDetails.itemPrice = priceInfo?.price ?? 0;
          skuDetails && state.skuList.push(skuDetails);
          state.depositPrice += Number(skuDetails.depositPrice);
          state.totalPrice += Number(skuDetails.itemPrice);
        });
        state.loaded = true;
        loading.hide();
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-checkout {
  &__main {
    padding: 40px grid-width-m(1) 120px grid-width-m(1);
  }
  .e-dropdown__desc {
    a {
      text-decoration: underline;
    }
  }
  &__aside {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: $black;
    color: $white;
    padding-top: #{mobile-header-height()};
    overflow-y: auto;
    &-header {
      height: 56px;
      padding: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 100px;
    &-exit {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .e-icon {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .e-site-button {
    &__text {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &-price-mobile {
    width: 100%;
    color: $white;
    padding: 16px 24px 24px;
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    &-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 4px;
      .e-icon {
        display: flex;
        align-items: center;
        > svg {
          padding: 2px;
        }
      }
    }
    &-bottom {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 20px;
      margin-top: 12px;
      &-left {
        font-size: 12px;
        line-height: 24px;
        cursor: pointer;
        > p,
        div,
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }
      }
      &-right {
        font-size: 16px;
        line-height: 24px;
      }
      &-disclaimer {
        position: absolute;
        bottom: 60px;
        padding: 24px;
        background-color: $grey-light;
      }
    }
  }

  @include tablet-landscape {
    &__wrapper {
      @include grid-container;
      height: 100%;
    }
    &__main {
      @include grid-block(1, 12);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
    }
    &__content {
      padding: 0 grid-width(2);
      transition: all 0.4s cubic-bezier(0.355, 0.005, 0.26, 1);
    }
    &__aside {
      @include grid-block(13, 12);
      height: 100vh;
      padding-top: 0;
      position: sticky;
    }
  }
}
</style>
