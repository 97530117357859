<template>
  <overall-settings v-bind="$props" v-if="!$isNullOrEmpty(fields?.headline?.value) || !$isNullOrEmpty(image?.value?.src) || !$isNullOrEmpty(fields?.text?.value) || fields?.buttons?.length > 0">
    <div class="s-news-detail-paragraph">
      <div class="s-news-detail-paragraph__main" v-grid-layout="fields.layout">
        <jss-rich-text class="s-news-detail-paragraph__headline" :field="fields.headline" tag="div" />
        <div class="s-news-detail-paragraph__content" :class="[...contentClasses]">
          <background-image class="s-news-detail-paragraph__image" :image="image" :full-image="fullImage" :is-background="false" v-if="!$isNullOrEmpty(image?.value?.src)" />
          <template-string class="s-news-detail-paragraph__text" :field="fields.text" :data="{ lastDayOfMonth: $getLastDayOfCurrentMonth() }" />
        </div>
        <div class="s-news-detail-paragraph__buttons">
          <site-button v-bind="button" v-for="button in fields.buttons" :key="button.id" />
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef NewsDetailParagraph
 * @property {NewsDetailParagraphFields} fields
 * */
import { computed, inject } from 'vue';
import { getAdaptiveImageField, gridLayoutClass, settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

/**
 * @typedef NewsDetailParagraphFields
 * @property {SimpleField} layout
 * @property {SimpleField} headline
 * @property {SimpleField} text
 * @property {ImageField} image
 * @property {ImageField} imageMobile
 * @property {ImageField} imageFull
 * @property {ImageField} imageFullMobile
 * */
export default {
  name: 'NewsDetailParagraph',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const { deviceState } = inject('device-common');
    const computes = {
      contentClasses: computed(() => {
        const { imagePosition, text } = props.fields || {};
        const imagePositionVal = settingValue(imagePosition, 'right');
        return [
          {
            [`image-${imagePositionVal}`]: !isNullOrEmpty(text?.value),
            'only-image': isNullOrEmpty(text?.value)
          }
        ];
      }),
      image: computed(
        () =>
          getAdaptiveImageField(deviceState, props.fields.adaptiveImage?.fields.desktopImage, props.fields.adaptiveImage?.fields.mobileImage, props.fields.adaptiveImage?.fields.tabletImage) ??
          props.fields?.image
      ),
      fullImage: computed(
        () =>
          getAdaptiveImageField(
            deviceState,
            props.fields.adaptiveImageFull?.fields.desktopImage,
            props.fields.adaptiveImageFull?.fields.mobileImage,
            props.fields.adaptiveImageFull?.fields.tabletImage
          ) ?? props.fields?.imageFull
      )
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-news-detail-paragraph {
  $c: &;
  &__headline {
    font-size: 26px;
    margin-bottom: 24px;
    line-height: 1.23;
  }
  &__image {
    margin: 40px 0;
  }
  &__text {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  @include tablet-landscape {
    @include grid-container;
    &__headline {
      margin-bottom: 40px;
    }
    &__image {
      margin: 60px 0;
    }
    &__content {
      &.image {
        &-left {
          #{$c}__image {
            float: left;
            margin-top: 0;
            margin-right: 24px;
            margin-bottom: 24px;
            width: grid-width(6);
            height: auto;
          }
        }
        &-right {
          #{$c}__image {
            float: right;
            margin-top: 0;
            margin-left: 24px;
            margin-bottom: 24px;
            width: grid-width(6);
            height: auto;
          }
        }
      }
      &:empty {
        display: none;
      }
    }
    &__buttons {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
    }
  }
}
.container {
  > .s-overall-settings {
    > .s-news-detail-paragraph {
      padding: 0 32px;
    }
  }
}
</style>
