<template>
  <overall-settings v-bind="$props">
    <div class="c-model-comparison" v-if="resize">
      <div class="c-model-comparison__title">
        <jss-rich-text :field="fields.title" />
      </div>
      <div class="swiper-container" ref="swiperEl" v-if="isMobile()">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in fields.items" :key="item.id">
            <background-image class="c-model-comparison__slide-image" :image="item.fields.image" />
            <div class="c-model-comparison__slide-soldout" v-if="item.fields.soldout.value">
              <jss-rich-text :field="item.fields.soldoutText" />
            </div>
            <div class="c-model-comparison__slide-btn">
              <site-button v-bind="item.fields.button" />
            </div>
            <div class="c-model-comparison__slide-details">
              <div class="c-model-comparison__slide-details-name">
                <jss-rich-text :field="item.fields.name" />
              </div>
              <div class="c-model-comparison__slide-details-time">
                <jss-rich-text :field="item.fields.timePrice" />
              </div>
              <div class="c-model-comparison__slide-details-des">
                <jss-rich-text :field="item.fields.des" />
              </div>
              <div class="c-model-comparison__slide-details-list">
                <div class="c-model-comparison__slide-details-list-item" v-for="list in item.fields.list" :key="list.id">
                  <div class="c-model-comparison__slide-details-list-item-title" @click="toggle(list)">
                    <jss-rich-text :field="list.fields.listText" />
                    <Icon :svg="list.fields.open?.value ? fields.closeIcon.value.svgCode : fields.openIcon.value.svgCode" :style="{ color: fields.iconColor?.fields.phrase.value }" />
                  </div>
                  <transition :css="false" @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
                    <div class="c-model-comparison__slide-details-list-item-dec" v-if="list.fields.open?.value">
                      <jss-rich-text v-for="text in list.fields.listItems" :key="text.id" :field="text.fields.listItem" />
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-model-comparison__comparison-group" v-else>
        <div class="c-model-comparison__comparison-item" v-for="item in fields.items" :key="item.id">
          <BackgroundImage class="c-model-comparison__slide-image" :class="{ soldout: item.fields.soldout.value }" :image="item.fields.image" />
          <div class="c-model-comparison__slide-soldout" :style="{ visibility: item.fields.soldout.value ? 'initial' : 'hidden' }">
            <jss-rich-text :field="item.fields.soldoutText" />
          </div>
          <div class="c-model-comparison__slide-btn" :style="{ visibility: item.fields.soldout.value ? 'initial' : 'hidden' }">
            <site-button v-bind="item.fields.button" />
          </div>
          <div class="c-model-comparison__slide-details">
            <div class="c-model-comparison__slide-details-name">
              <jss-rich-text :field="item.fields.name" />
            </div>
            <div class="c-model-comparison__slide-details-time">
              <jss-rich-text :field="item.fields.timePrice" />
            </div>
            <div class="c-model-comparison__slide-details-des">
              <jss-rich-text :field="item.fields.des" />
            </div>
            <div class="c-model-comparison__slide-details-list">
              <div class="c-model-comparison__slide-details-list-item" v-for="list in item.fields.list" :key="list.id">
                <div class="c-model-comparison__slide-details-list-item-title" @click="toggle(list)">
                  <jss-rich-text :field="list.fields.listText" />
                  <Icon :svg="list.fields.open?.value ? fields.closeIcon.value.svgCode : fields.openIcon.value.svgCode" :style="{ color: fields.iconColor?.fields.phrase.value }" />
                </div>
                <transition :css="false" @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
                  <div class="c-model-comparison__slide-details-list-item-dec" v-if="list.fields.open?.value">
                    <jss-rich-text v-for="text in list.fields.listItems" :key="text.id" :field="text.fields.listItem" />
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-model-comparison__btn">
        <site-button v-bind="fields.button" />
      </div>
      <div class="c-model-comparison__paginations" v-if="isMobile() && fields.items.length > 1" ref="paginationEl"></div>
    </div>
  </overall-settings>
</template>
<script>
import { reactive, toRefs, onMounted, onBeforeUnmount, computed } from 'vue';
import { isMobile } from '@/utils/dom-utils';
import Swiper from 'swiper';
import gsap from 'gsap';
import { gtmPush } from '@/utils/gtm-utils';
export default {
  name: 'ModelComparison',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper;
    const state = reactive({
      /**@type {HTMLElement}*/
      swiperEl: null,
      /**@type {HTMLElement}*/
      paginationEl: null,
      resize: true
    });
    const computes = {
      comparisonList: computed(() => [props.fields.leftList, props.fields.rightList])
    };
    const methods = {
      isMobile,
      buildSwiper() {
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 1,
          loop: false,
          pagination: {
            el: state.paginationEl,
            bulletElement: 'div',
            clickable: true
          }
        });
      },
      toggle: (item) => {
        const opened = !item.fields.open?.value;
        item.fields.open.value = opened;
        gtmPush({
          event: opened ? 'accordion_open_click' : 'accordion_close_click',
          event_label: item.fields.listText.value
        });
      },
      onItemBeforeEnter(el) {
        el.style.height = 0;
        el.style.paddingBottom = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 20,
          paddingBottom: 20,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          paddingBottom: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      }
    };
    onMounted(() => {
      if (isMobile()) {
        methods.buildSwiper();
      }
    });
    onBeforeUnmount(() => {
      if (isMobile()) {
        swiper.destroy(true, true);
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontSize.scss';
.c-model-comparison {
  width: 100%;
  &__title {
    @include h3;
    padding: $space-56 $space-30 $space-40;
  }
  .swiper-container {
    width: 100%;
    overflow: hidden;
  }
  &__slide {
    &-image {
      width: 100%;
      height: grid-width-m(math.div(12 * 9, 16));
      .e-background-image__img {
        background-repeat: no-repeat;
        background-size: contain;
      }
      .e-background-image__content {
        width: 100%;
        height: 100%;
      }
      &.soldout {
        opacity: 0.6;
      }
    }
    &-soldout {
      min-height: 28px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-btn {
      text-align: center;
    }
    &-details {
      width: 100%;
      padding: $space-30;
      &-name {
        @include h5;
        margin-bottom: $space-24;
      }
      &-time {
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: $space-12;
      }
      &-des {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: $space-48;
      }
      &-list {
        display: flex;
        flex-direction: column;
        &-item {
          display: flex;
          flex-direction: column;
          padding: $space-20 0;
          border-bottom: 1px solid rgba(242, 241, 240, 0.2);
          &-title {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            cursor: pointer;
          }
          &-dec {
            font-size: 16px;
            height: 0;
            overflow: hidden;
            > div {
              padding-top: $space-8;
            }
          }
        }
      }
    }
  }
  &__btn {
    width: 100%;
    padding: $space-48 grid-width-m(2) $space-30;
    > .e-site-button {
      width: 100%;
    }
  }
  &__paginations {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $space-12;
    gap: $space-12;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: $grey-neutral;
      cursor: pointer;
      &-active {
        background: $white;
      }
    }
  }
  @include tablet-landscape {
    @include grid-container;
    &__title {
      @include grid-block(3, 20, 1);
      padding: $space-30 0;
    }
    &__comparison {
      &-group {
        @include grid-block(3, 20, 2);
        margin-top: $space-60;
        display: flex;
        flex-direction: row;
        gap: grid-width(4);
      }
      &-item {
        flex: 1;
      }
    }
    &__slide {
      &-image {
        height: grid-width(math.div(8 * 1, 3));
      }
      &-details {
        padding: $space-40 0;
        &-name {
          margin-bottom: $space-12;
        }
        &-time {
          margin-bottom: $space-20;
        }
        &-des {
          margin-bottom: $space-40;
        }
        &-list {
          &-item {
            padding: $space-16 0;
          }
        }
      }
    }
    &__btn {
      @include grid-block(1, 24, 3);
      padding: 0 0 grid-width(2);
      text-align: center;
      > .e-site-button {
        width: 190px;
      }
    }
  }
}
</style>
