<template>
  <overall-settings v-bind="$props">
    <form-layout :fields="layoutFields" :class="['c-news-letter__layout', { submitted }]" ref="layoutRef">
      <div class="c-news-letter" v-if="!submitted">
        <heading class="c-news-letter__title" :field="fields.layout?.fields.title" rich />
        <jss-rich-text class="c-news-letter__body" :field="fields.body" />
        <dynamic-form :form="fields.form" :data="formConfig" @change="onFormChange" ref="formRef" />
        <site-button class="c-news-letter__submit-btn" v-bind="fields.submitButton" @click="onSubmit" />
        <jss-rich-text class="c-news-letter__bottom-text" :field="fields.bottomText" tag="div" />
      </div>
      <div class="c-news-letter__result" v-if="submitted">
        <heading class="c-news-letter__result-title" :field="fields.successLayout?.fields.title" rich />
        <jss-rich-text class="c-news-letter__result-body" v-if="needDoubleOptin && result?.isFirstSubmit" :field="fields.successNotice" />
        <jss-rich-text class="c-news-letter__result-body" v-else :field="fields.successNotice2" />
        <site-button class="c-news-letter__result-btn" v-bind="fields.successButton" />
      </div>
    </form-layout>
  </overall-settings>
</template>

<script>
/**
 * @typedef NewsletterFields
 * @property {DropLinkField<FormLayoutFields>} layout
 * @property {Form} form
 * @property {Array<Consent>} termsAndConditions
 * @property {ButtonField} submitButton
 * @property {SimpleField} body
 * @property {DropLinkField<FormLayoutFields>} successLayout
 * @property {SimpleField} successNotice
 * @property {SimpleField} successNotice2
 * @property {ButtonField} successButton
 * @property {SimpleField} bottomText
 * */
/**
 * @typedef NewsletterResponse
 * @property {Boolean} isFirstSubmit
 * */
import { computed, inject, nextTick, reactive, toRefs } from 'vue';
import { merge, debounce } from 'lodash';
import api from '@/api';
import { formatDate } from '@/utils/date-utils';
import { countryNeedDoubleOptin } from '@/services/siteService';
import { getPageAlpha2Code, scrollToTop, settingValue } from '@/utils/site-utils';
import { gtmPush, gtmFormView, gtmFormStart, gtmFormSubmit } from '@/utils/gtm-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';
import onScrollToView from '@/hooks/onScrollToView';

export default {
  name: 'Newsletter',
  props: {
    /**@type NewsletterFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const loading = inject('loading');
    const { formType } = props.page.fields;
    const state = reactive({
      /**@type FormLayout*/
      layoutRef: null,
      /**@type DynamicForm*/
      formRef: null,
      submitted: false,
      /**@type NewsletterResponse*/
      result: null,
      formConfig: {
        email: {
          trigger: 'blur'
        },
        countryRegion: {
          options: []
        }
      },
      needDoubleOptin: false,
      formChanged: false
    });
    const computes = {
      layoutFields: computed(() => (state.submitted ? props.fields.successLayout?.fields : props.fields.layout?.fields) ?? props.fields.layout?.fields)
    };
    const _qt = {
      trackSubmit(isSucceed, isSubscribed, formData) {
        const eventParams = {
          is_success: isSucceed,
          is_subscribe: isSubscribed
        };
        if (!isNullOrEmpty(formData)) {
          const { firstName, lastName, email, countryRegion } = formData;
          merge(eventParams, {
            email,
            first_name: firstName,
            last_name: lastName,
            country: countryRegion.code
          });
        }
        qtUtils.trackBtnClickWithCode(props.page, props.fields.submitButton, 'keep_me_informed_pg_clk', eventParams);
      }
    };
    const methods = {
      onFormChange: async () => {
        if (!state.formChanged) {
          state.formChanged = true;
          gtmFormStart(formType?.value, props.fields.form.id);
        }
      },
      onSubmit: debounce(async () => {
        const { channel } = props.fields;
        const [valid, formData] = await state.formRef.validate();
        const leadTypes = state.formRef.getLeadTypes();
        if (valid) {
          loading.show();
          const alpha2Code = getPageAlpha2Code(props.page);
          const { vehicleModelOfInterest, firstName, middleName, lastName, email, mobile, countryRegion, isAboveTwenty, externalData } = formData;
          const consentNos = state.formRef.getVerifiedConsents();
          state.needDoubleOptin = await countryNeedDoubleOptin(alpha2Code);
          const leadsDetails = {};
          const body = merge(
            {
              vehicleModelOfInterest: vehicleModelOfInterest ? vehicleModelOfInterest.map((x) => x.code).join(',') : null,
              countryRegion: countryRegion.code,
              language: props.page.itemLanguage,
              needDoubleOptin: state.needDoubleOptin,
              termsAndConditions: consentNos.map((x) => ({
                revisionNumber: x,
                title: window.document.title,
                formUrl: window.location.href,
                effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
              })),
              firstName: firstName,
              middleName: middleName ?? '',
              lastName: lastName,
              email: email,
              phone: mobile?.area ? `${mobile.area.code} ${mobile.number}` : '',
              channel: settingValue(channel, 'Official Website'),
              leadFormName: 'Newsletter',
              leadProvider: 'Global Website',
              leadform_Name__c: 'Newsletter',
              lead_Provider__c: 'Global Website',
              formScId: formData.formScId,
              gRecaptchaToken: formData.gRecaptchaToken ?? null
            },
            externalData
          );
          if (!isNullOrEmpty(isAboveTwenty)) {
            merge(body, { isAboveTwenty });
          }
          const [res, ex] = await api.lcms.crm.newsletter.subscribe(null, body);
          loading.hide();
          if (ex) {
            await toast.showEx(ex);
            _qt.trackSubmit(false, formData.checkbox, formData);
            return;
          }
          if (formData?.CPTC) {
            const cptcBody = merge(
              {
                firstName: firstName,
                middleName: middleName ?? '',
                lastName: lastName,
                email: email,
                countryRegion: alpha2Code,
                channel: 'Official Website'
              },
              externalData
            );
            if (!isNullOrEmpty(isAboveTwenty)) {
              merge(cptcBody, { isAboveTwenty });
            }
            const [cptcRes] = await api.lcms.crm.cptc.create(null, cptcBody);

            if (leadTypes?.length && cptcRes) {
              leadTypes.forEach((l) => {
                if (equalString(l.consentType, 'CPTC')) {
                  leadsDetails[l.leadType] = cptcRes.leadId ?? null;
                }
              });
            }
          }
          if (leadTypes?.length) {
            leadTypes.forEach((l) => {
              leadsDetails[l.leadType] = res ?? null;
            });
          }
          gtmFormSubmit(
            formType?.value,
            props.fields.form.id,
            {
              email: email,
              mobileAreaCode: mobile.area.code,
              phone: mobile.number
            },
            null,
            leadsDetails
          );
          // gtmPush({
          //   event: 'newsletter_subscribe',
          //   lead_id: res.leadId
          // });
          state.result = res;
          state.submitted = true;
          await nextTick();
          scrollToTop();
          _qt.trackSubmit(true, formData.checkbox, formData);
        }
      }, 100)
    };
    onScrollToView({
      getContainer() {
        return state.layoutRef.rootEl;
      },
      callback() {
        gtmFormView(formType?.value, props.fields.form.id);
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-news-letter {
  padding-bottom: $space-40;
  &__title {
    @include h5;
    margin-bottom: $space-40;
  }
  &__submit-btn {
    width: 100%;
  }
  &__bottom-text {
    margin-top: $space-40;
  }
  &__result {
    &-title {
      @include h5;
      margin-bottom: $space-40;
    }
    &-btn {
      margin-top: 40px;
    }
  }
  &__layout {
    &.submitted.s-form-layout {
      position: relative;
      @include height-except-header;
      .s-form-layout__image-wrapper {
        height: 100%;
      }
      .s-form-layout__image {
        height: 100%;
      }
      .s-form-layout__main {
        position: relative;
        z-index: 1;
        padding-top: 56px;
      }
      .s-form-layout__side-image {
        position: absolute;
        display: block;
        width: 100%;
        height: 100vh;
        .e-background-image__img {
          height: 100%;
          background-color: #f8ed6e;
          background-position: bottom center !important;
          background-size: 100% auto;
          background-repeat: no-repeat;
        }
      }
    }
  }
  @include tablet-landscape {
    &__submit-btn {
      width: auto;
    }
    &__layout {
      &.submitted.s-form-layout {
        position: static;
        .s-form-layout__side-image {
          position: static;
          height: auto;
        }
      }
    }
  }
}
</style>
