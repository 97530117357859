<template>
  <div class="c-news-list" v-if="fields">
    <div class="c-news-list__content">
      <div class="c-news-list__main" v-if="fields.poster?.value">
        <RouterLink :to="`/${lang}${fields.posterItem.url}`">
          <div class="c-news-list__main-title" ref="titleOutEl">
            <JssRichText :field="fields.titleOut" />
          </div>
          <div class="c-news-list__main-image">
            <!-- <BackgroundImage :image="fields.image" :parallax="!fields.turnOffParallax?.value" :hasAnimation="!fields.turnOffImageAnimation?.value"> -->
            <BackgroundImage :image="fields.image">
              <div class="c-news-list__main-image-title" ref="titleInEl">
                <JssRichText :field="fields.titleIn" />
              </div>
              <div class="c-news-list__main-image-icon" ref="titleIconEl" v-if="!deviceComputes.isMobileOrTablet.value">
                <Icon :svg="fields.icon?.value.svgCode" size="small" />
              </div>
            </BackgroundImage>
          </div>
          <div class="c-news-list__main-details">
            <div class="c-news-list__main-details-time">
              <JssRichText :field="fields.time" />
            </div>
            <div class="c-news-list__main-details-title">
              <JssRichText :field="fields.othertitle" />
            </div>
            <div class="c-news-list__main-details-tag" v-if="fields.tag">
              <JssText :field="fields.tag?.fields.text" />
            </div>
            <div class="c-news-list__main-details-body">
              <JssRichText :field="fields.body" />
            </div>
            <div class="c-news-list__main-details-button">
              <SiteButton v-bind="fields.readmore" />
            </div>
          </div>
        </RouterLink>
      </div>
      <div class="c-news-list__list">
        <template v-if="deviceComputes.isMobileOrTablet.value">
          <div class="c-news-list__list-item" v-for="item in showList" :key="item.id">
            <RouterLink :to="`/${lang}${item.url}`">
              <div class="c-news-list__list-item-image">
                <!-- <BackgroundImage :image="item.fields.newsPoster" :parallax="!fields.turnOffParallax?.value" :hasAnimation="!fields.turnOffImageAnimation?.value" /> -->
                <BackgroundImage :image="item.fields.newsPoster" />
              </div>
              <div class="c-news-list__list-item-time">
                <JssText :field="item.fields.createDate" />
              </div>
              <div class="c-news-list__list-item-title">
                <JssText :field="item.fields.newsTitle" />
              </div>
              <div class="c-news-list__list-item-tag" v-if="item.fields.tag">
                <JssText :field="item.fields.tag?.fields.text" />
              </div>
              <div class="c-news-list__list-item-body">
                <JssText :field="item.fields.newsSummary" />
              </div>
            </RouterLink>
          </div>
        </template>
        <template v-else>
          <div class="c-news-list__list-row" v-for="items in showList" :key="items.id">
            <div class="c-news-list__list-row-item" :class="{ bigItem: fields.bigItem?.value }" v-for="item in items" :key="item.id">
              <RouterLink :to="`/${lang}${item.url}`">
                <div class="c-news-list__list-item-image" :class="{ bigItem: fields.bigItem?.value }">
                  <!-- <BackgroundImage :image="item.fields.newsPoster" :parallax="!fields.turnOffParallax?.value" :hasAnimation="!fields.turnOffImageAnimation?.value"> -->
                  <BackgroundImage :image="item.fields.newsPoster">
                    <div class="c-news-list__list-item-image-icon">
                      <Icon :svg="fields.icon?.value.svgCode" size="small" />
                    </div>
                  </BackgroundImage>
                </div>
                <div class="c-news-list__list-item-time">
                  <JssRichText :field="item.fields.createDate" />
                </div>
                <div class="c-news-list__list-item-title">
                  <JssRichText :field="item.fields.newsTitle" />
                </div>
                <div class="c-news-list__list-item-tag" v-if="item.fields.tag">
                  <JssRichText :field="item.fields.tag?.fields.text" />
                </div>
                <div class="c-news-list__list-item-body">
                  <JssRichText :field="item.fields.newsSummary" />
                </div>
              </RouterLink>
            </div>
          </div>
        </template>
        <div class="c-news-list__list-button" v-if="showMoreFlag">
          <SiteButton v-bind="fields.loadmore" @click="showMore" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { nextTick } from 'vue';
import useDevice from '@/hooks/useDevice';
export default {
  name: 'NewsList',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const router = useRouter();
    const { deviceState, deviceComputes } = useDevice();
    const state = reactive({
      showList: [],
      showCount: 1,
      titleOutEl: null,
      titleInEl: null,
      titleIconEl: null
    });
    const computes = {
      rowArray: computed(() => {
        const count = props.fields.bigItem?.value ? 2 : 3;
        const total = Math.ceil(props.fields.items.length / count);
        let rowArray = [];
        for (let i = 0; i < total; i++) {
          let tmp = props.fields.items.slice(i * count, (i + 1) * count);
          rowArray.push(tmp);
        }
        return rowArray;
      }),
      showMoreFlag: computed(() => {
        const count = props.fields.bigItem?.value ? 2 : 3;
        if (deviceComputes.isMobileOrTablet.value) {
          if (state.showCount * 2 >= props.fields.items.length) {
            return false;
          } else {
            return true;
          }
        } else {
          if (state.showCount * 2 * count >= props.fields.items.length) {
            return false;
          } else {
            return true;
          }
        }
      }),
      lang: computed(() => router.currentRoute?.value?.params.lang ?? {})
    };
    const methods = {
      showMore: () => {
        state.showCount++;
        if (deviceComputes.isMobileOrTablet.value) {
          state.showList = props.fields.items.slice(0, state.showCount * 2);
        } else {
          state.showList = computes.rowArray.value.slice(0, state.showCount * 2);
        }
      }
    };
    onMounted(() => {
      if (deviceComputes.isMobileOrTablet.value) {
        state.showList = props.fields.items.slice(0, 2);
      } else {
        state.showList = computes.rowArray.value.slice(0, 2);
      }
      nextTick(() => {
        if (state.titleOutEl) {
          const titleOffset = state.titleOutEl.getBoundingClientRect();
          if (deviceComputes.isMobileOrTablet.value) {
            state.titleInEl.style['margin-top'] = 42 - titleOffset.height + 'px';
          } else {
            state.titleInEl.style['margin-top'] = 92 - titleOffset.height + 'px';
            state.titleIconEl.style['bottom'] = 92 - titleOffset.height + 'px';
          }
        }
      });
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      deviceState,
      deviceComputes
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/color.scss';
@import '../styles/rte/fontSize.scss';
.c-news-list {
  margin-top: $space-80;
  margin-bottom: $space-80;
  &__main {
    > a {
      @include grid-container;
    }
    &-title {
      @include grid-block(2, 10);
      @include h2;
      margin-left: -13px;
    }
    &-image {
      @include grid-block(2, 10);
      height: grid-width-m(math.div(10 * 2, 3));
      margin-top: -50px;
      overflow: hidden;
      .e-background-image {
        height: 100%;
      }
      &-title {
        @include h2;
        margin-left: -13px;
        color: $yellow;
      }
    }
    &-details {
      @include grid-block(3, 8);
      padding: $space-40 grid-width-m(1) 0;
      margin-right: grid-width-m(-2);
      margin-top: -$space-48;
      z-index: 2;
      background-color: #fff;
      &-time {
        font-weight: 300;
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: $space-24;
      }
      &-title {
        @include h5;
        margin-bottom: $space-32;
      }
      &-tag {
        @include h8;
        display: inline-block;
        padding: $space-8;
        background-color: $yellow;
      }
      &-body {
        @include h8;
        margin-top: $space-32;
        margin-bottom: $space-8;
      }
      .e-site-button__text {
        padding-left: 0;
      }
    }
  }
  &__list {
    margin-top: $space-40;
    padding: 0 grid-width-m(1);
    &-item {
      margin-top: $space-20;
      cursor: pointer;
      &-image {
        height: grid-width-m(math.div(10 * 2, 3));
        margin-bottom: $space-24;
        .e-background-image {
          height: 100%;
          &__content {
            height: 100%;
          }
        }
      }
      &-time {
        font-size: 14px;
        font-weight: 300;
        line-height: 1;
        margin-bottom: $space-16;
      }
      &-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 1;
        margin-bottom: $space-32;
      }
      &-tag {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        display: inline-block;
        padding: $space-8;
        background-color: $yellow;
      }
      &-body {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: $space-32;
        margin-bottom: $space-24;
      }
      .e-site-button__text {
        padding-left: 0;
      }
      &:hover {
        .c-news-list__list-item-title {
          text-decoration: underline;
        }
      }
    }
    &-button {
      width: grid-width-m(8);
      margin: $space-48 auto $space-80;
      .e-site-button {
        width: 100%;
      }
    }
  }
  @include tablet-landscape {
    margin-top: grid-width(2);
    margin-bottom: grid-width(2);
    &__main {
      &-title {
        @include grid-block(3, 12);
        margin-left: -26px;
      }
      &-image {
        @include grid-block(3, 12);
        height: grid-width(math.div(12 * 2, 3));
        margin-top: -100px;
        .e-background-image__content {
          height: 100%;
        }
        &-title {
          margin-left: -26px;
        }
        &-icon {
          width: grid-width(1);
          height: grid-width(1);
          background-color: $yellow;
          position: absolute;
          right: calc(#{grid-width(2)} - 10px);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.8s ease;
          transform: translateX(grid-width(1));
          z-index: -1;
        }
      }
      &-details {
        position: relative;
        @include grid-block(13, 9);
        padding: $space-40 grid-width(1) 0;
        margin-right: grid-width(-2);
        margin-top: grid-width(-5.5);
        z-index: 2;
        &-time {
          margin-bottom: $space-24;
        }
        &-title {
          margin-bottom: $space-32;
        }
        &-tag {
          padding: $space-8;
        }
        &-body {
          margin-top: $space-32;
          margin-bottom: $space-8;
        }
      }
      &:hover {
        .c-news-list__main {
          &-details-title {
            text-decoration: underline;
          }
          &-image-icon {
            transform: translateX(0);
          }
        }
      }
    }
    &__list {
      margin-top: $space-80;
      padding: 0 grid-width(2);
      &-row {
        display: flex;
        flex-direction: row;
        margin-bottom: grid-width(1);
        gap: 32px;
        &-item {
          width: calc(#{grid-width(math.div(20, 3))} - 20px);
          cursor: pointer;
          &.bigItem {
            width: calc(#{grid-width(10)} - 15px);
          }
          &:hover {
            .c-news-list__list {
              &-item-title {
                text-decoration: underline;
              }
              &-item-image-icon {
                transform: translateX(0);
              }
            }
          }
          .e-site-button__text {
            padding-left: 0;
          }
        }
      }
      &-item {
        &-image {
          height: grid-width(math.div(40, 9));
          &.bigItem {
            height: grid-width(math.div(20, 3));
          }
          &-icon {
            width: grid-width(1);
            height: grid-width(1);
            background-color: $yellow;
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.8s ease;
            transform: translateX(grid-width(1));
            z-index: -1;
          }
        }
        &-time {
          font-size: 16px;
          line-height: 1.3;
        }
        &-title {
          font-size: 24px;
          font-weight: 500;
          line-height: 1.3;
        }
        &-body {
          font-size: 14px;
          line-height: 21px;
        }
      }
      &-button {
        width: 190px;
        margin-top: $space-80;
        .e-site-button {
          width: 100%;
        }
      }
    }
  }
}
html.rtl {
  .c-news-list {
    &__main {
      &-image-icon {
        right: unset;
        left: -10px;
      }
      &-details {
        float: left;
        margin-left: grid-width(0);
        @include tablet-landscape {
          margin-right: 0;
        }
      }
      &:hover {
        .c-news-list__main {
          &-image-icon {
            transform: translateX(grid-width(2));
          }
        }
      }
    }
  }
}
</style>
