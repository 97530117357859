<template>
  <div class="c-merchandise-confirmation" v-if="fields">
    <div class="c-merchandise-confirmation__main" v-if="orderDetails">
      <div class="c-merchandise-confirmation-label main">
        <Icon class="c-merchandise-confirmation-label-icon" :svg="fields.mainIcon?.value.svgCode" />
        <JssRichText class="c-merchandise-confirmation-label-text" :field="fields.mainLabel" />
      </div>
      <div class="c-merchandise-confirmation__main-details">
        <div class="c-merchandise-confirmation__main-details-number">{{ `${$t('Order number')}: ${orderDetails.orderCode}` }}</div>
        <div class="c-merchandise-confirmation__main-details-email">
          <JssRichText class="c-merchandise-confirmation__main-details-label" :field="fields.emailLabel" />
          <div class="c-merchandise-confirmation__main-details-text">{{ orderDetails.email }}</div>
        </div>
        <br />
        <div class="c-merchandise-confirmation__main-details-des">
          <JssRichText class="c-merchandise-confirmation__main-details-label" :field="fields.description" />
        </div>
        <div class="c-merchandise-confirmation__main-details-btns">
          <SiteButton class="detail-button" :fields="detailButton" />
          <SiteButton class="faq-button" v-bind="fields.faqButton" />
        </div>
      </div>
    </div>
    <div class="c-merchandise-confirmation__summary" v-if="orderDetails">
      <div class="c-merchandise-confirmation-label">
        <JssRichText class="c-merchandise-confirmation-label-text" :field="fields.summaryLabel" />
      </div>
      <div class="c-merchandise-confirmation__summary-main">
        <div class="c-merchandise-confirmation__summary-list">
          <div class="c-merchandise-confirmation__summary-item row" v-for="item in orderDetails?.items" :key="item.skuId">
            <div class="c-merchandise-confirmation__summary-item-left">
              <img :src="item.image" :alt="item.name" />
            </div>
            <div class="c-merchandise-confirmation__summary-item-right">
              <div class="c-merchandise-confirmation__summary-item-right-top">
                <div class="c-merchandise-confirmation__summary-item-right-top-name">{{ item.name }}</div>
                <div class="c-merchandise-confirmation__summary-item-right-top-des" v-if="!servicePlanOrder">{{ item.spec.replace(/\|/g, ', ') }}</div>
              </div>
              <div class="c-merchandise-confirmation__summary-item-right-bottom">
                {{ $formatShopMoney(item.price, orderDetails.currency) }}
              </div>
            </div>
          </div>
        </div>
        <div class="c-merchandise-confirmation__summary-total">
          <span>{{ wheelsOrder ? `${$tu('Total')} (${$tu('Inc VAT')})` : servicePlanOrder ? $tu('Total') : $tu('Amount paid') }}</span>
          <span>{{ $formatShopMoney(orderDetails.payFee, orderDetails.currency) }}</span>
        </div>
      </div>
    </div>
    <div class="c-merchandise-confirmation__details" v-if="orderDetails">
      <div class="c-merchandise-confirmation-label">
        <JssRichText class="c-merchandise-confirmation-label-text" :field="fields.detailsLabel" />
      </div>
      <div class="c-merchandise-confirmation__details-main">
        <div class="c-merchandise-confirmation__details-item">
          <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Order number') }}</div>
          <div class="c-merchandise-confirmation__details-item-text">{{ orderDetails.orderCode }}</div>
        </div>
        <template v-if="servicePlanOrder">
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Expiration date') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">{{ $formatDate(expiryTime, 'dd-MM-yyyy HH:mm') }}</div>
          </div>
        </template>
        <template v-else>
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Order date') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">{{ $formatDate(orderDetails.dateCreate, 'dd-MM-yyyy HH:mm') }}</div>
          </div>
        </template>
        <template v-if="wheelsOrder">
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Billing address') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">
              <div v-if="orderDetails.billingAddressDTO?.detailInfo?.split(',')[0]">{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[0] }}</div>
              <div v-if="orderDetails.billingAddressDTO?.detailInfo?.split(',')[1]">{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[1] }}</div>
              <div>{{ orderDetails.billingAddressDTO?.cityName }}</div>
              <div>{{ orderDetails.billingAddressDTO?.nationalName }}</div>
              <div>{{ orderDetails.billingAddressDTO?.telNumber }}</div>
            </div>
          </div>
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Pickup location') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">
              <div>{{ orderDetails.storeName }}</div>
              <div>{{ orderDetails.storeAddressDTO?.addressDetail }}</div>
              <div>{{ orderDetails.storeAddressDTO?.cityName }}</div>
              <div>{{ orderDetails.storeAddressDTO?.provinceName }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="servicePlanOrder">
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Vehicle') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">
              <div>{{ orderDetails.carModel }}</div>
              <div>{{ orderDetails.vin }}</div>
            </div>
          </div>
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Billing address') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">
              <div v-if="orderDetails.billingAddressDTO?.detailInfo?.split(',')[0]">{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[0] }}</div>
              <div v-if="orderDetails.billingAddressDTO?.detailInfo?.split(',')[1]">{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[1] }}</div>
              <div>{{ orderDetails.billingAddressDTO?.cityName }}</div>
              <div>{{ orderDetails.billingAddressDTO?.nationalName }}</div>
              <div>{{ orderDetails.billingAddressDTO?.telNumber }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Shipping address') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">
              <div>{{ orderDetails.receiverNameFirst }} {{ orderDetails.receiverNameLast }}</div>
              <div v-if="orderDetails.receiverAddressDetail?.split(',')[0]">{{ orderDetails.receiverAddressDetail.split(',')[0] }}</div>
              <div v-if="orderDetails.receiverAddressDetail?.split(',')[1]">{{ orderDetails.receiverAddressDetail.split(',')[1] }}</div>
              <div>{{ orderDetails.receiverCityName }}</div>
              <div>{{ orderDetails.receiverCountryName }}</div>
              <div v-if="orderDetails.areaCode && orderDetails.receiverPhone">{{ `+${orderDetails.areaCode} ${orderDetails.receiverPhone}` }}</div>
            </div>
          </div>
          <div class="c-merchandise-confirmation__details-item">
            <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Billing address') }}</div>
            <div class="c-merchandise-confirmation__details-item-text">
              <div v-if="orderDetails.billingAddressDTO?.detailInfo?.split(',')[0]">{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[0] }}</div>
              <div v-if="orderDetails.billingAddressDTO?.detailInfo?.split(',')[1]">{{ orderDetails.billingAddressDTO?.detailInfo.split(',')[1] }}</div>
              <div>{{ orderDetails.billingAddressDTO?.cityName }}</div>
              <div>{{ orderDetails.billingAddressDTO?.nationalName }}</div>
            </div>
          </div>
        </template>
        <div class="c-merchandise-confirmation__details-item">
          <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Email address') }}</div>
          <div class="c-merchandise-confirmation__details-item-text">{{ appStore.loginInfo.email }}</div>
        </div>
        <div class="c-merchandise-confirmation__details-item" v-if="appStore?.loginInfo?.mobileAreaCode && appStore?.loginInfo?.phone">
          <div class="c-merchandise-confirmation__details-item-label">{{ $tu('Phone number') }}</div>
          <div class="c-merchandise-confirmation__details-item-text">{{ `+${appStore?.loginInfo?.mobileAreaCode} ${appStore?.loginInfo?.phone}` }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, toRefs, reactive, computed, onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import { getQueryStrings, appendQuery, redirectToLogin } from '@/utils/uri-utils';
import { equalString } from '@/utils/string-utils';
import { WEBSHOP_ORDER_TYPE, WEBSHOP_SALE_AFTER_ITEM_TYPE } from '@/utils/constants';
import useAppStore from '@/store/appStore';
import api from '@/api';
import { merge } from 'lodash';
export default {
  name: 'MerchandiseConfirmation',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const toast = inject('toast');
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const page = $jss.routeData();
    const router = useRouter();
    const appStore = useAppStore();
    let orderId;
    const state = reactive({
      orderDetails: null,
      invoiceDetail: null,
      totalPrice: 0,
      expiryTime: null
    });

    const computes = {
      detailButton: computed(() =>
        merge({}, props.fields.detailButton?.fields, {
          link: {
            value: {
              href: appendQuery(props.fields.detailButton?.fields.link.value.href, { orderCode: orderId })
            }
          }
        })
      ),
      wheelsOrder: computed(
        () =>
          equalString(state.orderDetails?.orderSpuType + '', WEBSHOP_ORDER_TYPE.aftersalesInstall + '') &&
          equalString(state.orderDetails?.items[0].saleAfterItemType + '', WEBSHOP_SALE_AFTER_ITEM_TYPE.project + '')
      ),
      servicePlanOrder: computed(
        () =>
          equalString(state.orderDetails?.orderSpuType + '', WEBSHOP_ORDER_TYPE.aftersalesInstall + '') &&
          equalString(state.orderDetails?.items[0].saleAfterItemType + '', WEBSHOP_SALE_AFTER_ITEM_TYPE.servicePlan + '')
      )
    };

    const methods = {
      getOrderDetails: async (id) => {
        const [orderRes, orderEx] = await api.shop.orderDetails({ orderId: id });
        if (orderEx) {
          await toast.showEx(orderEx);
          if (orderEx.code == 60000000 || orderEx.code == 60000009 || orderEx.code == 60000110) {
            setTimeout(() => {
              redirectToLogin(page, router);
            }, 2000);
          }
          return null;
        }
        state.orderDetails = orderRes;
        orderRes.items.forEach((i) => {
          state.totalPrice += Number(i.price);
        });

        // const [invoiceRes, invoiceEx] = await api.shop.invoiceDetails({ orderId: id });
        // if (invoiceEx) {
        //   await toast.showEx(invoiceEx);
        //   return null;
        // }
        // state.invoiceDetail = invoiceRes;

        if (computes.servicePlanOrder) {
          const [vinRes, vinEx] = await api.shop.getSubscriptionInfo(null, { vin: orderRes.vin });
          if (vinEx) {
            await toast.showEx(orderEx);
            return null;
          }
          state.expiryTime = new Date(vinRes[0].expiryTime);
        }
      }
    };

    onMounted(async () => {
      [orderId] = getQueryStrings('orderId');
      await methods.getOrderDetails(orderId);
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.c-merchandise-confirmation {
  padding: 56px 24px 0px;
  &-label {
    &.main {
      text-align: center;
      padding-bottom: 56px;
    }
    &-icon {
      width: 42px;
      height: 42px;
      padding: 3px;
      svg {
        width: 100%;
        height: 100%;
        fill: none !important;
      }
    }
    &-text {
      margin-top: 14px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  &__main {
    border-bottom: 1px solid $grey-89;
    &-details {
      padding: 24px 0 68px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $grey-next;
      &-number {
        font-size: 26px;
        line-height: 32px;
        font-weight: 500;
        color: $black;
      }
      &-email {
        margin-top: 28px;
        font-weight: 700;
        color: $black;
        font-size: 16px;
        line-height: 24px;
      }
      &-label {
        color: $grey-next;
        font-weight: 400;
      }
      &-btns {
        margin-top: 28px;
        .e-site-button {
          padding: 0;
        }
        .detail-button {
          .e-site-button__text {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .faq-button {
          margin-top: 10px;
        }
      }
    }
  }
  &__summary {
    padding: 64px 0;
    border-bottom: 1px solid $grey-89;
    &-list {
      padding: 24px 0 48px;
      border-bottom: 1px solid $grey-89;
      > div + div {
        margin-top: 16px;
      }
    }
    &-item {
      display: flex;
      flex-direction: row;
      gap: 16px;
      &-left {
        height: 100px;
        width: 80px;
        background-color: #f2f1f0;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        font-size: 12px;
        line-height: 20px;
        &-top-des,
        &-top-qty,
        &-bottom {
          opacity: 0.6;
        }
        &-top {
          &-name {
            font-weight: 700;
          }
        }
      }
    }
    &-delivery {
      padding: 24px 0;
      font-size: 12px;
      line-height: 20px;
      color: $grey-next;
      border-bottom: 1px solid $grey-89;
    }
    &-price {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      font-size: 16px;
      line-height: 24px;
      color: $grey-next;
      border: 1px solid var(--shades-nshadee, $grey-89);
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &-total {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      font-size: 18px;
      line-height: 28px;
      color: var(--core-carbonblack, $black);
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 64px 0;
    &-main {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-label {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--core-carbonblack, $black);
      }
      &-text {
        font-size: 16px;
        line-height: 24px;
        color: var(--core-carbonblack, $grey-next);
        > div + div {
          margin-top: 4px;
        }
      }
    }
  }
  &__invoice-file {
    color: $black;
    > a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }
  @include tablet-landscape {
    padding: 56px 212px 24px;
    &-label {
      width: grid-width(6);
      &.main {
        text-align: start;
      }
      &-text {
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
    &__main {
      display: flex;
      flex-direction: row;
      gap: 24px;
      &-details {
        padding-top: 0;
        &-btns {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
    &__summary,
    &__details {
      display: flex;
      flex-direction: row;
      gap: 24px;
      padding: 96px 0;
      &-main {
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 48px 24px;
      }
      &-item {
        width: calc(50% - 12px);
        &.row {
          width: 100%;
        }
      }
    }
  }
}
</style>
