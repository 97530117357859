<template>
  <overall-settings v-bind="$props">
    <div class="c-news-detail-introduce" v-if="fields">
      <jss-rich-text class="c-news-detail-introduce-title" :field="fields.title" tag="div" />
      <jss-rich-text class="c-news-detail-introduce-firstDescription" :field="fields.firstDescription" tag="div" />
      <div class="c-news-detail-introduce-desction-content" v-if="fields.secondDescription.value || fields.tips.value">
        <jss-rich-text class="c-news-detail-introduce-desction-content-item" :field="fields.secondDescription" tag="div" />
        <jss-rich-text class="c-news-detail-introduce-tips" :field="fields.tips" tag="div" />
      </div>
      <jss-rich-text class="c-news-detail-introduce-thirdDescription" :field="fields.thirdDescription" tag="div" />
    </div>
  </overall-settings>
</template>

<script>
export default {
  name: 'NewsDetailIntroduce',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-news-detail-introduce {
  width: 100%;
  position: relative;
  padding-left: grid-width-m(1);
  &-title {
    @include h7;
    width: grid-width-m(10);
    margin-top: 0;
  }
  &-firstDescription {
    @include h8;
    width: grid-width-m(10);
    margin-top: grid-width-m(0.5);
  }
  &-desction-content {
    width: grid-width-m(10);
    margin-top: grid-width-m(1.5);
    margin-left: grid-width-m(0);
    background: #f2f1f0;
    position: relative;
    padding: grid-width-m(0.2) grid-width-m(0.2) grid-width-m(0.9);
  }
  &-desction-content-item {
    @include h8;
    width: 100%;
  }
  &-tips {
    @include h8;
    position: absolute;
    background: #fff200;
    margin-top: grid-width-m(0.2);
    padding: grid-width-m(0.1);
    white-space: nowrap;
    bottom: grid-width-m(0.1);
    left: grid-width-m(0.1);
  }
  &-thirdDescription {
    @include h8;
    width: grid-width-m(10);
    margin-top: grid-width-m(1.5);
  }
  @include tablet-landscape {
    position: relative;
    padding: 0 grid-width(2) 0 grid-width(2);
    &-title {
      @include h7;
      width: grid-width(14);
      margin: 0 0 0 grid-width(3);
    }
    &-firstDescription {
      @include h8;
      width: grid-width(14);
      margin: grid-width(0.2) 0 0 grid-width(3);
    }
    &-desction-content {
      width: auto;
      margin-top: grid-width(1);
      margin-left: grid-width(8);
      background: #f2f1f0;
      position: relative;
      padding: grid-width(0.2) grid-width(0.2) grid-width(0.9);
    }
    &-desction-content-item {
      @include h7;
      width: 100%;
    }
    &-tips {
      @include h8;
      position: absolute;
      background: #fff200;
      margin-top: grid-width(0.2);
      padding: grid-width(0.1);
      white-space: nowrap;
      bottom: grid-width(0.2);
      left: grid-width(0.2);
    }
    &-thirdDescription {
      @include h8;
      width: grid-width(14);
      margin: grid-width(1) 0 0 grid-width(3);
    }
  }
}
</style>
