<script setup>
import { APP_CONST, sitecoreComponentProps } from '@/utils/constants';
import useAppStore from '@/store/appStore';
import { computed, inject, onMounted, ref } from 'vue';
import { canUseDOM } from '@/utils/dom-utils';
import { debounce, merge } from 'lodash';
import { isNullOrEmpty } from '@/utils/obj-utils';
import * as querystring from 'querystring';
import { buildShopCheckoutUrl, getGlobalCfgValues } from '@/utils/site-utils';
import { useRouter } from 'vue-router';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { appendQuery } from '@/utils/uri-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const router = useRouter();
/**@type AppStore*/
const appStore = useAppStore();
const loading = inject('loading');
const toast = inject('toast');
const overWeightModalRef = ref();
const overQuantityModalRef = ref();
const currency = ref(null);
const cartDetails = ref([]);
const weightLimit = ref(18);
const maxPerSku = ref(6);
const maxQuantity = ref(20);
const disableText = computed(() => {
  const { disableText } = props.fields || {};
  const disableTextVal = disableText?.value;
  if (isNullOrEmpty(disableTextVal)) return null;
  return querystring.parse(disableTextVal);
});
const checkedCartDetails = computed(() => cartDetails.value?.filter((x) => x.checked && !x.disabled));
const buildLoginBtn = (btn) => {
  if (!canUseDOM() || isNullOrEmpty(btn?.fields?.link.value?.href)) return btn;
  return merge({}, btn, {
    fields: {
      link: {
        value: {
          href: appendQuery(btn.fields?.link.value?.href, {
            redirect_url: window.location.href
          })
        }
      }
    }
  });
};
const onItemCheckChange = async (item) => {
  if (item.disabled) {
    await toast.confirm(ifEmpty(props.fields?.unavailableText?.value, 'This goods is not available now, please check the cart'));
    return;
  }
  item.checked = !item.checked;
};
const onQuantityChange = debounce(async (item, quantity) => {
  if (quantity <= 0 || isNaN(quantity)) return;
  const [_cartDetails, ex] = await appStore.updateToCart(APP_CONST.market.code, item.skuId, quantity);
  if (ex) {
    await toast.showEx(ex);
    return;
  }
  cartDetails.value = _cartDetails;
}, 2000);
const onRemoveItem = debounce(async (item) => {
  const { removeConfirmationText } = props.fields || {};
  const removeConfirmationTextVal = removeConfirmationText?.value;
  let confirmed = true;
  if (!isNullOrEmpty(removeConfirmationTextVal)) {
    confirmed = await toast.confirm(removeConfirmationTextVal, {
      okText: props.fields.removeOkText?.value,
      cancelText: props.fields.removeCancelText?.value
    });
  }
  if (!confirmed) return;
  const [_cartDetails, ex] = await appStore.removeFromCart(APP_CONST.market.code, item.skuId);
  if (ex) {
    await toast.showEx(ex);
    return;
  }
  cartDetails.value = _cartDetails;
}, 200);
const onCheckAllChange = (e, checked) => {
  for (let item of cartDetails.value) {
    if (item.disabled) continue;
    item.checked = checked;
  }
};
const clearAll = async () => {
  const { removeConfirmationText } = props.fields || {};
  const removeConfirmationTextVal = removeConfirmationText?.value;
  const confirmed = await toast.confirm(removeConfirmationTextVal, {
    okText: props.fields.removeOkText?.value,
    cancelText: props.fields.removeCancelText?.value
  });
  if (confirmed) {
    const [result, ex] = await appStore.clearCart();
    if (ex) {
      toast.showEx(ex);
      return;
    }
    cartDetails.value = result;
  }
};
const onCheckout = async () => {
  if (overWeightModalRef.value && appStore.cartTotalWeight > weightLimit.value) {
    await overWeightModalRef.value.open();
    return;
  }
  if (overQuantityModalRef.value && appStore.cartTotalQuantity > maxQuantity.value) {
    await overQuantityModalRef.value.open();
    return;
  }
  const url = buildShopCheckoutUrl(props.page, checkedCartDetails.value, true);
  if (!isNullOrEmpty(url)) window.location = url;
};
if (canUseDOM()) {
  loading.show();
}
onMounted(async () => {
  loading.show();
  const [shopWeightLimit, shopMaxQuantityPerSku, shopMaxQuantity] = getGlobalCfgValues(props.page, ['shopWeightLimit', 'shopMaxQuantityPerSku', 'shopMaxQuantity']);
  weightLimit.value = shopWeightLimit;
  maxPerSku.value = shopMaxQuantityPerSku;
  maxQuantity.value = shopMaxQuantity;
  const details = (await appStore.loadCartInfo()).filter((x) => equalString(x.market, APP_CONST.market.code));
  for (let detail of details) {
    detail.checked = !detail.disabled;
  }
  console.log('details', details);
  cartDetails.value = details;
  loading.hide();
});
</script>

<template>
  <overall-settings v-bind="$props">
    <div class="e-shop-cart">
      <div class="e-shop-cart__header">
        <jss-rich-text class="e-shop-cart__title fs-26 tl-fs-56 mg-b-32" :field="fields.title" />
        <template-string class="mg-b-20" v-if="cartDetails?.length > 0" :field="fields.body" :data="{ quantity: appStore.cartTotalQuantity }" />
      </div>
      <template v-if="cartDetails?.length > 0">
        <div class="e-shop-cart__main">
          <div class="e-shop-cart__items">
            <div class="e-shop-cart__item" v-for="item in cartDetails" :key="item.skuId">
              <div class="e-shop-cart__item-main">
                <checkbox :value="item.checked" @change="onItemCheckChange(item)" />
                <div class="e-shop-cart__item-image" v-aspect-ratio="'1_1'">
                  <img :src="item.detail.image" :alt="item.detail.name" />
                </div>
                <div class="e-shop-cart__item-detail">
                  <div class="e-shop-cart__item-detail-main">
                    <div class="e-shop-cart__item-name">{{ item.detail.name }}</div>
                    <div class="e-shop-cart__item-specs">{{ item.detail.specs.map((x) => x.value).join(', ') }}</div>
                    <div class="e-shop-cart__item-price">{{ $formatShopMoney(item.detail.price, appStore.cartCurrency) }}</div>
                  </div>
                  <div class="e-shop-cart__item-detail-quantity">
                    <div class="e-shop-cart__item-error" v-if="!$isNullOrEmpty(item.detail.disableType)">
                      {{ item.detail.disableType === 'outOfStock' ? $t('Out of stock') : disableText[item.detail.disableType] }}
                    </div>
                    <num-stepper v-model:value="item.quantity" :max="Math.min(item.detail.stock, maxPerSku)" :disabled="!item.disabled" @change="(number) => onQuantityChange(item, number)" />
                  </div>
                </div>
              </div>
              <icon class="e-shop-cart__item-trash hand" name="trash" @click="onRemoveItem(item)" />
            </div>
          </div>
          <div class="e-shop-cart__toolbar">
            <checkbox
              :value="cartDetails?.every((x) => x.checked || x.disabled) && cartDetails?.filter((x) => x.checked).length > 0"
              :mandatory-text="fields.selectAllText?.value"
              @change="onCheckAllChange"
            />
            <site-button v-bind="fields.clearButton" :disabled="cartDetails?.length === 0" @click="clearAll" />
          </div>
        </div>
        <div class="e-shop-cart__side">
          <div class="e-shop-cart__side-main">
            <div class="e-shop-cart__summary">
              <jss-rich-text class="fs-32 __lh-1 ls-2 mg-b-20" :field="fields.summaryTitle" tag="div" v-if="$deviceComputes.largeThanTablet.value" />
              <label-value :label="$tu('Shipping')">{{ $t('Calculated at checkout') }}</label-value>
              <label-value :value="`${appStore.cartTotalWeight}kg`">
                <template #label>
                  <popover-disclaimer :title="$tu('Weight')" :disclaimer="fields?.weightDisclaimer" position="top-left" />
                </template>
              </label-value>
              <label-value :value="$t('Calculated at checkout')" v-if="!$isMarket('uk')">
                <template #label>
                  <popover-disclaimer :title="$tu('VAT')" :disclaimer="fields?.vatDisclaimer" position="top-left" />
                </template>
              </label-value>
              <label-value class="e-shop-cart__side-total" :value="$formatShopMoney(appStore.cartTotalAmount, appStore.cartCurrency)">
                <template #label>
                  <popover-disclaimer :title="$tu('Subtotal')" :disclaimer="fields?.subtotalDisclaimer" position="top-left" />
                </template>
              </label-value>
              <site-button v-bind="fields.checkoutButton" :disabled="!checkedCartDetails.length" @click="onCheckout" />
            </div>
            <jss-rich-text class="mg-t-20 tl-mg-b-40" :field="fields.helpText" tag="div" />
          </div>
        </div>
      </template>
      <div class="e-shop-cart__empty" v-else>
        <jss-rich-text class="e-shop-cart__empty" :field="fields.emptyBody" />
        <div class="e-shop-cart__empty-buttons">
          <template v-for="button in fields.emptyButtons" :key="button.id">
            <template v-if="$equalString(button.name, 'button - sign in')">
              <site-button v-bind="buildLoginBtn(button)" v-if="!appStore.hasLoggedIn" />
            </template>
            <site-button v-bind="button" v-else />
          </template>
        </div>
      </div>
      <notice-modal v-bind="fields.overWeightModal" ref="overWeightModalRef" />
      <notice-modal v-bind="fields.overQuantityModal" ref="overQuantityModalRef" />
    </div>
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-shop-cart {
  $c: &;
  padding: 40px 0;
  &__title {
    font-size: 32px;
    border-bottom: 1px solid $grey-89;
    margin-bottom: 12px;
    padding-bottom: 32px;
  }
  &__header {
    padding: 0 24px;
  }
  &__main {
    padding: 0 24px;
  }
  &__side {
    position: sticky;
    bottom: 0;
    background-color: $white;
    padding: 0 24px 24px;
    .e-label-value {
      padding: 0;
      font-size: 14px;
      &__label {
        font-weight: 700;
      }
    }
    &-total {
      &.e-label-value {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    .e-form-checkbox {
      &__main {
        gap: 16px;
      }
    }
    .e-site-button {
      border-width: 2px;
    }
  }
  &__empty {
    min-height: 28vh;
    padding: 0 24px;
    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 8px;
      margin-bottom: 60px;
      .e-site-button {
        width: 100%;
      }
    }
  }
  &__items {
    border-bottom: 1px solid $grey-89;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 40px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    &-main {
      display: flex;
      gap: 16px;
    }
    .e-form-checkbox {
      align-self: center;
      flex-shrink: 0;
    }
    &-image {
      flex-shrink: 0;
      width: grid-width-m(3);
      padding: 16px;
      background-color: $grey-light;
      aspect-ratio: 1/1;
      > img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
    &-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: grid-width-m(4);
      &-main {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
      }
    }
    &-name {
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-specs {
      color: $grey-next;
    }
    &-price {
      color: $grey-next;
    }
    &-error {
      font-size: 12px;
      color: $red;
    }
    &-trash {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      > svg {
        width: 16px;
      }
    }
  }
  &__summary {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-top: 1px solid $grey-89;
    padding-top: 36px;
    background-color: $white;
    .e-site-button {
      margin-top: 20px;
      width: 100% !important;
    }
  }
  @include tablet-landscape {
    @include grid-container;
    padding: 40px 0;
    &__header {
      @include grid-block(3, 20);
      padding: 0;
    }
    &__main {
      @include grid-block(3, 10);
      padding: 0;
    }
    &__side {
      @include grid-block(15, 8);
      position: static;
      bottom: auto;
      min-height: 100%;
      padding: 0 0 24px 0;
      &-main {
        position: sticky;
        top: 120px;
      }
    }
    &__empty {
      @include grid-block(3, 20);
      padding: 0;
    }
    &__items {
      padding-top: 40px;
      padding-bottom: 64px;
    }
    &__item {
      &-main {
        gap: 24px;
      }
      &-detail {
        width: grid-width(5);
        margin-left: 8px;
      }
      &-image {
        width: grid-width(2);
      }
      &-trash {
        > svg {
          width: 24px;
        }
      }
    }

    &__toolbar {
      .e-form-checkbox {
        &__main {
          gap: 20px;
        }
      }
    }
    &__summary {
      padding: 36px 50px;
      border: 1px solid $grey-89;
    }
    &__title {
      font-size: 56px;
    }
    &__empty {
      &-buttons {
        width: grid-width(6);
      }
    }
  }
}
</style>
