<template>
  <div class="c-newsletter-completion">
    <Heading class="c-newsletter-completion__title" :field="fields.title" :type="fields.titleType" />
  </div>
</template>

<script>
export default {
  name: 'NewsletterCompletion',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-newsletter-completion {
  &__title {
    margin-bottom: $space-20;
  }
}
</style>
