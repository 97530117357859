<template>
  <OverallSettings v-bind="$props">
    <div class="c-newsletter-cta" v-if="fields">
      <JssRichText class="c-newsletter-cta__title" :field="fields.title" tag="div" />
      <JssRichText class="c-newsletter-cta__body" :field="fields.body" tag="div" />
      <DynamicForm class="c-newsletter-cta__form" :form="fields.form" />
      <SiteButton class="c-newsletter-cta__pcBtn" v-if="isDesktop()" v-bind="fields.subscribeBtn" />
      <div class="c-newsletter-cta__policy-group">
        <JssRichText class="c-newsletter-cta__policy" :field="fields.policyText" />
        <SiteButton class="c-newsletter-cta__policyBtn" v-bind="fields.policyBtn" />
      </div>
      <a href=""></a>
      <SiteButton class="c-newsletter-cta__mobileBtn" v-if="!isDesktop()" v-bind="fields.subscribeBtn" />
    </div>
  </OverallSettings>
</template>
<script>
import { isDesktop } from '@/utils/dom-utils';
export default {
  name: 'Login',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const methods = {
      isDesktop: isDesktop
    };
    return {
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-newsletter-cta {
  margin: 0 grid-width(2);
  &__title {
    @include h4;
    margin-bottom: $space-20;
  }
  &__body {
    font-size: 16px;
    margin-bottom: $space-20;
  }
  &__form {
    margin-bottom: $space-20;
  }
  &__policy-group {
    @include h9;
    margin-bottom: $space-60;
    .e-site-button__btn {
      height: auto;
      font-size: unset;
      border: none;
      margin-left: 3px;
      &.no-icon {
        padding: 0;
        text-decoration: underline;
      }
    }
  }
  @include tablet-landscape {
    &__title {
      margin-bottom: $space-40;
    }
    &__body {
      font-size: 16px;
      margin-bottom: $space-60;
    }
    &__pcBtn {
      margin-bottom: $space-60;
    }
    &__policy-group {
      margin-bottom: 0;
    }
  }
}
</style>
