<template>
  <overall-settings v-bind="$props">
    <div
      class="c-modelProperty"
      :style="'padding-left:' + (fields.marginLeft.value ? fields.marginLeft.value : 0) + 'px;' + 'padding-right:' + (fields.marginRight.value ? fields.marginRight.value : 0) + 'px;'"
    >
      <div class="c-modelProperty__content" :style="'background-image: url(' + fields.image.value.src + ')'">
        <div class="c-modelProperty__content__main" :class="{ 'c-modelProperty__main--end': fields.layout.value === 'End' }">
          <section
            class="c-modelProperty__explain--outSide"
            :class="{ 'c-modelProperty__explain--fLeft': fields.layoutWord.value === 'Left', 'c-modelProperty__explain--fCenter': fields.layoutWord.value === 'Center' }"
          >
            <section class="c-modelProperty__explain">
              <div class="c-modelProperty__spTitle__icon">
                <div class="icon-line1" :style="'background:' + fields.remarksIconColor.value"></div>
                <div class="icon-line2" :style="'background:' + fields.remarksIconColor.value"></div>
                <div class="icon-line3" :style="'background:' + fields.remarksIconColor.value"></div>
              </div>

              <jss-rich-text v-if="fields.title" class="c-modelProperty__spTitle" :field="fields.title" tag="div" />

              <jss-rich-text v-if="fields.remark" class="c-modelProperty__word--sp" :field="fields.remark" tag="div" />
            </section>
          </section>

          <div class="clear-line-modelProperty"></div>

          <section
            class="c-modelProperty__api"
            v-if="fields.list && fields.list.length > 0"
            :class="{ 'c-modelProperty__api__content--right': fields.layoutUnit.value === 'Right', 'c-modelProperty__api__content--center': fields.layoutUnit.value === 'Center' }"
          >
            <section class="c-modelProperty__api__content">
              <div class="c-modelProperty__api--item" v-for="(item, index) in fields.list" :key="'modelPropertyApi' + index">
                <jss-rich-text class="c-modelProperty__word--num" :field="item.fields.num" tag="div" />

                <div class="c-modelProperty__api__detail">
                  <jss-rich-text class="c-modelProperty__word--unit" :field="item.fields.unit" tag="div" />

                  <div class="c-modelProperty__word--title">
                    <jss-rich-text :field="item.fields.title" tag="div" />
                    <span class="c-modelProperty__word--title__icon">i</span>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';

export default {
  /**
   * @typedef ModelPropertyFields
   * @property {GlobalSettingEntry} theme
   * */

  name: 'SimpleImage',
  props: {
    /**@type ModelPropertyFields*/
    fields: {
      type: Object
    }
  },
  setup(props) {
    onMounted(() => {
      let outWidth = 0;

      setTimeout(() => {
        document.querySelectorAll('.c-modelProperty__api--item').forEach((val) => {
          outWidth += val.clientWidth + 6;
        });

        document.querySelector('.c-modelProperty__api__content').style.width = outWidth + 'px';
      }, 100);
    });
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.clear-line-modelProperty {
  width: 100%;
  height: 245px;
  overflow: hidden;
  background: transparent;
}
.c-modelProperty {
  width: 100%;

  .c-modelProperty__content {
    position: relative;
    width: 100%;
    @include grid-container;
    background-size: cover;
  }
  .c-modelProperty__content__main {
    @include grid-block(2, 10);
    overflow: hidden;
    padding: 40px 0 50px 0;
    display: flex;
    flex-direction: column;

    &.c-modelProperty__main--end {
      flex-direction: column-reverse;
    }
  }

  .c-modelProperty__explain--outSide {
    width: 100%;
    overflow: hidden;
  }
  .c-modelProperty__explain {
    position: relative;
    clear: both;
    width: 100%;
  }

  .c-modelProperty__spTitle__icon {
    position: relative;
    width: 20px;
    height: 20px;

    .icon-line1 {
      position: absolute;
      width: 20px;
      height: 4px;
      overflow: hidden;
      background: #fff200;
      top: 0;
      left: 0;
      transform: rotate(45deg);
      transform-origin: 0px 5px;
    }
    .icon-line2 {
      position: absolute;
      width: 16px;
      height: 4px;
      overflow: hidden;
      background: #fff200;
      right: 0;
      bottom: 0;
    }
    .icon-line3 {
      position: absolute;
      width: 4px;
      height: 16px;
      overflow: hidden;
      background: #fff200;
      right: 0;
      bottom: 0;
    }
  }

  .c-modelProperty__spTitle__content {
    position: relative;
    clear: both;
    width: 100%;
  }
  .c-modelProperty__spTitle {
    width: 100%;
    font-size: 24px;
    line-height: 1.2;
    color: #fff200;
    padding: 12px 0 0 20px;
  }
  .c-modelProperty__word--sp {
    clear: both;
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    padding: 20px 0 0 20px;
    color: #fff;
  }
  .c-modelProperty__api {
    clear: both;
    width: 100%;

    .c-modelProperty__api__content {
      width: 100%;
      overflow: hidden;
    }
    .c-modelProperty__api--item {
      width: 100%;
      padding: 0 0 45px 0;
      overflow: hidden;

      .c-modelProperty__api__detail {
        display: flex;
        height: 45px;
        float: left;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 10px;
      }

      .c-modelProperty__word--num {
        display: inline-block;
        font-size: 50px;
        color: yellow;
        line-height: 1;
        margin-right: 4px;
        float: left;
      }
      .c-modelProperty__word--unit {
        display: inline-block;
        font-size: 14px;
        color: yellow;
        line-height: 1;
      }
      .c-modelProperty__word--title {
        display: flex;
        font-size: 14px;
        color: #fff;
        line-height: 1.5;
        padding-top: 2px;
        align-items: center;

        .c-modelProperty__word--title__icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          cursor: pointer;
          background: #fff;
          color: #000;
          text-align: center;
          line-height: 15px;
          font-size: 10px;
          margin-left: 10px;
          overflow: hidden;
        }
      }
    }
  }
}

@include tablet-landscape {
  .clear-line-modelProperty {
    height: 270px;
  }
  .c-modelProperty {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track-piece {
      height: 0;
    }

    .c-modelProperty__content__main {
      @include grid-block(3, 20);
    }

    .c-modelProperty__explain {
      width: 340px;
      float: right;

      .c-modelProperty__explain--fLeft {
        float: left;
      }
      .c-modelProperty__explain--fCenter {
        float: none;
        margin: 0 auto;
      }
    }

    .c-modelProperty__api {
      display: flex;

      .c-modelProperty__api__content {
        .c-modelProperty__api--item {
          display: inline-block;
          padding: 0 170px 0 0;
          float: left;
          width: auto;
          overflow: visible;

          &:last-child {
            padding-right: 0;
          }
        }
        .c-modelProperty__word--num {
          font-size: 80px;
        }
        .c-modelProperty__api__detail {
          height: 70px;
          margin-left: 0;
        }
      }

      &.c-modelProperty__api__content--right {
        justify-content: flex-end;
      }
      &.c-modelProperty__api__content--center {
        justify-content: center;
      }
    }
  }
}
html.rtl {
  .c-modelProperty {
    &__api {
      &__detail {
        float: right;
      }
    }
    &__word {
      &--num {
        float: right;
      }
    }
    &__explain {
      float: left;
    }
    &__content {
      float: right;
    }
  }
}
</style>
