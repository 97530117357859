<template>
  <overall-settings v-bind="$props">
    <div class="c-merchandise-faq" v-if="fields">
      <div class="faq-content">
        <div class="faq-lt" :style="`height:` + faqLtHeight + 'px'">
          <div class="faq-lt-content" ref="faqLtContentEl">
            <jss-rich-text :field="fields?.title" tag="div" class="title" />
            <jss-rich-text :field="fields?.description" tag="div" class="desc" />
            <SiteButton v-bind="fields?.button" />
          </div>
        </div>
        <div class="faq-rt">
          <div class="faq-item" :class="index === 0 ? 'active' : ''" v-for="(item, index) in fields?.list" :key="index">
            <div class="question-content" @click="questionClick(index)">
              <jss-rich-text :field="item.fields.question" tag="div" class="question" />
              <Icon name="down" size="tiny" :class="item.selected ? 'open' : ''" />
            </div>
            <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
              <jss-rich-text :field="item.fields.answer" tag="div" class="answer" v-if="item.selected" />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, nextTick, computed, onMounted, onUnmounted } from 'vue';
import gsap from 'gsap';

export default {
  name: 'MerchandiseFaq',
  props: {
    /**@type MerchandiseFaqFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      faqLtHeight: 200,
      faqLtContentEl: null
    });

    const methods = {
      onItemBeforeEnter(el) {
        el.style.height = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 10,
          duration: 0.3,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          duration: 0.3,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      questionClick(_index) {
        let faqList = props?.fields?.list;
        faqList?.map((item, index) => {
          if (index === _index) {
            item.selected = !item.selected;
          }
        });
      },
      resizePageSet() {
        state.faqLtHeight = state.faqLtContentEl.getBoundingClientRect().height;
      }
    };

    onMounted(() => {
      methods.resizePageSet();
      window.addEventListener('resize', methods.resizePageSet);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-faq {
  user-select: none;
  width: 100%;
  @include grid-container;
  .faq-content {
    width: 100vw;
    padding: 80px grid-width(2);
    .faq-lt {
      .title {
        font-size: 26px;
        letter-spacing: 2px;
        line-height: 26px;
        color: $black;
      }
      .desc {
        color: #757575;
        margin-top: 32px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
      }
    }
    .faq-rt {
      margin-top: 32px;
      .faq-item {
        padding: 16px 0 6px;
        border-bottom: 1px solid #e3e3e3;
        &.active {
          border-top: 1px solid #e3e3e3;
        }
        .question-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          color: $black;
          margin-bottom: 10px;
          cursor: pointer;
          .question {
            width: 90%;
          }
          .e-icon {
            position: relative;
            transition: all 0.3s;
            &.open {
              transform: rotate(180deg);
              transform-origin: center center;
            }
          }
        }
        .answer {
          font-size: 16px;
          color: rgb(117, 117, 117);
          overflow: hidden;
        }
      }
    }
  }
}
@include tablet-landscape {
  .c-merchandise-faq {
    .faq-content {
      width: 100vw;
      padding: 120px grid-width(2);
      display: flex;
      justify-content: space-between;
      .faq-lt {
        max-width: 33%;
        position: sticky;
        top: 100px;
        .title {
          font-size: 32px;
          line-height: 32px;
        }
        .desc {
          margin-bottom: 24px;
        }
      }
      .faq-rt {
        width: 50%;
        margin-top: 0;
        .faq-item {
          padding: 16px 0 6px;
          border-bottom: 1px solid #e3e3e3;
          overflow: hidden;
          &.active {
            border-top: 1px solid #e3e3e3;
          }
          .question-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: $black;
            .e-icon {
              position: relative;
              transition: all 0.3s;
              &.open {
                transform: rotate(180deg);
                transform-origin: center center;
              }
            }
          }
          .answer {
            font-size: 16px;
            color: rgb(117, 117, 117);
          }
        }
      }
    }
  }
}
</style>
