<template>
  <div class="c-merchandise-detail">
    <div class="c-merchandise-detail__content">
      <div class="c-merchandise-detail__top" ref="topEl">
        <div class="c-merchandise-detail__content__swiper-box">
          <div ref="swiperEl" class="c-merchandise-detail__content__swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in imageList" :key="index.id">
                <BackgroundImage class="c-merchandise-detail__content__swiper__image" :fullscreenEnabled="true" :image="item.media" @click="showGalleryFn" />
              </div>
            </div>
            <div class="progress-content" v-if="imageList && imageList.length > 1">
              <div class="progress" ref="progressEl"></div>
            </div>
          </div>
          <div v-show="imageList.length > 1" ref="bottomSwiperEl" class="c-merchandise-detail__content__bot_swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" :class="activeIndex === index ? 'active' : ''" v-for="(item, index) in imageList" :key="index.id">
                <BackgroundImage class="c-merchandise-detail__content__bot_swiper__image" :image="item.media" />
              </div>
            </div>
          </div>
        </div>
        <div class="c-merchandise-detail__content__info">
          <div class="product-info">
            <div class="new" v-if="isNew">{{ $t('NEW') }}</div>
            <div class="name">{{ name }}</div>
            <div class="desc">{{ description }}</div>
            <div class="price">{{ $formatShopMoney(salePrice, currency) }}</div>
          </div>
          <div class="sku-content">
            <div class="sku-item-content" v-for="(item, index) in selectedSku.attrValueList" :key="index">
              <div class="sku-item">
                <div class="attr-key">{{ item.attrKey }}</div>
                <div class="icon-content" v-if="attrSelectObj[item.attrKey].attrKeyCode === 'frameSize'" @mouseenter="iconRemarksHover" @mouseleave="iconRemarksOut" @click="iconRemarksClick">
                  <icon name="warn" />
                </div>
                <div class="sku-item-right" v-if="attrSelectObj[item.attrKey].attrKeyCode === 'color'">
                  <div class="attr-value">{{ item.attrValue }}</div>
                </div>
                <section
                  class="frame-size-tip-dialog"
                  v-show="showFrameSizeExplanation"
                  v-if="!$isNullOrEmpty(fields.frameSizeDisclaimer?.value) && attrSelectObj[item.attrKey].attrKeyCode === 'frameSize'"
                >
                  <div @click="iconRemarksHide" class="frame-size-tip-close">
                    <Icon name="close" />
                  </div>
                  <jss-rich-text class="frame-size-tip" :field="fields.frameSizeDisclaimer" tag="div" />
                </section>
              </div>
              <div class="attr-list">
                <div
                  class="attr-item"
                  v-for="(attrItem, index) in attrSelectObj[item.attrKey].attrObjList"
                  :key="index"
                  @click="selectAttrFn(item, index, suitObj[item.attrKey]?.canSelectAttrList.indexOf(attrItem.value), attrItem.code)"
                  :class="{
                    color: attrSelectObj[item.attrKey].attrKeyCode === 'color',
                    colorActive: indexObj[item.attrKey]?.index === index && attrSelectObj[item.attrKey].attrKeyCode === 'color',
                    active: indexObj[item.attrKey]?.index === index && attrSelectObj[item.attrKey].attrKeyCode !== 'color',
                    disabled: suitObj[item.attrKey]?.canSelectAttrList.indexOf(attrItem.value) == -1 && attrSelectObj[item.attrKey].attrKeyCode !== 'color',
                    colorDisabled: suitObj[item.attrKey]?.canSelectAttrList.indexOf(attrItem.value) == -1 && attrSelectObj[item.attrKey].attrKeyCode === 'color',
                    max: attrSelectObj[item.attrKey].attrKeyCode === 'edition',
                    pointerEvents: attrSelectObj[item.attrKey].attrKeyCode === 'edition' && clickIconEnd
                  }"
                >
                  <p v-show="attrSelectObj[item.attrKey].attrKeyCode !== 'color'" class="attr-name" :class="attrSelectObj[item.attrKey].attrKeyCode === 'edition' ? 'edtion' : ''">
                    {{ attrItem.value }}
                  </p>
                  <div class="icon-content" v-if="attrItem.code === 'colorYellow'">
                    <Icon :field="gold?.fields?.value" />
                  </div>
                  <div class="icon-content" v-if="attrItem.code === 'colorBlack'">
                    <Icon :field="black?.fields?.value" />
                  </div>
                  <div class="icon-content" v-if="attrItem.code === 'colorBlackYellow'">
                    <Icon :field="blackAndGold?.fields?.value" />
                  </div>
                  <div class="pre-content" v-if="attrSelectObj[item.attrKey].attrKeyCode === 'edition' && attrItem.code === 'standardEditon'">
                    <div class="icon-content" @mouseenter="editionIconRemarksHover" @mouseleave="editionIconRemarksOut" @click="editionIconRemarksClick">
                      <icon name="warn" />
                    </div>
                    <section class="edtion-tip-dialog" v-show="showStandardEdtionDisclaimer" v-if="!$isNullOrEmpty(fields.standardEdtionDisclaimer?.value)">
                      <div @click="editionIconRemarksHide" class="frame-size-tip-close">
                        <Icon name="close" />
                      </div>
                      <jss-rich-text class="frame-size-tip" :field="fields.standardEdtionDisclaimer" tag="div" />
                    </section>
                    <jss-rich-text :field="fields.listingReminder" tag="div" class="pretext" />
                  </div>
                  <jss-rich-text v-if="attrSelectObj[item.attrKey].attrKeyCode === 'edition' && attrItem.code === 'firstEdition'" :field="fields.firstEdtionDescription" tag="div" class="desc" />
                  <jss-rich-text v-if="attrSelectObj[item.attrKey].attrKeyCode === 'edition' && attrItem.code === 'standardEditon'" :field="fields.standardEdtionDescription" tag="div" class="desc" />
                </div>
              </div>
            </div>
          </div>
          <div class="c-merchandise-detail__content__info__detail">
            <p class="name">{{ name }}</p>
            <p class="attr">{{ firstAttrObj }}</p>
            <p class="total">Total: {{ $formatShopMoney(salePrice, currency) }}</p>
            <p class="reservation" v-show="depositPrice">Reservation fee: {{ $formatShopMoney(depositPrice, currency) }}</p>
            <jss-rich-text :field="fields.explanationOnDeposit" tag="div" class="tip" />
            <jss-rich-text :field="fields.firstExplanationOnFreightCharges" tag="div" v-if="selectedEdtion === 'firstEdition'" class="desc" />
            <jss-rich-text :field="fields.secondExplanationOnFreightCharges" tag="div" v-if="selectedEdtion === 'standardEditon'" class="desc" />
          </div>
          <SiteButton v-if="(sellStock > 0 || salesMethod === 2) && selectedEdtion === 'firstEdition' && productStatus === 60291003" v-bind="fields.button" @click="submit" />
          <SiteButton
            disabled
            v-if="(sellStock < 1 && salesMethod === 1 && selectedEdtion === 'firstEdition') || (selectedEdtion === 'firstEdition' && productStatus === 60291004)"
            v-bind="fields.soldOutButton"
          />
          <SiteButton disabled v-if="disableSkuCodeList.indexOf(selectedEdtion) !== -1" v-bind="fields.preButton" />
          <div class="scroll-content" @click="scrollToFaq">
            <jss-rich-text :field="fields.scrollToFaqText" tag="div" class="c-merchandise-detail__content__info__scrollText" />
            <Icon name="down" size="tiny" />
          </div>
        </div>
      </div>
      <div class="desc-content" ref="textEl" v-if="longDescriptions.length > 0">
        <div class="desc-item first-desc" :style="'height:' + firstDescElHeight + 'px'">
          <div class="box" ref="boxEl">
            <jss-rich-text :field="firstDescription.content" tag="div" class="content" />
          </div>
        </div>
        <div class="desc-right">
          <div class="desc-item" :class="index === 0 ? 'active' : ''" v-for="(item, index) in longDescriptions" :key="index">
            <div class="title-content" @click="titleClick(index)" v-if="item.title.value">
              <jss-rich-text :field="item.title" tag="div" class="title" />
              <Icon name="down" size="tiny" :class="item.selected ? 'open' : ''" />
            </div>
            <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
              <jss-rich-text :field="item.content" tag="div" class="content" v-if="item.selected" />
            </transition>
          </div>
          <div class="desc-item last-desc" v-if="lastDescription">
            <div class="content" v-html="lastDescription?.content?.value"></div>
          </div>
        </div>
      </div>
      <div class="picture-image" v-if="pictureTexts && pictureTexts.length > 0" ref="imageAndTextEl">
        <div class="picture-image-item" v-for="(item, index) in pictureTexts" :key="index" :class="`picture-image-item__${index % 2}`">
          <div class="picture-image-item__content" v-show="item.image?.value?.src">
            <BackgroundImage class="picture-image-item__image" :image="item.image" />
          </div>
          <div class="picture-image-item__desc__content" v-show="item.desc?.value.trim().length > 0">
            <jss-rich-text :field="item.desc" tag="div" class="picture-image-item__desc" />
          </div>
        </div>
      </div>
      <div class="faq-content">
        <div class="faq-lt" :style="`height:` + faqLtHeight + 'px'">
          <div class="faq-lt-content" ref="faqLtContentEl">
            <jss-rich-text :field="fields.faqTitle" tag="div" class="title" />
            <jss-rich-text :field="fields.faqDescription" tag="div" class="desc" />
            <SiteButton v-bind="fields.emailButton" />
          </div>
        </div>
        <div class="faq-rt">
          <div class="faq-item" :class="index === 0 ? 'active' : ''" v-for="(item, index) in fields.faqList" :key="index">
            <div class="question-content" @click="questionClick(index)">
              <jss-rich-text :field="item.fields.question" tag="div" class="question" />
              <Icon name="down" size="tiny" :class="item.selected ? 'open' : ''" />
            </div>
            <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
              <jss-rich-text :field="item.fields.answer" tag="div" class="answer" v-if="item.selected" />
            </transition>
          </div>
        </div>
      </div>
      <div class="suction-bottom" :class="isFixed ? 'active' : ''">
        <div class="suction-bottom-lt">
          <p class="name">{{ name }}</p>
          <p class="price" v-show="isMobile()">{{ $formatShopMoney(salePrice, currency) }}</p>
          <p class="attr" v-show="!isMobile()">{{ firstAttrObj }}</p>
        </div>
        <div class="suction-bottom-rt">
          <p class="price" v-show="!isMobile()">{{ $formatShopMoney(salePrice, currency) }}</p>
          <SiteButton v-if="(sellStock > 0 || salesMethod === 2) && selectedEdtion === 'firstEdition' && productStatus === 60291003" v-bind="fields.button" @click="submit" />
          <SiteButton
            disabled
            v-if="(sellStock < 1 && salesMethod === 1 && selectedEdtion === 'firstEdition') || (selectedEdtion === 'firstEdition' && productStatus === 60291004)"
            v-bind="fields.soldOutButton"
          />
          <SiteButton disabled v-if="disableSkuCodeList.indexOf(selectedEdtion) !== -1" v-bind="fields.preButton" />
        </div>
      </div>
      <!-- <MerchandiseDetailGallery :galleryImageList="imageList" @changeShowGallery="closeGallery" v-if="showGallery" /> -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, computed, onUnmounted } from 'vue';
import Swiper from 'swiper';
import api from '@/api';
import { isMobile, largeThanTablet, getScrollTop } from '@/utils/dom-utils';
import { useRoute, useRouter } from 'vue-router';
import { getCurrentAlpha2Code, getGlobalConfigs, encodeData, decodeData } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
import { appendQuery, getBetterUrl, getQueryStrings } from '@/utils/uri-utils';
import gsap from 'gsap';

export default {
  name: 'MerchandiseDetail',
  props: {
    /**@type MerchandiseDetailFields*/
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper = null;
    let bottomSwiper = null;
    const state = reactive({
      mobileImageListBottomEl: null,
      mobileImageListTopEl: null,
      pcImageListBottomEl: null,
      pcImageListTopEl: null,
      attrSelectObj: {},
      attrSelectedObj: [], // 当前已选属性列表
      attrKeySelectedObj: [], // 当前已选属性key列表
      selectedEdtion: null,
      swiperEl: null,
      bottomSwiperEl: null,
      progressEl: null,
      botprogressEl: null,
      topEl: null,
      textEl: null,
      imageAndTextEl: null,
      activeIndex: 0,
      isFixed: false,
      imageList: [], // 商品图以及视频
      name: null, // 商品名称
      description: null, // 商品描述
      salePrice: null, // 商品售价
      depositPrice: null, // 定金
      openAttr: false,
      attrModelTitle: null, // 属性弹窗标题
      skuList: [], // 商品属性列表
      attrObj: [], // 当前已选择属性
      firstDescription: {},
      lastDescription: {},
      longDescriptions: [], // 文案列表
      pictureTexts: [], // 图文数组
      openImageSwiper: false,
      atlas: [], // 图集
      attrKeyList: {
        keyList: [],
        itemList: []
      },
      haveImage: false,
      isNew: false, // 是否为新品
      id: null,
      skuIds: null,
      swiperCurrentIndex: 0,
      currency: 'EUR',
      priceList: [],
      mainVideo: null,
      poster: null,
      indexObj: {},
      canAdd: true,
      selectedSku: {},
      suitObj: {}, // 符合当前可选项
      totalNumber: 0, // 该商品的库存量
      salesMethod: 1, // 该商品是否为预售商品 1: 现货 2:预售 预售商品无论是否有库存都可以走下单流程
      showFrameSizeExplanation: false,
      showStandardEdtionDisclaimer: false,
      firstAttrObj: null, // 匹配当前选项下的第一个sku属性列表
      isFirst: true,
      clickIconEnd: false,
      sellStock: 0, // 当前所选sku对应的库存
      productStatus: null, // 商品上下架状态 上架:60291003 下架:60291004
      firstDescElHeight: 300,
      boxEl: null,
      faqLtContentEl: null,
      faqLtHeight: 200, // faq 左侧高度
      showGallery: false,
      disableSkuCodeList: []
    });
    const route = useRoute();
    const router = useRouter();
    const { spuBusId } = route?.query || {};
    const [shopCheckoutLink] = getGlobalConfigs(props.page, 'shopCheckoutLink');
    const shopCheckoutLinkHref = shopCheckoutLink?.value?.href;
    const computes = {
      spuBusIds: computed(() => route?.query?.spuBusId),
      gold: computed(() => props?.fields?.colorImageList.find((x) => x.fields.code.value === 'gold')),
      black: computed(() => props?.fields?.colorImageList.find((x) => x.fields.code.value === 'black')),
      blackAndGold: computed(() => props?.fields?.colorImageList.find((x) => x.fields.code.value === 'blackAndGold'))
    };

    const buildSwiper = () => {
      let pageWidth = document.documentElement.clientWidth || document.body.offsetWidth || document.documentElement.offsetWidth;
      let progressWidth = pageWidth - 48;
      if (state.progressEl) {
        if (isMobile()) {
          state.progressEl.style.width = progressWidth / state.imageList.length + 'px';
        } else {
          state.progressEl.style.width = (pageWidth * 0.5 - 400) / state.imageList.length + 'px';
        }
      }
      if (swiper) {
        swiper.destroy(true, true);
      }

      swiper = new Swiper(state.swiperEl, {
        slidesPerView: 1,
        on: {
          slideChange: (swiper) => {
            state.activeIndex = swiper.activeIndex;
            if (isMobile()) {
              state.progressEl.style.width = (swiper.activeIndex + 1) * (progressWidth / state.imageList.length) + 'px';
            } else {
              state.progressEl.style.width = (swiper.activeIndex + 1) * ((pageWidth * 0.5 - 400) / state.atlas.length) + 'px';
            }
          }
        },
        thumbs: {
          swiper: bottomSwiper
        }
      });
    };

    const buildBottomSwiper = () => {
      if (bottomSwiper) {
        bottomSwiper.destroy(true, true);
      }

      bottomSwiper = new Swiper(state.bottomSwiperEl, {
        slidesPerView: 'auto',
        spaceBetween: 16,
        watchSlidesProgress: true,
        watchSlidesVisibility: true //防止不可点击
      });
    };

    const methods = {
      isMobile,
      largeThanTablet,
      onItemBeforeEnter(el) {
        el.style.height = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 10,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      titleClick(_index) {
        state.longDescriptions.map((item, index) => {
          if (index === _index) {
            item.selected = !item.selected;
          }
        });
      },
      showGalleryFn() {
        // state.showGallery = true;
        // document.documentElement.classList.add('modal-open');
      },
      closeGallery(val) {
        state.showGallery = val;
        document.documentElement.classList.remove('modal-open');
      },
      questionClick(_index) {
        props.fields.faqList.map((item, index) => {
          if (index === _index) {
            item.selected = !item.selected;
          }
        });
      },
      async getGoodDetail() {
        const [res] = await api.shop.getGoodDetail({
          spuBusId: spuBusId
        });
        let resData = res.carouseList;
        let longDescriptions = [];
        if (res?.longDescriptions && res?.longDescriptions?.length > 0) {
          res.longDescriptions.map((item) => {
            let _item = {};
            _item = {
              title: {
                value: item.title
              },
              content: {
                value: item.content
              }
            };
            longDescriptions.push(_item);
          });
        }
        if (longDescriptions.length > 0) {
          longDescriptions.map((item, index) => {
            if (index === 0) {
              state.firstDescription = item;
            } else if (index === res.longDescriptions.length - 1 && !item.title.value.trim()) {
              state.lastDescription = item;
            } else if (item.title.value.trim() && item.content.value.trim()) {
              state.longDescriptions.push(item);
            }
          });
        }
        if (res.tags && res.tags.length > 0) {
          res.tags.forEach((item) => {
            if (item == 'ST000001') {
              state.isNew = true;
            }
          });
        }
        state.productStatus = res.status;
        state.name = res.name;
        state.totalNumber = res.totalNumber;
        state.salesMethod = res.salesMethod;
        state.description = res.description;
        state.id = res.id;
        if (res.id) {
          methods.getGoodsSkuList();
        }
        let pictureTexts = [];
        res?.pictureTexts?.map((item) => {
          let _item = {};
          _item = {
            desc: {
              value: item.content
            },
            image: {
              value: {
                src: item.pic
              }
            }
          };
          pictureTexts.push(_item);
        });
        state.pictureTexts = pictureTexts;
        if (res.mainImage) {
          resData.unshift(res.mainImage);
        }
        if (res.mainVideo) {
          state.mainVideo = res.mainVideo;
          resData.push(res.mainVideo);
        }

        let arr = [];
        nextTick(() => {
          if (isMobile()) {
            let pageWidth = document.documentElement.clientWidth || document.body.offsetWidth || document.documentElement.offsetWidth;
            if (state.botprogressEl) {
              state.botprogressEl.style.width = (pageWidth - 52) / state.atlas.length + 'px';
            }
          }
          buildBottomSwiper();
          buildSwiper();
        });
      },
      async getGoodsSkuList() {
        const [res] = await api.shop.getGoodsSkuList({
          entrance: '1',
          id: state.id
        });
        if (res && res.skuList && res.skuList.length > 0) {
          let canBuyList = [];
          // 过滤禁用项
          res.skuList.map((item) => {
            if (item.status === 1) {
              canBuyList.push(item);
            }
          });
          state.skuIds = canBuyList[0]?.skuId;

          // 把edition放在第一位
          canBuyList.forEach((option) => {
            if (option?.attrValueList && option?.attrValueList.length > 0) {
              for (let i = 0; i < option.attrValueList.length; i++) {
                if (option.attrValueList[i].attrKeyCode === 'edition') {
                  let firstItem = option.attrValueList[i];
                  option.attrValueList.splice(i, 1);
                  option.attrValueList.unshift(firstItem);
                  break;
                }
              }
            }
          });
          let listArr = [];
          canBuyList.map((item) => {
            if (item.attrValueList[0]?.attrValueCode === 'firstEdition' && state.isFirst) {
              state.isFirst = false;
              listArr.unshift(item);
            } else {
              listArr.push(item);
            }
          });
          let list = listArr;
          list.map((option) => {
            option?.attrValueList.forEach((item) => {
              if (state.attrKeyList.keyList.indexOf(item.attrKey) == -1) {
                let listItem = {
                  code: item.attrKeyCode,
                  value: item.attrKey,
                  attrValueCode: item.attrValueCode
                };
                state.attrKeyList.keyList.push(item.attrKey);
                state.attrKeyList.itemList.push(listItem);
              }
            });
          });
          if (list.length < 1) {
            return false;
          }
          // 数据整合
          if (state.attrKeyList.itemList.length > 0) {
            state.attrKeyList.itemList.forEach((item, index) => {
              state.attrSelectObj[item.value] = {
                list: [],
                attrObjList: []
              };
              state.indexObj[item.value] = {
                index: 0
              };
            });
          }
          list.forEach((option) => {
            if (option?.attrValueList && option?.attrValueList.length > 0) {
              option?.attrValueList.forEach((item) => {
                state.attrKeyList.itemList.forEach((attrItem) => {
                  if (item.attrKey === attrItem.value && state.attrSelectObj[item.attrKey].list.indexOf(item.attrValue) === -1) {
                    let listItem = {
                      code: item.attrValueCode,
                      value: item.attrValue
                    };
                    state.attrSelectObj[item.attrKey].list.push(item.attrValue);
                    state.attrSelectObj[item.attrKey].attrObjList.push(listItem);
                    state.attrSelectObj[item.attrKey].attrKeyCode = item.attrKeyCode;
                  }
                });
              });
            }
          });
          state.skuList = list; // 所有skuList 列表
          state.skuList[0]?.attrValueList.forEach((item) => {
            state.attrObj.push(item);
          });
        }
        methods.selectAttrFn(state.attrKeyList.itemList[0], 0);
      },
      selectAttrFn(attrNameAndCode, index, canSelect, attrValueCode) {
        let attrName = attrNameAndCode?.value ? attrNameAndCode?.value : attrNameAndCode?.attrKey;
        let keyCode = attrNameAndCode?.code ? attrNameAndCode?.code : attrNameAndCode?.attrKeyCode;
        if (canSelect === -1) {
          return false;
        }
        if (state.indexObj[attrName]) {
          state.indexObj[attrName].index = index;
        }
        let attrItem = {
          attrKey: attrName,
          attrKeyCode: keyCode,
          attrValue: state.attrSelectObj[attrName]?.list[index]
        };
        if (state.attrSelectedObj.length > 0) {
          state.attrSelectedObj.forEach((item, index) => {
            if (item.attrKey === attrName) {
              state.attrSelectedObj.splice(index, 1, attrItem);
              state.canAdd = false;
            } else {
              state.canAdd = true;
            }
          });
          if (state.canAdd) {
            state.attrSelectedObj.push(attrItem);
            state.attrKeySelectedObj.push(attrName);
          }
        } else {
          state.attrSelectedObj.push(attrItem);
          state.attrKeySelectedObj.push(attrName);
        }

        state.attrSelectedObj = methods.fn1(state.attrSelectedObj);
        // 如果选的是Edition 就所有的选项重置
        let linkArr = [];
        if (keyCode === 'edition') {
          state.selectedEdtion = attrValueCode ? attrValueCode : attrNameAndCode.attrValueCode;
          state.attrSelectedObj.forEach((item, index) => {
            if (item.attrKeyCode !== 'edition') {
              state.attrSelectedObj.splice(index, 1);
            } else {
              linkArr.push(item);
            }
          });
        }

        if (linkArr.length > 0) {
          state.attrSelectedObj = linkArr;
        }

        let selectedKeyList = [];
        state.attrKeyList.itemList.forEach((option) => {
          if (option.code != 'edition') {
            selectedKeyList.push(option);
          }
        });

        selectedKeyList.forEach((item) => {
          state.suitObj[item.value] = {
            selected: [],
            canSelect: [],
            canSelectAttrList: []
          };
        });

        state.attrSelectedObj.forEach((item) => {
          selectedKeyList.forEach((key) => {
            if (item.attrKey != key.value) {
              state.suitObj[key.value].selected.push(item);
            }
          });
        });
        state.skuList.forEach((option) => {
          if (option?.attrValueList && option?.attrValueList.length > 0) {
            option?.attrValueList.forEach((item) => {
              if (state.suitObj[item.attrKey] && state.suitObj[item.attrKey].selected) {
                if (methods.getInclude(option?.attrValueList, state.suitObj[item.attrKey].selected)) {
                  state.suitObj[item.attrKey].canSelect.push(option);
                }
              }
            });
          }
        });

        selectedKeyList.forEach((item) => {
          if (state.suitObj[item.value].canSelect && state.suitObj[item.value].canSelect.length > 0) {
            state.suitObj[item.value].canSelect.forEach((el) => {
              if (el?.attrValueList && el?.attrValueList.length > 0) {
                el?.attrValueList.forEach((op) => {
                  if (op.attrKey === item.value) {
                    state.suitObj[item.value].canSelectAttrList.push(op.attrValue);
                  }
                });
              }
            });
          }
        });

        let allList = [];
        state.attrKeyList.itemList.forEach((item) => {
          if (item.code !== 'edition') {
            if (state.suitObj[item.value].canSelect && state.suitObj[item.value].canSelect.length > 0) {
              state.suitObj[item.value].canSelect.forEach((el) => {
                allList.push(el);
              });
            }
          }
        });
        let allListObj = methods.getNumCount(allList);
        let linkList = [];
        state.skuList.forEach((item) => {
          // 重复次数跟属性长度一样时说明是三个数组的交集，同时符合所有已选属性
          if (allListObj.get(item) === selectedKeyList.length) {
            linkList.push(item);
          }
        });
        state.selectedSku = linkList[0];
        if (state.selectedSku?.attrValueList && state.selectedSku?.attrValueList.length > 0) {
          state.selectedSku?.attrValueList.forEach((item) => {
            state.indexObj[item.attrKey].index = state.attrSelectObj[item.attrKey].list.indexOf(item.attrValue);
          });
        }
        const currentAlpha2Code = getCurrentAlpha2Code();
        let salePriceInfo = null;
        let depositPriceInfo = null;
        state.selectedSku?.priceInfoList.forEach((item) => {
          if (item.country === currentAlpha2Code && item.priceCategory === 'Sale Price') {
            salePriceInfo = item;
          }
          if (item.country === currentAlpha2Code && item.priceCategory === 'Deposit Price') {
            depositPriceInfo = item;
          }
        });
        state.currency = salePriceInfo?.currency;
        state.salePrice = salePriceInfo?.price ?? 0;
        state.depositPrice = depositPriceInfo?.price ?? 0;
        state.skuIds = state.selectedSku?.skuId;
        state.sellStock = state.selectedSku?.sellStock;
        let firstAttrObj = [];
        state.selectedSku?.attrValueList.forEach((item) => {
          firstAttrObj.push(item.attrValue);
        });
        state.firstAttrObj = firstAttrObj.join(', ');
        let imageList = [];
        let arrItem = {
          media: {
            value: {
              src: state.selectedSku?.skuImage
            }
          }
        };
        imageList.push(arrItem);
        state.imageList = imageList;
      },
      fn1(tempArr) {
        for (let i = 0; i < tempArr.length; i++) {
          for (let j = i + 1; j < tempArr.length; j++) {
            if (tempArr[i].attrKey == tempArr[j].attrKey) {
              tempArr.splice(j, 1);
              j--;
            }
          }
        }
        return tempArr;
      },
      getNumCount(nums) {
        const map = new Map();
        for (let i = 0; i < nums.length; i++) {
          let val = map.get(nums[i]);
          if (!val) {
            map.set(nums[i], 1);
          } else {
            map.set(nums[i], ++val);
          }
        }
        return map;
      },
      getInclude(arr1, arr2) {
        let linkArr1 = JSON.parse(JSON.stringify(arr1));
        let linkArr2 = JSON.parse(JSON.stringify(arr2));

        let _arr1 = [];
        let _arr2 = [];
        linkArr1.forEach((item) => {
          let _item = {};
          _item = {
            attrValue: item.attrValue
          };
          _arr1.push(JSON.stringify(_item));
        });
        linkArr2.forEach((item) => {
          let _item = {};
          _item = {
            attrValue: item.attrValue
          };
          _arr2.push(JSON.stringify(_item));
        });

        return _arr2.every((val) => _arr1.includes(val));
      },
      submit() {
        let url = getBetterUrl(
          appendQuery(shopCheckoutLinkHref, {
            spuBusIds: encodeData(route?.query?.spuBusId),
            skuIds: encodeData(state.skuIds)
          }),
          props.page.itemLanguage,
          true
        );
        window.location = url;
      },
      onscroll() {
        let topElObj = state.topEl?.getBoundingClientRect();
        if (topElObj.height + topElObj.top < 100) {
          state.isFixed = true;
        } else {
          state.isFixed = false;
        }
      },
      iconRemarksHover() {
        state.showFrameSizeExplanation = true;
      },
      iconRemarksOut(event, index) {
        state.showFrameSizeExplanation = false;
      },
      iconRemarksClick() {
        state.showFrameSizeExplanation = true;
      },
      iconRemarksHide() {
        state.showFrameSizeExplanation = false;
      },
      editionIconRemarksHover() {
        state.showStandardEdtionDisclaimer = true;
      },
      editionIconRemarksOut(event, index) {
        state.showStandardEdtionDisclaimer = false;
        let timer = setTimeout(function() {
          state.clickIconEnd = false;
          clearTimeout(timer);
        }, 100);
      },
      editionIconRemarksClick(e) {
        e.stopPropagation();
        state.showStandardEdtionDisclaimer = true;
        let timer = setTimeout(function() {
          state.clickIconEnd = true;
          clearTimeout(timer);
        }, 100);
      },
      editionIconRemarksHide(e) {
        e.stopPropagation();
        state.showStandardEdtionDisclaimer = false;
        let timer = setTimeout(function() {
          state.clickIconEnd = false;
          clearTimeout(timer);
        }, 100);
      },
      scrollToFaq() {
        let topElHeight = state.topEl?.getBoundingClientRect()?.height ? state.topEl?.getBoundingClientRect()?.height : 0;
        let textElHeight = state.textEl?.getBoundingClientRect()?.height ? state.textEl?.getBoundingClientRect()?.height : 0;
        let imageAndTextElHeight = state.imageAndTextEl?.getBoundingClientRect()?.height ? state.imageAndTextEl?.getBoundingClientRect()?.height : 0;
        window.scrollTo({
          top: topElHeight + textElHeight + imageAndTextElHeight,
          behavior: 'smooth'
        });
      },
      resizePageSet() {
        state.faqLtHeight = state.faqLtContentEl.getBoundingClientRect().height;
        let timer = setTimeout(function() {
          clearTimeout(timer);
          if (state.boxEl) {
            state.firstDescElHeight = isMobile() ? state.boxEl.getBoundingClientRect().height + 22 : state.boxEl.getBoundingClientRect().height;
          }
        }, 2000);
      }
    };

    onMounted(() => {
      const [disableSkuCode] = getGlobalConfigs(props.page, 'disableSkuCode');
      state.disableSkuCodeList = disableSkuCode?.value?.split(',');
      methods.getGoodDetail();
      methods.resizePageSet();
      document.addEventListener('scroll', methods.onscroll, true);
      window.addEventListener('resize', methods.resizePageSet);
    });

    onUnmounted(() => {
      document.removeEventListener('scroll', methods.onscroll, true);
      window.removeEventListener('resize', methods.resizePageSet);
    });

    return {
      ...computes,
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-detail {
  width: 100%;
  @include grid-container;
  &__top {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__swiper-box {
      width: 100vw;
      position: relative;
    }
    &__swiper {
      width: 100vw;
      position: relative;
      .swiper-slide {
        background-color: #f2f1f0;
        .e-background-image__fullscreen-btn {
          top: 16px;
          border-color: $white;
          color: $white;
          &:hover {
            border-color: $yellow;
            color: $black;
          }
        }
      }
      &__image {
        width: 100vw;
        height: calc(100vw * 1.25);
      }
      .progress-content {
        width: calc(100vw - 48px);
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 32px;
        margin: auto;
        z-index: 1;
        .progress {
          width: 0;
          height: 1px;
          background: $white;
          transition: all 0.6s;
        }
      }
    }
    &__info {
      width: calc(100% - 48px);
      margin: 24px auto 0;
      .sku-content {
        width: 100%;
        margin-top: 24px;
        .sku-item-content {
          padding-bottom: 24px;
          padding-top: 8px;
        }
        .sku-item {
          width: 100%;
          padding: 0px 0 16px;
          display: flex;
          gap: 5px;
          align-items: center;
          position: relative;
          .sku-item-right {
            display: flex;
            .attr-value {
              font-size: 16px;
              line-height: 24px;
              color: #262626;
              margin-right: 24px;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
          .frame-size-tip-dialog {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 40px;
            background: #f7f6f6;
            color: $black;
            padding: 40px;
            .frame-size-tip-close {
              position: absolute;
              right: 10px;
              top: 10px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .frame-size-tip {
              font-size: 12px;
              line-height: 20px;
              color: $black;
            }
          }
          .attr-key {
            font-size: 16px;
            line-height: 24px;
            color: #262626;
          }
          .icon-content {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
        .attr-list {
          display: flex;
          flex-wrap: wrap;
          gap: 14px;
          .attr-item {
            border: 1px solid $black;
            padding: 16px;
            cursor: pointer;
            &.pointerEvents {
              pointer-events: none;
            }
            &.color {
              border: none;
              padding: 3px;
              border-radius: 100%;
              border: 1px solid transparent;
            }
            &.colorActive {
              border: 1px solid $black;
            }
            &.colorDisabled {
              border: 1px solid #f2f1f0;
              cursor: default;
              display: none;
            }
            &.active {
              color: $white;
              background: $black;
              .desc {
                color: #b8b8b8;
              }
              .price {
                color: $white;
              }
            }
            &.disabled {
              background: #f2f1f0;
              border-color: #f2f1f0;
              cursor: default;
              display: none;
            }
            &.max {
              width: 100%;
            }
            .icon-content {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .pre-content {
              display: flex;
              align-items: center;
              gap: 4px;
              margin-top: 10px;
              position: relative;
              .icon-content {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .edtion-tip-dialog {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 30px;
                background: #f7f6f6;
                color: $black;
                padding: 40px;
                .frame-size-tip-close {
                  position: absolute;
                  right: 10px;
                  top: 10px;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .frame-size-tip {
                  font-size: 12px;
                  line-height: 20px;
                  color: $black;
                }
              }
              .e-icon {
                width: 16px;
                height: 16px;
                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .attr-name {
              font-size: 16px;
              line-height: 24px;
              font-weight: 700;
              min-width: 65px;
              text-align: center;
              &.edtion {
                text-align: left;
              }
            }
            .desc {
              font-size: 12px;
              color: #757575;
              line-height: 20px;
              margin-top: 8px;
            }
            .price {
              color: #2f2f2f;
              font-size: 12px;
              font-weight: 700;
              line-height: 20px;
              margin-top: 8px;
            }
          }
        }
      }
      .product-info {
        width: 100%;
        .new {
          font-size: 12px;
          line-height: 20px;
          color: #727272;
        }
        .name {
          font-size: 32px;
          line-height: 32px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: $black;
          margin-top: 4px;
        }
        .desc {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #313131;
          margin-top: 4px;
        }
        .price {
          font-size: 16px;
          line-height: 24px;
          color: $black;
          margin-top: 24px;
        }
      }
      .e-site-button {
        margin-top: 32px;
        width: 100%;
      }
      &__detail {
        padding: 32px 24px;
        background: #f5f5f5;
        margin-top: 8px;
        .name {
          font-size: 26px;
          line-height: 32px;
          color: $black;
          text-transform: uppercase;
        }
        .attr {
          font-size: 12px;
          text-transform: uppercase;
          line-height: 20px;
          color: $black;
        }
        .total {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: $black;
          margin-top: 18px;
        }
        .reservation {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: $black;
          margin-top: 18px;
        }
        .tip {
          font-size: 12px;
          line-height: 20px;
          color: $black;
        }
        .desc {
          font-size: 12px;
          line-height: 20px;
          color: #757575;
          margin-top: 18px;
        }
      }
      &__more {
        font-size: 12px;
        color: #757575;
        line-height: 20px;
        text-align: center;
        margin-top: 32px;
        display: flex;
        justify-content: center;
        .text-swiper {
          width: calc(100vw - 48px);
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .swiper-slide {
            display: flex;
            justify-content: center;
            color: $black;
            font-size: 12px;
            line-height: 20px;
            user-select: none;
          }
          .swiper-pagination {
            position: absolute;
            height: 1px;
            background: #d2d2d2;
            top: 40px;
            display: flex;
            width: auto;
            .swiper-pagination-bullet {
              display: block;
              width: 60px;
              height: 1px;

              &-active {
                background: $black;
              }
            }
          }
        }
        a {
          color: $black;
          text-decoration-line: underline;
          cursor: pointer;
          margin-left: 5px;
        }
      }
      .scroll-content {
        display: none;
      }
    }
    .desc-content {
      width: 100vw;
      padding: 0 24px;
      margin-bottom: 66px;
      margin-top: 32px;
      .desc-item {
        padding: 16px 0 6px;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e3e3e3;
        &:first-child {
          border-top: 1px solid #e3e3e3;
        }
        p {
          white-space: normal !important;
        }
        .title-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          margin-bottom: 10px;
        }
        &.first-desc {
          border-bottom: none;
          margin-bottom: 56px;
          .title {
            font-size: 26px;
            line-height: 32px;
          }
          .content {
            font-size: 16px;
            p {
              display: block;
              padding-left: 30px;
              position: relative;
              color: #757575 !important;
              font-size: 16px !important;
              line-height: 32px !important;
              b {
                position: absolute;
                background: #757575;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                left: 10px;
                top: 13px;
                font-size: 0;
              }
              strong {
                position: relative;
                left: -20px;
              }
            }
          }
          .e-icon {
            display: none;
          }
          .e-site-button {
            margin-top: 24px;
            width: fit-content;
            padding: 18px 25px;
          }
        }
        &.last-desc {
          border: none;
          padding: 0;
          margin-top: 32px;
          a {
            color: $black;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .title {
          font-size: 16px;
          line-height: 24px;
        }
        .e-icon {
          position: relative;
          transition: all 0.3s;
          &.open {
            transform: rotate(180deg);
            transform-origin: center center;
          }
        }
        .content {
          font-size: 16px;
          line-height: 24px;
          color: #757575;
          overflow: hidden;
          word-wrap: break-word;
          p {
            font-size: 16px !important;
            line-height: 24px !important;
            color: #757575 !important;
            font-family: 'Overpass';
            strong {
              color: $black !important;
            }
            b {
              color: $black !important;
            }
          }
          span {
            font-size: 16px !important;
            line-height: 24px !important;
            color: #757575 !important;
            font-family: 'Overpass';
          }
        }
      }
    }
    .picture-image {
      width: 100vw;
      padding: 0 24px 36px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      &.active {
        margin-bottom: 88px;
      }
      .picture-image-item {
        &__content {
          width: 100%;
          height: calc((100vw - 48px) * 0.663 + 48px);
          display: flex;
          align-items: center;
        }
        &__image {
          width: 100%;
          height: calc((100vw - 48px) * 0.663);
          display: flex;
          align-items: center;
          .e-background-image__img.background-image {
            height: auto;
            > img {
              height: auto;
            }
          }
        }
      }
    }
    .faq-content {
      width: 100vw;
      padding: 80px 24px;
      .faq-lt {
        .title {
          font-size: 26px;
          letter-spacing: 2px;
          line-height: 26px;
          color: $black;
        }
        .desc {
          color: #757575;
          margin-top: 32px;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 32px;
        }
      }
      .faq-rt {
        margin-top: 32px;
        .faq-item {
          padding: 16px 0 6px;
          border-bottom: 1px solid #e3e3e3;
          &.active {
            border-top: 1px solid #e3e3e3;
          }
          .question-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: $black;
            margin-bottom: 10px;
            cursor: pointer;
            .question {
              width: 90%;
            }
            .e-icon {
              position: relative;
              transition: all 0.3s;
              &.open {
                transform: rotate(180deg);
                transform-origin: center center;
              }
            }
          }
          .answer {
            font-size: 16px;
            color: rgb(117, 117, 117);
            overflow: hidden;
          }
        }
      }
    }
    .suction-bottom {
      width: 100vw;
      padding: 16px 24px;
      background: radial-gradient(64.89% 86.06% at 50% 142.79%, rgba(46, 46, 46, 0.64) 0%, rgba(0, 0, 0, 0.8) 100%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      z-index: 100;
      left: 0;
      transform: translateY(100%);
      bottom: 0;
      transition: all 0.6s;
      &.active {
        transform: translateY(0);
      }
      .suction-bottom-lt {
        .name {
          font-size: 12px;
          color: $white;
          line-height: 20px;
          font-weight: 700;
        }
        .price {
          font-size: 12px;
          color: $white;
          line-height: 20px;
        }
      }
    }
  }
  .attr-model-content {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    z-index: 12;
    background: $white;
    top: 0;
    display: none;
    &.openAttr {
      display: flex;
    }
    .attr-model {
      width: 100%;
      height: 100%;
      padding: 0 24px;
      overflow-y: auto;
      .e-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }
      .title {
        font-size: 26px;
        line-height: 32px;
        margin-top: 96px;
      }
      .attr-list {
        width: 100%;
        max-height: calc(100vh - 150px);
        overflow-y: auto;
        &.haveImage {
          display: flex;
          flex-wrap: wrap;
          gap: 9px;
          margin-top: 24px;
        }
        .attr-item {
          padding: 24px 0;
          border-bottom: 1px solid #e3e3e3;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          &.out {
            cursor: default;
          }
          .attr-item__image {
            display: none;
          }
          .attr-item__attr {
            display: none;
          }
          &.haveImage {
            padding: 0;
            display: flex;
            flex-direction: column;
            border: none;
            align-items: flex-end;
            color: #b8b8b8;
            .select-content {
              display: none;
            }
            .stock {
              font-size: 12px;
              line-height: 20px;
              width: 100%;
              background: #f2f1f0;
              text-align: right;
              padding-right: 12px;
              padding-top: 12px;
              color: #b8b8b8;
            }
            .attr-item__image {
              width: calc(50vw - 32px);
              height: calc((50vw - 32px) * 0.89);
              display: block;
            }
            .attr-item__attr {
              width: calc(50vw - 32px);
              text-align: center;
              margin-top: 16px;
              display: block;
              &.out {
                color: #b8b8b8;
              }
            }
          }
          &.selected {
            color: $black;
            .stock {
              color: $black;
            }
          }
          .select-content {
            display: flex;
            align-items: center;
            .select {
              width: 20px;
              height: 20px;
              border-radius: 100%;
              border: 1px solid #b8b8b8;
              margin-right: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              &.disabled {
                background: #f2f1f0;
              }
              &.selected {
                background: $black;
                &:after {
                  content: '';
                  display: block;
                  border-radius: 100%;
                  width: 10px;
                  height: 10px;
                  background: #fff200;
                }
              }
            }
          }
          .stock {
            color: #2f2f2f;
            &.out {
              color: #b8b8b8;
            }
          }
        }
      }
    }
  }
  .image-content {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    background: $white;
    display: none;
    flex-direction: column;
    &.open {
      display: flex;
    }
    .progress-content {
      width: calc(100vw - 52px);
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin: 0 auto;
      .progress {
        height: 1px;
        background: $white;
        transition: all 0.3s;
      }
    }
    .close {
      position: absolute;
      top: 24px;
      right: 21px;
      cursor: pointer;
      z-index: 2;
    }
    .top-swiper-content {
      width: 100%;
      height: calc(100vh - 127px);
      display: flex;
      align-items: center;
      background: #f2f1f0;
    }
    .top-swiper {
      width: 100%;
      height: calc(100vw * 0.86);
      .swiper-slide-image {
        width: 100%;
        height: calc(100vw * 0.86);
      }
      .e-background-image__fullscreen-btn {
        border-color: $black;
        color: $black;
      }
    }
    .bot-swiper-content {
      padding: 26px 0;
      width: 100%;
      .bot-swiper {
        height: 50px;
        width: calc(100% - 52px);
        margin: 0 auto;
        .swiper-slide {
          width: 50px;
          height: 50px;
          margin-right: 16px;
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #f2f1f0;
            opacity: 0.4;
          }
          &.active {
            &:after {
              display: none;
            }
          }
        }
        .swiper-slide-image {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

@include tablet-landscape {
  .c-merchandise-detail {
    &__content {
      position: relative;
      width: 100%;
      .desc-content {
        padding: 56px 108px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        .desc-item,
        .desc-right {
          width: calc(50vw - 54px - 108px);
          &.first-desc {
            border: none;
            position: sticky;
            top: 100px;
            height: 260px;
            padding: 0;
            margin-bottom: 0;
          }
        }
      }
      .picture-image {
        width: 100vw;
        padding: 55px 108px 0;
        .picture-image-item {
          display: flex;
          justify-content: space-between;
          &__0 {
            .picture-image-item__content {
              order: 1;
            }
            .picture-image-item__desc__content {
              order: 2;
            }
          }
          &__1 {
            .picture-image-item__content {
              order: 2;
            }
            .picture-image-item__desc__content {
              order: 1;
            }
          }
          &__content {
            width: calc((100vw - 216px - 128px) * 0.64);
            height: calc((100vw - 216px - 128px) * 0.64 * 0.77);
          }
          &__image {
            width: 100%;
            height: 100%;
          }
          &__desc__content {
            width: calc((100vw - 216px - 128px) * 0.36);
            height: calc((100vw - 216px - 128px) * 0.64 * 0.77);
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              font-size: 16px !important;
              line-height: 24px !important;
              color: #757575 !important;
              font-family: 'Overpass';
              strong {
                color: $black !important;
              }
              b {
                color: $black !important;
              }
            }
            span {
              font-size: 16px !important;
              line-height: 24px !important;
              color: #757575 !important;
              font-family: 'Overpass';
            }
          }
        }
      }
      .faq-content {
        width: 100vw;
        padding: 120px 108px;
        display: flex;
        justify-content: space-between;
        .faq-lt {
          max-width: 33%;
          position: sticky;
          top: 100px;
          .title {
            font-size: 32px;
            line-height: 32px;
          }
          .desc {
            margin-bottom: 24px;
          }
        }
        .faq-rt {
          width: 50%;
          margin-top: 0;
          .faq-item {
            padding: 16px 0 6px;
            border-bottom: 1px solid #e3e3e3;
            overflow: hidden;
            &.active {
              border-top: 1px solid #e3e3e3;
            }
            .question-content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 16px;
              color: $black;
              .e-icon {
                position: relative;
                transition: all 0.3s;
                &.open {
                  transform: rotate(180deg);
                  transform-origin: center center;
                }
              }
            }
            .answer {
              font-size: 16px;
              color: rgb(117, 117, 117);
            }
          }
        }
      }
      .suction-bottom {
        margin-top: 0;
        padding: 16px 108px;
        .suction-bottom-lt {
          .name {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
          }
          .price {
            font-size: 16px;
            color: #b8b8b8;
            line-height: 24px;
          }
          .attr {
            font-size: 16px;
            line-height: 24px;
            color: #b8b8b8;
          }
        }
        .suction-bottom-rt {
          display: flex;
          align-items: center;
          gap: 24px;
          .price {
            font-size: 16px;
            line-height: 24px;
            color: $white;
          }
        }
      }
      &__swiper-box {
        width: calc((100% - 40px) / 2);
        height: calc((50vw - 20px) * 1.08);
        margin-left: 16px;
        top: -20px;
        position: sticky;
      }
      &__swiper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .swiper-wrapper {
          width: 100%;
          display: flex;
          .swiper-slide {
            width: 100%;
            height: calc((50vw - 20px) * 1.08);
            margin-top: 16px;
            &:hover {
              .e-background-image__fullscreen-btn {
                opacity: 1;
              }
            }
            .e-background-image__fullscreen-btn {
              opacity: 0;
              top: 24px;
              right: 24px !important;
              transition: all 0.3s;
            }
          }
        }
        &__image {
          width: 100%;
          height: 100%;
        }
        .progress-content {
          width: calc(100% - 380px);
          bottom: 24px;
        }
      }
      &__bot_swiper {
        width: calc(100% - 48px);
        height: 50px;
        position: absolute;
        bottom: 49px;
        left: 24px;
        overflow: hidden;
        .swiper-wrapper {
          width: 100%;
          display: flex;
          .swiper-slide {
            width: 50px;
            height: 50px;
            &.active {
              .c-merchandise-detail__content__bot_swiper__image {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
        &__image {
          width: 100%;
          height: 100%;
          position: relative;
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
          }
        }
      }
      &__info {
        width: calc((100vw - 40px) / 2);
        display: flex;
        flex-direction: column;
        padding: 32px 108px 32px 104px;
        margin: 0;
        top: 0;
        overflow: hidden;
        .e-site-button {
          margin-top: 48px;
        }
        .sku-content {
          .sku-item {
            border-bottom: none;
            .frame-size-tip-dialog {
              .frame-size-tip-close {
                display: none;
              }
            }
          }
        }
        .product-info {
          margin-top: 0;
          .new {
            margin-bottom: 111px;
          }
          .name {
            margin-bottom: 16px;
          }
          .desc {
            margin-bottom: 24px;
          }
          .price {
            margin-bottom: 12px;
          }
        }
        .sku-content {
          cursor: pointer;
          .attr-list {
            .attr-item {
              .pre-content {
                .edtion-tip-dialog {
                  .frame-size-tip-close {
                    display: none;
                  }
                }
              }
            }
          }
        }
        .e-site-button {
          order: 3;
        }
        &__more {
          order: 4;
          .text-swiper {
            width: 100%;
          }
        }
        .scroll-content {
          order: 5;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 56px;
          cursor: pointer;
        }
        &__scrollText {
          text-align: center;
          margin-right: 8px;
        }
      }
    }
    &__top {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100vw;
      justify-content: space-between;
    }
    .attr-model-content {
      background: rgba(0, 0, 0, 0.6);
      .attr-model-left {
        width: 67vw;
        height: 100%;
      }
      .attr-model {
        width: 33vw;
        padding: 48px 64px;
        background: $white;
        position: absolute;
        top: 0;
        right: 0;
        .e-icon {
          top: 48px;
          right: 64px;
        }
        .title {
          margin-top: 56px;
        }
        .attr-list {
          margin-top: 24px;
          .attr-item {
            &.haveImage {
              width: calc(50% - 5px);
              padding: 0;
              .attr-item__image {
                width: 100%;
                height: calc((33vw - 128px - 9px) / 2 * 0.89);
              }
              .attr-item__attr {
                width: 100%;
                text-align: center;
                margin-top: 16px;
                &.out {
                  color: #b8b8b8;
                }
              }
            }
          }
        }
      }
    }
    .image-content {
      flex-direction: row;
      background: #f2f1f0;
      &.open {
        display: flex;
      }
      .progress-content {
        display: none;
      }
      .close {
        position: absolute;
        top: 24px;
        right: 21px;
      }
      .top-swiper-content {
        width: calc(100vw - 110px);
        height: 100%;
        order: 2;
      }
      .top-swiper {
        height: 100vh;
        width: 100%;
        overflow: hidden;
        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .swiper-slide-image {
          width: 54vw;
          height: 48vw;
        }
      }
      .bot-swiper-content {
        width: 104px;
        height: 100vh;
        padding: 0 0 0 24px;
        order: 1;
        .bot-swiper {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          .swiper-wrapper {
            flex-direction: column;
            height: calc(100vh - 200px);
          }
          .swiper-slide {
            width: 80px;
            height: 80px;
            margin-bottom: 24px;
            margin-right: 0;
          }
          .swiper-slide-image {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
}
</style>
