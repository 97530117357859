<template>
  <div class="c-merchandise-checkout" ref="rootEl">
    <div class="c-merchandise-checkout__wrapper">
      <div class="c-merchandise-checkout__main" v-show="loaded">
        <MerchandiseCheckoutSteps :steps="fields.steps" :current="step" :exitButton="fields.exitButton" v-if="fields.steps?.length" />
        <div class="c-merchandise-checkout__content">
          <MerchandiseCheckoutVerify ref="verifyRef" v-bind="fields.verifySection" :bound="bound" :bind-required="bindRequired" :step="step" :stepCode="stepCode" />
          <MerchandiseCheckoutDelivery ref="deliveryRef" v-bind="fields.deliverySection" :dealerList="dealerList" :changeDealer="changeDealer" :step="step" :stepCode="stepCode" />
          <MerchandiseCheckoutPayment
            ref="paymentRef"
            v-bind="fields.paymentSection"
            :failed="paymentWasFailed"
            :paymentRes="paymentRes"
            :price="$formatShopMoney(depositPrice ? depositPrice : totalPrice, currency)"
            :step="step"
            :stepCode="stepCode"
          />
          <div class="c-merchandise-checkout__actions">
            <SiteButton class="c-merchandise-checkout-life__actions-exit" v-bind="fields.exitButton" v-if="$deviceComputes.largeThanTablet.value" />
            <SiteButton v-bind="fields.continueButton" @click="goNext" v-if="step === 1" />
            <SiteButton v-bind="fields.payButton" @click="goNext" v-if="step === 2" />
          </div>
        </div>
      </div>

      <Transition name="bottom-slide-in">
        <div class="c-merchandise-checkout__aside" v-if="!$deviceComputes.isMobileOrTablet.value || openPriceBreakdown">
          <div class="c-merchandise-checkout__aside-header" v-if="$deviceComputes.isMobileOrTablet.value">
            <Icon name="close" @click="onClosePriceBreakdown" />
          </div>
          <MerchandiseCheckoutSummaryLife :page="page" :fields="fields" :skuList="skuList" :priceDetails="prePrice" :currency="currency" :deliveryDetails="deliveryDetails" v-if="loaded" />
        </div>
      </Transition>
    </div>

    <div class="c-merchandise-checkout-price-mobile" v-if="$deviceComputes.isMobileOrTablet.value">
      <div class="c-merchandise-checkout-life-price-mobile-bottom">
        <div
          class="c-merchandise-checkout-life-price-mobile-bottom-left"
          @click="!largeThanTablet() && toggleDis()"
          @mouseover="largeThanTablet() && showDis()"
          @mouseout="largeThanTablet() && hideDis()"
          :style="{ cursor: showDisclaimer ? 'pointer' : 'default' }"
        >
          <JssRichText class="c-merchandise-checkout-life-price-mobile-bottom-left" :field="fields.summaryLabel" />
          <Icon :field="fields.disclaimerIcon" v-if="!$isNullOrEmpty(fields.lifePriceDisclaimer)" />
          <div class="c-merchandise-checkout-life-price-mobile-bottom-left-des">{{ $t('Including VAT') }}</div>
        </div>
        <div class="c-merchandise-checkout-life-price-mobile-bottom-right">{{ $formatShopMoney(prePrice?.fee ?? 0, currency) }}</div>
        <div
          class="c-merchandise-checkout-life-price-mobile-bottom-disclaimer"
          v-html="$formatString(fields.lifePriceDisclaimer?.fields.text.value, { price: $formatShopMoney(prePrice?.fee ?? 0, currency) })"
          v-if="showDisclaimer"
        />
      </div>
      <div class="c-merchandise-checkout-life-price-mobile-top" @click="onOpenPriceBreakdown">
        <div class="c-merchandise-checkout-life-price-mobile-top-btn">{{ $t('Order summary') }}</div>
        <Icon name="arrow-up" size="tiny" />
      </div>
    </div>

    <NoticeModal v-bind="fields.localeRedirectModal" ref="orderLocaleRedirectModalRef" />
    <GeeCaptcha ref="geeCaptchaRef" />
  </div>
</template>
<script>
import { toRefs, reactive, computed, inject, onMounted, nextTick, provide } from 'vue';
import { useRouter } from 'vue-router';
import { buildAddress, getCurrentAlpha2Code, getGlobalConfigs, scrollToTop, decodeData } from '@/utils/site-utils';
import { appendQuery, getBetterUrl, getQueryStrings } from '@/utils/uri-utils';
import { DELIVERY_TYPE_CODE, WEBSHOP_PAMENT_TYPE_CODE } from '@/utils/constants';
import { equalString } from '@/utils/string-utils';
import { loadStripe } from '@/utils/stripe-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { largeThanTablet } from '@/utils/dom-utils';
import useAppStore from '@/store/appStore';
import { webStorage } from '@/utils/web-storage';
import { S_SHOP_ORDER_INFO } from '@/utils/web-storage-keys';
import { formatDate } from '@/utils/date-utils';
import { bindConsents, countryNeedDoubleOptin } from '@/services/siteService';
import { debounce, merge, difference } from 'lodash';
import api from '@/api';

export default {
  name: 'MerchandiseCheckoutWheels',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const toast = inject('toast');
    const loading = inject('loading');
    const appMethods = inject('appMethods');
    const appStore = useAppStore();
    const router = useRouter();
    const currentAlpha2Code = getCurrentAlpha2Code();
    const [shopConfirmationWheelsLink, disableSkuCode, market] = getGlobalConfigs(props.page, 'shopConfirmationWheelsLink', 'disableSkuCode', 'market');
    let spuBusIds, skuIds, token, clientSecret, redirectStatus, stripeHelper, stripe;
    const shopConfirmationHref = shopConfirmationWheelsLink?.value?.href;
    const state = reactive({
      loaded: false,
      rootEl: null,
      step: 0,
      verifyRef: null,
      deliveryRef: null,
      paymentRef: null,
      orderInfo: null,
      bound: {
        email: false,
        mobile: false
      },
      spuDetails: {},
      skuList: [],
      prePrice: null,
      totalPrice: 0,
      depositPrice: 0,
      openPriceBreakdown: false,
      orderLocaleRedirectModalRef: null,
      paymentWasFailed: false,
      paymentRes: null,
      currency: null,
      showDisclaimer: false,
      deliveryDetails: null,
      geeCaptchaRef: null,
      dealerList: [],
      dealer: null
    });

    const computes = {
      stepCode: computed(() => (state.step < props.fields.steps.length ? props.fields.steps[state.step]?.fields.code.value : '')),
      bindRequired: computed(() => appStore?.hasLoggedIn && !state.bound?.email)
    };

    const _methods = {
      checkPayment: async () => {
        loading.show();
        state.step = 2;
        if (redirectStatus === 'succeeded') {
          await _methods.startListening();
        } else {
          state.paymentWasFailed = true;
          state.loaded = true;
          await loading.hide();
          await nextTick();
          await state.paymentRef.initWidget();
        }
      },
      startListening: async () => {
        const [pk] = getQueryStrings('pk');
        stripeHelper = await loadStripe(props.page, toast);
        stripe = stripeHelper.initSdk({ publicKey: pk });
        await _methods.listening();
      },
      listening: async () => {
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
        switch (paymentIntent.status) {
          case 'succeeded':
            if (!shopConfirmationHref || !state.orderInfo?.orderInfo.orderCode) return;
            window.location = getBetterUrl(
              appendQuery(shopConfirmationHref, {
                orderId: state.orderInfo.orderInfo.orderCode
              }),
              props.page.itemLanguage,
              true
            );
            break;
          case 'processing':
            setTimeout(() => {
              _methods.listening();
            }, 2000);
            break;
          case 'requires_payment_method':
          default:
            state.loaded = true;
            state.paymentWasFailed = true;
            loading.hide();
            break;
        }
      },
      checkBind: async () => {
        const [bindRes] = await api.cidp.bindable();
        if (bindRes) {
          state.bound = bindRes;
        } else {
          state.bound = { phone: true, email: true };
        }
      },
      submitCheckout: async () => {
        loading.show();
        const { email } = state.verifyRef;
        const { deliveryType, dealerFormRef, personalFormRef, addressFormRef, consentFormRef } = state.deliveryRef;
        const [dealerValid, dealerData] = await dealerFormRef.validate();
        const [personalValid, personalData] = await personalFormRef.validate();
        const [billingValid, billingData] = await addressFormRef.validate();
        const [consentValid, consentData] = await consentFormRef.validate();
        const consents = consentFormRef.getVerifiedConsents();
        const deliveryCode = deliveryType?.fields?.code?.value;
        const pickup = equalString(deliveryCode, '51081003');
        if (!dealerValid || !personalValid || !billingValid || !consentValid) {
          loading.hide();
          return null;
        }
        const newsletterConsent = consentData?.termsCondition ? consentFormRef.getItemConsents('termsCondition') : [];
        const bindConsent = difference(consents, newsletterConsent);
        state.dealer = dealerData.dealer;
        const addressDetails = buildAddress(billingData.address, billingData.address2);
        if (isNullOrEmpty(state.orderInfo)) {
          const items = state.skuList.map((s) => ({
            num: 1,
            skuId: s.skuId,
            price: s.itemPrice,
            currency: state.currency
          }));
          let addressId = '';
          const [addRes, addEx] = await api.shop.addAddress(null, {
            mobileAreaCode: personalData.mobile?.area.code,
            mobile: personalData.mobile?.number,
            countryCode: billingData.country.code,
            countryName: billingData.country.text,
            city: billingData.city,
            cityName: billingData.city,
            province: billingData.city,
            provinceName: billingData.city,
            detail: addressDetails,
            postcode: billingData.zipCode,
            contactName: `${personalData.firstName} ${personalData.lastName}`,
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            nationalCode: billingData.country.code,
            addressType: 1
          });
          if (addEx) {
            loading.hide();
            await toast.showEx(addEx);
            return null;
          }
          addressId = addRes.id;
          state.deliveryDetails = {
            pickup,
            dealerData,
            personalData,
            shippingData: billingData
          };
          const body = {
            market: market?.fields?.code?.value,
            businessSource: 1,
            couponFee: state.prePrice.couponFee,
            email: email ?? state.email,
            expressFee: state.prePrice.expressFee,
            fee: state.prePrice.fee,
            force: false,
            itemFee: state.prePrice.itemFee,
            itemPayType: state.spuDetails.payType,
            items,
            payFee: state.prePrice.payFee,
            payType: 51061005,
            pointAmount: state.prePrice.pointAmount,
            pointFee: 0,
            pointRate: 0,
            country: currentAlpha2Code,
            currency: state.currency,
            receiverAddressId: addressId,
            billingAddressId: addressId,
            requestId: Math.round(Math.random() * 100000000),
            phone: `+${personalData.mobile?.area.code} ${personalData.mobile?.number}`,
            userPhone: isNullOrEmpty(appStore?.phone) ? '' : `+${appStore?.mobileAreaCode} ${appStore?.phone}`,
            priceType: 10,
            secKill: false,
            sendType: DELIVERY_TYPE_CODE.storeInstallation,
            serviceFee: state.prePrice.serviceFee,
            source: WEBSHOP_PAMENT_TYPE_CODE.directPayment,
            storeId: dealerData.dealer.id
          };

          const [res, ex] = await api.shop.creatOrder(null, body);
          if (ex) {
            loading.hide();
            await toast.showEx(ex);
            return null;
          }
          state.orderInfo = res;

          bindConsents(bindConsent, {
            email: email ?? state.email,
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            countryRegion: personalData.mobile?.area.data.alpha2Code,
            phone: personalData.mobile?.number,
            storeCode: dealerData.dealer.storeCode
          })
            .then(async () => {
              if (consentData?.termsCondition) {
                const needDoubleOptin = await countryNeedDoubleOptin(currentAlpha2Code);
                const body = {
                  countryRegion: currentAlpha2Code,
                  language: props.page.itemLanguage,
                  needDoubleOptin,
                  termsAndConditions: newsletterConsent.map((revisionNumber) => ({
                    revisionNumber: revisionNumber,
                    title: window.document.title,
                    formUrl: window.location.href,
                    effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
                  })),
                  phone: `${personalData.mobile?.area.code}${personalData.mobile?.number}`,
                  firstName: personalData.firstName,
                  lastName: personalData.lastName,
                  email: email ?? state.email,
                  storeCode: dealerData.dealer.storeCode,
                  zipCode: billingData.zipCode
                };
                merge(body, personalData?.externalData, billingData?.externalData, consentData?.externalData);
                api.lcms.crm.newsletter.subscribe(null, body).catch();
              }
            })
            .catch();

          const orderInfo = merge(body, { orderInfo: res });
          webStorage.set(S_SHOP_ORDER_INFO, orderInfo);
        }
        const [resP, exP] = await api.shop.payMulti(null, {
          orderCode: state.orderInfo.orderCode,
          payChannel: 70
        });
        if (exP) {
          loading.hide();
          await toast.showEx(exP);
          return false;
        }
        state.paymentRes = resP;
        await state.paymentRef.initWidget(resP);
        return true;
      },
      paymentCheckout: async () => {
        state.paymentRef.onPay();
      },
      initPrice: async (storeId = null) => {
        const spuBusId = decodeData(spuBusIds);
        const skuId = decodeData(skuIds);
        state.skuList = [];
        const [spuRes, spuEx] = await api.shop.getGoodsDetails({ spuBusId });
        if (spuEx) {
          await toast.showEx(spuEx);
          loading.hide();
          return null;
        } else if (!spuRes.storeList.length) {
          await toast.showEx(null);
          loading.hide();
          return null;
        }
        state.spuDetails = spuRes;
        const storeCodes = spuRes.storeList.map((i) => i.storeCode).join(',');
        const [storeRes, storeEx] = await api.store.query({ pageNum: '1', pageSize: '999999', storeCodes: storeCodes });
        if (storeEx) {
          await toast.showEx(storeEx);
          loading.hide();
          return null;
        }
        state.dealerList = storeRes;
        const [skuRes, skuEx] = await api.shop.getGoodsBySkuList({ ids: skuId });
        if (skuEx) {
          await toast.showEx(skuEx);
          loading.hide();
          return null;
        } else if (!skuRes.length) {
          await toast.showEx(null);
          loading.hide();
          return null;
        }
        const skuDetails = skuRes[0];
        if (!skuDetails.status) {
          await toast.showEx(null);
          return;
        }
        let depositPrice = null;
        if (spuRes.paymentMethod == 2) {
          depositPrice = skuDetails.priceInfoList.find((p) => equalString(p.priceCategory, 'Deposit Price'));
        }
        const priceInfo = skuDetails.priceInfoList.find((p) => equalString(p.country, currentAlpha2Code) && !equalString(p.priceCategory, 'Deposit Price'));
        skuDetails.paymentMethod = spuRes.paymentMethod;
        skuDetails.carModel = spuRes.carModelList;
        skuDetails.description = skuDetails.specList.map((a) => a.propertyValue).join(', ');
        state.currency = priceInfo?.currency;
        skuDetails.depositPrice = depositPrice?.price ?? 0;
        skuDetails.itemPrice = priceInfo?.price;
        state.skuList.push(skuDetails);
        state.depositPrice += Number(skuDetails.depositPrice);
        state.totalPrice += Number(skuDetails.itemPrice);

        let items = [
          {
            skuId,
            num: 1,
            price: priceInfo?.price
          }
        ];

        const [payRes, payEx] = await api.shop.preCreate(null, {
          sendType: DELIVERY_TYPE_CODE.storeInstallation,
          priceType: '10',
          currency: state.currency,
          country: currentAlpha2Code,
          receiverAddressId: null,
          items,
          secKill: false,
          source: WEBSHOP_PAMENT_TYPE_CODE.directPayment,
          storeId: storeId ?? spuRes.storeList[0].storeId
        });
        if (payEx) {
          if (payEx.code == 60000000) {
            await appMethods.logoutToLogin();
          } else {
            await _methods.initPrice();
          }
        }
        state.prePrice = payRes;
        state.skuList[0] = merge(state.skuList[0], payRes?.items[0]);
      }
    };

    const methods = {
      largeThanTablet,
      onExitClick: () => {
        window.history.back();
      },
      goNext: debounce(async () => {
        if (state.step === 1) {
          const success = await _methods.submitCheckout();
          if (!success) {
            return;
          }
        }
        if (state.step === 2) {
          const success = await _methods.paymentCheckout();
          if (!success) {
            return;
          }
        }
        loading.hide();
        ++state.step;
        await nextTick();
        scrollToTop();
      }, 300),
      onOpenPriceBreakdown: () => {
        state.openPriceBreakdown = true;
      },
      onClosePriceBreakdown: () => {
        state.openPriceBreakdown = false;
      },
      toggleDis: () => {
        state.showDisclaimer = !state.showDisclaimer;
      },
      showDis: () => {
        state.showDisclaimer = true;
      },
      hideDis: () => {
        state.showDisclaimer = false;
      }
    };

    const merchandiseCheckoutMethods = {
      goNext: (options) => {
        if (state.step === 0) {
          state.email = options.email;
        }
        methods.goNext();
      },
      hideLoading: () => {
        loading.hide();
      },
      validateGeeTest() {
        return state.geeCaptchaRef.validate();
      },
      changeDealer: async (dealer) => {
        await _methods.initPrice(dealer.id);
      }
    };
    provide('merchandiseCheckoutMethods', merchandiseCheckoutMethods);
    onMounted(async () => {
      [token, spuBusIds, skuIds, clientSecret, redirectStatus] = getQueryStrings('access_token', 'spuBusIds', 'skuIds', 'payment_intent_client_secret', 'redirect_status');

      if (isNullOrEmpty(spuBusIds) || isNullOrEmpty(skuIds)) {
        window.history.back();
      }
      if (!isNullOrEmpty(clientSecret)) {
        state.orderInfo = webStorage.get(S_SHOP_ORDER_INFO);
        await _methods.checkPayment();
      } else {
        loading.show();
        if (!isNullOrEmpty(token)) {
          const [userInfo] = await api.cidp.user.info(null, null, {
            headers: {
              authorization: token
            }
          });
          if (userInfo) {
            userInfo.token = token;
            appStore.setLoginInfo(userInfo);
          }
        }
        if (appStore?.hasLoggedIn) {
          await _methods.checkBind();
          if (state.bound.email) {
            state.email = appStore.loginInfo.email;
            state.step = 1;
            scrollToTop();
          }
        } else {
          await appMethods.logoutToLogin();
        }
        await state.geeCaptchaRef.init();

        const skuId = decodeData(skuIds);
        const disableSkuList = disableSkuCode?.value.split(',');
        if (disableSkuList.includes(skuId)) {
          await toast.showEx(null);
          setTimeout(() => {
            window.history.back();
          }, 4500);
        } else {
          await _methods.initPrice();
          state.loaded = true;
          loading.hide();
        }
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-checkout {
  &__main {
    padding: 40px grid-width-m(1) 120px grid-width-m(1);
  }
  .e-dropdown__desc {
    a {
      text-decoration: underline;
    }
  }
  &__aside {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: $black;
    color: $white;
    padding-top: #{mobile-header-height()};
    overflow-y: auto;
    &-header {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 100px;
    &-exit {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .e-icon {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .e-site-button {
    &__text {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &-price-mobile {
    width: 100%;
    color: $white;
    padding: 16px 24px 24px;
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    &-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 4px;
      .e-icon {
        display: flex;
        align-items: center;
        > svg {
          padding: 2px;
        }
      }
    }
    &-bottom {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 20px;
      margin-top: 12px;
      &-left {
        font-size: 12px;
        line-height: 24px;
        cursor: pointer;
        > p,
        div,
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }
      }
      &-right {
        font-size: 16px;
        line-height: 24px;
      }
      &-disclaimer {
        position: absolute;
        bottom: 60px;
        padding: 24px;
        background-color: $grey-light;
      }
    }
  }

  @include tablet-landscape {
    &__wrapper {
      @include grid-container;
      height: 100%;
    }
    &__main {
      @include grid-block(1, 12);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
    }
    &__content {
      padding: 0 grid-width(2);
      transition: all 0.4s cubic-bezier(0.355, 0.005, 0.26, 1);
    }
    &__aside {
      @include grid-block(13, 12);
      height: 100vh;
      padding-top: 0;
      position: sticky;
    }
  }
}
</style>
