<script setup>
import { computed, inject, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { getGlobalCfgValues, getGlobalConfig, settingValue } from '@/utils/site-utils';
import { sitecoreComponentProps } from '@/utils/constants';
import api from '@/api';
import { appendQuery, getBetterUrl, getQueryStrings } from '@/utils/uri-utils';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import useAppStore from '@/store/appStore';
import services from '@/services';
import { ifEmpty } from '@/utils/string-utils';
import { useRoute } from 'vue-router';
import { merge } from 'lodash';
import { canUseDOM } from '@/utils/dom-utils';
import utils from '@/utils';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
let observer;
/**@type AppStore*/
const appStore = useAppStore();
const route = useRoute();
const loading = inject('loading');
const toast = inject('toast');
const categories = ref([]);
const activeId = ref(null);
const activeCate = ref();
const headerVisible = ref(false);
const opened = ref(false);
const header = computed(() => utils.sitecore.findComponent(props.page, 'Header', 'jss-header'));
const menuBar = computed(() => utils.sitecore.findComponent(props.page, 'MenuBar', 'jss-header'));
const menuBarSticky = computed(() => !!menuBar.value?.fields?.sticky.value);
const shopCartLink = computed(() => getGlobalConfig(props.page, 'shopCartLink'));
const rootClasses = computed(() => {
  const themeVal = settingValue(props.fields?.theme, 'white');
  return [
    `theme-${themeVal}`,
    {
      floating: props.fields?.floating.value,
      'has-header': !!header.value?.fields,
      'has-menubar': !!menuBar.value?.fields,
      'header-hidden': !headerVisible.value,
      'menubar-sticky': menuBarSticky.value,
      opened: opened.value
    }
  ];
});
const init = async () => {
  const { categoryLink } = props.fields ?? {};
  const [shopClassifyCode, shopListLink] = getGlobalCfgValues(props.page, ['shopClassifyCode', 'shopListLink']);
  let [cateId] = getQueryStrings('categoryId:number');
  const [cateList] = await Promise.all([services.mall.getCategoriesByCode(shopClassifyCode), appStore.loadCartInfo()]);
  for (let cate of cateList) {
    const href = appendQuery(ifEmpty(getBetterUrl(categoryLink?.value?.href), getBetterUrl(shopListLink?.href), route.path), {
      categoryId: cate.id
    });
    cate.link = merge({}, categoryLink, { value: { href } });
  }
  categories.value = cateList;
  if (!isNullOrEmpty(cateId)) {
    activeId.value = cateId;
    activeCate.value = categories.value.find((x) => x.id === activeId.value);
  }
  loading.hide();
};
const checkHeader = (header) => {
  headerVisible.value = !header.classList.contains('hide');
};
const onMutationChange = (records) => {
  if (records?.length > 0 && records[0].attributeName === 'class') {
    const header = records[0].target;
    checkHeader(header);
  }
};
const toggleOpen = () => {
  opened.value = !opened.value;
};
if (canUseDOM()) {
  loading.show();
}
onMounted(() => {
  loading.show();
  const header = document.querySelector('.c-header');
  if (header && props.fields) {
    observer = new MutationObserver(onMutationChange);
    observer.observe(header, {
      attributes: true,
      childList: false
    });
    nextTick().then(() => {
      checkHeader(header);
    });
  }
  init();
});
onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});
</script>

<template>
  <transition name="fade-in">
    <div class="c-shop-bar__mask" v-if="fields && $deviceComputes.isMobileOrTablet.value && opened" @click="toggleOpen" />
  </transition>
  <div class="c-shop-bar" :class="rootClasses" v-if="fields && !appStore.afterSalesInstall">
    <div class="c-shop-bar__selected" @click="toggleOpen">
      <span class="c-shop-bar__selected-name">{{ ifEmpty(activeCate?.name, fields.title.value) }}</span>
      <icon name="down" size="tiny" />
    </div>
    <transition name="options-ani">
      <ul class="c-shop-bar__list" v-if="opened || $deviceComputes.largeThanTablet.value">
        <li v-if="!$isNullOrEmpty(fields.homeLink)">
          <site-link class="c-shop-bar__home" :link="fields.homeLink" />
        </li>
        <li v-for="(category, index) in categories" :class="[{ active: activeId === category.id }]" :key="index">
          <span v-if="activeId === category.id">{{ category.name }}</span>
          <site-link :link="category.link" v-else>{{ category.name }}</site-link>
        </li>
      </ul>
    </transition>
    <site-link class="c-shop-bar__cart" :link="shopCartLink">
      <icon name="cart" />
      <span class="c-shop-bar__sum" v-if="appStore.cartTotalQuantity > 0">{{ appStore.cartTotalQuantity }}</span>
    </site-link>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-shop-bar {
  $c: &;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: $white;
  color: $black;
  position: sticky;
  top: 0;
  z-index: 3;
  font-size: 12px;
  transition: all 600ms cubic-bezier(0, 0, 0.4, 0.2);
  &.floating {
    position: fixed;
    width: 100%;
  }
  &.has-header {
    top: nth($header-height, 1);
    &.header-hidden {
      top: 0;
    }
  }
  &__selected {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background: rgba($black, 0.6);
    backdrop-filter: blur(30px);
  }
  &__list {
    list-style-type: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: $white;
    color: $grey-next;
    min-height: 60vh;
    #{$c}__home {
      &:hover {
        font-weight: 600;
      }
    }
    > li {
      &.active {
        color: $black;
      }
    }
  }
  &__cart {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;
  }
  &__sum {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
    left: 15px;
    height: 12px;
    font-size: 10px;
    line-height: 1;
    min-width: 12px;
    border-radius: 6px;
    background-color: $yellow;
    color: $black;
    padding: 0 2px 0 3px;
    text-align: center;
  }
  &.theme {
    &-white {
      background-color: $white;
      color: $grey-next;
      #{$c}__list {
        background-color: $white;
        color: $grey-next;
        > li {
          &:hover {
            color: $grey-neutral;
          }
          &.active {
            color: $black;
          }
        }
      }
    }
    &-black {
      background-color: $black;
      color: $grey-taubmans;
      #{$c}__list {
        background-color: $black;
        color: $grey-taubmans;
        > li {
          &:hover {
            color: $yellow;
          }
          &.active {
            color: $white;
          }
        }
      }
    }
    &-trans-white {
      background-color: rgba($white, 0.6);
      color: $grey-next;
      #{$c}__list {
        background-color: rgba($white, 0.6);
        color: rgba($black, 0.6);
        > li {
          &:hover {
            color: $grey-neutral;
          }
          &.active {
            color: $black;
          }
        }
      }
    }
    &-trans-black {
      background-color: rgba($black, 0.6);
      color: $grey-taubmans;
      #{$c}__list {
        background-color: rgba($black, 0.6);
        color: $grey-taubmans;
        > li {
          &:hover {
            color: $grey-neutral;
          }
          &.active {
            color: $white;
          }
        }
      }
    }
    &-semi-trans-white {
      background-color: rgba($white, 0.6);
      color: $grey-next;
      backdrop-filter: blur(30px);
      #{$c}__list {
        background-color: rgba($white, 0.6);
        color: $grey-next;
        > li {
          &:hover {
            color: $grey-neutral;
          }
          &.active {
            color: $black;
          }
        }
      }
    }
    &-semi-trans-black {
      background-color: rgba($black, 0.6);
      color: $grey-taubmans;
      backdrop-filter: blur(30px);
      #{$c}__list {
        background-color: rgba($black, 0.6);
        color: $grey-taubmans;
        > li {
          &:hover {
            color: $yellow;
          }
          &.active {
            color: $white;
          }
        }
      }
    }
  }
  &.opened {
    #{$c}__selected {
      .e-icon {
        transform: rotate(180deg);
      }
    }
    &.theme {
      &-trans-white {
        background-color: $white;
        transition-duration: 10ms;
        #{$c}__list {
          background-color: $white;
        }
      }
      &-trans-black {
        background-color: $black;
        transition-duration: 10ms;
        #{$c}__list {
          background-color: $black;
        }
      }
      &-semi-trans-white {
        background-color: $white;
        transition-duration: 10ms;
        #{$c}__list {
          background-color: $white;
        }
      }
      &-semi-trans-black {
        background-color: $black;
        transition-duration: 10ms;
        #{$c}__list {
          background-color: $black;
        }
      }
    }
  }
  @include tablet-landscape {
    padding: 18px grid-width(1);
    &.has-header {
      top: nth($header-height, 2);
      &.header-hidden {
        top: 0;
      }
    }
    &__selected {
      display: none;
    }
    &__list {
      position: static;
      flex-direction: row;
      gap: grid-width(1);
      padding: 0;
      background-color: transparent !important;
      min-height: auto;
    }
  }
  @include desktop {
    &.has-header {
      top: nth($header-height, 3);
      &.header-hidden {
        top: 0;
      }
    }
  }
}
</style>
