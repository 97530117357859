<template>
  <personal-center>
    <div class="c-my-order-detail"></div>
  </personal-center>
</template>

<script>
export default {
  name: 'MyOrderDetail'
};
</script>

<style lang="scss"></style>
