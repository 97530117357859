<script setup>
import { sitecoreComponentProps } from '@/utils/constants';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { isOnApp, settingValue } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { useRoute } from 'vue-router';
import { isAbsoluteUri } from '@/utils/uri-utils';
import { debounce } from 'lodash';
import utils from '@/utils';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
let observer;
const route = useRoute();
const headerVisible = ref(true);
const opened = ref(false);
const scrolled = ref(false);
const activeItem = ref(null);
const header = computed(() => {
  return utils.sitecore.findComponent(props.page, 'Header', 'jss-header');
});
const rootClasses = computed(() => {
  const { theme, scrollingTheme, sticky } = props.fields ?? {};
  const themeVal = settingValue(theme, 'opaque-dark');
  const scrollingThemeVal = settingValue(scrollingTheme, 'black');
  return [
    {
      [`theme-${themeVal}`]: !isNullOrEmpty(themeVal),
      [`scrolling-theme-${scrollingThemeVal}`]: !isNullOrEmpty(scrollingThemeVal),
      'has-header': !!header.value?.fields,
      'header-hidden': !headerVisible.value,
      scroll: scrolled.value,
      open: opened.value,
      sticky: sticky.value,
      'header-theme-transparent': header.value?.fields?.theme?.fields?.phrase?.value === 'transparent'
    }
  ];
});
const button = computed(() => {
  if (!props.fields || !props.fields?.button?.fields) return null;
  const { theme } = props.fields ?? {};
  const { theme: buttonTheme } = props.fields?.button?.fields ?? {};
  const themeVal = settingValue(theme, 'opaque-dark');
  let buttonThemeVal = settingValue(buttonTheme, 'opaque-dark');
  if (['opaque-dark', 'semi-transparent-dark'].includes(themeVal) && buttonThemeVal === 'dark') {
    buttonThemeVal = 'light';
  }
  if (['opaque-light', 'semi-transparent-light'].includes(themeVal) && buttonThemeVal === 'light') {
    buttonThemeVal = 'dark';
  }
  return {
    fields: {
      ...props.fields?.button?.fields,
      theme: {
        fields: {
          ...(buttonTheme?.fields ?? {}),
          phrase: {
            value: buttonThemeVal
          }
        }
      }
    }
  };
});
const getActiveItem = () => {
  if (props.fields.activeMenu) {
    return props.fields?.items.find((x) => x.id === props.fields.activeMenu.id);
  }
  const { sitecoreRoute } = route.params;
  if (isNullOrEmpty(sitecoreRoute)) return props.fields?.items.find((x) => x.fields?.link.value?.href === '/');
  return props.fields?.items.find((x) => {
    const { href } = x.fields?.link.value ?? {};
    if (isAbsoluteUri(href)) {
      const url = new URL(href);
      if (url.host !== global?.location?.host) return false;
      return new RegExp(route.params.sitecoreRoute, 'gi').test(url.pathname);
    }
    return new RegExp(route.params.sitecoreRoute.join('/'), 'gi').test(x.fields?.link.value?.href);
  });
};
const onMutationChange = (records) => {
  if (records?.length > 0 && records[0].attributeName === 'class') {
    const header = records[0].target;
    headerVisible.value = !header.classList.contains('hide');
  }
};
const onAnchorClick = (item) => {
  opened.value = false;
  const anchorId = item.fields.anchorId.value;
  if (anchorId) {
    const target = document.getElementById(anchorId);
    target.scrollIntoView({ behavior: 'smooth' });
    activeItem.value = item;
    return false;
  }
};
const onLinkClick = () => {
  opened.value = false;
};
const toggle = () => {
  let dom = document.querySelector('.c-menu-bar');
  if (!opened.value) {
    dom.style.transition = 'none';
  } else {
    dom.style.transition = 'all 600ms cubic-bezier(0, 0, 0.4, 0.2)';
  }
  opened.value = !opened.value;
};
const onScroll = debounce((e) => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  scrolled.value = scrollTop !== 0;
  let _activeItem = null;
  if (!props.fields?.items) return;
  for (let item of props.fields.items) {
    const { anchorId } = item.fields || {};
    if (anchorId.value) {
      const anchor = document.getElementById(anchorId.value);
      if (anchor) {
        const anchorRect = anchor.getBoundingClientRect();
        if (anchorRect.top <= window.innerHeight && anchorRect.bottom > 0) {
          _activeItem = item;
        }
      }
    }
  }
  activeItem.value = _activeItem ?? getActiveItem();
}, 100);
onMounted(() => {
  if (isOnApp(route)) return;
  window.addEventListener('scroll', onScroll, { passive: false });
  activeItem.value = getActiveItem();
  const header = document.querySelector('.c-header');
  if (header && props.fields) {
    observer = new MutationObserver(onMutationChange);
    observer.observe(header, {
      attributes: true,
      childList: false
    });
  }
});
onBeforeUnmount(() => {
  if (isOnApp(route)) return;
  window.removeEventListener('scroll', onScroll);
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});
</script>

<template>
  <div class="c-menu-bar" :class="rootClasses" v-if="fields && !$isOnApp($route)">
    <div class="c-menu-bar__main">
      <div class="c-menu-bar__selection" @click="toggle">
        <div class="c-menu-bar__selected">{{ activeItem?.fields.text.value }}</div>
        <icon name="caret-down" size="tiny" :class="opened ? 'open' : ''" />
      </div>
      <transition name="options-ani">
        <div class="c-menu-bar__menus" v-show="opened || !$deviceComputes.isMobileOrTablet.value">
          <template v-for="item in fields.items" :key="item.id">
            <div class="c-menu-bar__menu" :class="[{ active: activeItem === item }]" v-if="item.fields.anchorId.value" @click="onAnchorClick(item)">
              {{ item.fields.text.value }}
            </div>
            <site-link
              class="c-menu-bar__menu"
              :class="[{ active: activeItem === item }]"
              :link="{ value: { ...item.fields.link.value, text: item.fields.text.value } }"
              @click="onLinkClick"
              v-else-if="item.fields.link.value"
            />
          </template>
        </div>
      </transition>
    </div>
    <menu-button class="c-menu-bar__button" v-bind="button" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-menu-bar {
  $c: &;
  width: 100%;
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 600ms cubic-bezier(0, 0, 0.4, 0.2);
  position: relative;
  &.sticky {
    position: fixed;
    top: 0;
    z-index: 10;
    &.has-header {
      top: nth($header-height, 1) - 2px;
      &.header-hidden {
        top: 0;
      }
    }
  }
  &.header-theme-transparent {
    &.has-header {
      top: nth($header-height, 1) - 2px;
      z-index: 2;
    }
  }
  &.theme {
    &-opaque-dark {
      background: $black;
      color: $white;
      #{$c}__menus {
        background: $black;
      }
      #{$c}__menu {
        &,
        &:active,
        &:visited {
          color: $grey-taubmans;
        }
        &.active {
          color: $white;
          &:hover {
            color: $white;
          }
        }
        &:hover {
          color: #fff200;
        }
      }
    }
    &-opaque-light {
      background: $white;
      color: $black;
      #{$c}__menus {
        background: $white;
      }
      #{$c}__menu {
        &.active {
          color: $black;
        }
        &:hover {
          color: $black;
        }
      }
    }
    &-transparent-dark {
      background: transparent;
      border-top: 1px solid #b8b8b8;
      color: $black;
      &.scroll {
        &.scrolling-theme {
          &-black {
            background: $black;
            color: $white;
            #{$c}__menus {
              background: $black;
            }
            #{$c}__menu {
              &,
              &:active,
              &:visited {
                color: $grey-taubmans;
              }
              &.active {
                color: $white;
                &:hover {
                  color: $white;
                }
              }
              &:hover {
                color: #fff200;
              }
            }
          }
          &-white {
            background: $white;
            color: $black;
            #{$c}__menus {
              background: $white;
            }
            #{$c}__menu {
              &.active {
                color: $black;
              }
              &:hover {
                color: $black;
              }
            }
          }
        }
      }
      #{$c}__menus {
        background: transparent;
      }
      #{$c}__menu {
        &,
        &:active,
        &:visited {
          color: $grey-next;
        }
        &.active {
          color: $black;
        }
        &:hover {
          color: $black;
        }
      }
    }
    &-transparent-light {
      background: transparent;
      border-top: 1px solid #757575;
      color: $white;
      &.scroll {
        &.scrolling-theme {
          &-black {
            background: $black;
            color: $white;
            #{$c}__menus {
              background: $black;
            }
            #{$c}__menu {
              &,
              &:active,
              &:visited {
                color: $grey-taubmans;
              }
              &.active {
                color: $white;
                &:hover {
                  color: $white;
                }
              }
              &:hover {
                color: #fff200;
              }
            }
          }
          &-white {
            background: $white;
            color: $black;
            #{$c}__menus {
              background: $white;
            }
            #{$c}__menu {
              &.active {
                color: $black;
              }
              &:hover {
                color: $black;
              }
            }
          }
        }
      }
      #{$c}__menus {
        background: transparent;
      }
      #{$c}__menu {
        &,
        &:active,
        &:visited {
          color: $grey-taubmans;
        }
        &.active {
          color: $white;
          &:hover {
            color: $white;
          }
        }
        &:hover {
          color: #fff200;
        }
      }
    }
    &-semi-transparent-dark {
      background: rgba($black, 0.5);
      backdrop-filter: blur(30px);
      color: $white;
      #{$c}__menus {
        background: rgba($black, 0.5);
        backdrop-filter: blur(30px);
      }
      #{$c}__menu {
        &,
        &:active,
        &:visited {
          color: $grey-taubmans;
        }
        &.active {
          color: $white;
          &:hover {
            color: $white;
          }
        }
        &:hover {
          color: #fff200;
        }
      }
    }
    &-semi-transparent-light {
      background: rgba($white, 0.5);
      backdrop-filter: blur(30px);
      color: $black;
      #{$c}__menus {
        background: rgba($white, 0.5);
        backdrop-filter: blur(30px);
      }
      #{$c}__menu {
        &.active {
          color: $black;
        }
        &:hover {
          color: $black;
        }
      }
    }
  }
  &.scrolling-theme {
    &-black {
      &.open {
        background: $black;
        &.has-header {
          color: $white;
          #{$c}__menu {
            &.active {
              color: $white;
            }
          }
        }
      }
      #{$c}__menus {
        background: $black;
      }
      &.has-header {
        #{$c}__menus {
          // transition: background 600ms cubic-bezier(0, 0, 0.4, 0.2);
        }
        &.header-hidden {
          background: $black;
          color: $white;
          #{$c}__menus {
            background: $black;
          }
          #{$c}__menu {
            &,
            &:active,
            &:visited {
              color: $grey-taubmans;
            }
            &.active {
              color: $white;
              &:hover {
                color: $white;
              }
            }
            &:hover {
              color: #fff200;
            }
          }
        }
      }
    }
    &-white {
      &.open {
        background: $white;
        &.has-header {
          color: $black;
          #{$c}__menu {
            &.active {
              color: $black !important;
            }
          }
        }
      }
      #{$c}__menus {
        background: $white;
      }
      &.has-header {
        #{$c}__menus {
          // transition: background 600ms cubic-bezier(0, 0, 0.4, 0.2);
        }
        &.header-hidden {
          background: $white;
          color: $black;
          #{$c}__menus {
            background: $white;
          }
          #{$c}__menu {
            &.active {
              color: $black;
            }
            &:hover {
              color: $black;
            }
          }
        }
      }
    }
  }
  &__menus {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 24px;
    font-size: 12px;
  }
  &__menu {
    cursor: pointer;
    &,
    &:active,
    &:visited {
      color: $grey-next;
    }
    &.active {
      color: $white;
    }
  }
  &__selection {
    display: flex;
    gap: 8px;
    .e-icon {
      position: relative;
      top: -2px;
      transition: transform 0.3s;
      transform-origin: center center;
      &.open {
        transform: rotate(180deg);
        top: 0px;
      }
    }
  }
  &__selected {
    font-size: 12px;
  }
  @include tablet-landscape {
    padding: 8px grid-width(1);
    &.sticky {
      &.has-header {
        top: nth($header-height, 2) - 2px;
      }
    }
    &.header-theme-transparent {
      &.has-header {
        top: nth($header-height, 2) - 2px;
      }
    }

    &.theme {
      &-opaque-dark {
        #{$c}__menus {
          background: transparent;
        }
      }
      &-opaque-light {
        #{$c}__menus {
          background: transparent;
        }
      }
      &-semi-transparent-dark {
        #{$c}__menus {
          background: transparent;
          backdrop-filter: none;
        }
      }
      &-semi-transparent-light {
        #{$c}__menus {
          background: transparent;
          backdrop-filter: none;
        }
      }
    }
    &.scrolling-theme {
      &-black {
        #{$c}__menus {
          background: transparent;
        }
        &.has-header {
          &.header-hidden {
            #{$c}__menus {
              background: transparent;
            }
          }
        }
      }
      &-white {
        #{$c}__menus {
          background: transparent;
        }
        &.has-header {
          &.header-hidden {
            #{$c}__menus {
              background: transparent;
            }
          }
        }
      }
    }
    &__selection {
      display: none;
    }
    &__menus {
      position: static;
      padding: 0;
      gap: 56px;
      flex-direction: row;
      align-items: center;
      background: transparent;
    }
  }
  @include desktop {
    &.sticky {
      &.has-header {
        top: nth($header-height, 3) - 2px;
      }
    }
    &.header-theme-transparent {
      &.has-header {
        top: nth($header-height, 3) - 2px;
      }
    }
  }
}
</style>
