<template>
  <overall-settings v-bind="$props">
    <div class="c-merchandise-return-send">
      <div class="c-merchandise-return-send__content">
        <BackgroundImage class="c-merchandise-return-send__content__image" :image="fields.image" />
        <div class="c-merchandise-return-send__content__main">
          <div class="c-merchandise-return-send__content__main__back" @click="goOrderDetail">
            <Icon class="c-merchandise-return-send__content__main__back__icon" name="back" />
            <div class="c-merchandise-return-send__content__main__back__label" v-html="$tu('Order details')" />
          </div>
          <JssRichText class="c-merchandise-return-send__content__main__title" :field="fields.title" />
          <JssRichText class="c-merchandise-return-send__content__main__description" :field="fields.description" />
          <div class="c-merchandise-return-send__content__main__tab" v-html="$tu('Shipping address')" />
          <JssRichText class="c-merchandise-return-send__content__main__addressdescription" :field="fields.addressDescription" />
          <div class="c-merchandise-return-send__content__main__address">
            <JssRichText class="c-merchandise-return-send__content__main__address__title pc" :field="fields.addressPcTitle" />
            <JssRichText class="c-merchandise-return-send__content__main__address__title mobile" :field="fields.addressMobileTitle" />
            <div class="c-merchandise-return-send__content__main__address__item">
              <div class="c-merchandise-return-send__content__main__address__item__label" v-html="$t('Address')" />
              <div class="c-merchandise-return-send__content__main__address__item__value">{{ companyReceiverAddress }}</div>
            </div>
            <div class="c-merchandise-return-send__content__main__address__item">
              <div class="c-merchandise-return-send__content__main__address__item__label" v-html="$t('Name')" />
              <div class="c-merchandise-return-send__content__main__address__item__value">{{ companyReceiverName }}</div>
            </div>
            <div class="c-merchandise-return-send__content__main__address__item">
              <div class="c-merchandise-return-send__content__main__address__item__label" v-html="$t('Phone number')" />
              <div class="c-merchandise-return-send__content__main__address__item__value">{{ companyReceiverPhone }}</div>
            </div>
          </div>
          <JssRichText class="c-merchandise-return-send__content__main__tab" :field="fields.formTitle" />
          <dynamic-form :form="fields.form" ref="formRef" />
          <SiteButton v-bind="fields.button" @click="submit" />
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { computed, onMounted, reactive, toRefs, inject } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { useRoute, useRouter } from 'vue-router';
import { redirectTo, appendQuery, getBetterUrl } from '@/utils/uri-utils';
import api from '@/api';

export default {
  name: 'MerchandiseReturnSend',
  props: {
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {
    const loading = inject('loading');
    const toast = inject('toast');
    const appMethods = inject('appMethods');
    const router = useRouter();
    const route = useRoute();
    const { refundOrderCode } = route?.query || {};
    const { orderCode } = route?.query || {};
    const state = reactive({
      formRef: null,
      companyReceiverAddress: null,
      companyReceiverName: null,
      companyReceiverPhone: null
    });
    const computes = {
      lang: computed(() => router.currentRoute.value?.params.lang ?? {})
    };
    const methods = {
      async submit() {
        loading.show();
        const [valid, formData] = await state.formRef.validate();
        if (valid) {
          const [res, ex] = await api.shop.updateExpressByCustomer(null, {
            refundOrderCode: refundOrderCode,
            refundExpressCode: formData.refundExpressCode,
            refundExpressCompany: formData.refundExpressCompany
          });
          if (ex) {
            await toast.showEx(ex);
            if (ex.code === 60000000) {
              await appMethods.logoutToLogin();
            }
            return;
          }
          loading.hide();
          methods.goOrderDetail();
        }
      },
      async getRefundAddress() {
        const [res, ex] = await api.shop.getRefundAddress(null, {
          spuType: 51121001
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        if (res) {
          state.companyReceiverAddress = res.companyReceiverAddress;
          state.companyReceiverName = res.companyReceiverName;
          state.companyReceiverPhone = res.companyReceiverPhone;
        }
        loading.hide();
      },
      goOrderDetail() {
        let url = getBetterUrl(
          appendQuery(props.fields.orderDetailLink?.value?.href, {
            orderCode: orderCode
          }),
          props.page.itemLanguage,
          true
        );
        window.location = url;
      }
    };
    onMounted(() => {
      methods.getRefundAddress();
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-merchandise-return-send {
  $c: &;
  @include grid-container;
  &__content {
    @include grid-block(1, 12);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    &__image {
      width: 100%;
      height: 67vw;
    }
    &__main {
      padding: 0 grid-width(2);
      &__back {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-top: 26px;
        cursor: pointer;
        &__icon {
          width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        &__label {
          font-size: 12px;
          line-height: 20px;
          color: #4f4f4f;
        }
      }
      &__title {
        font-size: 32px;
        line-height: 1;
        letter-spacing: 2px;
        margin-top: 48px;
        color: $black;
      }
      &__description {
        font-size: 16px;
        line-height: 24px;
        color: #757575;
        margin-top: 24px;
      }
      &__tab {
        font-size: 26px;
        line-height: 32px;
        color: $black;
        margin-top: 64px;
      }
      &__addressdescription {
        font-size: 16px;
        line-height: 24px;
        color: #757575;
        margin-top: 16px;
      }
      &__address {
        padding: 35px 24px;
        border: 1px solid #e3e3e3;
        margin-top: 32px;
        &__title {
          font-size: 26px;
          line-height: 32px;
          color: $black;
          letter-spacing: 2px;
          &.pc {
            display: none;
          }
        }
        &__item {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-top: 20px;
          &__label {
            font-size: 16px;
            line-height: 24px;
            color: #757575;
          }
          &__value {
            font-size: 16px;
            line-height: 24px;
            color: $black;
          }
        }
      }
      .e-site-button {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 90px;
      }
    }
  }
  @include tablet-landscape {
    &__content {
      @include grid-block(1, 24);
      flex-direction: row;
      &__image {
        width: 50%;
        height: 100vh;
        order: 2;
        position: sticky !important;
        top: 0;
      }
      &__main {
        order: 1;
        width: 50%;
        &__back {
          margin-top: 35px;
          &__label {
            font-size: 16px;
          }
        }
        &__title {
          font-size: 56px;
          margin-top: 60px;
          letter-spacing: 3px;
        }
        &__description {
          font-size: 16px;
          margin-top: 32px;
        }
        &__tab {
          font-size: 24px;
          line-height: 24px;
          margin-top: 40px;
        }
        &__address {
          padding: 24px;
          margin-top: 24px;
          &__title {
            font-size: 24px;
            line-height: 24px;
            &.mobile {
              display: none;
            }
            &.pc {
              display: block;
            }
          }
          &__item {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 24px;
            &__value {
              text-align: right;
              width: 70%;
            }
          }
        }
      }
    }
  }
}
</style>
